import React from 'react'
import { Field } from 'react-final-form'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'

export default function EditorSelect({ name, label, options, validate, ...rest }) {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <FormControl>
          <InputLabel
            shrink={false}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
              fontSize: '14px',
              fontWeight: 400,
              padding: '0px 12px',
              top: input.value ? '1px' : '35%',
              color: '#76767F',
              transform: input.value ? 'translateY(0)' : 'translateY(-30%)',
              '&.Mui-focused': {
                top: '1px',
                transition: 'smooth',
                background: 'transparent',
                transform: 'translateY(0)',
                color: '#76767F',
                fontSize: '14px'
              }
            }}
            id={name}
          >
            {label}
          </InputLabel>
          <Select
            variant={'standard'}
            labelId={name}
            id={name}
            {...input}
            {...rest}
            error={meta.error && meta.touched}
            sx={{
              '& .MuiInputBase-root': {
                display: 'flex',
                height: 25,
                padding: '0 16px'
              },
              '& .MuiInputBase-input': {
                marginTop: '1px',
                padding: '0px'
              }
            }}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {meta.error && meta.touched && <FormHelperText sx={{ color: '#D32F2F' }}>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  )
}
