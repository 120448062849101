import React from 'react'

const UserAvatar = ({ img, firstName, lastName, style, onClick = () => {} }) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: '40px',
        height: '40px',
        display: 'flex',
        borderRadius: '20px',
        overflow: 'hidden',
        backgroundColor: style?.background ? style?.background : '#F4F5F9',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '16px',
        flexShrink: 0,
        ...style
      }}
    >
      {img ? (
        <img width={'100%'} src={img} alt='' />
      ) : (
        <div
          style={{
            fontWeight: '600',
            fontSize: style?.fontSize || '16px',
            lineHeight: '24px',
            textAlign: 'center',
            color: '#5F5F68',
            cursor: 'pointer'
          }}
        >
          {firstName && lastName
            ? firstName?.slice(0, 1) + lastName?.slice(0, 1)
            : !firstName && !lastName
              ? 'N/A'
              : (firstName ? firstName?.slice(0, 1) : '') + (lastName ? lastName?.slice(0, 1) : '')}
        </div>
      )}
    </div>
  )
}

export default UserAvatar
