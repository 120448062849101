import React, { useContext } from 'react'
import { Box } from '@mui/material'
import ChooseTypeCard from './ChooseTypeCard'
import { PageNumberContext } from './PageNumberContext'

export default function FirstPageForm({ input }) {
  const { setPage } = useContext(PageNumberContext)
  const { onChange } = input

  const config = [
    {
      type: 'AUTOMATED_EMAIL',
      title: 'AI-Generated Email',
      description:
        'Cyberlift will automatically generate a unique and personalized template for each employee using AI',
      //infoText: 'Automatic Email: Our AI engine will create unique phishing simulations tailored to each employee.',
      isAI: true,
      onClick: () => {
        onChange('AUTOMATED_EMAIL')
        setPage(2)
      }
    },
    {
      type: 'MANUAL_EMAIL',
      title: 'Custom Email',
      description: 'Select a template from the database or create your own email from scratch to suit your needs',
      //infoText: 'Personalized Emails (AI Powered): Choose or create personalized email templates to send phishing simulations automatically. ',
      isAI: true,
      onClick: () => {
        onChange('MANUAL_EMAIL')
        setPage(2)
      }
    },
    {
      type: 'SMS',
      title: 'SMS',
      description: 'Send text messages automatically',
      infoText: 'SMS: Create and send SMS phishing simulations with realistic links to test mobile device awareness.',
      isAI: false,
      isCanEnabled: false,
      onClick: () => {
        onChange('SMS')
        setPage(2)
      }
    },
    {
      type: 'SLACK',
      title: 'Slack',
      description: 'Post messages to Slack channels',
      infoText:
        'Slack: Post simulated phishing messages directly to your Slack channels to gauge team response and awareness.',
      isAI: false,
      isCanEnabled: false,
      onClick: () => {
        onChange('SLACK')
        setPage(2)
      }
    },
    {
      type: 'WHATSAPP',
      title: 'Whatsapp',
      description: 'Automate WhatsApp messages',
      infoText:
        'WhatsApp: Set up and deliver WhatsApp-based phishing messages to engage in realistic social engineering scenarios.',
      isAI: false,
      isCanEnabled: true,
      onClick: () => {},
      isDisabled: true
    },
    {
      type: 'PHONE',
      title: 'Phone Call',
      description: 'Automate phone call setups',
      infoText:
        'Phone Call: Automate realistic phone call simulations to test your team’s resistance to vishing attacks.',
      isAI: false,
      isCanEnabled: true,
      onClick: () => {},
      isDisabled: true
    }
  ]

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} flexWrap={'wrap'} rowGap={'12px'}>
      {config.map((item, index) => (
        <ChooseTypeCard key={index} {...item} />
      ))}
    </Box>
  )
}
