import React, { useState } from 'react'
import { Box } from '@mui/material'
import ManualSimulationTopBar from './ManualSimulationTopBar'
import ManualSimulationTable from './SimulationTable/ManualSimulationTable'
import { QueryParamsContext } from './QueryParamsContext'

export default function ManualSimulationV2() {
  const [params, setParams] = useState({
    searchText: '',
    statuses: ['IN_PROGRESS', 'COMPLETED', 'ARCHIVED']
  })

  return (
    <QueryParamsContext.Provider
      value={{
        params,
        setParams
      }}
    >
      <Box bgcolor={'#F8F8FB'} p={3} pb={0} width={'100%'}>
        <ManualSimulationTopBar />
        <ManualSimulationTable />
      </Box>
    </QueryParamsContext.Provider>
  )
}
