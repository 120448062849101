import React from 'react'
import { ReactComponent as ManualEmailIcon } from '../../../../common/icons/articleIcon.svg'
import { ReactComponent as SMSIcon } from '../../../../common/icons/SMSIcon.svg'
import { ReactComponent as WhatsUpIcon } from '../../../../common/icons/whatsUpIcon.svg'
import { ReactComponent as SlackIcon } from '../../../../common/icons/slackIcon.svg'
import { ReactComponent as PhoneIcon } from '../../../../common/icons/phoneIcon.svg'
import { ReactComponent as EmailIcon } from '../../../../common/icons/envelopIcon.svg'
import { Box, Step, StepLabel } from '@mui/material'

const iconMap = {
  AUTOMATED_EMAIL: EmailIcon,
  MANUAL_EMAIL: ManualEmailIcon,
  SMS: SMSIcon,
  WHATSAPP: WhatsUpIcon,
  SLACK: SlackIcon,
  PHONE: PhoneIcon
}

export default function CustomStep({ type, stepNumber, ...props }) {
  const Icon = iconMap[type] || ManualEmailIcon

  return (
    <Step {...props}>
      <StepLabel
        sx={{
          '& .MuiStepLabel-label': {
            fontSize: 14,
            fontWeight: 600,
            fontFamily: 'Plus Jakarta Sans'
          }
        }}
        StepIconComponent={() => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '12px,',
              '& svg': {
                width: 24,
                height: 24
              }
            }}
          >
            <Box
              sx={{
                width: 24,
                height: 24,
                textAlign: 'center',
                borderRadius: '50%',
                backgroundColor: '#F0E7F9'
              }}
            >
              {stepNumber}
            </Box>
            <Icon />
          </Box>
        )}
      ></StepLabel>
    </Step>
  )
}
