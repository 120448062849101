import React, { useState } from 'react'

import { ReactComponent as AddIcon } from '../../../../common/icons/addIcon.svg'
import { Box, IconButton, Step, StepLabel, Stepper, Typography } from '@mui/material'
import CustomStep from './CustomStep'
import StepFormModal from '../StepFormModal'

export default function DynamicStepper({ status, steps }) {
  const [open, setOpen] = useState(false)

  function handleClose() {
    setOpen(false)
  }
  function handleAddStep() {
    setOpen(true)
  }
  return (
    <Box width={'30px'}>
      <Stepper
        orientation='vertical'
        sx={{
          '& .MuiStepConnector-line': {
            borderColor: '#E7E8EA',
            paddingRight: '18px',
            minHeight: 35
          }
        }}
      >
        {steps.map((step, index) => (
          <CustomStep key={step.id} data={step} type={step.type} stepNumber={index + 1} active />
        ))}
        {status === 'IN_PROGRESS' && (
          <Step
            sx={{
              width: '120px'
            }}
          >
            <StepLabel
              StepIconComponent={() => (
                <Box
                  sx={{
                    display: 'flex',
                    paddingLeft: '12px,',
                    alignItems: 'center',
                    '& svg': {
                      width: 24,
                      height: 24
                    }
                  }}
                >
                  <IconButton color='primary' onClick={handleAddStep}>
                    <AddIcon fontSize='large' />
                    <Typography
                      pl={'12px'}
                      fontSize={'14px'}
                      fontWeight={600}
                      fontFamily={'Plus Jakarta Sans'}
                      color={'#6123FF'}
                    >
                      Add step
                    </Typography>
                  </IconButton>
                </Box>
              )}
            ></StepLabel>
          </Step>
        )}
      </Stepper>
      {open && <StepFormModal open={open} handleClose={handleClose} step={steps.length + 1} />}
    </Box>
  )
}
