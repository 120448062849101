import { Button, Collapse } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { OnboardingContext } from 'src/App'
import Spacer from 'src/common/components/Spacer'
import PlusIcon from 'src/common/icons/plusIcon'
import { ExternalLink } from '../settings'
import PuzzileIcon from 'src/common/icons/puzzileIcon'

import './style.css'

export const Circle = ({ size = 40, color = '#E7E7EE', children, style }) => (
  <div
    style={{
      width: size,
      height: size,
      borderRadius: size,
      background: color,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...style
    }}
  >
    {children}
  </div>
)

const StepIndicator = ({ step, index }) => (
  <Circle
    size={step > index ? 30 : 40}
    style={{ marginTop: step > index ? 0 : '-6px' }}
    color={step > index ? '#00BA97' : step < index ? '#fff' : '#F4F5F9'}
  >
    {step > index ? (
      <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.1876 5.39732L7.31254 13.2861L3.375 9.3419'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ) : (
      <p style={{ fontWeight: 600, fontSize: 20 }}>{index}</p>
    )}
  </Circle>
)

const CollapseTitle = ({ index, text, step, shownSteps, onShow }) => (
  <div className='row row-center mb16'>
    <p className={step > index ? 'title-20' : step < index ? 'title-20-500-grey' : 'title-20'}>{text}</p>
    <Spacer />
  </div>
)

const GetStartedPage = () => {
  const [shownSteps, setShownSteps] = useState([1])
  const [mannualStep, setMannualStep] = useState(null)

  const [onboarding, updateOnboarding] = useContext(OnboardingContext)
  const activeStep = onboarding?.activeStep || 0

  const navigate = useNavigate()

  const handleCollapseClick = index => {
    // console.log(activeStep, index);
    if (activeStep >= index) {
      setMannualStep(index)
    }
  }

  return (
    <div
      className='column'
      style={{
        backgroundColor: '#F8F8FB',
        height: '100vh',
        overflowY: 'auto',
        padding: '24px'
      }}
    >
      <div className='container-915'>
        <h1>Almost there!</h1>
        <p style={{ lineHeight: '24px', marginBottom: '32px' }}>
          Your team deserves the best protection out there. We're here to help!
          <br /> Let's get them ready for cyberattacks right now
        </p>
        <Collapse
          sx={{
            cursor: mannualStep !== 1 && activeStep > 1 && 'pointer',
            background: activeStep > 1 ? '#6DEDC81C' : '#fff',
            border: activeStep > 1 ? '1px solid #22AD850D' : '0',
            borderRadius: '24px',
            padding: '32px',
            marginBottom: '8px'
          }}
          in={mannualStep ? mannualStep == 1 : activeStep == 1}
          collapsedSize={88}
          onClick={() => {
            handleCollapseClick(1)
          }}
        >
          <div className='row'>
            <div className='column column-center mr24'>
              <StepIndicator index={1} step={activeStep} />
            </div>
            <div className='column'>
              <CollapseTitle
                index={1}
                text={'Whitelist'}
                step={activeStep}
                shownSteps={shownSteps}
                onShow={i => setShownSteps([i])}
              />
              <div
                style={{
                  opacity: mannualStep ? (mannualStep == 1 ? '1' : '0') : activeStep == 1 ? '1' : '0',
                  transition: '300ms'
                }}
                className=''
              >
                <p className='text font600 mb16'>
                  Ensure uninterrupted services by adding our IP addresses and domains to your whitelist.
                </p>
                <p className='text mb24'>
                  To prevent our newsletters from being filtered as spam, it is essential to add our IP addresses and
                  domains to your system’s whitelist settings. This involves configuring settings in your mailing
                  service, and possibly updating your Email Link Scanning, Traffic Filtering Systems, Proxy, Firewall,
                  Web Filter, Endpoint Protection, or other network restrictions.
                </p>
                <ExternalLink
                  to={'https://helpcenter.cyberlift.ai/content/add-allow-list-and-ip-allow-list-in-microsoft'}
                  text={'List of IP and Domains'}
                ></ExternalLink>
                <ExternalLink
                  className='mb24'
                  to={'https://helpcenter.cyberlift.ai/'}
                  text={'Learn how in our Knowledge Base'}
                ></ExternalLink>
                <Button
                  variant={'contained'}
                  sx={{
                    background: '#6123FF',
                    borderRadius: '12px',
                    fontFamily: 'Plus Jakarta Sans',
                    color: '#fff',
                    textTransform: 'none',
                    width: 270
                  }}
                  onClick={e => {
                    e.stopPropagation()
                    setShownSteps([2])
                    setMannualStep(2)
                    updateOnboarding({
                      ...onboarding,
                      activeStep: activeStep >= 2 ? activeStep : 2
                    })
                  }}
                >
                  Got it
                </Button>
              </div>
            </div>
          </div>
        </Collapse>

        <Collapse
          sx={{
            cursor: mannualStep !== 2 && activeStep >= 2 && 'pointer',
            background: activeStep > 2 ? '#6DEDC81C' : activeStep < 2 ? '#FFFFFF4D' : '#fff',
            border: activeStep > 2 ? '1px solid #22AD850D' : activeStep < 2 ? '1px solid #E7E7EE' : '0',
            borderRadius: '24px',
            padding: '32px',
            marginBottom: '8px'
          }}
          in={mannualStep ? mannualStep == 2 : activeStep == 2}
          collapsedSize={88}
          onClick={() => handleCollapseClick(2)}
        >
          <div className='row'>
            <div className='column column-center mr24'>
              <StepIndicator index={2} step={activeStep} />
            </div>
            <div className='column'>
              <CollapseTitle
                index={2}
                text={'Adding Employees/Users'}
                step={activeStep}
                shownSteps={shownSteps}
                onShow={i => setShownSteps([i])}
              />

              <div
                style={{
                  opacity: mannualStep ? (mannualStep == 2 ? '1' : '0') : activeStep == 2 ? '1' : '0',
                  transition: '300ms'
                }}
                className='column column-start'
              >
                <p className='text font600 mb16'>
                  Set up employee/user accounts on the platform through convenient methods.
                </p>
                <p className='text mb24'>
                  Next, it&apos;s time to add your team members to the platform. You can do this manually via a CSV
                  file, which is quicker, or automatically by integrating with Microsoft Outlook/Active Directory/Google
                  Workspace services for hands-free management post-setup.
                </p>
                <Button
                  onClick={() => {
                    // setOpenDrawer(false)
                    navigate('/team')
                    updateOnboarding({
                      ...onboarding,
                      importUsersDone: true,
                      showImport: true,
                      activeStep: activeStep >= 2 ? activeStep : 2
                    })
                  }}
                  sx={{
                    borderColor: '#E7E7EE',
                    borderRadius: '12px',
                    color: '#6123FF',
                    textTransform: 'none',
                    mr: '12px'
                  }}
                  startIcon={<PlusIcon />}
                >
                  Add .CSV file
                </Button>
                <Button
                  onClick={() => {
                    // setOpenDrawer(false)
                    updateOnboarding({
                      ...onboarding,
                      importUsersDone: true,
                      activeStep: activeStep >= 2 ? activeStep : 2
                    })
                    navigate('/settings/integrations')
                  }}
                  sx={{
                    borderColor: '#E7E7EE',
                    borderRadius: '12px',
                    color: '#6123FF',
                    textTransform: 'none',
                    mr: '12px',
                    mb: '24px'
                  }}
                  startIcon={<PuzzileIcon />}
                >
                  Connect workspace services
                </Button>
                <div className='row row-center'>
                  <Button
                    variant={'contained'}
                    sx={{
                      background: '#6123FF',
                      borderRadius: '12px',
                      fontFamily: 'Plus Jakarta Sans',
                      color: '#fff',
                      textTransform: 'none',
                      width: 270
                    }}
                    onClick={e => {
                      e.stopPropagation()
                      setShownSteps([3])
                      updateOnboarding({
                        ...onboarding,
                        activeStep: activeStep >= 3 ? activeStep : 3
                      })
                      setMannualStep(3)
                    }}
                    disabled={!onboarding.importUsersDone}
                  >
                    Done
                  </Button>
                  {/* {onboarding.importUsersDone &&
                      <p style={{ marginLeft: 16 }}><span className='title-24'></span> <span style={{ fontSize: '14px', fontWeight: 600, color: '#76767F' }}>users added</span></p>

                    } */}
                </div>
              </div>
            </div>
          </div>
        </Collapse>

        <Collapse
          sx={{
            background: activeStep > 3 ? '#6DEDC81C' : activeStep < 3 ? '#FFFFFF4D' : '#fff',
            border: activeStep > 3 ? '1px solid #22AD850D' : activeStep < 3 ? '1px solid #E7E7EE' : '0',
            borderRadius: '24px',
            padding: '32px',
            marginBottom: '8px'
          }}
          in={mannualStep ? mannualStep == 3 : activeStep == 3}
          collapsedSize={88}
          onClick={() => handleCollapseClick(3)}
        >
          <div className='row'>
            <div className='column column-center mr24'>
              <StepIndicator index={3} step={activeStep} />
            </div>
            <div className='column'>
              <CollapseTitle
                index={3}
                text={'Cyberlift Plugin Installation'}
                step={activeStep}
                shownSteps={shownSteps}
                onShow={i => setShownSteps([i])}
              />
              <div
                style={{
                  opacity: mannualStep ? (mannualStep == 3 ? '1' : '0') : activeStep == 3 ? '1' : '0',
                  transition: '300ms'
                }}
                className=''
              >
                <p className='text font600 mb16'>
                  Facilitate swift reporting of suspicious emails through the Cyberlift plugin.
                </p>
                <p className='text mb24'>
                  Equip your team to report suspicious emails effortlessly within Google Workspace or Microsoft 365.
                  Installing the Cyberlift plugin is your next step towards enhancing security.
                </p>
                <ExternalLink
                  to={'https://helpcenter.cyberlift.ai/'}
                  className={'mb24'}
                  text={'Learn how in our Knowledge Base'}
                ></ExternalLink>
                <Button
                  variant={'contained'}
                  sx={{
                    background: '#6123FF',
                    borderRadius: '12px',
                    fontFamily: 'Plus Jakarta Sans',
                    color: '#fff',
                    textTransform: 'none',
                    width: 270
                  }}
                  onClick={e => {
                    e.stopPropagation()
                    setShownSteps([4])
                    // navigate('/team')
                    updateOnboarding({
                      ...onboarding,
                      activeStep: activeStep >= 4 ? activeStep : 4
                    })
                    setMannualStep(4)
                  }}
                >
                  Got it
                </Button>
              </div>
            </div>
          </div>
        </Collapse>

        <Collapse
          sx={{
            background: activeStep > 4 ? '#6DEDC81C' : activeStep < 4 ? '#FFFFFF4D' : '#fff',
            border: activeStep > 4 ? '1px solid #22AD850D' : activeStep < 4 ? '1px solid #E7E7EE' : '0',
            borderRadius: '24px',
            padding: '32px',
            marginBottom: '8px'
          }}
          in={mannualStep ? mannualStep == 4 : activeStep == 4}
          collapsedSize={88}
          onClick={() => handleCollapseClick(4)}
        >
          <div className='row'>
            <div className='column column-center mr24'>
              <StepIndicator index={4} step={activeStep} />
            </div>
            <div className='column'>
              <CollapseTitle
                index={4}
                text={'Start Campaigns'}
                step={activeStep}
                shownSteps={shownSteps}
                onShow={i => setShownSteps([i])}
              />

              <div
                style={{
                  opacity: mannualStep ? (mannualStep == 4 ? '1' : '0') : activeStep == 4 ? '1' : '0',
                  transition: '300ms'
                }}
                className=''
              >
                <p className='text font600 mb16'>Launch your campaigns for effective phishing simulation training.</p>
                <p className='text mb24'>
                  Before you start, ensure that the Cyberlift setup is complete to enable the sending of phishing
                  simulation trainings. Note: Incomplete setup will hinder the functionality of Cyberlift.
                </p>
                <Button
                  variant={'contained'}
                  sx={{
                    background: '#6123FF',
                    borderRadius: '12px',
                    fontFamily: 'Plus Jakarta Sans',
                    color: '#fff',
                    textTransform: 'none',
                    width: 270
                  }}
                  onClick={e => {
                    e.stopPropagation()
                    // setOpenDrawer(false)
                    updateOnboarding({
                      ...onboarding,
                      activeStep: activeStep >= 4 ? activeStep : 4,
                      done: true
                    })
                    navigate('/simulation-campaign')
                  }}
                >
                  Start Campaigns
                </Button>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  )
}

export default GetStartedPage
