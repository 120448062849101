import React from 'react'
import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

export default function DateTimeColumn({ row }) {
  return (
    <Box width={'100%'}>
      <Typography noWrap fontFamily={'Plus Jakarta Sans'} fontWeight={600} fontSize={14} color={'#1B1B26'}>
        {convertToUserTimezone(row.actionAt)}
      </Typography>
      <Box display={'flex'} columnGap={1} alignItems={'center'}>
        <Typography noWrap fontFamily={'Plus Jakarta Sans'} fontWeight={400} fontSize={13} color={'#1B1B26'}>
          {dayjs(row.actionAt).format('DD MMMM')}
        </Typography>
      </Box>
    </Box>
  )
}

dayjs.extend(utc)
dayjs.extend(timezone)

const convertToUserTimezone = serverTime => {
  const userTimezone = dayjs.tz.guess()
  const parsedTime = dayjs.utc(serverTime, 'YYYY-MM-DD HH:mm')
  return parsedTime.tz(userTimezone).format('HH:mm')
}
