import React from 'react'
import TargetImage from '../../../../common/images/Target.png'
import { Box, Container, Typography } from '@mui/material'

export default function EmptyActivityDetails() {
  return (
    <Container
      maxWidth={'md'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '70vh'
      }}
    >
      <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <img src={TargetImage} alt='Logo' />
      </Box>
      <Box width={'100%'} display={'flex'} flexDirection={'column'} rowGap={1} pb={3} alignItems={'center'}>
        <Typography variant='body1' fontWeight={700} fontFamily={'Plus Jakarta Sans'}>
          Track User Activity Within Simulation Campaigns
        </Typography>
        <Typography textAlign={'center'} variant='body1' fontWeight={500} fontFamily={'Plus Jakarta Sans'}>
          As soon as a user engages in a Simulation Campaign, this screen will begin logging all relevant actions. Here,
          you’ll find detailed records of activities such as Opened, Reported, Received, and more, providing a
          comprehensive view of user interactions within the campaign
        </Typography>
      </Box>
    </Container>
  )
}
