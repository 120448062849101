import { useState, useEffect } from 'react'

const useSortableList = (items, activeModulesCount) => {
  const [sortedItems, setSortedItems] = useState([])
  const [sortBy, setSortBy] = useState(null)

  useEffect(() => {
    setSortedItems([...items])
  }, [items])
  useEffect(() => {
    if (sortBy) {
      handleSort(sortBy.name)
    }
  }, [activeModulesCount])
  const handleSort = field => {
    const fieldMap = { name: 'topic', 'active modules': 'activeModules' }
    const fieldName = fieldMap[field.toLowerCase()]

    setSortedItems(prevItems =>
      [...prevItems].sort((a, b) => {
        const valA = fieldName === 'activeModules' ? activeModulesCount[a.trainingId] || 0 : a[fieldName]
        const valB = fieldName === 'activeModules' ? activeModulesCount[b.trainingId] || 0 : b[fieldName]
        if (valA < valB) return sortBy?.asc ? -1 : 1
        if (valA > valB) return sortBy?.asc ? 1 : -1
        return 0
      })
    )

    setSortBy({
      name: field,
      asc: field === sortBy?.name ? !sortBy.asc : true
    })
  }

  return { sortedItems, handleSort, sortBy }
}

export default useSortableList
