import React from 'react'

const SecurityIcon = ({ color = '#1B1B26' }) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.25 6.875H3.75C3.58424 6.875 3.42527 6.94085 3.30806 7.05806C3.19085 7.17527 3.125 7.33424 3.125 7.5V16.25C3.125 16.4158 3.19085 16.5747 3.30806 16.6919C3.42527 16.8092 3.58424 16.875 3.75 16.875H16.25C16.4158 16.875 16.5747 16.8092 16.6919 16.6919C16.8092 16.5747 16.875 16.4158 16.875 16.25V7.5C16.875 7.33424 16.8092 7.17527 16.6919 7.05806C16.5747 6.94085 16.4158 6.875 16.25 6.875ZM10 12.5C9.69097 12.5 9.38887 12.4084 9.13192 12.2367C8.87497 12.065 8.6747 11.821 8.55644 11.5354C8.43818 11.2499 8.40723 10.9358 8.46752 10.6327C8.52781 10.3296 8.67663 10.0512 8.89515 9.83265C9.11367 9.61413 9.39208 9.46531 9.69517 9.40502C9.99827 9.34473 10.3124 9.37568 10.5979 9.49394C10.8835 9.6122 11.1275 9.81247 11.2992 10.0694C11.4709 10.3264 11.5625 10.6285 11.5625 10.9375C11.5625 11.3519 11.3979 11.7493 11.1049 12.0424C10.8118 12.3354 10.4144 12.5 10 12.5V12.5Z'
      fill='white'
    />
    <path
      d='M10 12.5C10.8629 12.5 11.5625 11.8004 11.5625 10.9375C11.5625 10.0746 10.8629 9.375 10 9.375C9.13706 9.375 8.4375 10.0746 8.4375 10.9375C8.4375 11.8004 9.13706 12.5 10 12.5Z'
      stroke='#1B1B26'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M10 12.5V14.375' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M16.25 6.875H3.75C3.40482 6.875 3.125 7.15482 3.125 7.5V16.25C3.125 16.5952 3.40482 16.875 3.75 16.875H16.25C16.5952 16.875 16.875 16.5952 16.875 16.25V7.5C16.875 7.15482 16.5952 6.875 16.25 6.875Z'
      stroke='#1B1B26'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.1875 6.875V4.0625C7.1875 3.31658 7.48382 2.60121 8.01126 2.07376C8.53871 1.54632 9.25408 1.25 10 1.25C10.7459 1.25 11.4613 1.54632 11.9887 2.07376C12.5162 2.60121 12.8125 3.31658 12.8125 4.0625V6.875'
      stroke='#1B1B26'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default SecurityIcon
