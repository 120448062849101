import React from 'react'
import { Box, Typography, LinearProgress } from '@mui/material'

const ProgressCard = ({ value, label, total, color, benchmark }) => {
  const progress = (value / total) * 100

  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' width='100%' mb={2} position='relative'>
      <Box flex={1} mx={2} position='relative'>
        <Box display={'flex'} columnGap={'10px'} alignItems={'center'}>
          <Typography color='#1B1B26' fontWeight={500} fontFamily={'Satoshi'} fontSize={'24px'}>
            {value}
          </Typography>
          <Typography color='#1B1B26' fontWeight={400} fontFamily={'Plus Jakarta Sans'} fontSize={'13px'}>
            {label}
          </Typography>
        </Box>
        <LinearProgress
          disableShrink
          variant='determinate'
          value={progress}
          sx={{
            height: '6px',
            borderRadius: 5,
            width: '100%',
            backgroundColor: '#dddde1',
            '& .MuiLinearProgress-bar': {
              backgroundColor: color || '#dddde1'
            }
          }}
        />

        {!isNaN(benchmark) && (
          <Box
            position='absolute'
            left={`calc(60% - 50px)`}
            top='30px'
            display='flex'
            flexDirection='column'
            alignItems='center'
          >
            <Box width='2px' height='20px' bgcolor='cyan' />
            <Box
              sx={{ padding: '2px 8px', backgroundColor: '#C7FAFF', borderRadius: '4px', border: '1px solid #99ECF4' }}
            >
              <Typography fontFamily={'Plus Jakarta Sans'} fontWeight={400} fontSize={'12px'} color='#000000'>
                Benchmark - {benchmark}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ProgressCard
