import React, { useEffect } from 'react'
import DataTableHeader from './DataTableHeader'
import DataTableBody from './DataTableBody'
import DataTablePagination from './DataTablePagination'
import { useSorting } from './hooks/useSorting'
import { usePagination } from './hooks/usePagination'
import { Box, Table, TableCell, TableContainer, TableRow } from '@mui/material'
import EmptyTable from './EmptyTable'

export default function DataTableContainer({
  config,
  data,
  emptyText,
  totalCount,
  onRequestData,
  selectionEnabled,
  toggleRowSelection,
  toggleSelectAll,
  isAllSelected,
  filter,
  isSelected,
  colorBody,
  initialSort
}) {
  const { columns, stickyPagination = false, stickyHeader = true, maxHeight, height } = config
  const { orderBy, order, handleRequestSort } = useSorting(initialSort?.orderBy, initialSort?.order)
  const { page, size, handleChangePage, handleChangeRowsPerPage } = usePagination(0, 25)

  useEffect(() => {
    const sort = orderBy && `${orderBy},${order}`
    onRequestData({ page, size, sort, params: filter })
  }, [page, size, orderBy, order, filter])

  useEffect(() => {
    handleChangePage(0)
  }, [filter?.statuses, filter?.searchText])

  return (
    <Box sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <TableContainer
        sx={{
          width: '100%',
          borderRadius: '12px',
          maxHeight: maxHeight,
          height: height,
          overflowY: 'auto',
          overflowX: 'auto',
          '::-webkit-scrollbar': {
            width: '6px',
            height: '6px'
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#DBDBE6',
            borderRadius: '2px',
            '&:hover': {
              backgroundColor: '#DBDBE6'
            }
          },
          '::-webkit-scrollbar-track': {
            backgroundColor: 'transparent'
          },
          scrollbarWidth: 'thin',
          scrollbarColor: '#DBDBE6 transparent'
        }}
      >
        <Table
          stickyHeader={stickyHeader}
          sx={{
            borderRadius: 12,
            fontFamily: 'Plus Jakarta Sans',
            overflow: 'auto',
            width: '100%'
          }}
        >
          <DataTableHeader
            config={columns}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            selectionEnabled={selectionEnabled}
            isAllSelected={isAllSelected}
            toggleSelectAll={toggleSelectAll}
          />
          {!!data?.length && (
            <DataTableBody
              config={columns}
              colorBody={colorBody}
              data={data}
              emptyText={emptyText}
              isSelected={isSelected}
              selectionEnabled={selectionEnabled}
              toggleRowSelection={toggleRowSelection}
            />
          )}

          {!data?.length && (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <EmptyTable title={emptyText} />
              </TableCell>
            </TableRow>
          )}
        </Table>
      </TableContainer>

      <DataTablePagination
        totalCount={totalCount}
        page={page}
        rowsPerPage={size}
        onRequestPageChange={handleChangePage}
        onRequestRowsPerPageChange={handleChangeRowsPerPage}
        sticky={stickyPagination}
      />
    </Box>
  )
}
