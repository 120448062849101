import React from 'react'
import NewCampaignPage from '../new-campaign'
import { useQuery } from 'src/hooks'

export const EditCampaign = () => {
  const query = useQuery()
  const id = query.get('id')

  return <NewCampaignPage selectedId={id} />
}
