import { Button, Dialog, DialogContent, DialogTitle, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { useContext, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { SnackBarContext } from 'src/App'
import Spacer from 'src/common/components/Spacer'
import { OktaLogo } from 'src/common/logo/okta'
import settingsService from 'src/services/settings.service'

const ConnectorIcon = ({ type }) => {
  if (type == 'OKTA_SSO') {
    return <OktaLogo />
  }
}

const connectorsLocal = [
  {
    title: 'Okta',
    connectorType: 'OKTA_SSO',
    availableToUpdate: false
  }
]

export const SsoSettings = () => {
  dayjs.extend(utc)

  const [open, setOpen] = useState(false)
  const [selectedConnector, setSelectedConnector] = useState(null)

  const { setSuccessMessage, setErrorMessage } = useContext(SnackBarContext)

  const onClose = () => {
    setOpen(false)
  }

  const onAction = () => {
    settingsService
      .disconnect(selectedConnector)
      .then(() => {
        updateConnectors()
        setOpen(false)
        setSuccessMessage('Provider disconnected')
      })
      .catch(err => {
        console.error(err)
        setErrorMessage('Something went wrong')
      })
  }

  const { connectors, query, handleConnectTo, updateConnectors, setUpdadeDialog } = useOutletContext()

  function disconnect(connector) {
    setOpen(true)
    setSelectedConnector(connector)
    if (connector == 'AD') {
      setUpdadeDialog(false)
    }
  }

  return (
    <div className='settings-section'>
      <h2 id='integrations-section'>SSO Settings</h2>
      <p style={{ maxWidth: '730px', lineHeight: '24px' }}>Connect Cyberlift to your external platform</p>
      <div
        style={{
          marginTop: '20px',
          flexWrap: 'wrap',
          width: '100%',
          marginBottom: 20
        }}
        className='row'
      >
        {connectorsLocal?.map(el => {
          return (
            <div
              className='column'
              key={el.connectorType}
              style={{
                alignItems: 'flex-start',
                border:
                  query.get('errorSync') && query.get('errorSyncType') == el.connectorType
                    ? '1px solid #B40020'
                    : '1px solid #E7E7EE',
                padding: '17px 21px',
                width: 322,
                borderRadius: 14,
                marginRight: 14,
                marginBottom: 16,
                height: 180
              }}
            >
              <div style={{ marginBottom: 7 }}>
                <ConnectorIcon type={el.connectorType} />
              </div>

              <p style={{ fontWeight: 600 }}>{el.title}</p>

              {connectors?.map(connector => connector.provider).includes(el.connectorType) && (
                <>
                  <div
                    style={{
                      color: '#00BC62',
                      marginTop: 10
                    }}
                    key={el.connectorType}
                    className='row row-center'
                  >
                    <p>Synced</p>
                    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M13.5 4.5L6.5 11.4997L3 8'
                        stroke='#00BC62'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  {/*<p*/}
                  {/*  style={{*/}
                  {/*    fontSize: 13,*/}
                  {/*    opacity: 0.7,*/}
                  {/*    marginTop: 6*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  Last sync:{' '}*/}
                  {/*  {dayjs*/}
                  {/*    .utc(connectors.find(con => con.provider == el.connectorType).updatedAt)*/}
                  {/*    .local()*/}
                  {/*    .format('DD MMM YYYY HH:mm')}*/}
                  {/*</p>*/}
                </>
              )}
              {query.get('errorSync') && query.get('errorSyncType') == el.connectorType && (
                <p className='error-text' style={{ marginTop: 20, marginLeft: 0 }}>
                  {query.get('errorSync')}
                </p>
              )}
              <Spacer />
              {connectors?.map(connector => connector.provider).includes(el.connectorType) ? (
                <div className='row' style={{ width: '100%' }}>
                  <Button
                    key={el.connectorType + 'btn'}
                    sx={{
                      fontFamily: 'Plus Jakarta Sans',
                      fontSize: '15px',
                      color: '#6123FF',
                      textTransform: 'none',
                      padding: 0,
                      mr: '20px'
                    }}
                    onClick={() => disconnect(el.connectorType)}
                  >
                    Disconnect
                  </Button>
                  {el.availableToUpdate && (
                    <Button
                      key={el.connectorType + 'btn'}
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '15px',
                        color: '#6123FF',
                        textTransform: 'none',
                        padding: 0
                      }}
                      onClick={() => handleConnectTo(el.connectorType, true)}
                    >
                      Update
                    </Button>
                  )}
                </div>
              ) : (
                <Button
                  key={el.connectorType + 'btn'}
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    fontSize: '15px',
                    color: '#6123FF',
                    textTransform: 'none',
                    padding: 0
                  }}
                  onClick={() => handleConnectTo(el.connectorType)}
                >
                  Connect
                </Button>
              )}
            </div>
          )
        })}
      </div>

      <Dialog
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '22px',
            paddingTop: '8px',
            maxWidth: 'unset',
            width: '684px'
          }
        }}
        open={open}
        onClose={onClose}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Satoshi',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '35px',
            lineHeight: '110%',
            color: '#1B1B26'
          }}
        >
          Disconnect?
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              fontSize: '14px',
              marginBottom: '80px',
              lineHeight: '22px'
            }}
          >
            Are you sure you want to disconnect this provider?
          </p>
          <div className='row'>
            <Button
              sx={{
                padding: '10px 0px',
                fontFamily: 'Plus Jakarta Sans',
                textTransform: 'none',
                borderRadius: '8px',
                borderColor: '#D0D0D8',
                color: '#1B1B26'
              }}
              variant={'outlined'}
              fullWidth
              onClick={onClose}
            >
              Cancel
            </Button>
            <div style={{ width: '20px' }}></div>
            <Button
              onClick={onAction}
              sx={{
                padding: '10px 0px',
                fontFamily: 'Plus Jakarta Sans',
                textTransform: 'none',
                borderRadius: '8px',
                background: '#6123FF'
              }}
              variant={'contained'}
              fullWidth
            >
              Yes
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
