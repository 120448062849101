import React, { useState, useContext } from 'react'
import { Button } from '@mui/material'
import { ReactComponent as FilterIcon } from '../../../common/icons/filter_icon.svg'
import FilterMenuList, { filterNameMapper } from './FilterMenuList'
import { FilterParamsContext } from '../FilterParamsContext'
import { FILTER_OPTIONS } from '../TrainingPackages2'

export default function FilterPopper() {
  const [anchorEl, setAnchorEl] = useState(null)
  const { filter } = useContext(FilterParamsContext)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const filterName =
    filter?.statuses.length === 0
      ? 'No status'
      : filter.statuses.length === FILTER_OPTIONS.length
        ? 'All status'
        : filter.statuses.map(status => filterNameMapper[status]).join(', ')

  return (
    <div>
      <Button
        sx={{
          background: '#EDEDF2',
          borderRadius: '24px',
          fontSize: '16px',
          fontWeight: 600,
          fontFamily: 'Plus Jakarta Sans',
          color: '#1C1C27',
          textTransform: 'none',
          border: 'none',
          width: 'fit-content',
          '&:hover': {
            backgroundColor: '#6123ff1f'
          }
        }}
        variant={'contained'}
        startIcon={<FilterIcon />}
        disableElevation
        onClick={handleClick}
      >
        {filterName}
      </Button>
      <FilterMenuList open={open} anchorEl={anchorEl} handleClose={handleClose} />
    </div>
  )
}
