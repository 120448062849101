import React from 'react'
import { Box, Divider, IconButton } from '@mui/material'
import { ReactComponent as ImageIcon } from '../../../../common/icons/image_icon.svg'
import { ReactComponent as CloseIcon } from '../../../../common/icons/close_icon.svg'

import SelectPageTypeDropdown from './SelectPageTypeDropdown'
import LoginPagePopover from './LoginPagePopover'
import { LoginPageNameMap } from './utils'

export default function PageAttachment({ handleRemove, setTemplateChanged, pageType, setPageType }) {
  return (
    <Box
      width={'100%'}
      display={'flex'}
      borderRadius={'10px'}
      pt={0.5}
      pb={0.5}
      px={2}
      bgcolor={'#EAEAF1'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Box display={'flex'} columnGap={1} alignItems={'center'}>
        <ImageIcon />
        <LoginPagePopover name={LoginPageNameMap[pageType]} loginPage={pageType} />
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Divider
          sx={{
            width: '2px',
            marginRight: '8px'
          }}
          variant={'middle'}
          flexItem
          orientation={'vertical'}
        />
        <SelectPageTypeDropdown setTemplateChanged={setTemplateChanged} pageType={pageType} setPageType={setPageType} />
        <Box display={'flex'} alignItems={'center'}>
          <IconButton onClick={handleRemove}>
            <CloseIcon onClick={handleRemove} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
