import React from 'react'
import { ReactComponent as UserImage } from '../../../../common/images/users.svg'
import { Box, Container, Typography } from '@mui/material'

export default function EmptyActivityPage() {
  return (
    <Container
      maxWidth={'md'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '80vh'
      }}
    >
      <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <UserImage />
      </Box>
      <Box width={'100%'} display={'flex'} flexDirection={'column'} rowGap={1} pb={3} alignItems={'center'}>
        <Typography variant='body1' fontWeight={700} fontFamily={'Plus Jakarta Sans'}>
          Monitor each user’s activity within simulation campaigns
        </Typography>
        <Typography textAlign={'center'} variant='body1' fontWeight={500} fontFamily={'Plus Jakarta Sans'}>
          Utilize the activity screen to observe individual actions within each phishing simulation campaign. This
          resource provides detailed statistics and live updates on user performance, displaying statuses like Planned,
          In Progress, and Completed
        </Typography>
      </Box>
    </Container>
  )
}
