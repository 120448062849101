import { Button } from '@mui/material'
import React from 'react'
import Highlighter from 'react-highlight-words'
import { Letter } from 'react-letter'
import CustomCheckbox from 'src/common/components/CustomCheckbox'
import ViewIcon from 'src/common/icons/viewIcon'
import { toggleElementById } from 'src/heplers'

export const InternalTemplatesItem = ({
  el,
  selectedInternalTemplates,
  setSelectedInternalTemplates,
  onPreviewTemplate,
  selectedTemplateItems,
  setSelectedTemplateItems,
  search
}) => {
  return (
    <div className={`templates-grid-item column ${selectedInternalTemplates.includes(el.id) && ' active'}`}>
      <div className='templates-grid-item-img'>
        <div className='template-thumbbail'>
          <div
            onClick={e => {
              e.preventDefault()
            }}
          >
            <Letter html={el?.body} />
          </div>
        </div>
        <Button
          variant={'text'}
          sx={{
            color: '#76767F',
            textTransform: 'none',
            fontSize: '13px',
            position: 'absolute',
            top: '6px',
            right: '4px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
          startIcon={<ViewIcon color='#76767F' size='18' />}
          onClick={() => onPreviewTemplate(el.id, true)}
        >
          <span>View</span>{' '}
        </Button>
      </div>
      <div className='row row-start'>
        <CustomCheckbox
          checked={selectedInternalTemplates.map(item => item.id).includes(el.id)}
          onClick={() => {
            setSelectedInternalTemplates(toggleElementById(selectedInternalTemplates, el))
            // setSelectedTemplateItems(toggleElementById(selectedTemplateItems, { ...el, type: 'internal' }))
          }}
        />
        <div className='column' style={{ marginLeft: '8px' }}>
          <p onClick={() => onPreviewTemplate(el.id, true)} className='templates-grid-item-title text-shrink-2'>
            <Highlighter
              highlightClassName='highlight-text'
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={el.subject}
            />
          </p>
          <p className='templates-grid-item-domain'>{el.sender}</p>
        </div>
      </div>
    </div>
  )
}
