import React from 'react'

const PuzzileIcon = () => (
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.12'
      d='M6.49857 20.2486C6.40007 20.2486 6.30255 20.2292 6.21155 20.1915C6.12056 20.1538 6.03788 20.0985 5.96823 20.0289C5.89859 19.9592 5.84334 19.8766 5.80565 19.7856C5.76796 19.6946 5.74856 19.597 5.74857 19.4985L5.74862 15.4959C5.34851 15.6854 4.90698 15.7707 4.4651 15.7437C4.02321 15.7167 3.59532 15.5784 3.22123 15.3417C2.84714 15.1049 2.53901 14.7774 2.32549 14.3896C2.11197 14.0018 2 13.5663 2 13.1236C2 12.6808 2.11197 12.2453 2.32549 11.8575C2.53901 11.4697 2.84714 11.1422 3.22123 10.9054C3.59532 10.6687 4.02321 10.5304 4.4651 10.5034C4.90698 10.4765 5.34851 10.5617 5.74862 10.7512L5.74857 6.74857C5.74857 6.65007 5.76797 6.55254 5.80566 6.46155C5.84335 6.37055 5.89859 6.28787 5.96824 6.21823C6.03788 6.14858 6.12056 6.09334 6.21156 6.05564C6.30256 6.01795 6.40009 5.99856 6.49858 5.99856L10.8762 5.99861C10.6867 5.59851 10.6015 5.15698 10.6284 4.71509C10.6554 4.2732 10.7937 3.84532 11.0304 3.47123C11.2672 3.09714 11.5947 2.78901 11.9825 2.57549C12.3703 2.36197 12.8058 2.25 13.2486 2.25C13.6913 2.25 14.1268 2.36197 14.5146 2.57549C14.9024 2.78901 15.2299 3.09714 15.4667 3.47123C15.7034 3.84532 15.8417 4.2732 15.8687 4.71509C15.8956 5.15698 15.8104 5.59851 15.6209 5.99861L19.9985 5.99856C20.1974 5.99856 20.3882 6.07757 20.5289 6.21822C20.6695 6.35887 20.7485 6.54964 20.7485 6.74855L20.7486 10.7512C20.3485 10.5617 19.9069 10.4765 19.4651 10.5034C19.0232 10.5304 18.5953 10.6687 18.2212 10.9054C17.8471 11.1422 17.539 11.4697 17.3255 11.8575C17.1119 12.2453 17 12.6808 17 13.1236C17 13.5663 17.1119 14.0018 17.3255 14.3896C17.539 14.7774 17.8471 15.1049 18.2212 15.3417C18.5953 15.5784 19.0232 15.7167 19.4651 15.7437C19.9069 15.7706 20.3485 15.6854 20.7486 15.4959L20.7485 19.4986C20.7485 19.6975 20.6695 19.8882 20.5289 20.0289C20.3882 20.1695 20.1974 20.2486 19.9985 20.2486L6.49857 20.2486Z'
      fill='#6123FF'
    />
    <path
      d='M6.49857 20.2486C6.40007 20.2486 6.30255 20.2292 6.21155 20.1915C6.12056 20.1538 6.03788 20.0985 5.96823 20.0289C5.89859 19.9592 5.84334 19.8766 5.80565 19.7856C5.76796 19.6946 5.74856 19.597 5.74857 19.4985L5.74862 15.4959C5.34851 15.6854 4.90698 15.7707 4.4651 15.7437C4.02321 15.7167 3.59532 15.5784 3.22123 15.3417C2.84714 15.1049 2.53901 14.7774 2.32549 14.3896C2.11197 14.0018 2 13.5663 2 13.1236C2 12.6808 2.11197 12.2453 2.32549 11.8575C2.53901 11.4697 2.84714 11.1422 3.22123 10.9054C3.59532 10.6687 4.02321 10.5304 4.4651 10.5034C4.90698 10.4765 5.34851 10.5617 5.74862 10.7512L5.74857 6.74857C5.74857 6.65007 5.76797 6.55254 5.80566 6.46155C5.84335 6.37055 5.89859 6.28787 5.96824 6.21823C6.03788 6.14858 6.12056 6.09334 6.21156 6.05565C6.30256 6.01795 6.40009 5.99856 6.49858 5.99856L10.8762 5.99861C10.6867 5.59851 10.6015 5.15698 10.6284 4.71509C10.6554 4.2732 10.7937 3.84532 11.0304 3.47123C11.2672 3.09714 11.5947 2.78901 11.9825 2.57549C12.3703 2.36197 12.8058 2.25 13.2486 2.25C13.6913 2.25 14.1268 2.36197 14.5146 2.57549C14.9024 2.78901 15.2299 3.09714 15.4667 3.47123C15.7034 3.84532 15.8417 4.2732 15.8687 4.71509C15.8956 5.15698 15.8104 5.59851 15.6209 5.99861L19.9985 5.99856C20.1974 5.99856 20.3882 6.07757 20.5289 6.21822C20.6695 6.35887 20.7485 6.54964 20.7485 6.74855L20.7486 10.7512C20.3485 10.5617 19.9069 10.4765 19.4651 10.5034C19.0232 10.5304 18.5953 10.6687 18.2212 10.9054C17.8471 11.1422 17.539 11.4697 17.3255 11.8575C17.1119 12.2453 17 12.6808 17 13.1236C17 13.5663 17.1119 14.0018 17.3255 14.3896C17.539 14.7774 17.8471 15.1049 18.2212 15.3417C18.5953 15.5784 19.0232 15.7167 19.4651 15.7437C19.9069 15.7706 20.3485 15.6854 20.7486 15.4959L20.7485 19.4986C20.7485 19.6975 20.6695 19.8882 20.5289 20.0289C20.3882 20.1695 20.1974 20.2486 19.9985 20.2486L6.49857 20.2486Z'
      stroke='#6123FF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default PuzzileIcon
