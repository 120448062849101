import { ReactComponent as FolderIcon } from '../../icons/folder.svg'
import React from 'react'
import { Box } from '@mui/material'

export default function EmptyTable({ title = 'No items found' }) {
  return (
    <Box
      width={'100%'}
      display={'flex'}
      minHeight={'400px'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      height={'100%'}
    >
      <FolderIcon />
      <p style={{ textAlign: 'center', color: '#76767F' }}>{title}</p>
    </Box>
  )
}
