import React from 'react'
import { Box } from '@mui/material'
import DataTableContainer from '../../../common/components/DataTable/DataTableContainer'

import useTrainingTable from './useTrainingTable'
import LoadingIndicator from '../../../common/components/LoadingIndicator'
import TrainingModal from '../NewTraining/TrainingModal'
import ArchiveDialog from '../ArchiveDialog'

export default function TrainingTable() {
  const {
    columns,
    isArchiveShowing,
    handleArchiveTraining,
    handleArchiveClose,
    isEditShowing,
    sharedId,
    handleClose,
    fetchData,
    params,
    totalCount,
    trainings,
    isLoading
  } = useTrainingTable()

  if (isLoading) {
    return (
      <Box display={'flex'} height={'100vh'} alignItems={'center'}>
        <LoadingIndicator />
      </Box>
    )
  }

  return (
    <Box width={'100%'}>
      <DataTableContainer
        onRequestData={fetchData}
        filter={params}
        config={{
          columns,
          stickyPagination: true,
          stickyHeader: true,
          height: 'calc(100vh - 255px)'
        }}
        emptyText={'No training packages found'}
        data={trainings}
        totalCount={totalCount}
      />
      {isEditShowing && (
        <TrainingModal editId={sharedId} title={'Update package'} open={isEditShowing} handleClose={handleClose} />
      )}
      {isArchiveShowing && (
        <ArchiveDialog
          name={sharedId.name}
          open={isArchiveShowing}
          onClose={handleArchiveClose}
          onArchive={handleArchiveTraining}
        />
      )}
    </Box>
  )
}
