import React from 'react'
import { ReactComponent as ArrowIcon } from '../../../common/icons/chevron_right.svg'
import { useState } from 'react'

export default function CollapsedFilterListWithInnerScroll({ children, title }) {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px 8px',
          cursor: 'pointer',
          borderBottom: '1px solid #E0E0E0'
        }}
        onClick={() => setCollapsed(!collapsed)}
      >
        <span
          style={{
            fontFamily: 'Plus Jakarta Sans',
            fontSize: 16,
            fontWeight: 500,
            color: '#1C1C27'
          }}
        >
          {title}
        </span>
        <ArrowIcon
          style={{
            transform: collapsed ? 'rotate(90deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s'
          }}
        />
      </div>
      <div
        style={{
          display: collapsed ? 'none' : 'block',
          overflowY: 'auto',
          height: '100%',

          '::-webkit-scrollbar': {
            width: '6px',
            height: '6px'
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#DBDBE6',
            borderRadius: '2px',
            '&:hover': {
              backgroundColor: '#DBDBE6'
            }
          },
          '::-webkit-scrollbar-track': {
            backgroundColor: 'transparent'
          },
          scrollbarWidth: 'thin',
          scrollbarColor: '#DBDBE6 transparent'
        }}
      >
        {children}
      </div>
    </div>
  )
}
