import axios from 'axios'

const api = axios.create({
  baseURL: window._env_.REACT_APP_API_HOST
})

api.interceptors.response.use(
  response => response,
  error => {
    if ([401, 403].includes(error.response.status)) {
      window.location.replace('/login')
    }
    return Promise.reject(error)
  }
)

export default api
