import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Box, debounce, TextField, Typography } from '@mui/material'
import { FilterParamsContext } from './FilterParamsContext'
import { ReactComponent as SearchIcon } from '../../common/icons/search.svg'
import NewTrainingButton from './NewTraining/NewTrainingButton'
import FilterPopper from './Filter/FilterPopper'

export default function TrainingPackagesTopBar() {
  const { filter, setFilter } = useContext(FilterParamsContext)
  const [searchText, setSearchText] = useState(filter.searchText)

  const debouncedSetFilter = useMemo(
    () =>
      debounce(searchText => {
        setFilter(prevFilter => ({ ...prevFilter, searchText }))
      }, 500),
    [setFilter]
  )

  const handleSearchChange = useCallback(
    e => {
      const value = e.target.value
      setSearchText(value)
      debouncedSetFilter(value)
    },
    [debouncedSetFilter]
  )

  return (
    <div style={{ marginBottom: '32px', justifyContent: 'space-between' }} className='row row-center'>
      <Box display={'flex'} columnGap={'16px'} alignItems={'center'}>
        <Typography fontSize={'24px'} fontWeight={900} fontFamily={'Satoshi'}>
          Training Packages
        </Typography>
        <FilterPopper />
      </Box>
      <Box display={'flex'} columnGap={'32px'}>
        <Box width='280px'>
          <TextField
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                border: '1px solid #E7E7EE',
                background: '#fff',
                width: '100%'
              }
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
            onChange={handleSearchChange}
            value={searchText}
            size='small'
            placeholder='Search Trainings'
          />
        </Box>
        <NewTrainingButton />
      </Box>
    </div>
  )
}
