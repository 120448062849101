import React, { useContext, useEffect, useState } from 'react'

import { Link, NavLink, useNavigate } from 'react-router-dom'
import './styles.css'

import Logo from '../logo'
import DashBoardIcon from '../icons/DashBoardIcon'
import ThreatsIcon from '../icons/threats'
import TeamIcon from '../icons/team'
import TemplatesIcon from '../icons/templates'
import MicrotrainingsIcon from '../icons/micro'
import TrainingIcon from '../icons/training'
import SettingsIcon from '../icons/settings'
import LogoutIcon from '../icons/logout'
import AuthService from 'src/services/auth.service'
import userService from 'src/services/user.service'
import GroupsIcon from '../icons/groups'
import WorkflowsIcon from '../icons/workflows'
import BreachesIcon from '../icons/breaches'
import { parseJwt } from 'src/heplers'
import Spacer from '../components/Spacer'
import { OnboardingContext, UserViewContext } from 'src/App'
import ManualSimIcon from '../icons/manual-sim'
import GetStartedIcon from '../icons/getStartedIcon'
import CompaniesIcon from '../icons/companies'
import HelpcenterIcon from '../icons/helpcenter-icon'
import EmployeesPlatformIcon from '../icons/employees-platform'
import SecurityIcon from '../icons/security'

const specialCompanyIds = [
  '2ffd7fc7-6d0f-48b6-94c2-1aab115f1ae8',
  'c3964bfc-23b4-4e65-a7f1-149888adee6e',
  '4deba56b-8d8f-4240-ae96-cb9906949fd4'
]

function Nav() {
  const treshHoldHeight = 890
  const [collapsed, setCollaplsed] = useState(false)
  const [user, setUser] = useState(null)
  const [isCompactMenu, setIsCompactMenu] = useState(window.innerHeight < treshHoldHeight)

  const [, setUserViewId] = useContext(UserViewContext)
  const [onboarding] = useContext(OnboardingContext)

  const navigate = useNavigate()
  const handleLogout = () => {
    AuthService.logout()
      .then(() => {
        navigate('/login')
        localStorage.removeItem('user')
      })
      .catch(() => {
        localStorage.removeItem('user')
        navigate('/login')
      })
  }

  const handleShowAdmin = () => {
    if (user?.id) {
      setUserViewId(user.id)
    }
  }

  const handleResise = () => {
    if (window.innerHeight < treshHoldHeight && !isCompactMenu) {
      setIsCompactMenu(true)
    }
    if (window.innerHeight > treshHoldHeight && isCompactMenu) {
      setIsCompactMenu(false)
    }
  }

  const userLocal = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    const userId = parseJwt(userLocal.accessToken).userId
    userService.getUser(userId).then(data => setUser(data.data))

    window.addEventListener('resize', handleResise)
    return () => {
      window.removeEventListener('resize', handleResise)
    }
  }, [])

  const companyId = parseJwt(userLocal.accessToken)?.companyId

  return (
    <div className='navigation column'>
      <NavLink className={'logo-link'} to={'/'}>
        <Logo collapsed={collapsed} />
      </NavLink>
      {!onboarding.done && (
        <NavLink
          title='Get started'
          className={`nav-item row ${collapsed && 'nav-item-collapsed'}`}
          to={'/get-started'}
        >
          <GetStartedIcon /> {collapsed ? '' : <span>Get started</span>} <Spacer />{' '}
          <span
            style={{
              fontSize: 12,
              marginRight: 10,
              color: '#6123FF'
            }}
          >
            {onboarding?.activeStep - 1 || 0} of 4
          </span>
        </NavLink>
      )}
      <NavLink title='Dashboard' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/'}>
        <DashBoardIcon /> {collapsed ? '' : 'Dashboard'}
      </NavLink>
      <NavLink title='Threats' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/threats'}>
        <ThreatsIcon /> {collapsed ? '' : 'Threats'}
      </NavLink>
      <NavLink title='Workflows' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/workflows'}>
        <WorkflowsIcon /> {collapsed ? '' : 'Workflows'}
      </NavLink>
      <NavLink title='Breaches' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/breaches'}>
        <BreachesIcon /> {collapsed ? '' : 'Breaches'}
      </NavLink>
      {!collapsed && <p className='nav-item-category'>TEAM</p>}
      <NavLink title='Employees' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/team'}>
        <TeamIcon /> {collapsed ? '' : 'Employees'}
      </NavLink>
      <NavLink title='Groups' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/groups'}>
        <GroupsIcon /> {collapsed ? '' : 'Groups'}
      </NavLink>
      {specialCompanyIds.includes(companyId) && (
        <NavLink title='Companies' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/companies'}>
          <CompaniesIcon /> {collapsed ? '' : 'Companies'}
        </NavLink>
      )}
      {!collapsed && <p className='nav-item-category'>Simulations</p>}
      <NavLink title='Templates' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/templates'}>
        <TemplatesIcon /> {collapsed ? '' : 'Templates'}
      </NavLink>
      <NavLink
        title='Manual Simulation V2'
        className={`nav-item row ${collapsed && 'nav-item-collapsed'}`}
        to={'/simulation-campaign'}
      >
        <ManualSimIcon /> {collapsed ? '' : 'Simulation Campaigns'}
      </NavLink>
      {!collapsed && <p className='nav-item-category'>Awareness</p>}
      <NavLink
        title='Training Topics'
        className={`nav-item row ${collapsed && 'nav-item-collapsed'}`}
        to={'/microtraining'}
      >
        <MicrotrainingsIcon /> {collapsed ? '' : 'Training Topics'}
      </NavLink>
      {/*<NavLink*/}
      {/*  title='Training Packages'*/}
      {/*  className={`nav-item row ${collapsed && 'nav-item-collapsed'}`}*/}
      {/*  to={'/training-packages'}*/}
      {/*>*/}
      {/*  <TrainingIcon /> {collapsed ? '' : 'Training Packages'}*/}
      {/*</NavLink>*/}
      <NavLink
        title='Training Packages'
        className={`nav-item row ${collapsed && 'nav-item-collapsed'}`}
        to={'/training-packages'}
      >
        <TrainingIcon /> {collapsed ? '' : 'Training Packages'}
      </NavLink>
      <NavLink
        title='Security Assistant'
        className={`nav-item row ${collapsed && 'nav-item-collapsed'}`}
        to={'/security-assistant'}
      >
        <SecurityIcon /> {collapsed ? '' : 'Security Assistant'}
      </NavLink>

      <Spacer />

      <NavLink
        style={{ marginTop: 20 }}
        title='Settings'
        className={`nav-item row ${collapsed && 'nav-item-collapsed'}`}
        to={'/settings'}
      >
        <SettingsIcon /> {collapsed ? '' : 'Settings'}
      </NavLink>
      <Link
        target='_blank'
        title='Employees platform'
        className={`nav-item row ${collapsed && 'nav-item-collapsed'}`}
        to={window._env_.REACT_USER_APP_HOST}
      >
        <EmployeesPlatformIcon /> {collapsed ? '' : 'Employees platform'}
        <Spacer />
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g opacity='0.6'>
            <path d='M5 15L15 5' stroke='#76767F' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M6.875 5H15V13.125' stroke='#76767F' strokeLinecap='round' strokeLinejoin='round' />
          </g>
        </svg>
      </Link>
      <Link
        target='_blank'
        title='Help Center'
        className={`nav-item row ${collapsed && 'nav-item-collapsed'}`}
        to={'https://helpcenter.cyberlift.ai/'}
      >
        <HelpcenterIcon /> {collapsed ? '' : 'Help Center'}
        <Spacer />
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g opacity='0.6'>
            <path d='M5 15L15 5' stroke='#76767F' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M6.875 5H15V13.125' stroke='#76767F' strokeLinecap='round' strokeLinejoin='round' />
          </g>
        </svg>
      </Link>
      {user && (
        <div className='row row-center'>
          <div onClick={handleShowAdmin} className='nav-user row'>
            <div className='avatar'>{user.firstName.slice(0, 1) + user.lastName.slice(0, 1)}</div>

            {!collapsed && (
              <>
                <div className='column'>
                  <p className='name text-shrink-1' style={{ maxWidth: 127 }}>
                    {user.firstName + ' ' + user.lastName}
                  </p>
                  {/* <p className='email'>{user.email}</p> */}
                </div>
                <Spacer />
              </>
            )}
          </div>
          {!collapsed && (
            <div onClick={handleLogout} className={`nav-item-single-icon`} style={{ margin: 0 }}>
              <LogoutIcon />
            </div>
          )}
        </div>
      )}

      <div className='collapse-menu' style={{ right: -10 }} onClick={() => setCollaplsed(!collapsed)}>
        <div className={`collapse-menu-icon ${collapsed && 'collapse-menu-icon-collapsed'}`}>
          <svg width='7' height='14' viewBox='0 0 7 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M6.49999 12.8334L0.666656 7.00008L6.49999 1.16675'
              stroke='#1B1B26'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default Nav
