import React from 'react'
import { Box, Typography } from '@mui/material'
import { ReactComponent as AIEmail } from '../../../../common/icons/AiEmailIcon.svg'
import background from '../../../../common/images/shape.svg'

export default function PersonalizedEmailCard({ isHidden }) {
  if (isHidden) {
    return null
  }

  return (
    <Box
      sx={{
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
        padding: '16px',
        backgroundImage: `url(${background})`
      }}
    >
      <AIEmail />
      <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: 14, fontWeight: 600, color: '#1B1B26' }}>
        AI-Generated Email
      </Typography>
      <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: 13, fontWeight: 400, color: '#1B1B26' }}>
        The system uses advanced artificial intelligence algorithms to automatically create personalized emails.
      </Typography>
    </Box>
  )
}
