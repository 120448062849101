import React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export default function NameColumn({ data }) {
  return (
    <Box
      component={Link}
      sx={{ textDecoration: 'none' }}
      to={`/training-packages/${data.id}`}
      display='flex'
      flexDirection='column'
      width={400}
    >
      <Tooltip
        arrow
        title={
          <Typography fontWeight={400} fontSize={14}>
            {data.name}
          </Typography>
        }
        placement='top-start'
      >
        <Typography noWrap sx={{ ...styles.name, color: data.status === 'ARCHIVED' ? '#76767F' : '#1B1B26' }}>
          {data.name}
        </Typography>
      </Tooltip>
      <Tooltip
        arrow
        title={
          <Typography fontWeight={400} fontSize={14}>
            {data.description}
          </Typography>
        }
        placement='top-start'
      >
        <Typography sx={styles.description}>{splitIfMoreThanTwoLines(data.description)}</Typography>
      </Tooltip>
    </Box>
  )
}

const styles = {
  name: {
    textDecoration: 'none',
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 600,
    fontSize: 14
  },
  description: {
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 400,
    fontSize: 13,
    color: '#76767F'
  }
}

function splitIfMoreThanTwoLines(text) {
  return text.length > 115 ? text.slice(0, 115) + '...' : text
}
