import React from 'react'

const SpinnerIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M12 3V6' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M21 12H18' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M18.3635 18.3635L16.2422 16.2422' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12 21V18' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M5.63672 18.3635L7.75804 16.2422' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M3 12H6' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M5.63672 5.63672L7.75804 7.75804' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

export default SpinnerIcon
