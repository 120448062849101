import React from 'react'
import SimpleDialog from '../../common/components/Dialog'

export default function ArchiveDialog({ open, onClose, onArchive, name }) {
  return (
    <SimpleDialog
      open={open}
      onClose={onClose}
      str={`Archive: ${name}`}
      description={'Are you sure you want to archive this package?'}
      actionTitle={'Archive'}
      onAction={onArchive}
    />
  )
}
