import React, { useState } from 'react'
import { FilterParamsContext } from './FilterParamsContext'
import { Box } from '@mui/material'
import TrainingPackagesTopBar from './TrainingPackagesTopBar'
import TrainingTable from './TrainingTable/TrainingTable'
import StatsDashboard from './StatsDashboard'

export const FILTER_OPTIONS = ['PLANNED', 'IN_PROGRESS', 'COMPLETED', 'ARCHIVED']
export default function TrainingPackages2() {
  const [filter, setFilter] = useState({
    searchText: '',
    statuses: FILTER_OPTIONS
  })

  return (
    <FilterParamsContext.Provider
      value={{
        filter,
        setFilter
      }}
    >
      <Box bgcolor={'#F8F8FB'} p={3} pb={0} width={'100%'}>
        <TrainingPackagesTopBar />
        <StatsDashboard />
        <TrainingTable />
      </Box>
    </FilterParamsContext.Provider>
  )
}
