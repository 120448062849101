import React from 'react'
import { Typography } from '@mui/material'

export default function Labels({ children }) {
  return (
    <Typography fontFamily={'Plus Jakarta Sans'} fontWeight={400} fontSize={'16px'} color={'#1B1B26'}>
      {children}
    </Typography>
  )
}
