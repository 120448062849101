import React from 'react'
import { ReactComponent as AIIcon } from '../../../../common/icons/aiPower.svg'
import { Button } from '@mui/material'
import background from '../../../../common/images/shape.svg'

export default function AiButton({ handleClick }) {
  return (
    <Button
      sx={{
        backgroundImage: `url(${background})`,
        fontFamily: 'Plus Jakarta Sans',
        fontSize: 16,
        height: '28px',
        textTransform: 'none',
        fontWeight: 500,
        color: '#C908D9',
        '& .MuiButton-root': {
          backgroundColor: 'none'
        }
      }}
      variant='text'
      startIcon={<AIIcon />}
      onClick={handleClick}
    >
      Write with AI
    </Button>
  )
}
