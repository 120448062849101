import React from 'react'

const HelpcenterIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.1'
      d='M15.3031 4.69727C16.7096 6.10379 17.4998 8.01144 17.4998 10.0006C17.4998 11.9897 16.7096 13.8973 15.3031 15.3039L12.2095 12.2103C12.7955 11.6242 13.1248 10.8294 13.1248 10.0006C13.1248 9.17176 12.7955 8.37691 12.2095 7.79086L15.3031 4.69727Z'
      fill='#1B1B26'
    />
    <path
      opacity='0.1'
      d='M4.6967 15.3039C3.29018 13.8973 2.5 11.9897 2.5 10.0006C2.5 8.01144 3.29018 6.10379 4.6967 4.69727L7.79029 7.79086C7.20424 8.37691 6.875 9.17177 6.875 10.0006C6.875 10.8294 7.20424 11.6242 7.79029 12.2103L4.6967 15.3039Z'
      fill='#1B1B26'
    />
    <path
      d='M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z'
      stroke='#1B1B26'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 13.125C11.7259 13.125 13.125 11.7259 13.125 10C13.125 8.27411 11.7259 6.875 10 6.875C8.27411 6.875 6.875 8.27411 6.875 10C6.875 11.7259 8.27411 13.125 10 13.125Z'
      stroke='#1B1B26'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M7.79006 7.79086L4.69647 4.69727' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12.2095 7.79086L15.3031 4.69727' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12.2095 12.2102L15.3031 15.3038' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M7.79006 12.2102L4.69647 15.3038' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

export default HelpcenterIcon
