import React from 'react'
import useSortableList from './useSortableList'
import CustomCheckbox from '../../../common/components/CustomCheckbox'
import { SortHeader } from '../../groups'
import { ListItem } from './ListItem'
import EmptyTable from '../../../common/components/DataTable/EmptyTable'

const List = ({ items, handleSelectAll, selectedItems, onSelect, search, type }) => {
  const { sortedItems, handleSort, sortBy } = useSortableList(items, type)
  const selectedAll = !items.some(el => !selectedItems.includes(el.id))
  if (!sortedItems?.length) {
    return <EmptyTable title={'No items found'} />
  }
  return (
    <div style={{ maxHeight: '400px', overflow: 'auto' }}>
      <div className='row row-center groups-list-heading'>
        <CustomCheckbox checked={selectedAll} onClick={() => handleSelectAll(selectedAll)} />
        <SortHeader name='Name' sort={sortBy} onSort={handleSort} width={type === 'groups' ? '75%' : '55%'} />
        {type === 'groups' ? (
          <SortHeader name='Users' sort={sortBy} onSort={handleSort} width='25%' />
        ) : (
          <SortHeader name='Email' sort={sortBy} onSort={handleSort} width='45%' />
        )}
      </div>
      {sortedItems.map(item => (
        <ListItem
          key={item.id}
          item={item}
          onSelect={onSelect}
          selected={selectedItems.includes(item.id)}
          search={search}
          type={type}
        />
      ))}
    </div>
  )
}

export default List
