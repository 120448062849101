import React from 'react'

import { Field, useForm } from 'react-final-form'
import { Box } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

import FormFieldTitle from './FormFieldTitle'
import CustomCheckbox from '../../../common/components/CustomCheckbox'
import Labels from './Labels'
import { datePickerProps } from './datePickerProps'

export default function TrainingIAvailability({ values }) {
  const { change } = useForm()
  const validateStartDate = (value, allValues) => {
    if (!value || !allValues.endDate) return undefined
    const startDate = dayjs(value)
    const endDate = dayjs(allValues.endDate)
    return startDate.isAfter(endDate) ? 'Start date should not be after end date' : undefined
  }

  const validateEndDate = (value, allValues) => {
    if (!value || !allValues.startDate) return undefined
    const endDate = dayjs(value)
    const startDate = dayjs(allValues.startDate)
    return endDate.isBefore(startDate) ? 'End date should be after start date' : undefined
  }

  function handleAvailableChange(input) {
    input.onChange(!input.value)
    change('endDate', null)
  }

  return (
    <Box display='flex' flexDirection='column' rowGap={2}>
      <FormFieldTitle title='Training availability' />
      <Box display='flex' columnGap={2}>
        <Box display='flex' width='50%'>
          <Field
            name='startDate'
            validate={validateStartDate}
            render={({ input, meta }) => (
              <DatePicker
                onBlur={e => {
                  input.onBlur(e)
                }}
                {...datePickerProps(input, meta, 'Start Date')}
              />
            )}
          />
        </Box>
        <Box display='flex' width='50%' flexDirection='column' rowGap={2}>
          <Field
            name='endDate'
            validate={validateEndDate}
            render={({ input, meta }) => (
              <DatePicker
                onBlur={e => {
                  input.onBlur(e)
                }}
                disabled={values?.available}
                {...datePickerProps(input, meta, 'End Date')}
              />
            )}
          />
          <Field name='available'>
            {({ input }) => (
              <Box display='flex' columnGap={1} alignItems='center'>
                <CustomCheckbox
                  onBlur={e => {
                    input.onBlur(e)
                  }}
                  onClick={() => handleAvailableChange(input)}
                  checked={input.value}
                />
                <Labels>Always available</Labels>
              </Box>
            )}
          </Field>
        </Box>
      </Box>
    </Box>
  )
}
