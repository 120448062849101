import React from 'react'

export const StarsChip = ({ value }) => {
  return (
    <div
      style={{
        alignItems: 'center',
        padding: '6px 0px'
      }}
      className='row'
    >
      {value > 50 ? (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g filter='url(#filter0_d_71_16717)'>
            <path
              d='M20.5301 7.93153L16.3746 6.95487C16.1535 6.90289 15.959 6.76177 15.8412 6.56748L13.6283 2.91718C13.2803 2.34288 12.6715 2 12.0001 2C11.3287 2 10.7199 2.34288 10.3719 2.91707L8.15894 6.56748C8.04117 6.76176 7.84675 6.90288 7.62558 6.95487L3.47012 7.93153C2.81636 8.08512 2.30228 8.55812 2.09477 9.19674C1.88728 9.83521 2.02502 10.5203 2.46364 11.0288L5.25149 14.2614C5.39981 14.4334 5.47397 14.662 5.45517 14.8883L5.09981 19.1423C5.04386 19.8113 5.33476 20.4465 5.87808 20.8413C6.4214 21.2357 7.11555 21.3163 7.7345 21.0564L11.6704 19.4039C11.8797 19.316 12.1202 19.316 12.3296 19.4039L16.2655 21.0564C16.5055 21.1571 16.7567 21.2068 17.0059 21.2066C17.3998 21.2066 17.7892 21.0828 18.1218 20.8411C18.665 20.4464 18.9559 19.8113 18.9001 19.1421L18.5447 14.8882C18.5259 14.6617 18.6001 14.4331 18.7484 14.2611L21.5364 11.0285C21.9749 10.52 22.1128 9.83506 21.9053 9.19659C21.6978 8.55812 21.1838 8.08513 20.5301 7.93153Z'
              fill='#1B1B26'
            />
            <path
              d='M20.5301 7.93153L16.3746 6.95487C16.1535 6.90289 15.959 6.76177 15.8412 6.56748L13.6283 2.91718C13.2803 2.34288 12.6715 2 12.0001 2C11.3287 2 10.7199 2.34288 10.3719 2.91707L8.15894 6.56748C8.04117 6.76176 7.84675 6.90288 7.62558 6.95487L3.47012 7.93153C2.81636 8.08512 2.30228 8.55812 2.09477 9.19674C1.88728 9.83521 2.02502 10.5203 2.46364 11.0288L5.25149 14.2614C5.39981 14.4334 5.47397 14.662 5.45517 14.8883L5.09981 19.1423C5.04386 19.8113 5.33476 20.4465 5.87808 20.8413C6.4214 21.2357 7.11555 21.3163 7.7345 21.0564L11.6704 19.4039C11.8797 19.316 12.1202 19.316 12.3296 19.4039L16.2655 21.0564C16.5055 21.1571 16.7567 21.2068 17.0059 21.2066C17.3998 21.2066 17.7892 21.0828 18.1218 20.8411C18.665 20.4464 18.9559 19.8113 18.9001 19.1421L18.5447 14.8882C18.5259 14.6617 18.6001 14.4331 18.7484 14.2611L21.5364 11.0285C21.9749 10.52 22.1128 9.83506 21.9053 9.19659C21.6978 8.55812 21.1838 8.08513 20.5301 7.93153Z'
              fill='url(#paint0_linear_71_16717)'
            />
          </g>
          <mask
            id='mask0_71_16717'
            // style='mask-type:alpha'
            maskUnits='userSpaceOnUse'
            x='2'
            y='2'
            width='20'
            height='20'
          >
            <path
              d='M20.5301 7.93153L16.3746 6.95487C16.1535 6.90289 15.959 6.76177 15.8412 6.56748L13.6283 2.91718C13.2803 2.34288 12.6715 2 12.0001 2C11.3287 2 10.7199 2.34288 10.3719 2.91707L8.15894 6.56748C8.04117 6.76176 7.84675 6.90288 7.62558 6.95487L3.47012 7.93153C2.81636 8.08512 2.30228 8.55812 2.09477 9.19674C1.88728 9.83521 2.02502 10.5203 2.46364 11.0288L5.25149 14.2614C5.39981 14.4334 5.47397 14.662 5.45517 14.8883L5.09981 19.1423C5.04386 19.8113 5.33476 20.4465 5.87808 20.8413C6.4214 21.2357 7.11555 21.3163 7.7345 21.0564L11.6704 19.4039C11.8797 19.316 12.1202 19.316 12.3296 19.4039L16.2655 21.0564C16.5055 21.1571 16.7567 21.2068 17.0059 21.2066C17.3998 21.2066 17.7892 21.0828 18.1218 20.8411C18.665 20.4464 18.9559 19.8113 18.9001 19.1421L18.5447 14.8882C18.5259 14.6617 18.6001 14.4331 18.7484 14.2611L21.5364 11.0285C21.9749 10.52 22.1128 9.83506 21.9053 9.19659C21.6978 8.55812 21.1838 8.08513 20.5301 7.93153Z'
              fill='url(#paint1_linear_71_16717)'
            />
          </mask>
          <g mask='url(#mask0_71_16717)'>
            <g opacity='0.3'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M13.3336 12.3446L2.06962 -12.5H-12.3984L5.45327 30.25H21.2016L13.3336 12.3446Z'
                fill='black'
              />
            </g>
          </g>
          <defs>
            <filter
              id='filter0_d_71_16717'
              x='2'
              y='2'
              width='20'
              height='20.2068'
              filterUnits='userSpaceOnUse'
              colorInterpolationFilters='sRGB'
            >
              <feFlood floodOpacity='0' result='BackgroundImageFix' />
              <feColorMatrix
                in='SourceAlpha'
                type='matrix'
                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                result='hardAlpha'
              />
              <feOffset dy='1' />
              <feComposite in2='hardAlpha' operator='out' />
              <feColorMatrix type='matrix' values='0 0 0 0 0.804407 0 0 0 0 0.590847 0 0 0 0 0.0355932 0 0 0 1 0' />
              <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_71_16717' />
              <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_71_16717' result='shape' />
            </filter>
            <linearGradient
              id='paint0_linear_71_16717'
              x1='12'
              y1='2'
              x2='12'
              y2='21.2067'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#FFD644' />
              <stop offset='1' stopColor='#F3B319' />
            </linearGradient>
            <linearGradient
              id='paint1_linear_71_16717'
              x1='12'
              y1='2'
              x2='12'
              y2='21.2067'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#FFD644' />
              <stop offset='1' stopColor='#F3B319' />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g filter='url(#filter0_d_71_16753)'>
            <path
              d='M20.5301 7.93153L16.3746 6.95487C16.1535 6.90289 15.959 6.76177 15.8412 6.56748L13.6283 2.91718C13.2803 2.34288 12.6715 2 12.0001 2C11.3287 2 10.7199 2.34288 10.3719 2.91707L8.15894 6.56748C8.04117 6.76176 7.84675 6.90288 7.62558 6.95487L3.47012 7.93153C2.81636 8.08512 2.30228 8.55812 2.09477 9.19674C1.88728 9.83521 2.02502 10.5203 2.46364 11.0288L5.25149 14.2614C5.39981 14.4334 5.47397 14.662 5.45517 14.8883L5.09981 19.1423C5.04386 19.8113 5.33476 20.4465 5.87808 20.8413C6.4214 21.2357 7.11555 21.3163 7.7345 21.0564L11.6704 19.4039C11.8797 19.316 12.1202 19.316 12.3296 19.4039L16.2655 21.0564C16.5055 21.1571 16.7567 21.2068 17.0059 21.2066C17.3998 21.2066 17.7892 21.0828 18.1218 20.8411C18.665 20.4464 18.9559 19.8113 18.9001 19.1421L18.5447 14.8882C18.5259 14.6617 18.6001 14.4331 18.7484 14.2611L21.5364 11.0285C21.9749 10.52 22.1128 9.83506 21.9053 9.19659C21.6978 8.55812 21.1838 8.08513 20.5301 7.93153Z'
              fill='#D0D0D8'
            />
          </g>
          <mask
            id='mask0_71_16753'
            // style='mask-type:alpha'
            maskUnits='userSpaceOnUse'
            x='2'
            y='2'
            width='20'
            height='20'
          >
            <path
              d='M20.5301 7.93153L16.3746 6.95487C16.1535 6.90289 15.959 6.76177 15.8412 6.56748L13.6283 2.91718C13.2803 2.34288 12.6715 2 12.0001 2C11.3287 2 10.7199 2.34288 10.3719 2.91707L8.15894 6.56748C8.04117 6.76176 7.84675 6.90288 7.62558 6.95487L3.47012 7.93153C2.81636 8.08512 2.30228 8.55812 2.09477 9.19674C1.88728 9.83521 2.02502 10.5203 2.46364 11.0288L5.25149 14.2614C5.39981 14.4334 5.47397 14.662 5.45517 14.8883L5.09981 19.1423C5.04386 19.8113 5.33476 20.4465 5.87808 20.8413C6.4214 21.2357 7.11555 21.3163 7.7345 21.0564L11.6704 19.4039C11.8797 19.316 12.1202 19.316 12.3296 19.4039L16.2655 21.0564C16.5055 21.1571 16.7567 21.2068 17.0059 21.2066C17.3998 21.2066 17.7892 21.0828 18.1218 20.8411C18.665 20.4464 18.9559 19.8113 18.9001 19.1421L18.5447 14.8882C18.5259 14.6617 18.6001 14.4331 18.7484 14.2611L21.5364 11.0285C21.9749 10.52 22.1128 9.83506 21.9053 9.19659C21.6978 8.55812 21.1838 8.08513 20.5301 7.93153Z'
              fill='#CFD0D5'
            />
          </mask>
          <g mask='url(#mask0_71_16753)'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M13.3336 12.3446L2.06962 -12.5H-12.3984L5.45327 30.25H21.2016L13.3336 12.3446Z'
              fill='#BABBC2'
            />
          </g>
          <defs>
            <filter
              id='filter0_d_71_16753'
              x='2'
              y='2'
              width='20'
              height='20.2067'
              filterUnits='userSpaceOnUse'
              colorInterpolationFilters='sRGB'
            >
              <feFlood floodOpacity='0' result='BackgroundImageFix' />
              <feColorMatrix
                in='SourceAlpha'
                type='matrix'
                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                result='hardAlpha'
              />
              <feOffset dy='1' />
              <feComposite in2='hardAlpha' operator='out' />
              <feColorMatrix type='matrix' values='0 0 0 0 0.627451 0 0 0 0 0.635294 0 0 0 0 0.670588 0 0 0 1 0' />
              <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_71_16753' />
              <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_71_16753' result='shape' />
            </filter>
          </defs>
        </svg>
      )}
      <p
        style={{
          marginLeft: '8px',
          fontWeight: 500,
          fontSize: '24px',
          lineHeight: '120%'
        }}
      >
        {value}
      </p>
    </div>
  )
}
