import React from 'react'

const UnArchiveIcon = ({ disabled }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.5 15.75L18 5.25'
      stroke={disabled ? 'rgb(118, 118, 127)' : '#6123FF'}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18 14.625V5.25H8.625'
      stroke={disabled ? 'rgb(118, 118, 127)' : '#6123FF'}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.75 20.25H20.25'
      stroke={disabled ? 'rgb(118, 118, 127)' : '#6123FF'}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default UnArchiveIcon
