import React from 'react'
import { Box, Divider, Menu, Typography } from '@mui/material'
import CloseButton from '../../../../common/components/CloseButton'
import FilterMenuListItem from './FilterMenuListItem'

export default function FilterMenuList({
  open,
  anchorEl,
  handleClose,
  statuses,
  onChange,
  uncheckedTabs,
  setUncheckedtabs
}) {
  function handleFilterCheckAll() {
    if (uncheckedTabs.length == 0) {
      setUncheckedtabs(['Running', 'Paused'])
    } else {
      setUncheckedtabs([])
    }
  }

  return (
    <Menu
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: '#ffffff',
          color: '#000000',
          width: '323px',
          borderRadius: '12px',
          padding: '24px'
        },
        '& li': {
          opacity: '0.7',
          fontFamily: 'Plus Jakarta Sans',
          padding: 0
        },
        '& li:hover': {
          opacity: '1'
        }
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography color={'#1B1B26'} fontFamily={'Satoshi'} fontSize={24} fontWeight={900}>
            Workflows filter
          </Typography>
          <CloseButton onClick={handleClose} />
        </Box>
        <Typography
          sx={{
            opacity: '0.5',
            paddingBottom: '24px'
          }}
          color={'#1B1B26'}
          fontFamily={'Plus Jakarta Sans'}
          fontSize={14}
          fontWeight={500}
        >
          Choose a workflow status
        </Typography>
      </Box>
      <FilterMenuListItem
        checked={uncheckedTabs.length == 0}
        text={`All status, ${statuses[0].itemsCount + statuses[1].itemsCount}`}
        name={'ALL'}
        onClick={handleFilterCheckAll}
      />
      <Divider variant={'horizontal'} />
      <FilterMenuListItem
        checked={!uncheckedTabs.includes(statuses[0].text)}
        text={`${statuses[0].text}, ${statuses[0].itemsCount}`}
        name={statuses[0].text}
        onClick={() => {
          onChange(statuses[0].text)
        }}
      />
      <FilterMenuListItem
        checked={!uncheckedTabs.includes(statuses[1].text)}
        text={`${statuses[1].text}, ${statuses[1].itemsCount}`}
        name={statuses[0].text}
        onClick={() => {
          onChange(statuses[1].text)
        }}
      />
    </Menu>
  )
}
