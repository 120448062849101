import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import LoadingIndicator from '../../common/components/LoadingIndicator'
import { Box } from '@mui/material'
import { useOnScreen } from '../../hooks'
import EmptyTable from '../../common/components/DataTable/EmptyTable'

export function useInfiniteScrollerMyTemplates({ apiHook, options, params, renderItem }) {
  const ref = useRef(null)

  const isVisible = useOnScreen(ref)
  const [getItems, { data, isSuccess }] = apiHook()
  const [pages, setPages] = useState(0)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [infinityData, setInfinityData] = useState([])

  const fetchMore = () => {
    getItems({
      ...options,
      page: pages,
      size: options.size,
      searchText: params?.searchText
    }).then(res => {
      const fetchedData = res.data.data
      if (fetchedData.length < options.size) {
        setHasNextPage(false)
      }
      setInfinityData(prev => [...new Set([...prev, ...fetchedData])])
      setPages(prev => prev + 1)
    })
  }

  useEffect(() => {
    if (!isTwoArrayObjectsEqual(data?.data, infinityData) && isSuccess) {
      setInfinityData(prev => [
        ...new Set([...prev, ...data.data].filter((obj, index, self) => index === self.findIndex(o => o.id === obj.id)))
      ])
    }
  }, [data?.data, isSuccess])
  useEffect(() => {
    if (params.myTemplates && isVisible) {
      fetchMore()
    }
  }, [isVisible, params.myTemplates])

  useLayoutEffect(() => {
    if (!params.myTemplates) return

    setInfinityData([])
    setPages(0)

    setHasNextPage(true)
    getItems({
      ...options,
      page: pages,
      size: options.size,
      searchText: params?.searchText
    }).then(res => {
      const fetchedData = res.data.data
      if (fetchedData.length < options.size) {
        setHasNextPage(false)
      }
      setInfinityData(fetchedData)
    })
  }, [params?.searchText])

  useLayoutEffect(() => {
    if (params?.deletedId) {
      setInfinityData(prev => prev.filter(item => item.id !== params.deletedId))
    }
  }, [params.deletedId])

  function InfiniteItems() {
    return (
      <>
        <Box
          gap={'8px'}
          display={!params?.myTemplates ? 'none' : 'flex'}
          alignItems={'center'}
          width={'100%'}
          flexWrap={'wrap'}
        >
          {infinityData?.map(renderItem)}
          {!infinityData?.length && <EmptyTable title={'No templates found'} />}
        </Box>
        {hasNextPage && (
          <div
            style={{
              padding: '8px',
              display: 'flex',
              height: 150,
              alignItems: 'center',
              width: '100%'
            }}
            ref={ref}
          >
            {params.myTemplates && <LoadingIndicator />}
          </div>
        )}
      </>
    )
  }

  return {
    InfiniteItems
  }
}

function isTwoArrayObjectsEqual(arr1, arr2) {
  return arr1?.length === arr2?.length && arr1.every((item, index) => item === arr2[index])
}
