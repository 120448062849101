import React from 'react'

const WorkflowsIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.2'
      d='M6.375 15V14.25C6.37501 13.6533 6.61206 13.081 7.03401 12.6591C7.45596 12.2371 8.02824 12.0001 8.62495 12.0001L15.375 11.9999C15.9718 11.9999 16.544 11.7629 16.966 11.3409C17.3879 10.919 17.625 10.3467 17.625 9.75002V9'
      stroke='#1B1B26'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M6.375 9V15' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M6.375 20.25C7.82475 20.25 9 19.0747 9 17.625C9 16.1753 7.82475 15 6.375 15C4.92525 15 3.75 16.1753 3.75 17.625C3.75 19.0747 4.92525 20.25 6.375 20.25Z'
      stroke='#1B1B26'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.625 9C19.0747 9 20.25 7.82475 20.25 6.375C20.25 4.92525 19.0747 3.75 17.625 3.75C16.1753 3.75 15 4.92525 15 6.375C15 7.82475 16.1753 9 17.625 9Z'
      fill='#1B1B26'
      fillOpacity='0.2'
      stroke='#1B1B26'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.375 9C7.82475 9 9 7.82475 9 6.375C9 4.92525 7.82475 3.75 6.375 3.75C4.92525 3.75 3.75 4.92525 3.75 6.375C3.75 7.82475 4.92525 9 6.375 9Z'
      stroke='#1B1B26'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default WorkflowsIcon
