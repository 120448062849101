import React from 'react'
import CustomTablePagination from './CustomTablePagination'
import { Box } from '@mui/material'
import './index.css'

export default function DataTablePagination({
  totalCount,
  page,
  rowsPerPage,
  onRequestPageChange,
  onRequestRowsPerPageChange,
  sticky = false
}) {
  return (
    <Box bgcolor={'#EFEFF6'} pl={'48px'} pr={'48px'} className={sticky ? 'sticky-pagination' : 'regular-pagination'}>
      <CustomTablePagination
        totalCount={totalCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onRequestPageChange={onRequestPageChange}
        onRequestRowsPerPageChange={onRequestRowsPerPageChange}
        sticky={sticky}
      />
    </Box>
  )
}
