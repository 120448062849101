import React, { useContext } from 'react'
import { Box, Button } from '@mui/material'

import TrainingIAvailability from './TrainingIAvailability'
import TrainingInfo from './TrainingInfo'
import CompletionDeadline from './CompletionDeadline'
import { PageNumberContext } from '../../../context/PageNumberContext'

export default function FirstPageForm({ values }) {
  const { setPage } = useContext(PageNumberContext)

  return (
    <Box sx={styles.container}>
      <TrainingInfo />
      <TrainingIAvailability values={values} />
      <CompletionDeadline values={values} />
      <Box sx={styles.buttonContainer}>
        <Button
          disabled={isDisabled(values)}
          sx={{ ...styles.button, background: !isDisabled(values) && '#6123FF' }}
          autoFocus
          onClick={() => setPage(2)}
        >
          Continue
        </Button>
      </Box>
    </Box>
  )
}

const styles = {
  button: {
    borderRadius: '12px',
    fontFamily: 'Plus Jakarta Sans',
    color: '#fff',
    textTransform: 'none',
    border: 'none',
    width: 200,
    '&:hover': {
      background: '#1565c0'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    pt: 3,
    rowGap: 7,
    width: '100%'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    columnGap: 2,
    justifyContent: 'flex-end',
    padding: '16px'
  }
}

function isDisabled(values) {
  const { available, deadlineType, startDate, endDate, name, description, deadlineDate } = values
  const isInvalidDate = date => !date || date === 'Invalid Date'
  if (isInvalidDate(startDate) || !description || !name) return true
  if (!available && (isInvalidDate(startDate) || isInvalidDate(endDate))) return true
  return deadlineType !== 'NO_DEADLINE' && isInvalidDate(deadlineDate)
}
