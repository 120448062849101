import React from 'react'

const DownloadIconV2 = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.2'
      d='M2.5 10.625L10 18.125L17.5 10.625H13.75V3.75C13.75 3.58424 13.6842 3.42527 13.5669 3.30806C13.4497 3.19085 13.2908 3.125 13.125 3.125H6.875C6.70924 3.125 6.55027 3.19085 6.43306 3.30806C6.31585 3.42527 6.25 3.58424 6.25 3.75V10.625H2.5Z'
      fill='#6123FF'
    />
    <path
      d='M2.5 10.625L10 18.125L17.5 10.625H13.75V3.75C13.75 3.58424 13.6842 3.42527 13.5669 3.30806C13.4497 3.19085 13.2908 3.125 13.125 3.125H6.875C6.70924 3.125 6.55027 3.19085 6.43306 3.30806C6.31585 3.42527 6.25 3.58424 6.25 3.75V10.625H2.5Z'
      stroke='#6123FF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default DownloadIconV2
