import React, { useState } from 'react'
import { TextField, Chip, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const EmailChip = styled(Chip)(() => ({
  background: '#c0a7ff1a',
  color: '#6123ff',
  border: '1px solid #c0a7ff',

  '& .MuiSvgIcon-root': {
    color: '#909090',
    width: '18px'
  },

  '& .MuiChip-deleteIcon:hover': {
    color: '#717171',
    width: '18px'
  }
}))

const Input = styled(TextField)(() => ({
  fontSize: '16px',
  flex: 1,
  minWidth: '120px',
  border: '0',
  outline: 0,

  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px',
    border: '0',
    outline: 0
  },
  '& .MuiInputBase-root': {
    border: '0',
    outline: 0
  },
  '& .MuiInputBase-input': {
    border: '0',
    outline: 0,
    padding: '6px 6px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '0',
    outline: 0,
    padding: 0
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px'
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px'
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  }
}))

const EmailInput = ({ value, onEmailEnter = () => {} }) => {
  const [email, setEmail] = useState('')
  const [emails, setEmails] = useState(value ? value?.split(', ') : [])

  const handleAddEmail = e => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ';' || e.key === ' ') {
      e.preventDefault()

      const trimmedEmail = email.trim()

      if (trimmedEmail && validateEmail(trimmedEmail)) {
        onEmailEnter([...emails, trimmedEmail].join(', '))
        setEmails(prev => [...prev, trimmedEmail])
        setEmail('')
      }
    }
  }

  const blurInput = () => {
    const trimmedEmail = email.trim()

    if (trimmedEmail && validateEmail(trimmedEmail)) {
      onEmailEnter([...emails, trimmedEmail].join(', '))
      setEmails(prev => [...prev, trimmedEmail])
      setEmail('')
    }
  }

  const handleDeleteEmail = emailToDelete => {
    setEmails(prev => prev.filter(e => e !== emailToDelete))
  }

  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
        alignItems: 'center',
        borderRadius: '20px 20px 0px 0px',
        padding: '10px',
        border: '1px solid #E7E7EE',
        background: '#ffffff',
        maxWidth: '745px'
      }}
    >
      {emails.map((email, index) => (
        <EmailChip
          key={index}
          label={email}
          onDelete={() => handleDeleteEmail(email)}
          color='primary'
          variant='outlined'
        />
      ))}
      <Input
        variant='outlined'
        placeholder='Receivers emails'
        value={email}
        onChange={e => setEmail(e.target.value)}
        onKeyDown={handleAddEmail}
        InputProps={{ disableUnderline: true }}
        onBlur={blurInput}
      />
    </Box>
  )
}

export default EmailInput
