import React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

export default function CampaignTimelines({ data }) {
  const navigate = useNavigate()
  function handleClick() {
    navigate(`steps/${data.id}`)
  }

  if (data?.sequenceNumber === 1) {
    return (
      <Box
        onClick={handleClick}
        sx={{
          cursor: 'pointer'
        }}
        display={'flex'}
        minWidth={'20%'}
        width={'20%'}
        flexDirection={'column'}
      >
        <Tooltip title={` ${dayjs(data.startDate).format('DD MMM')} ${timelineMapper[data.timeline]}`}>
          <Typography noWrap fontSize={'13px'} fontWeight={600} color={'#76767F'}>
            {dayjs(data.startDate).format('DD MMM')}{' '}
            {data.timeline === 'CUSTOM_DATE' ? dayjs(data.startDate).format('DD MMM') : timelineMapper[data.timeline]}
          </Typography>
        </Tooltip>
        <Typography noWrap fontSize={'14px'} fontWeight={600} color={'#1B1B26'}>
          {typeMapper[data.type]}
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        cursor: 'pointer'
      }}
      display={'flex'}
      minWidth={'20%'}
      width={'20%'}
      flexDirection={'column'}
      onClick={handleClick}
    >
      {data.triggerAction && !data.daysCount && (
        <Tooltip
          title={`${data.timeline === 'CUSTOM_DATE' ? dayjs(data.startDate).format('DD MMM') : timelineMapper[data.timeline]} ${actionMapper[data.triggerAction]}`}
        >
          <Typography fontSize={'13px'} fontWeight={600} color={'#76767F'}>
            {data.timeline === 'CUSTOM_DATE' ? dayjs(data.startDate).format('DD MMM') : timelineMapper[data.timeline]}{' '}
            {actionMapper[data.triggerAction]}
          </Typography>
        </Tooltip>
      )}
      {data.triggerAction && data.daysCount && (
        <Tooltip title={`In ${data.daysCount} days ${actionMapper[data.triggerAction]}`}>
          <Typography fontSize={'13px'} fontWeight={600} color={'#76767F'}>
            In {data.daysCount} days {actionMapper[data.triggerAction]}
          </Typography>
        </Tooltip>
      )}
      {!data.triggerAction && data.timeline && (
        <Tooltip
          title={`${data.timeline === 'CUSTOM_DATE' ? dayjs(data.startDate).format('DD MMM') : timelineMapper[data.timeline]}`}
        >
          <Typography fontSize={'13px'} fontWeight={600} color={'#76767F'}>
            {data.timeline === 'CUSTOM_DATE' ? dayjs(data.startDate).format('DD MMM') : timelineMapper[data.timeline]}
          </Typography>
        </Tooltip>
      )}
      <Typography fontSize={'14px'} fontWeight={600} color={'#1B1B26'}>
        {typeMapper[data.type]}
      </Typography>
    </Box>
  )
}

const typeMapper = {
  AUTOMATED_EMAIL: 'AI-Generated Email',
  MANUAL_EMAIL: 'Custom Email',
  SMS: 'Personalized SMS',
  WHATSAPP: 'Personalized WhatsApp message',
  SLACK: 'Automatic Slack message',
  PHONE: 'Automatic phone call'
}

const actionMapper = {
  PREVIOUS_STEP_ENDED: 'after previous step ended',
  EMAIL_OPENED: 'after email opened',
  EMAIL_NOT_OPENED_IN_5_DAYS: 'after email not opened in 5 days',
  PHISHED: 'after phished',
  NOT_PHISHED_IN_5_DAYS: 'after not phished in 5 days',
  LINK_CLICKED: 'after link clicked',
  EMAIL_REPLIED: 'after email replied',
  ATTACH_OPENED: 'after attach opened',
  QR_CODE_SCANNED: 'after QR code scanned',
  CREDENTIALS_PROVIDED: 'after credentials provided'
}
const timelineMapper = {
  CUSTOM_DATE: 'Custom',
  IMMEDIATELY: 'Immediately',
  THROUGHOUT_DAY: 'Throughout the day',
  THROUGHOUT_WEEK: 'Throughout the week',
  THROUGHOUT_MONTH: 'Throughout the month',
  THROUGHOUT_3_MONTHS: 'Throughout 3 months',
  THROUGHOUT_6_MONTHS: 'Throughout 6 months',
  THROUGHOUT_YEAR: 'Throughout the year'
}
