import React from 'react'
import { Grid } from '@mui/material'

const schemeMapping = {
  simple: 1,
  third: 4,
  half: 6,
  twoThirds: 8,
  oneThirds: 9,
  fiveSixths: 9.7,
  oneSixth: 10
}
export default function TwoColumnLayout({ leftComponent, rightComponent, type }) {
  const columnWidth = schemeMapping[type]
  return (
    <Grid container>
      <Grid container item>
        <Grid item xs={columnWidth}>
          {leftComponent}
        </Grid>
        <Grid item xs={12 - columnWidth} pl={2}>
          {rightComponent}
        </Grid>
      </Grid>
    </Grid>
  )
}
