import React from 'react'
import { FormControlLabel, MenuItem } from '@mui/material'
import CustomCheckbox from '../../../../common/components/CustomCheckbox'

export default function FilterMenuListItem({ checked, text, onClick, name }) {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: 16,
        fontWeight: 500,
        color: '#1C1C27',
        '& .MuiMenuItem-root': {
          padding: 0
        }
      }}
    >
      <FormControlLabel name={name} control={<CustomCheckbox checked={checked} />} label={text} />
    </MenuItem>
  )
}
