import React, { useEffect, useState, useCallback } from 'react'
import { Form } from 'react-final-form'
import { Box } from '@mui/material'
import BasedDrawer from '../../../common/components/Drawer/BasedDrawer'
import { PageNumberContext } from '../../../context/PageNumberContext'
import FirstPageForm from './FirstPageForm'
import SecondPageForm from './SecondPageForm'
import ThirdPageForm from './ThirdPageForm'
import {
  useAddPackageMutation,
  useLazyGetOneTrainingPackageQuery,
  useUpdatePackageMutation
} from '../../../api/trainingPackages/trainingPackages'
import customToastWithAlert from '../../../common/components/CustomToastWithAlert'
import { useNavigate } from 'react-router-dom'
import LoadingIndicator from '../../../common/components/LoadingIndicator'

const INITIAL_FORM_VALUES = {
  trainingModuleIds: [],
  type: 'MODULES',
  startDate: new Date(),
  deadlineType: 'NO_DEADLINE',
  available: true
}

export default function TrainingModal({ editId, title, handleClose, open }) {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES)

  const [addPackages, { data: addData, isSuccess: isAddSuccess, error: addError }] = useAddPackageMutation()
  const [updatePackages, { isSuccess: isEditSuccess, error: editError }] = useUpdatePackageMutation()
  const [getTraining, { data: trainingData, isLoading }] = useLazyGetOneTrainingPackageQuery()

  const loadTrainingData = useCallback(() => {
    if (editId) {
      getTraining(editId)
    }
  }, [editId, getTraining])

  useEffect(() => {
    loadTrainingData()
  }, [loadTrainingData])

  useEffect(() => {
    if (trainingData) {
      setFormValues({
        ...trainingData,
        deadlineType: trainingData.deadlineDate ? 'FIXED_DATE_DEADLINE' : 'NO_DEADLINE',
        available: !trainingData.endDate,
        groupUserSelection: {
          groupIds: trainingData.groupIds,
          userIds: trainingData.userIds
        }
      })
    }
  }, [trainingData])

  useEffect(() => {
    if (isAddSuccess) {
      customToastWithAlert({ type: 'success', message: 'Training added successfully' })
      navigate(`/training-packages/${addData?.id}`)
      handleClose()
    }
    if (addError) {
      customToastWithAlert({
        type: 'error',
        message: `${addError?.data?.description || 'Unknown error'}`
      })
    }
    if (isEditSuccess) {
      customToastWithAlert({ type: 'success', message: 'Training updated successfully' })
      navigate(`/training-packages/${editId}`)
      handleClose()
    }
    if (editError) {
      customToastWithAlert({
        type: 'error',
        message: `${editError?.data?.description || 'Unknown error'}`
      })
    }
  }, [isAddSuccess, addError, isEditSuccess, editError, addData?.id, editId])

  const handleFormSubmit = values => {
    const { deadlineType, users, available, groupUserSelection, ...rest } = values
    const payload = {
      ...rest,
      groupIds: groupUserSelection?.groupIds,
      userIds: groupUserSelection?.userIds
    }

    if (editId) {
      updatePackages({ id: editId, ...payload })
    } else {
      addPackages(payload)
    }
  }

  if (isLoading) {
    return (
      <Box display='flex' justifyContent='center' rowGap={2} width='100%'>
        <LoadingIndicator />
      </Box>
    )
  }

  return (
    <PageNumberContext.Provider value={{ page, setPage }}>
      <BasedDrawer open={open} handleClose={handleClose}>
        <Box
          id='customized-dialog-title'
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: 'Satoshi',
            fontWeight: 900,
            fontSize: 35
          }}
        >
          {title}
        </Box>
        <Form
          onSubmit={handleFormSubmit}
          initialValues={formValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, values }) => (
            <>
              {page === 1 && <FirstPageForm values={values} />}
              {page === 2 && <SecondPageForm values={values} />}
              {page === 3 && <ThirdPageForm values={values} handleSubmit={handleSubmit} />}
            </>
          )}
        />
      </BasedDrawer>
    </PageNumberContext.Provider>
  )
}
