import React from 'react'
import { ReactComponent as EmptyOverviewIcon } from '../../../../common/images/emptyOverview.svg'
import { Box, Container, Typography } from '@mui/material'
import AddStepButton from '../AddStepButton'

export default function EmptyOverview() {
  return (
    <Container
      maxWidth={'md'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '80vh'
      }}
    >
      <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <EmptyOverviewIcon />
      </Box>
      <Box width={'100%'} display={'flex'} flexDirection={'column'} rowGap={1} pb={3} alignItems={'center'}>
        <Typography variant='body1' fontWeight={700} fontFamily={'Plus Jakarta Sans'}>
          Elevate your security with phishing simulation campaigns
        </Typography>
        <Typography textAlign={'center'} variant='body1' fontWeight={500} fontFamily={'Plus Jakarta Sans'}>
          Harness our Phishing AI for detailed simulation campaigns that boost team vigilance, assess security, and
          strengthen defenses against cyber threats
        </Typography>
      </Box>
      <AddStepButton variant={'contained'} />
    </Container>
  )
}
