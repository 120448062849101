import dayjs from 'dayjs'
import React from 'react'
import Highlighter from 'react-highlight-words'

const TableNum = ({ children }) => <p style={{ fontWeight: 500 }}>{children}</p>

export const columns = () => {
  return [
    {
      headerName: 'Company name',
      width: 300,
      total: true,
      field: 'name',
      sortField: 'name',
      renderCell: ({ row, search }) => (
        <div className='row' style={{ alignItems: 'center' }}>
          <p
            style={{
              fontWeight: '600',
              fontSize: '16px',
              marginBottom: '8px'
            }}
          >
            <Highlighter
              highlightClassName='highlight-text'
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={row.name}
            />
          </p>
        </div>
      )
    },
    {
      field: 'createdAt',
      headerName: 'Date of registration',
      width: 230,
      editable: true,
      renderCell: ({ row }) => (
        <p style={{ fontWeight: 500 }}>{row.createdAt ? dayjs(row.createdAt).format('MMM DD, YYYY') : ''}</p>
      )
    },
    {
      field: 'paid',
      headerName: 'Plan',
      width: 230,
      editable: true,
      renderCell: ({ row }) => <TableNum>{row.paid ? 'Paid' : 'Free'}</TableNum>
    },
    {
      field: 'totalUsersCount',
      headerName: 'Employees ',
      width: 230,
      editable: true,
      renderCell: ({ row }) => <TableNum>{row.totalUsersCount}</TableNum>
    },
    {
      field: 'simulationsSentCount',
      headerName: 'Simulations',
      width: 230,
      editable: true,
      renderCell: ({ row }) => <TableNum>{row.simulationsSentCount}</TableNum>
    },
    {
      field: 'trainingPackagesCount',
      headerName: 'Training packages',
      width: 230,
      editable: true,
      renderCell: ({ row }) => <TableNum>{row.trainingPackagesCount}</TableNum>
    },
    {
      field: 'workflowsCount',
      headerName: 'Workflows',
      width: 230,
      editable: true,
      renderCell: ({ row }) => <TableNum>{row.workflowsCount}</TableNum>
    },
    {
      field: 'threatsReportedCount',
      headerName: 'Reported threats',
      width: 230,
      editable: true,
      renderCell: ({ row }) => <TableNum>{row.threatsReportedCount}</TableNum>
    }
  ]
}
