import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import { LoginPageNameMap } from './utils'

export default function SelectPageTypeDropdown({ setTemplateChanged, pageType, setPageType }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setTemplateChanged(true)
    setAnchorEl(event.currentTarget)
  }

  return (
    <div>
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          fontSize: 16,
          fontWeight: 400,
          fontFamily: 'Plus Jakarta Sans',
          textTransform: 'capitalize'
        }}
        columnGap={1}
        aria-controls='basic-menu'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        {LoginPageNameMap[pageType]}
        <IconButton onClick={handleClick}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {loginPageOptions.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setPageType(option)
              setAnchorEl(null)
            }}
          >
            {`${LoginPageNameMap[option]}`}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

const loginPageOptions = [
  'COMMON',
  'AWS',
  'BITBUCKET',
  //'DOCUSIGN',
  'DROPBOX',
  'ELASTIC_EMAIL',
  'FIGMA',
  'GITHUB',
  //'GITLAB',
  'GOOGLE',
  'JIRA',
  //'ATLASSIAN',
  'GOOGLE_MEET',
  'MICROSOFT_TEAMS'
]
