import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom'

import './styles.css'
import { Button, IconButton } from '@mui/material'
import Spacer from '../Spacer'

const TrainingCardsV3 = ({ modulePreview, onClose = () => {} }) => {
  const [activeCard, setActiveCard] = useState(1)
  const [answerSelected, setAnswerSelected] = useState(null)

  const handleNext = () => {
    if (activeCard < 3) {
      setActiveCard(activeCard + 1)
    }
  }

  const handlePerv = () => {
    if (activeCard > 1) {
      setActiveCard(activeCard - 1)
    }
  }

  return (
    <div className='cards-v3'>
      <div
        onClick={onClose}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: '0',
          left: '0'
        }}
      ></div>
      <IconButton disabled={activeCard == 1} style={{ opacity: activeCard == 1 && '0' }} onClick={handlePerv}>
        <svg width='65' height='65' viewBox='0 0 65 65' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <ellipse
            cx='32.4875'
            cy='32.4875'
            rx='32.4875'
            ry='32.4875'
            transform='matrix(-1 0 0 1 65 0)'
            fill='#271C60'
            fillOpacity='0.93'
          />
          <path d='M36.0543 25L28.0059 33L36.0543 41' stroke='#8883A1' strokeWidth='2.14039' strokeLinecap='round' />
        </svg>
      </IconButton>
      <div className='cards-carousel'>
        <div
          style={{
            transform: `rotate(-${activeCard == 1 ? '8' : activeCard == 2 ? '4' : '0'}deg)`
          }}
          className='cards-v3-item'
        >
          <h2>Quiz</h2>
          <p>{modulePreview.quizQuestion}</p>
          {modulePreview.quizAnswers.map(el => (
            <div
              className={`answer-option row row-center ${answerSelected?.answer == el.answer && el.isCorrect && 'answer-option-correct'} ${answerSelected?.answer == el.answer && !el.isCorrect && 'answer-option-wrong'}`}
              key={el.answer.toLowerCase().replaceAll(' ', '')}
              onClick={() => !answerSelected && setAnswerSelected(el)}
            >
              <div className='answer-option-marker'></div>
              {el.answer}
              <Spacer />
              {answerSelected?.answer == el.answer && el.isCorrect && (
                <>
                  <svg width='20' height='15' viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M0.975586 6.73649L8.0191 13.3171L18.9757 1.31714'
                      stroke='#1ED800'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                    />
                  </svg>
                </>
              )}
              {answerSelected?.answer == el.answer && !el.isCorrect && (
                <>
                  <svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M7.96973 7.97046L21.9697 21.9705'
                      stroke='#FF0000'
                      strokeWidth='1.23744'
                      strokeLinecap='round'
                    />
                    <path
                      d='M7.96973 21.9705L21.9697 7.97046'
                      stroke='#FF0000'
                      strokeWidth='1.23744'
                      strokeLinecap='round'
                    />
                  </svg>
                </>
              )}
              {answerSelected?.answer == el.answer && !el.isCorrect && (
                <div className='explanation-wrong'>
                  <span>{el.explanation}</span>
                </div>
              )}
            </div>
          ))}
          {answerSelected && answerSelected?.isCorrect && <p>That’s right!</p>}
          {answerSelected && !answerSelected?.isCorrect && (
            <Button
              style={{
                background: '#3D306D',
                color: '#fff',
                textTransform: 'none'
              }}
              onClick={() => setAnswerSelected(null)}
            >
              Reset quiz
            </Button>
          )}
        </div>
        <div
          style={{
            transform: `rotate(-${activeCard == 1 ? '4' : '0'}deg)`,
            display: activeCard < 3 ? 'block' : 'none'
          }}
          className='cards-v3-item'
        >
          <h2>What to do?</h2>
          <ul style={{ marginBottom: '20px' }}>
            {modulePreview.whatToDo.map(el => (
              <div className='row' key={el.replaceAll(' ', '').toLowerCase()}>
                <svg
                  style={{
                    marginTop: '8px',
                    marginRight: '12px',
                    flexShrink: '0'
                  }}
                  width='10'
                  height='10'
                  viewBox='0 0 10 10'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <ellipse cx='4.97309' cy='5.16731' rx='4.25531' ry='4.25532' fill='#1ED800' />
                </svg>
                <p style={{ marginBottom: '10px' }}>{el}</p>
              </div>
            ))}
          </ul>
          <h2>Importance of Topic</h2>
          <p>{modulePreview.importance}</p>
          <h2>Real Life Example</h2>
          <p>{modulePreview.realLifeExample}</p>
        </div>
        <div style={{ display: activeCard < 2 ? 'block' : 'none' }} className='cards-v3-item column'>
          <h2>{modulePreview.module}</h2>
          <p>{modulePreview.description}</p>
          <div
            style={{
              display: 'flex',
              flex: '1',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img style={{ height: '300px' }} src={modulePreview.imageUrl} alt='' />
          </div>
        </div>
      </div>
      <IconButton disabled={activeCard == 3} style={{ opacity: activeCard == 3 && '0' }} onClick={handleNext}>
        <svg width='65' height='65' viewBox='0 0 65 65' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <ellipse
            cx='32.4875'
            cy='32.4875'
            rx='32.4875'
            ry='32.4875'
            transform='matrix(-1 0 0 1 65 0)'
            fill='#271C60'
          />
          <path d='M29.9457 25L37.9941 33L29.9457 41' stroke='#8883A1' strokeWidth='1.60003' strokeLinecap='round' />
        </svg>
      </IconButton>
    </div>
  )
}

export default TrainingCardsV3
