import React from 'react'
import Archive from '../../../common/icons/archive'
import { ReactComponent as Planned } from '../../../common/icons/hourglassIcon.svg'
import Done from '../../../common/icons/done'
import SpinnerIcon from '../../../common/icons/spiner'

export default function StatusComponent({ status }) {
  const Icon = iconMapper[status]
  return (
    <div
      style={{
        display: 'flex',
        columnGap: 8,
        alignItems: 'center',
        color: status === 'COMPLETED' ? '#00BA97' : status === 'ARCHIVE' ? '#76767F' : '#1B1B26',
        fontFamily: 'Plus Jakarta Sans',
        fontSize: 14,
        fontWeight: 600
      }}
    >
      {Icon}
      {statusNameMapper[status]}
    </div>
  )
}

const statusNameMapper = {
  PLANNED: 'Planned',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  ARCHIVED: ' Archived'
}

const iconMapper = {
  COMPLETED: <Done />,
  ARCHIVED: <Archive />,
  PLANNED: <Planned />,
  IN_PROGRESS: <SpinnerIcon />
}
