import React, { useState, useEffect, useRef } from 'react'
import LoadingIndicator from '../../common/components/LoadingIndicator'
import { useOnScreen } from '../../hooks'
import { Box } from '@mui/material'
import EmptyTable from '../../common/components/DataTable/EmptyTable'

export function useInfiniteScroller({ apiHook, options, params, renderItem }) {
  const ref = useRef(null)
  const isVisible = useOnScreen(ref)
  const [getItems, { data: scrollData }] = apiHook()
  const [pages, setPages] = useState(0)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [infinityData, setInfinityData] = useState([])
  const { totalCount } = scrollData || { data: [], totalCount: 0 }

  const fetchMore = () => {
    if (!hasNextPage) return
    getItems({
      ...options,
      services: params.services,
      types: params.types,
      page: pages,
      size: options.size,
      searchText: params?.searchText
    }).then(res => {
      const fetchedData = res.data.data
      if (fetchedData.length < options.size) {
        setHasNextPage(false)
      }
      setInfinityData(prev => [...new Set([...prev, ...fetchedData])])
      setPages(prev => prev + 1)
    })
  }

  useEffect(() => {
    if (isVisible) {
      fetchMore()
    }
  }, [isVisible])

  useEffect(() => {
    setInfinityData([])
    setPages(0)
    setHasNextPage(true)
    getItems({
      ...options,
      services: params.services,
      types: params.types,
      page: 0,
      size: options.size,
      searchText: params?.searchText
    }).then(res => {
      const fetchedData = res.data.data
      if (fetchedData.length < options.size) {
        setHasNextPage(false)
      }
      setInfinityData(fetchedData)
      setPages(1)
    })
  }, [params?.services, params?.types, params?.searchText, options.size, totalCount])

  function InfiniteItems() {
    return (
      <>
        <Box gap={'8px'} display={'flex'} alignItems={'center'} width={'100%'} flexWrap={'wrap'}>
          {infinityData?.map(renderItem)}
        </Box>
        {hasNextPage && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              width: '100%'
            }}
            ref={ref}
          >
            <LoadingIndicator />
          </div>
        )}
        {!infinityData.length && <EmptyTable title={'No templates found'} />}
      </>
    )
  }

  return {
    InfiniteItems
  }
}
