import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Divider, Snackbar, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import authService from 'src/services/auth.service'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import settingsService from 'src/services/settings.service'

const InputText = styled(TextField)(() => ({
  border: '1px solid #E7E7EE',
  padding: '10px 16px',
  fontSize: '16px',
  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px'
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px'
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px'
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  }
}))

const AdConnectDialog = ({ open, onClose, onSuccess, update = false }) => {
  const [clientId, setClientId] = useState('')
  const [secretId, setSecretId] = useState('')
  const [tenantId, setTenantId] = useState('')

  const [error, setError] = useState(null)
  const [successSnack, setSuccessSnack] = useState(null)

  const [loading, setLoading] = useState(false)

  const clearForm = () => {
    setClientId('')
    setSecretId('')
    setTenantId('')
  }

  useEffect(() => {
    if (update) {
      settingsService
        .getADInfo()
        .then(resp => {
          setClientId(resp.data.clientId || '')
          setTenantId(resp.data.tenantId || '')
        })
        .catch(e => console.error(e))
    } else {
      setClientId('')
      setTenantId('')
    }
  }, [open])

  const handleSave = () => {
    setLoading(true)
    if (update) {
      authService
        .updateAD(clientId, secretId, tenantId)
        .then(() => {
          setError(null)
          onClose()
          onSuccess()
          setLoading(false)
          clearForm()
        })
        .catch(() => {
          setError('Something went wrong')
          setLoading(false)
        })
    } else {
      authService
        .healthAD(clientId, secretId, tenantId)
        .then(resp => {
          if (resp.data.status == 'CONNECTION_FAILURE') {
            setLoading(false)
            setError('Connection failure. Pelease check credentials')
          }
          if (resp.data.status == 'SUCCESSFULLY_CONNECTED') {
            authService
              .authorizeAD(clientId, secretId, tenantId)
              .then(() => {
                setError(null)
                clearForm()
                onClose()
                onSuccess()
                setLoading(false)
              })
              .catch(() => {
                setError('Something went wrong')
                setLoading(false)
              })
          }
        })
        .catch(error => {
          setLoading(false)
          setError(error.response.data.description)
        })
    }
  }

  return (
    <>
      <Dialog
        sx={{ '& .MuiPaper-root': { borderRadius: '22px' } }}
        open={open}
        onClose={() => {
          onClose()
          clearForm()
          setError(null)
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Satoshi',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: update ? '30px' : '35px',
            lineHeight: '110%',
            color: '#1B1B26'
          }}
        >
          {update ? 'Update ' : ''}Active Directory integration
        </DialogTitle>
        <DialogContent sx={{ width: '600px' }}>
          {loading && (
            <div
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                background: '#00000030',
                zIndex: '2'
              }}
              className='column column-center'
            >
              <LoadingIndicator text={'Please wait'} />
            </div>
          )}
          <p style={{ marginBottom: 24 }}>Please provide your AD details below</p>
          <InputText
            fullWidth
            sx={{ borderRadius: '20px', mb: '16px' }}
            label='Client ID'
            type='text'
            variant='standard'
            value={clientId}
            error={error}
            onChange={e => {
              setClientId(e.target.value)
              setError(null)
            }}
          />

          <InputText
            fullWidth
            sx={{ borderRadius: '20px', mb: '16px' }}
            label='Tenant ID'
            type='text'
            variant='standard'
            value={tenantId}
            error={error}
            onChange={e => {
              setTenantId(e.target.value)
              setError(null)
            }}
          />

          <InputText
            fullWidth
            sx={{ borderRadius: '20px', mb: '16px' }}
            label='Client Secret'
            type='text'
            variant='standard'
            value={secretId}
            error={error}
            onChange={e => {
              setSecretId(e.target.value)
              setError(null)
            }}
          />
          {error && (
            <p
              style={{
                marginTop: '-10px',
                marginBottom: '10px',
                fontSize: '14px'
              }}
              className='error-text'
            >
              {error}
            </p>
          )}
        </DialogContent>
        <Divider />
        <div className='row'>
          <Button
            disabled={loading}
            onClick={() => {
              onClose()
              clearForm()
              setError(null)
            }}
            sx={{
              height: '50px',
              textTransform: 'none',
              borderRadius: '14px',
              color: '#6123FF',
              borderColor: '#6123FF',
              flex: '1',
              margin: '24px 16px 24px 32px'
            }}
            variant={'outlined'}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            sx={{
              height: '50px',
              textTransform: 'none',
              borderRadius: '14px',
              backgroundColor: '#6123FF',
              flex: '1',
              margin: '24px 32px 24px 0px'
            }}
            variant={'contained'}
          >
            {update ? 'Update' : 'Connect'}
          </Button>
        </div>
      </Dialog>
      <Snackbar
        sx={{ '& .MuiSnackbarContent-root': { borderRadius: '8px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        disabled={loading}
        open={successSnack}
        autoHideDuration={6000}
        onClose={() => setSuccessSnack(false)}
        message={'Successfully connected!'}
      />
    </>
  )
}

export default AdConnectDialog
