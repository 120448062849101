import React, { useState } from 'react'
import { Button } from '@mui/material'
import { ReactComponent as ArchiveIcon } from '../../../common/icons/archiveBoxIcon.svg'
import { useArchiveTrainingPackageMutation } from '../../../api/trainingPackages/trainingPackages'
import ArchiveDialog from '../ArchiveDialog'

export default function ArchiveButton({ id, name, isHidden }) {
  const [isShowing, setIsShowing] = useState(false)
  const [archiveTraining] = useArchiveTrainingPackageMutation()
  function handleOpen() {
    setIsShowing(true)
  }
  function handleClose() {
    setIsShowing(false)
  }
  function handleArchive() {
    archiveTraining(id)
  }

  if (isHidden) {
    return null
  }
  return (
    <>
      <Button
        onClick={handleOpen}
        variant={'outlined'}
        startIcon={<ArchiveIcon />}
        sx={{
          height: '40px',
          borderRadius: '12px',
          textTransform: 'none',
          fontSize: '14px',
          fontWeight: 600,
          fontFamily: 'Plus Jakarta Sans',
          bgcolor: '#fff',
          border: '1px solid #E7E7EE',
          color: '#1B1B26',
          '&:hover': {
            border: 'none',
            bgcolor: '#F0E7F9'
          }
        }}
      >
        Archive
      </Button>
      {isShowing && <ArchiveDialog name={name} onArchive={handleArchive} onClose={handleClose} open={isShowing} />}
    </>
  )
}
