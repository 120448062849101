import React, { useEffect, useState } from 'react'
import { Dialog, Drawer, IconButton, Menu, MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Spacer from 'src/common/components/Spacer'
import PlusIcon from 'src/common/icons/plusIcon'

import './style.css'
import { DatePicker } from '@mui/x-date-pickers'
import RadioSelect from 'src/common/components/RadioSelect'
import useDebounce from 'src/hooks'
import templatesService from 'src/services/templates.service'
import simulationCampaignsService from 'src/services/simulation-campaigns.service'
import companyService from 'src/services/company.service'
import { toggleElement, toggleElementById } from 'src/heplers'
import dayjs from 'dayjs'
import CustomCheckbox from 'src/common/components/CustomCheckbox'
import Info from 'src/common/components/Info'
import { ChooseUsersGroups } from 'src/common/components/ChooseUsersGroups'
import AttachIcon from 'src/common/icons/attach'
import EditIcon from 'src/common/icons/editIcon'
import ViewIcon from 'src/common/icons/viewIcon'
import { InternalTemplatesItem } from './components/InternalTemplatesItem'
import { LoadNextPage } from './components/LoadNextPage'
import TemplateCreate from '../templates/components/templateCreate'
import { Button } from 'src/common/components/Button'
import { Letter } from 'react-letter'
import { SearchInput } from 'src/common/components/DataGrid'
import { CloseDrawer } from 'src/common/icons/closeDrawer'
import CustomTemplatePreview from '../manual-simulation/components/templatePreview'
import TemplateView from 'src/views/TemplateView'
import Highlighter from 'react-highlight-words'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import { InputText } from '../settings/components/phishingSimulations'

export const Circle = ({ size = 40, color = '#E7E7EE', children, style }) => (
  <div
    style={{
      width: size,
      height: size,
      borderRadius: size,
      background: color,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...style
    }}
  >
    {children}
  </div>
)

export const timelineOptions = [
  {
    name: 'Immediately',
    value: 'IMMEDIATELY',
    index: 1
  },
  {
    name: 'Throughout the Day',
    value: 'THROUGHOUT_DAY',
    index: 2
  },
  {
    name: 'Throughout the Week',
    value: 'THROUGHOUT_WEEK',
    index: 3
  },
  {
    name: 'Throughout the Month',
    value: 'THROUGHOUT_MONTH',
    index: 4
  },
  {
    name: 'Throughout 3 Months',
    value: 'THROUGHOUT_3_MONTHS',
    index: 5
  },
  {
    name: 'Throughout 6 Months',
    value: 'THROUGHOUT_6_MONTHS',
    index: 6
  },
  {
    name: 'Throughout the Year',
    value: 'THROUGHOUT_YEAR',
    index: 7
  }
]

const NewCampaignPage = ({ selectedId }) => {
  const navigate = useNavigate()

  const [form, setForm] = useState({})
  const [openLibDialog, setOpenLibDialog] = useState(false)
  const [openCreateTemplate, setOpenCreateTemplate] = useState(false)
  const [skipDateCheck, setSkipDateCheck] = useState(Boolean(selectedId))
  // const [campaigns, setCampaigns] = useState([])

  const [includeHeader, setIncludeHeader] = useState(false)
  const [header, setHeader] = useState('')
  const [headerName, setHeaderName] = useState('')

  const [loading, setLoading] = useState(true)
  const [openTemplatePreview, setOpenTemplatePreview] = useState(false)
  const [openInternalTemplatePreview, setOpenInternalTemplatePreview] = useState(false)

  const [totalCustomTemplates, setTotalCustomTemplates] = useState(null)
  const [allCustomTemplates, setAllCustomTemplates] = useState(null)
  const [allTemplates, setAllTemplates] = useState([])

  const [errors, setErrors] = useState(null)

  const [selectedCustomTemplates, setSelectedCustomTemplates] = useState([])
  const [selectedTemplates, setSelectedTemplates] = useState([])

  const [selectedTemplateId, setSelectedTemplateId] = useState(null)

  const [disableLoadMore, setDisableLoadMore] = useState(false)
  const [disableLoadMoreCustom, setDisableLoadMoreCustom] = useState(false)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [pageCustom, setPageCustom] = useState(0)

  const [selectedUsers, setSelectedUsers] = useState([])

  const TYPES = {
    mail: 'email',
    sms: 'SIMULATION_SMS'
  }
  const [type, setType] = useState(TYPES.mail)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const openTypeMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const debouncedSearchTerm = useDebounce(search, 500)

  const initialTemplatesFilter = { active: true, includeBody: true, size: 8 }

  const onFilter = filter => {
    Promise.all([
      templatesService.getTemplates({
        ...initialTemplatesFilter,
        ...filter
      }),
      templatesService.getCustomTemplates(filter.searchText || '', pageCustom, 8)
    ]).then(values => {
      const [resp, customTemplatesData] = values
      setAllTemplates([
        ...selectedTemplates.filter(el => el.subject.toLowerCase().includes(filter.searchText.toLowerCase())),
        ...resp.data.filter(el => !selectedTemplates.map(item => item.id).includes(el.id))
      ])
      setAllCustomTemplates([
        ...selectedCustomTemplates.filter(el => el.subject.toLowerCase().includes(filter.searchText.toLowerCase())),
        ...customTemplatesData.data.filter(el => !selectedCustomTemplates.map(item => item.id).includes(el.id))
      ])
      setPage(0)
      setPageCustom(0)
    })
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      onFilter({ searchText: search })
    } else {
      onFilter({ searchText: '' })
      setDisableLoadMore(false)
      setDisableLoadMoreCustom(false)
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (selectedId) {
      simulationCampaignsService
        .getSimulationCampaign(selectedId)
        .then(resp => {
          let preselectedTemplates = []
          let preselectedCustomTemplates = []

          setForm({ ...resp.data, nameOld: resp.data.name })
          if (resp.data.headers) {
            setIncludeHeader(true)
            setHeader(resp.data.headers[0].value || '')
            setHeaderName(resp.data.headers[0].name || '')
          }

          // console.log(resp.data?.customTemplates.find(el => el.subject == TYPES.sms));

          if (resp.data?.customTemplates.find(el => el.subject == TYPES.sms)) {
            setType(TYPES.sms)
          }
          // setSelectedTemplateItems([...(resp.data?.customTemplates || []), ...(resp.data?.internalTemplates || [])])
          setSelectedCustomTemplates(
            resp.data?.customTemplates.map(el => ({
              ...el,
              type: 'custom'
            })) || []
          )
          setSelectedTemplates(resp.data?.internalTemplates || [])
          setSelectedUsers(resp.data?.userIds)

          preselectedTemplates = resp.data?.internalTemplates || []
          preselectedCustomTemplates = resp.data?.customTemplates || []

          Promise.all([
            templatesService.getCustomTemplates('', pageCustom, 8),
            templatesService.getTemplates(initialTemplatesFilter),
            companyService.getCompanyConfigsSim()
          ])
            .then(values => {
              setLoading(false)
              const [customTemplatesData, templatesData, configsResp] = values
              if (!resp.data.headers) {
                setHeader(configsResp.data.headerValue || '')
                setHeaderName(configsResp.data.headerName || '')
              }
              setAllCustomTemplates([
                ...preselectedCustomTemplates,
                ...customTemplatesData.data.filter(
                  el => !preselectedCustomTemplates.map(item => item.id).includes(el.id)
                )
              ])
              setTotalCustomTemplates(customTemplatesData.headers['x-total-count'])
              setAllTemplates([
                ...preselectedTemplates,
                ...templatesData.data.filter(el => !preselectedTemplates.map(item => item.id).includes(el.id))
              ])
            })
            .catch(err => {
              console.error(err)
              setLoading(false)
            })
        })
        .catch(err => {
          setLoading(false)
          console.error(err)
        })
    } else {
      Promise.all([
        templatesService.getCustomTemplates('', pageCustom, 8),
        templatesService.getTemplates(initialTemplatesFilter),
        companyService.getCompanyConfigsSim(),
        simulationCampaignsService.getSimulationCampaigns()
      ])
        .then(values => {
          const [customTemplatesData, templatesData, configsResp, campaignsResp] = values
          setHeader(configsResp.data.headerValue || '')
          setHeaderName(configsResp.data.headerName || '')
          setAllCustomTemplates(customTemplatesData.data)
          setTotalCustomTemplates(customTemplatesData.headers['x-total-count'])
          setAllTemplates(templatesData.data)
          setForm({
            nameOld: 'Campaign #' + ((campaignsResp.data.length || 0) + 1),
            name: 'Campaign #' + ((campaignsResp.data.length || 0) + 1),
            groupIds: []
          })
          setLoading(false)
        })
        .catch(err => {
          console.error(err)
          setLoading(false)
        })
    }
  }, [])

  const handleNextPage = () => {
    templatesService.getTemplates({ ...initialTemplatesFilter, page: page + 1 }).then(resp => {
      if (resp.data?.length < 8) {
        setDisableLoadMore(true)
      }
      if (disableLoadMore) {
        setDisableLoadMore(false)
      }
      setAllTemplates([
        ...allTemplates,
        ...resp.data.filter(el => !selectedTemplates.map(item => item.id).includes(el.id))
      ])
      setPage(page + 1)
    })
  }

  const handleNextPageCustom = () => {
    const size = 8
    templatesService.getCustomTemplates(search, pageCustom + 1, size).then(resp => {
      if (resp.data?.length < 8) {
        setDisableLoadMoreCustom(true)
      }
      if (disableLoadMore) {
        setDisableLoadMoreCustom(false)
      }
      setAllCustomTemplates([
        ...allCustomTemplates,
        ...resp.data.filter(el => !selectedCustomTemplates.map(item => item.id).includes(el.id))
      ])
      setPageCustom(pageCustom + 1)
    })
  }

  const onOpenCreateTemplate = () => {
    setOpenCreateTemplate(true)
  }

  const handleSaveForm = () => {
    const campaignBody = {
      name: form.name.replace(/^\s+/, ''),
      startDate: form.startDate,
      timeline: form.timeline,
      customTemplateIds: selectedCustomTemplates.map(item => item.id) || [],
      internalTemplateIds: selectedTemplates.map(item => item.id) || [],
      ...(form.groupIds && form.groupIds.length && { groupIds: form.groupIds }),
      userIds: selectedUsers,
      ...(includeHeader && {
        headers: [
          {
            name: headerName,
            value: header
          }
        ]
      })
    }

    if (!campaignBody.name) {
      setErrors(errors ? { ...errors, name: 'Name is required' } : { name: 'Name is required' })
      return
    }

    if (selectedId) {
      simulationCampaignsService.updateSimulationCampaignById(selectedId, campaignBody).then(() => {
        navigate('/manual-simulation', {
          state: { campaignAction: 'updated' }
        })
      })
    } else {
      simulationCampaignsService.addSimulationCampaign(campaignBody).then(() => {
        navigate('/manual-simulation', {
          state: { campaignAction: 'created' }
        })
      })
    }
  }

  const handleGroupSelect = (id, bulk) => {
    if (Array.isArray(id)) {
      let newItems = form.groupIds
      if (typeof bulk !== 'undefined') {
        id.forEach(element => {
          newItems = bulk
            ? newItems.includes(element)
              ? newItems.filter(el => el !== element)
              : newItems
            : [...newItems, element]
        })
      }

      setForm({ ...form, groupIds: newItems })
    } else {
      setForm({ ...form, groupIds: toggleElement(form.groupIds, id) })
    }
  }
  const handleUserSelect = (id, bulk) => {
    if (Array.isArray(id)) {
      let newItems = selectedUsers
      if (typeof bulk !== 'undefined') {
        id.forEach(element => {
          newItems = bulk
            ? newItems.includes(element)
              ? newItems.filter(el => el !== element)
              : newItems
            : [...newItems, element]
        })
      }

      setSelectedUsers(newItems)
    } else {
      setSelectedUsers(toggleElement(selectedUsers, id))
    }
  }

  const hanldeSelectTimeline = el => {
    setForm({
      ...form,
      timeline: timelineOptions.find(opt => opt.name == el).value
    })
  }

  const handleTemplateCreate = (template, editedTemplate = false) => {
    if (template) {
      setSelectedCustomTemplates(
        toggleElementById(selectedCustomTemplates, {
          ...template,
          type: 'custom'
        })
      )
      templatesService.getCustomTemplates('', 0, 8).then(resp => {
        setAllCustomTemplates([
          ...selectedCustomTemplates,
          template,
          ...resp.data.filter(el => ![...selectedCustomTemplates, template].map(item => item.id).includes(el.id))
        ])
        setTotalCustomTemplates(resp.headers['x-total-count'])
        setDisableLoadMoreCustom(false)
        setPageCustom(0)
      })
      setSelectedTemplateId(null)
      setOpenCreateTemplate(false)
    } else {
      const newSelectedCustomTemplates = selectedCustomTemplates.map(el =>
        el.id == editedTemplate.id ? { ...editedTemplate, type: 'custom' } : el
      )
      setSelectedCustomTemplates(newSelectedCustomTemplates)

      setSelectedTemplateId(null)
      setOpenCreateTemplate(false)

      templatesService.getCustomTemplates('', 0, 8).then(resp => {
        setAllCustomTemplates([
          ...newSelectedCustomTemplates,
          ...resp.data.filter(el => ![...selectedCustomTemplates].map(item => item.id).includes(el.id))
        ])
        setTotalCustomTemplates(resp.headers['x-total-count'])
        setDisableLoadMoreCustom(false)
        setPageCustom(0)
      })
    }
  }

  const onEditTemplate = id => {
    setSelectedTemplateId(id)
    setOpenCreateTemplate(true)
  }

  const onPreviewTemplate = (id, isInternal) => {
    setSelectedTemplateId(id)
    if (isInternal) {
      setOpenInternalTemplatePreview(true)
    } else {
      setOpenTemplatePreview(true)
    }
  }

  const templatesFormInvalid = !selectedCustomTemplates?.length && !selectedTemplates?.length
  const groupsFormInvalid = !form.groupIds?.length && !selectedUsers?.length

  const formInvalid =
    groupsFormInvalid || templatesFormInvalid || !form.name || !form.timeline || (includeHeader ? !header : false)

  function ArrowBack() {
    return (
      <svg
        style={{ margin: '0px 5px 0px 5px' }}
        width='10'
        height='16'
        viewBox='0 0 10 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M8.75 0.5L1.25 8L8.75 15.5' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    )
  }

  return (
    <div
      className='column'
      style={{
        backgroundColor: '#F8F8FB',
        height: '100vh',
        overflowY: 'auto',
        padding: '20px'
      }}
    >
      <div className='row row-center create-campaign-actions'>
        <Button
          disabled={loading}
          startIcon={<ArrowBack />}
          onClick={() => {
            navigate('/manual-simulation')
          }}
        >
          Back
        </Button>
        <Spacer />
        <Button disabled={formInvalid} variant={'contained'} onClick={handleSaveForm}>
          {selectedId ? 'Update campaign' : 'Create campaign'}
        </Button>
      </div>
      {loading ? (
        <div style={{ height: 'calc(100vh - 120px)', overflow: 'auto' }}>
          <LoadingIndicator />
        </div>
      ) : (
        <div style={{ height: 'calc(100vh - 120px)', overflow: 'auto' }}>
          <div className='container-1035'>
            <h1
              className='create-campaign-editable'
              contentEditable='true'
              style={{ outline: 0 }}
              onInput={e => {
                e.preventDefault()
                setForm({
                  ...form,
                  name: e.currentTarget.textContent
                })
              }}
            >
              {form.nameOld}
            </h1>

            <div className='create-campaign-section'>
              <p className='create-campaign-section-title' style={{ marginRight: 20 }}>
                Campaign duration
              </p>
              <div className='row row-center'>
                <DatePicker
                  format={'DD/MM/YYYY'}
                  disabled={form.status == 'ARCHIVED'}
                  sx={{
                    marginRight: '20px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px'
                    },
                    '& .MuiOutlinedInput-input': {
                      padding: '12.5px 14px'
                    }
                  }}
                  minDate={skipDateCheck ? null : dayjs(new Date())}
                  value={dayjs(form.startDate)}
                  label='Start Date'
                  onChange={e => {
                    setForm({
                      ...form,
                      startDate: dayjs(e).format('YYYY-MM-DD')
                    })
                    setSkipDateCheck(false)
                  }}
                />
                {/* <DatePicker minDate={dayjs(form.startDate)} disabled={form.status == 'ARCHIVED'} sx={{ marginRight: '20px', '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiOutlinedInput-input': { padding: '12.5px 14px' } }} value={dayjs(form.endDate)} label="End Date" onChange={(e) => setForm({ ...form, endDate: dayjs(e).format('YYYY-MM-DD') })} /> */}
                <RadioSelect
                  style={{
                    width: '300px',
                    backgroundColor: '#fff',
                    border: '1px solid #E7E7EE',
                    borderRadius: '14px',
                    marginRight: '16px',
                    height: '48px'
                  }}
                  lowerCase={true}
                  options={timelineOptions.map(el => el.name)}
                  value={form.timeline ? timelineOptions.find(opt => opt.value == form.timeline)?.name : ''}
                  placeholder={'Timeline'}
                  onSelectOption={hanldeSelectTimeline}
                />
              </div>

              <div className='column' style={{ margin: '10px 0px 0px 0px' }}>
                <div className='row row-center' style={{ marginBottom: 20 }}>
                  <CustomCheckbox checked={includeHeader} onClick={() => setIncludeHeader(!includeHeader)} />
                  <p style={{ marginRight: 20 }}>Include header</p>
                </div>
                {includeHeader && (
                  <div
                    className='row row-center'
                    style={{
                      position: 'relative',
                      width: '400px'
                    }}
                  >
                    <InputText
                      fullWidth
                      sx={{ borderRadius: '20px' }}
                      label={`Header (${headerName})`}
                      variant='standard'
                      onChange={e => {
                        setHeader(e.target.value)
                      }}
                      value={header}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: 25,
                        right: 10
                      }}
                    >
                      <Info
                        text={
                          'Define the email header for internal identification of our phishing simulations by your security team.'
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='create-campaign-section'>
              <p className='create-campaign-section-title'>
                Templates
                {!(selectedId || [...selectedCustomTemplates, ...selectedTemplates].length > 0) && (
                  <>
                    <span
                      onClick={openTypeMenu}
                      className='users-groups-switcher'
                      style={{
                        color: '#6123FF',
                        cursor: 'pointer',
                        marginLeft: 10,
                        fontWeight: 700
                      }}
                    >
                      {type == TYPES.mail ? 'Email' : 'SMS'}
                      <svg
                        style={{
                          margin: '0 10px',
                          transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                          transition: '300ms'
                        }}
                        width='18'
                        height='10'
                        viewBox='0 0 18 10'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1.5 1L9 8.5L16.5 1'
                          stroke='#6123FF'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <Menu
                      sx={{
                        '& .MuiPaper-root': {
                          color: '#1B1B26',
                          borderRadius: '12px'
                        },
                        '& li': {
                          opacity: '0.7',
                          fontFamily: 'Plus Jakarta Sans'
                        },
                        '& li:hover': {
                          opacity: '1'
                        }
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => setAnchorEl(null)}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button'
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}
                    >
                      <MenuItem
                        // disabled={type == TYPES.mail}
                        onClick={() => {
                          setAnchorEl(null)
                          setType(TYPES.mail)
                          setForm({
                            ...form,
                            nameOld: form.nameOld?.replace('SMS ', ''),
                            name: form.name?.replace('SMS ', '')
                          })
                        }}
                      >
                        Email
                      </MenuItem>
                      <MenuItem
                        disabled={type} //todo: == TYPES.sms
                        onClick={() => {
                          setAnchorEl(null)
                          setType(TYPES.sms)
                          setForm({
                            ...form,
                            nameOld: 'SMS ' + form.nameOld,
                            name: 'SMS ' + form.name
                          })
                        }}
                      >
                        SMS
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </p>
              {[...selectedCustomTemplates, ...selectedTemplates].length ? (
                <div className='row templates-grid'>
                  {[...selectedCustomTemplates, ...selectedTemplates].map(el => (
                    <div key={el.id} className={`templates-grid-item column`}>
                      <div className='templates-grid-item-img'>
                        <div className='template-thumbbail'>
                          <div
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            <Letter html={el?.body} />
                          </div>
                        </div>
                        {type == TYPES.sms && (
                          <Button
                            variant={'text'}
                            sx={{
                              color: '#76767F',
                              textTransform: 'none',
                              fontSize: '13px',
                              position: 'absolute',
                              top: '4px',
                              right: '4px',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                            startIcon={<EditIcon color='#76767F' size='18' />}
                            onClick={() => onEditTemplate(el.id)}
                          >
                            <span>Edit</span>{' '}
                          </Button>
                        )}
                        <Button
                          variant={'text'}
                          sx={{
                            color: '#76767F',
                            textTransform: 'none',
                            fontSize: '13px',
                            position: 'absolute',
                            top: type == TYPES.sms ? '38px' : '6px',
                            right: '4px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                          }}
                          startIcon={<ViewIcon color='#76767F' size='18' />}
                          onClick={() => onPreviewTemplate(el.id, el.type !== 'custom')}
                        >
                          <span>View</span>{' '}
                        </Button>
                      </div>
                      <div className='row row-start'>
                        <div
                          className='column'
                          style={{
                            marginLeft: '8px'
                          }}
                        >
                          <p
                            onClick={() => onPreviewTemplate(el.id, el.type !== 'custom')}
                            className='templates-grid-item-title text-shrink-2'
                          >
                            {el?.subject == TYPES.sms ? el.name : el.subject}
                          </p>
                          <p className='templates-grid-item-domain'>{el?.sender}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    background: '#fafafa',
                    borderRadius: '12px',
                    padding: 20,
                    marginBottom: 20
                  }}
                  className='column column-center'
                >
                  <svg width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M30 37.5V26.25C30 25.2554 30.3951 24.3016 31.0983 23.5984C31.8016 22.8951 32.7554 22.5 33.75 22.5H101.25C102.245 22.5 103.198 22.8951 103.902 23.5984C104.605 24.3016 105 25.2554 105 26.25V78.75C105 79.7445 104.605 80.6984 103.902 81.4016C103.198 82.1049 102.245 82.5 101.25 82.5H90'
                      fill='#E7E7EE'
                    />
                    <rect x='17' y='38' width='73' height='47' fill='white' />
                    <path
                      d='M86.25 37.5H18.75C16.6789 37.5 15 39.1789 15 41.25V93.75C15 95.8211 16.6789 97.5 18.75 97.5H86.25C88.3211 97.5 90 95.8211 90 93.75V41.25C90 39.1789 88.3211 37.5 86.25 37.5Z'
                      stroke='#E7E7EE'
                      strokeWidth='5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M15 52.5H90'
                      stroke='#E7E7EE'
                      strokeWidth='5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>

                  <p
                    style={{
                      textAlign: 'center',
                      color: '#6a6a6a'
                    }}
                  >
                    You need at least one template
                  </p>
                </div>
              )}

              <div className='column column-center'>
                <div className='row row-center'>
                  <Button
                    onClick={onOpenCreateTemplate}
                    startIcon={<PlusIcon />}
                    sx={{
                      borderColor: '#E7E7EE',
                      borderRadius: '12px',
                      color: '#6123FF',
                      textTransform: 'none',
                      flex: '1',
                      mr: '12px',
                      width: '300px',
                      flexShrink: 0
                    }}
                    variant={'outlined'}
                  >
                    New Template
                  </Button>
                  {type == TYPES.mail && (
                    <Button
                      onClick={() => {
                        setOpenLibDialog(true)
                      }}
                      startIcon={<AttachIcon />}
                      sx={{
                        borderColor: '#E7E7EE',
                        borderRadius: '12px',
                        color: '#6123FF',
                        textTransform: 'none',
                        flex: '1',
                        mr: '12px',
                        width: '300px',
                        flexShrink: 0
                      }}
                      variant={'outlined'}
                    >
                      Browse Library
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <div className='create-campaign-section'>
              {/* <p className='create-campaign-section-title'>Groups/Users</p> */}
              <div className='column'>
                <ChooseUsersGroups
                  selectedGroups={form.groupIds}
                  selectedUsers={selectedUsers}
                  onSelectGroup={handleGroupSelect}
                  onSelectUser={handleUserSelect}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <Dialog
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '22px',
            maxWidth: 'unset'
          }
        }}
        open={openLibDialog}
        onClose={() => {
          setOpenLibDialog(false)
          setSearch('')
        }}
      >
        <div className='templates-lib-dialog'>
          <div className='row row-center mb20'>
            <p className='create-campaign-dialog-title'>Choose templates</p>
            <SearchInput
              sx={{ margin: '0px 0px 0px 20px', width: '400px' }}
              onChange={e => setSearch(e.target.value)}
              value={search}
              size='small'
              placeholder='Search'
            />
            <Spacer />
            <IconButton
              onClick={() => {
                setOpenLibDialog(false)
                setSearch('')
              }}
            >
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M18.75 5.25L5.25 18.75' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M18.75 18.75L5.25 5.25' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
              </svg>
            </IconButton>
          </div>
          <div className='templates-lib-dialog-container mb20'>
            {/* <SearchInput sx={{ margin: '20px 0px', width: '100%' }} onChange={(e) => setSearch(e.target.value)} value={search} size='small' placeholder='Search' /> */}
            <div style={{ width: '100%', marginBottom: 20 }} className='row row-center'>
              <p style={{ color: '#aaa', marginRight: 20 }}>Custom templates</p>
              <div
                style={{
                  flex: 1,
                  height: '1px',
                  background: '#bbb'
                }}
              ></div>
            </div>
            <div className='row templates-grid'>
              {allCustomTemplates &&
                allCustomTemplates
                  .filter(el => el.subject !== 'SIMULATION_SMS')
                  .map(el => (
                    <div
                      key={el.id}
                      className={`templates-grid-item column ${selectedCustomTemplates.map(item => item.id).includes(el.id) && ' active'}`}
                    >
                      <div className='templates-grid-item-img'>
                        <div
                          onClick={e => {
                            e.preventDefault()
                          }}
                        >
                          <div
                            className='template-thumbbail'
                            dangerouslySetInnerHTML={{
                              __html: el.body.replaceAll(
                                ' src="cid:[qrCodeImage]"',
                                'width="30px" src="https://admin.cyberlift.io/images/qr-in-template.jpg"'
                              )
                            }}
                          ></div>
                        </div>
                        {/* to fix editor first */}
                        <Button
                          variant={'text'}
                          sx={{
                            color: '#76767F',
                            textTransform: 'none',
                            fontSize: '13px',
                            position: 'absolute',
                            top: '4px',
                            right: '4px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                          }}
                          startIcon={<EditIcon color='#76767F' size='18' />}
                          onClick={() => onEditTemplate(el.id)}
                        >
                          <span>Edit</span>{' '}
                        </Button>
                        <Button
                          variant={'text'}
                          sx={{
                            color: '#76767F',
                            textTransform: 'none',
                            fontSize: '13px',
                            position: 'absolute',
                            top: '36px',
                            right: '4px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                          }}
                          startIcon={<ViewIcon color='#76767F' size='18' />}
                          onClick={() => onPreviewTemplate(el.id)}
                        >
                          <span>View</span>{' '}
                        </Button>
                      </div>
                      <div className='row row-start'>
                        <CustomCheckbox
                          checked={selectedCustomTemplates.map(item => item.id).includes(el.id)}
                          onClick={() => {
                            setSelectedCustomTemplates(
                              toggleElementById(selectedCustomTemplates, {
                                ...el,
                                type: 'custom'
                              })
                            )
                            // setSelectedTemplateItems(toggleElementById(selectedTemplateItems, { ...el, type: 'custom' }))
                          }}
                        />
                        <div
                          className='column'
                          style={{
                            marginLeft: '8px'
                          }}
                        >
                          <p
                            onClick={() => onPreviewTemplate(el.id)}
                            className='templates-grid-item-title text-shrink-2'
                          >
                            <Highlighter
                              highlightClassName='highlight-text'
                              searchWords={[search]}
                              autoEscape={true}
                              textToHighlight={el.subject}
                            />
                          </p>
                          <p className='templates-grid-item-domain'>{el.sender}</p>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>

            {!disableLoadMoreCustom && allCustomTemplates?.length > 4 && (
              <LoadNextPage onHandleNextPage={handleNextPageCustom} />
            )}

            <div style={{ width: '100%', marginBottom: 20 }} className='row row-center'>
              <p style={{ color: '#aaa', marginRight: 20 }}>System templates</p>
              <div
                style={{
                  flex: 1,
                  height: '1px',
                  background: '#bbb'
                }}
              ></div>
            </div>
            <div className='row templates-grid'>
              {allTemplates &&
                allTemplates.map(el => (
                  <InternalTemplatesItem
                    search={search}
                    key={el.id}
                    el={el}
                    onPreviewTemplate={onPreviewTemplate}
                    selectedInternalTemplates={selectedTemplates}
                    // selectedTemplateItems={selectedTemplateItems}
                    setSelectedInternalTemplates={setSelectedTemplates}
                    // setSelectedTemplateItems={setSelectedTemplateItems}
                  />
                ))}
            </div>

            {!disableLoadMore && allTemplates?.length > 4 && <LoadNextPage onHandleNextPage={handleNextPage} />}
          </div>
          <div className='row row-center'>
            <Spacer />
            <Button
              variant='contained'
              onClick={() => {
                setOpenLibDialog(false)
                setSearch('')
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '22px',
            maxWidth: 'unset'
          }
        }}
        open={openCreateTemplate}
        onClose={() => {
          setOpenCreateTemplate(false)
          setSelectedTemplateId(null)
        }}
      >
        <div className='create-template-container'>
          <TemplateCreate
            isSms={type == TYPES.sms}
            totalTemplates={totalCustomTemplates}
            onClose={() => {
              setOpenCreateTemplate(false)
              setSelectedTemplateId(null)
            }}
            selectedTemplateId={selectedTemplateId}
            onCreate={handleTemplateCreate}
          />
        </div>
      </Dialog>

      <Drawer
        sx={{ zIndex: 30000 }}
        anchor='right'
        open={openTemplatePreview}
        onClose={() => {
          setOpenTemplatePreview(false)
          setSelectedTemplateId(null)
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: '24px',
            right: '1182px',
            cursor: 'pointer'
          }}
          onClick={() => {
            setOpenTemplatePreview(false)
            setSelectedTemplateId(null)
          }}
        >
          <CloseDrawer />
        </div>
        <CustomTemplatePreview id={selectedTemplateId} />
      </Drawer>
      <Drawer
        sx={{ zIndex: 30000 }}
        anchor='right'
        open={openInternalTemplatePreview}
        onClose={() => {
          setOpenInternalTemplatePreview(false)
          setSelectedTemplateId(null)
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: '24px',
            right: '1182px',
            cursor: 'pointer'
          }}
          onClick={() => {
            setOpenInternalTemplatePreview(false)
            setSelectedTemplateId(null)
          }}
        >
          <CloseDrawer />
        </div>
        <TemplateView id={selectedTemplateId} />
      </Drawer>
    </div>
  )
}

export default NewCampaignPage
