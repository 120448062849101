import React from 'react'
import { Box, Typography } from '@mui/material'

export default function NameEmailColumn({ row }) {
  return (
    <>
      <Typography noWrap fontFamily={'Plus Jakarta Sans'} fontWeight={600} fontSize={14} color={'#1B1B26'}>
        {row.firstName} {row.lastName}
      </Typography>
      <Box display={'flex'} columnGap={1} alignItems={'center'}>
        <Typography noWrap fontFamily={'Plus Jakarta Sans'} fontWeight={400} fontSize={13} color={'#1B1B26'}>
          {row.email}
        </Typography>
      </Box>
    </>
  )
}
