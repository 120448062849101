import React from 'react'

export const ToggleIcon = ({ color = '#1B1B26' }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.2'
        d='M7.5 15C9.15685 15 10.5 13.6569 10.5 12C10.5 10.3431 9.15685 9 7.5 9C5.84315 9 4.5 10.3431 4.5 12C4.5 13.6569 5.84315 15 7.5 15Z'
        fill={color}
      />
      <path
        d='M7.49999 6H16.5C19.8137 6 22.5 8.68629 22.5 12V12C22.5 15.3137 19.8137 18 16.5 18H7.49999C4.18629 18 1.5 15.3137 1.5 12V12C1.5 8.68629 4.18629 6 7.49999 6Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 15C9.15685 15 10.5 13.6569 10.5 12C10.5 10.3431 9.15685 9 7.5 9C5.84315 9 4.5 10.3431 4.5 12C4.5 13.6569 5.84315 15 7.5 15Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
