import React from 'react'
import { Field } from 'react-final-form'
import TextField from '@mui/material/TextField'

const required = value => (value ? undefined : 'This field is required')

export default function TemplateNameField() {
  return (
    <Field
      name={'name'}
      validate={required}
      render={({ input, meta }) => (
        <TextField
          {...input}
          label={'Template name'}
          variant={'outlined'}
          fullWidth
          InputLabelProps={{
            shrink: false,
            sx: {
              borderColor: 'none',
              fontFamily: 'Plus Jakarta Sans',
              fontSize: '14px',
              fontWeight: 400,
              top: input.value ? '5px' : '35%',
              transform: input.value ? 'translateY(0)' : 'translateY(-50%)',
              padding: '0px 12px',
              '&.Mui-focused': {
                top: '5px',
                background: 'transparent',
                transform: 'translateY(0)',
                color: '#76767F',
                fontSize: '14px'
              }
            }
          }}
          InputProps={{
            sx: {
              border: 'none',
              fontFamily: 'Plus Jakarta Sans',
              fontSize: '16px',
              fontWeight: 400,
              borderRadius: '14px',
              padding: '0 16px',
              backgroundColor: '#fff',
              '&.Mui-focused': {
                borderColor: '#76767F'
              }
            }
          }}
          sx={{
            '& .MuiInputBase-root': {
              display: 'flex',
              height: 50,
              padding: '0 16px'
            },
            '& .MuiInputBase-input': {
              marginTop: '12px',
              padding: '0px'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            }
          }}
          error={meta.error && meta.touched}
          helperText={meta.error && meta.touched ? meta.error : ''}
        />
      )}
    />
  )
}
