import { apiSlice } from '../apiSlice'
import { SIMULATIONS } from '../tags'

export const simulationAPI = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSimulations: builder.query({
      query: params => {
        return {
          url: 'v2/simulation-campaigns',
          method: 'GET',
          params
        }
      },
      transformResponse: (response, meta) => {
        const totalCount = meta.response.headers.get('X-Total-Count')
        return { data: response, totalCount }
      },
      providesTags: [SIMULATIONS.SIMULATION]
    }),
    getOneSimulation: builder.query({
      query: id => `v2/simulation-campaigns/${id}`,
      providesTags: [SIMULATIONS.SIMULATION]
    }),
    postSimulation: builder.mutation({
      query: body => ({
        url: 'v2/simulation-campaigns',
        method: 'POST',
        body
      }),
      invalidatesTags: [SIMULATIONS.SIMULATION]
    }),
    putSimulation: builder.mutation({
      query: body => ({
        url: `v2/simulation-campaigns/${body.id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [SIMULATIONS.SIMULATION]
    }),
    archiveSimulation: builder.mutation({
      query: id => ({
        url: `v2/simulation-campaigns/${id}/archive`,
        method: 'POST'
      }),
      invalidatesTags: [SIMULATIONS.SIMULATION]
    }),
    cloneSimulation: builder.mutation({
      query: id => ({
        url: `v2/simulation-campaigns/${id}/clone`,
        method: 'POST'
      }),
      invalidatesTags: [SIMULATIONS.SIMULATION]
    }),
    unarchiveSimulation: builder.mutation({
      query: id => ({
        url: `v2/simulation-campaigns/${id}/unarchive`,
        method: 'POST'
      }),
      invalidatesTags: [SIMULATIONS.SIMULATION]
    }),

    addStepSimulation: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `v2/simulation-campaigns/${id}/steps`,
        method: 'POST',
        body
      }),
      invalidatesTags: [SIMULATIONS.SIMULATION]
    }),
    updateStepSimulation: builder.mutation({
      query: ({ simulationId, ...body }) => ({
        url: `v2/simulation-campaigns/${simulationId}/steps/${body.id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [SIMULATIONS.SIMULATION]
    }),
    deleteStepSimulation: builder.mutation({
      query: ({ simulationId, stepId }) => ({
        url: `v2/simulation-campaigns/${simulationId}/steps/${stepId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [SIMULATIONS.SIMULATION]
    }),

    getCustomTemplatesList: builder.query({
      query: params => ({
        url: 'v1/custom-templates',
        method: 'GET',
        params
      }),
      transformResponse: (response, meta) => {
        const totalCount = meta.response.headers.get('X-Total-Count')
        return { data: response, totalCount }
      },
      providesTags: [SIMULATIONS.TEMPLATES]
    }),

    getTemplatesList: builder.query({
      query: params => ({
        url: 'v1/templates',
        method: 'GET',
        params
      }),
      transformResponse: (response, meta) => {
        const totalCount = meta.response.headers.get('X-Total-Count')
        return { data: response, totalCount }
      },
      providesTags: [SIMULATIONS.TEMPLATES]
    }),
    removeCustomTemplate: builder.mutation({
      query: id => ({
        url: `v1/custom-templates/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [SIMULATIONS.TEMPLATES]
    }),

    getTemplatesTypes: builder.query({
      query: () => `v1/templates/types`,
      providesTags: [SIMULATIONS.TEMPLATES]
    }),
    getTemplatesServices: builder.query({
      query: () => `v1/templates/services`,

      providesTags: [SIMULATIONS.TEMPLATES]
    }),
    getSimulationUsersList: builder.query({
      query: ({ id, ...params }) => {
        return {
          url: `v2/simulation-campaigns/${id}/users`,
          method: 'GET',
          params
        }
      },
      transformResponse: (response, meta) => {
        const totalCount = meta.response.headers.get('X-Total-Count')
        return { data: response, totalCount }
      },
      providesTags: [SIMULATIONS.USERS]
    }),
    getSimulationFilterCounters: builder.query({
      query: () => `/v2/simulation-campaigns/counters`,
      providesTags: [SIMULATIONS.COUNTERS]
    }),
    getSimulationStats: builder.query({
      query: ({ id }) => `/v2/simulation-campaigns/${id}/simulation-stats`,
      providesTags: [SIMULATIONS.SIMULATION]
    }),
    getUserSimulationInfo: builder.query({
      query: ({ id, userId }) => `/v2/simulation-campaigns/${id}/users/${userId}`,
      providesTags: [SIMULATIONS.USERS]
    }),
    downloadUserListCSV: builder.query({
      query: ({ id }) => ({
        url: `/v2/simulation-campaigns/${id}/export-users`,
        method: 'GET',
        responseHandler: response => response.blob()
      }),
      invalidatesTags: [SIMULATIONS.USERS]
    }),
    getStepSimulationStatistics: builder.query({
      query: ({ id, stepId, ...params }) => {
        return {
          url: `v2/simulation-campaigns/${id}/steps/${stepId}/simulations`,
          method: 'GET',
          params
        }
      },
      transformResponse: (response, meta) => {
        const totalCount = meta.response.headers.get('X-Total-Count')
        return { data: response, totalCount }
      },
      invalidatesTags: [SIMULATIONS.USERS]
    }),
    getCommonStepData: builder.query({
      query: ({ id, stepId }) => `v2/simulation-campaigns/${id}/steps/${stepId}`,
      providesTags: [SIMULATIONS.USERS]
    }),
    addCustomTemplate: builder.mutation({
      query: body => {
        return {
          url: 'v1/custom-templates',
          method: 'POST',
          body
        }
      },
      invalidatesTags: [SIMULATIONS.TEMPLATES]
    }),
    updateCustomTemplate: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `v1/custom-templates/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [SIMULATIONS.TEMPLATES]
    }),
    sendToMeDraft: builder.mutation({
      query: body => ({
        url: 'v1/custom-templates/send-draft',
        method: 'POST',
        body
      })
    }),
    sendToMeCustomTemplate: builder.mutation({
      query: id => ({
        url: `v1/custom-templates/${id}/send`,
        method: 'POST'
      })
    }),
    sendAiDraftPrompt: builder.mutation({
      query: (prompt, lang = 'English') => ({
        url: 'v1/chat/template/',
        method: 'POST',
        body: { message: prompt, language: lang }
      })
    }),
    getCustomTemplatesDomains: builder.query({
      query: () => ({
        url: `v1/custom-templates/domains`,
        method: 'GET'
      })
    }),
    getCustomTemplateById: builder.query({
      query: id => `v1/custom-templates/${id}`,
      providesTags: [SIMULATIONS.TEMPLATES]
    }),
    getSystemTemplateById: builder.query({
      query: id => `v1/templates/${id}`,
      providesTags: [SIMULATIONS.TEMPLATES]
    }),
    getLoginPageHtml: builder.query({
      query: ({ loginPage }) => `v1/phishing-pages/${loginPage}`,
      providesTags: [SIMULATIONS.TEMPLATES]
    })
  })
})

export const {
  useGetLoginPageHtmlQuery,
  useLazyGetSystemTemplateByIdQuery,
  useLazyGetCustomTemplateByIdQuery,
  useAddCustomTemplateMutation,
  useUpdateCustomTemplateMutation,
  useSendToMeCustomTemplateMutation,
  useSendToMeDraftMutation,
  useSendAiDraftPromptMutation,
  useGetCustomTemplatesDomainsQuery,
  useGetCommonStepDataQuery,
  useGetStepSimulationStatisticsQuery,
  useLazyGetStepSimulationStatisticsQuery,
  useLazyDownloadUserListCSVQuery,
  useGetUserSimulationInfoQuery,
  useRemoveCustomTemplateMutation,
  useArchiveSimulationMutation,
  useCloneSimulationMutation,
  useUnarchiveSimulationMutation,
  useGetSimulationStatsQuery,
  useGetSimulationFilterCountersQuery,
  useLazyGetCustomTemplatesListQuery,
  useGetSimulationUsersListQuery,
  useLazyGetSimulationUsersListQuery,
  useLazyGetTemplatesListQuery,
  useGetTemplatesTypesQuery,
  useGetTemplatesServicesQuery,
  useAddStepSimulationMutation,
  useUpdateStepSimulationMutation,
  useDeleteStepSimulationMutation,
  useGetSimulationsQuery,
  useGetOneSimulationQuery,
  useLazyGetOneSimulationQuery,
  useLazyGetSimulationsQuery,
  usePostSimulationMutation,
  usePutSimulationMutation
} = simulationAPI
