import React from 'react'
import { ReactComponent as ExcelIcon } from '../../../../common/icons/excel_icon.svg'
import { ReactComponent as PdfIcon } from '../../../../common/icons/pdf_icon.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'

export default function SelectExtensionDropdown({ selectedExtension, setTemplateChanged, setSelectedExtension }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setTemplateChanged(true)
    setAnchorEl(event.currentTarget)
  }

  return (
    <div>
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          fontSize: 16,
          fontWeight: 400,
          fontFamily: 'Plus Jakarta Sans',
          textTransform: 'capitalize'
        }}
        columnGap={1}
        aria-controls='basic-menu'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        {selectedExtension === 'pdf' ? <PdfIcon /> : <ExcelIcon />}
        {selectedExtension}
        <IconButton onClick={handleClick}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          onClick={() => {
            setSelectedExtension('pdf')
            setAnchorEl(null)
          }}
        >
          <PdfIcon
            style={{
              paddingRight: '8px'
            }}
          />
          Pdf
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSelectedExtension('xls')
            setAnchorEl(null)
          }}
        >
          <ExcelIcon
            style={{
              paddingRight: '8px'
            }}
          />
          Xls
        </MenuItem>
      </Menu>
    </div>
  )
}
