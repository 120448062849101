import React, { useContext } from 'react'
import { PageNumberContext } from './PageNumberContext'
import ChooseTypeCard from './ChooseTypeCard'
import { Field, useFormState } from 'react-final-form'
import { Box } from '@mui/material'

import GenericSelect from '../../../common/components/GenericSelect'

import { phoneNumbersOptions } from './utils'
import MessageField from './MessageField'

export default function SmsWhatsappForm({ isSlack }) {
  const { values } = useFormState()
  const { setPage } = useContext(PageNumberContext)

  const cardMapper = {
    AUTOMATED_EMAIL: {
      type: 'AUTOMATED_EMAIL',
      title: 'AI-Generated Email',
      description:
        'Cyberlift will automatically generate a unique and personalized template for each employee using AI',
      isAI: true,
      onChange: () => setPage(1)
    },
    MANUAL_EMAIL: {
      type: 'MANUAL_EMAIL',
      title: 'Custom Email',
      description: 'Select a template from the database or create your own email from scratch to suit your needs',
      isAI: true,
      onChange: () => setPage(1)
    },
    SMS: {
      type: 'SMS',
      title: 'SMS',
      description: 'Send text messages automatically',
      isAI: false,
      onChange: () => setPage(1)
    },
    WHATSAPP: {
      type: 'WHATSAPP',
      title: 'Whatsapp',
      description: 'Automate WhatsApp messages',
      isAI: false,
      onChange: () => setPage(1)
    },
    SLACK: {
      type: 'SLACK',
      title: 'Slack',
      description: 'Post messages to Slack channels',
      isAI: false,
      onChange: () => setPage(1)
    },
    PHONE: {
      type: 'PHONE',
      title: 'Phone Call',
      description: 'Automate phone call setups',
      isAI: false,
      onClick: () => {},
      isDisabled: true
    }
  }

  return (
    <Box display={'flex'} flexDirection={'column'} rowGap={3}>
      <ChooseTypeCard {...cardMapper[values?.type]} />
      {!isSlack && (
        <Field
          name={'metadata.phoneNumber'}
          render={({ input }) => (
            <GenericSelect
              label={'Select phone number'}
              options={phoneNumbersOptions}
              value={input.value}
              onChange={input.onChange}
            />
          )}
        />
      )}
      <Field name={'metadata.message'} component={MessageField} />
    </Box>
  )
}
