import React, { useEffect, useRef } from 'react'
import { Box, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import GenericSelect from '../../../common/components/GenericSelect'
import { useFormState } from 'react-final-form'

export default function MessageField({ input }) {
  const { values } = useFormState()
  const isSMS = values?.type === 'SMS'
  const [fieldValue, setFieldValue] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { value, onChange } = input
  const textFieldRef = useRef(null)

  useEffect(() => {
    onChange(fieldValue)
  }, [fieldValue])

  useEffect(() => {
    setFieldValue(value)
  }, [])

  const handleVariableChange = e => {
    const variable = e.target.value
    const cursorPosition = textFieldRef.current.selectionStart
    const newValue = fieldValue.slice(0, cursorPosition) + variable + fieldValue.slice(cursorPosition)

    if (newValue.length > 160 && isSMS) {
      setErrorMessage('The message cannot exceed 160 characters.')
      return
    }

    setErrorMessage('')
    setFieldValue(newValue)
    setTimeout(() => {
      textFieldRef.current.selectionStart = cursorPosition + variable.length
      textFieldRef.current.selectionEnd = cursorPosition + variable.length
    }, 0)
  }

  const handleTextFieldChange = e => {
    const newValue = e.target.value

    if (newValue.length > 160 && isSMS) {
      setErrorMessage('The message cannot exceed 160 characters.')
      return
    }

    setErrorMessage('')
    setFieldValue(newValue)
  }

  return (
    <Box display={'flex'} flexDirection={'column'} rowGap={1}>
      <GenericSelect value={''} options={variablesOptions} onChange={handleVariableChange} label={'Variables'} />
      <TextField
        inputRef={textFieldRef}
        onChange={handleTextFieldChange}
        placeholder={'Message'}
        value={fieldValue}
        multiline
        rows={6}
        maxRows={12}
        sx={{
          width: '100%',
          resize: 'none',
          '& .MuiOutlinedInput-root': {
            fontSize: 16,
            fontWeight: 400,
            fontFamily: 'Plus Jakarta Sans',
            borderRadius: '10px'
          }
        }}
      />
      {errorMessage && (
        <Typography color='error' variant='body2' sx={{ marginTop: '4px' }}>
          {errorMessage}
        </Typography>
      )}
      {isSMS && (
        <Typography variant='body2' color='textSecondary'>
          {`${fieldValue.length}/160 characters`}
        </Typography>
      )}
    </Box>
  )
}

const variablesOptions = [
  { name: 'First name', value: '[firstName]' },
  { name: 'Last name', value: '[lastName]' },
  { name: 'Email', value: '[email]' },
  { name: 'Company short name', value: '[companyShortName]' },
  { name: 'Company name', value: '[companyName]' },
  { name: 'Phishing link', value: '[phishingLink]' }
]
