import React from 'react'

import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'

const CyberSwitchV2 = styled(props => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(
  ({ theme }) => ({
    width: 42,
    height: 24,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 1,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(17px)',
        '& .MuiSwitch-thumb': {
          color: 'transparrent',
          background: 'linear-gradient(264.92deg, #00BA97 4.82%, #00BC62 73.44%)',
          boxShadow: 'inset 0px -1.5px 0px #01A26D'
        },

        '& + .MuiSwitch-track': {
          background: 'linear-gradient(264.92deg, #00BA97 4.82%, #00BC62 73.44%)',
          opacity: 0.2,
          border: 0
        }
        // '&.Mui-disabled + .MuiSwitch-track': {
        //   opacity: 0.5,
        //   background: 'linear-gradient(264.92deg, #414141 4.82%, #676767 73.44%)'
        // }
      },
      // '&.Mui-disabled': {
      //   '& .MuiSwitch-thumb': {
      //     color: 'transparrent',
      //     background: '#cccccc',
      //     boxShadow: 'inset 0px -1.5px 0px #9f9f9f'
      //   }
      // },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: 'transparrent',
        border: '6px solid #fff'
      }
      // '&.Mui-disabled .MuiSwitch-thumb': {
      //   color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
      // },
      // '&.Mui-disabled + .MuiSwitch-track': {
      //   opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
      // }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 18,
      height: 18,
      color: '#ffffff',
      transform: 'translateX(2px) translateY(2px)'
    },
    '& .MuiSwitch-track': {
      height: 24,
      borderRadius: 24 / 2,
      background: '#E7E7EE',

      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  })
)

export default CyberSwitchV2
