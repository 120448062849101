import React from 'react'

const MicroProgress = ({ data, total }) => {
  return (
    <div
      style={{
        width: '230px',
        height: '8px',
        borderRadius: '8px',
        background: '#E7E7EE',
        position: 'relative',
        marginTop: '40px'
      }}
    >
      <div
        style={{
          width: `${(230 / 100) * data}px`,
          height: '8px',
          borderRadius: '8px',
          background: '#00BC62'
        }}
      ></div>
      <div
        style={{
          position: 'absolute',
          width: '46px',
          top: '-19px',
          left: `${((230 - 46) / 100) * data}px`,
          height: '46px',
          borderRadius: '46px',
          background: 'linear-gradient(264.92deg, #00BA97 4.82%, #00BC62 73.44%)',
          border: '4px solid #82DEB3',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M23.5 16L16 12' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16 19.15L2.59375 12L16 4.85001L29.4062 12L16 19.15Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.5 14.7167V21.6823C4.49959 21.898 4.56933 22.108 4.69869 22.2807C5.54078 23.4012 9.09339 27.5005 16 27.5005C22.9066 27.5005 26.4592 23.4012 27.3013 22.2807C27.4307 22.108 27.5004 21.898 27.5 21.6823V14.7167L16.3529 20.6618C16.1324 20.7794 15.8676 20.7794 15.6471 20.6618L4.5 14.7167ZM6.09473 13.8672H25.9053L16 19.15L6.09473 13.8672Z'
            fill='white'
            fillOpacity='0.8'
          />
          <path d='M16 12L23.5 16V23' stroke='#01BB84' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
        <p
          style={{
            position: 'absolute',
            top: '-40px',
            color: '#1B1B26',
            fontSize: '16px',
            fontWeight: 600
          }}
        >
          {total}
        </p>
        <p
          style={{
            position: 'absolute',
            top: '-25px',
            color: '#76767F',
            fontSize: '14px'
          }}
        >
          Success
        </p>
      </div>
    </div>
  )
}

export default MicroProgress
