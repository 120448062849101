import React from 'react'
import { Switch, Box, Typography } from '@mui/material'
import { useState, useEffect } from 'react'

export default function ModuleRow({ setActiveModulesArray, active, name, id, input }) {
  const [isActive, setIsActive] = useState(active)

  useEffect(() => {
    if (isActive) {
      if (!input.value.includes(id)) {
        input.onChange([...new Set([...input.value, id])])
        setActiveModulesArray(prev => [...new Set([...prev, id])])
      }
    } else {
      input.onChange([...new Set(input.value.filter(item => item !== id))])
      setActiveModulesArray(prev => [...new Set(prev.filter(item => item !== id))])
    }
  }, [isActive, id])

  const handleChange = () => {
    setIsActive(!isActive)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: '10px',
        alignItems: 'center',
        padding: '16px 24px',
        border: '1px solid #e7e7ee',
        background: '#f4f5f9'
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography fontFamily={'Plus Jakarta Sans'} fontSize={'16px'}>
          {name}
        </Typography>
      </Box>
      <Switch
        sx={{
          '& .MuiSwitch-switchBase': {
            color: isActive ? '#6123FF' : '#E0E0E0'
          }
        }}
        checked={isActive}
        onChange={handleChange}
      />
    </Box>
  )
}
