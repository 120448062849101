import React, { useEffect, useState } from 'react'
import { Menu, MenuItem } from '@mui/material'

import './style.css'

const WorkflowActionSelect = ({ onSelectOption }) => {
  const [option, setOption] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [form, setForm] = useState({})
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelect = s => {
    setOption(s)
    onSelectOption(s)
    setAnchorEl(null)
  }

  return (
    <>
      <div onClick={handleClick} className='row row-center select-workflow'>
        <p>Add Action</p>
      </div>
      <Menu
        sx={{
          '& .MuiPaper-root': {
            color: '#1B1B26',
            borderRadius: '12px'
          },
          '& li': {
            fontFamily: 'Plus Jakarta Sans'
          },
          '& li:hover': {}
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={() => handleSelect('WEBHOOK')}>
          <div className='column'>
            <p style={{ fontWeight: 600, width: 800 }}>Send Webhook</p>
          </div>
        </MenuItem>
        <div
          style={{
            height: '1px',
            width: '100%',
            background: '#E7E7EE',
            margin: '0px 0px'
          }}
        ></div>
        <MenuItem onClick={() => handleSelect('TRAINING_PACKAGE')}>
          <div className='column'>
            <p style={{ fontWeight: 600 }}>Enroll to Training Package</p>
          </div>
        </MenuItem>
        <div
          style={{
            height: '1px',
            width: '100%',
            background: '#E7E7EE',
            margin: '0px 0px'
          }}
        ></div>
        <MenuItem onClick={() => handleSelect('NOTIFICATION')}>
          <div className='column'>
            <p style={{ fontWeight: 600 }}>Send notification</p>
          </div>
        </MenuItem>
        <MenuItem onClick={() => handleSelect('SLACK')}>
          <div className='column'>
            <p style={{ fontWeight: 600 }}>Send training in Slack</p>
          </div>
        </MenuItem>
      </Menu>
    </>
  )
}

export default WorkflowActionSelect
