import React from 'react'

export default function PercentageComponent({ percentage }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: percentage < 39 ? '#df4040d9' : percentage < 70 ? '#FFD700' : '#00BA97',
        fontFamily: 'Satoshi',
        fontSize: 18,
        width: 66,
        fontWeight: 500,
        borderRadius: 8,
        padding: '1px 0'
      }}
    >
      {percentage}%
    </div>
  )
}
