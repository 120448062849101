import { compactObject } from 'src/heplers'
import api from './api.service'
import authHeader from './auth-header'

class WorkflowsService {
  getWorkflows(filters) {
    const params = new URLSearchParams(compactObject(filters))
    return api.get('/v1/workflows', { headers: authHeader(), params })
  }

  getWorkflow(id) {
    return api.get('/v1/workflows/' + id, { headers: authHeader() })
  }

  deleteWorkflow(id) {
    return api.delete('/v1/workflows/' + id, { headers: authHeader() })
  }

  getWorkflowLogs(id) {
    return api.get('/v1/workflows/' + id + '/logs', {
      headers: authHeader()
    })
  }

  getWorkflowUsers(id) {
    return api.get('/v1/workflows/' + id + '/users', {
      headers: authHeader()
    })
  }

  getTriggers() {
    return api.get('/v1/workflows/triggers', { headers: authHeader() })
  }

  addWorkflow(data) {
    return api.post('/v1/workflows', data, { headers: authHeader() })
  }

  updateWorkflow(id, data) {
    return api.put('/v1/workflows/' + id, data, { headers: authHeader() })
  }

  updateStatus(id, status) {
    return api.put('/v1/workflows/' + id + '/status', { status: status }, { headers: authHeader() })
  }

  getWorkflowsLastLog() {
    return api.get('/v1/workflows/last-log', {
      headers: authHeader()
    })
  }

  getWorkflowsLogs(filters) {
    const params = new URLSearchParams(compactObject(filters))
    return api.get('/v1/workflows/logs', {
      headers: authHeader(),
      params
    })
  }
}

export default new WorkflowsService()
