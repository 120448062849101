import React from 'react'
import { Box, Typography } from '@mui/material'
import { ReactComponent as BrowserIcon } from '../../../../common/icons/browser_icon.svg'
import { useFormState } from 'react-final-form'

export default function SecondStepCard() {
  const { values } = useFormState()

  return (
    <Box display={'flex'} columnGap={8} alignItems={'center'}>
      <Box display={'flex'} columnGap={2} alignItems={'center'}>
        <BrowserIcon />
        <Typography component={'span'} fontFamily={'Satoshi'} fontWeight={600} fontSize={'20px'} color={'#1B1B26'}>
          Modules
        </Typography>
      </Box>
      <Box display={'flex'} flexDirection={'column'} rowGap={1}>
        <Typography component={'span'} fontFamily={'Satoshi'} fontWeight={500} fontSize={'24px'} color={'#1B1B26'}>
          {values?.trainingModuleIds?.length}{' '}
          <Typography component={'span'} fontFamily={'Satoshi'} fontWeight={500} fontSize={'14px'} color={'#76767f'}>
            Includes
          </Typography>
        </Typography>
      </Box>
    </Box>
  )
}
