import React from 'react'
import { Box, Typography } from '@mui/material'

export default function StepCard({ data, config }) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '85px',
        display: 'flex',
        columnGap: '12px',
        padding: '14px 20px',
        backgroundColor: '#FFFFFF',
        borderRadius: '12px'
      }}
    >
      {config.map(col =>
        col.renderComponent ? (
          col.renderComponent(data[col.field], data)
        ) : (
          <Box
            key={col.field}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '4px',
              columnGap: '12px',
              width: col.width || '100%'
            }}
          >
            <Typography fontSize={'13px'} fontWeight={600} fontFamily={'Plus Jakarta Sans'} color={'#76767F'}>
              {col.title}
            </Typography>
            <Typography fontSize={'18px'} fontWeight={500} fontFamily={'Satoshi'} color={'#1B1B26'}>
              {data[col.field] === 0 ? '-' : data[col.field]}
            </Typography>
          </Box>
        )
      )}
    </Box>
  )
}
