import React, { useContext, useState } from 'react'
import { createDropdown, Separator, Toolbar, useEditorState } from 'react-simple-wysiwyg'
import { IconButton, Tooltip } from '@mui/material'
import './editor.css'
import ImageDrawer from './ImageDrawer'

import { ReactComponent as ImageIcon } from '../../../../common/icons/image_icon.svg'
import { ReactComponent as StrikethroughSIcon } from '../../../../common/icons/text_strikeThrough_icon.svg'
import { ReactComponent as FormatClearIcon } from '../../../../common/icons/remove_styles_icon.svg'
import { ReactComponent as FormatListBulletedIcon } from '../../../../common/icons/list_bullet_icon.svg'
import { ReactComponent as FormatUnderlinedIcon } from '../../../../common/icons/text_underline_icon.svg'
import { ReactComponent as FormatItalicIcon } from '../../../../common/icons/text_italic_icon.svg'
import { ReactComponent as FormatBoldIcon } from '../../../../common/icons/text_bolder_icon.svg'
import { DialogRefContext } from '../../../../common/components/CustomDialog'

const BtnStyles = createDropdown('Styles', [
  ['Normal', 'formatBlock', 'div'],
  ['Header 1', 'formatBlock', 'h1'],
  ['Header 2', 'formatBlock', 'h4'],
  ['Code', 'formatBlock', 'pre']
])

export const BtnVars = createDropdown('Variables', [
  ['First name', 'insertText', '[firstName]'],
  ['Last name', 'insertText', '[lastName]'],
  ['Email', 'insertText', '[email]'],
  ['Company short name', 'insertText', '[companyShortName]'],
  ['Company name', 'insertText', '[companyName]']
])

export default function CustomToolbar() {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { dialogRef } = useContext(DialogRefContext)
  const { $el } = useEditorState()
  const handleInsertImage = (url, width) => {
    const img = `<img src="${url}" width="${width}" alt="image"/>`
    if (document.activeElement !== $el) {
      $el?.focus()
    }
    document.execCommand('insertHTML', false, img)
  }

  const handleCommand = command => {
    document.execCommand(command)
  }
  return (
    <>
      <Toolbar style={{ border: 'none', background: 'transparent' }} className={'rsw-toolbar'}>
        <BtnStyles />
        <Tooltip
          arrow
          title={'Bold'}
          placement={'top'}
          componentsProps={{
            tooltip: {
              sx: {
                color: 'white',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                backgroundColor: 'black',
                '& .MuiTooltip-arrow': {
                  color: 'black'
                }
              }
            }
          }}
        >
          <IconButton onClick={() => handleCommand('bold')}>
            <FormatBoldIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          title={'Italic'}
          placement={'top'}
          componentsProps={{
            tooltip: {
              sx: {
                color: 'white',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                backgroundColor: 'black',
                '& .MuiTooltip-arrow': {
                  color: 'black'
                }
              }
            }
          }}
        >
          <IconButton onClick={() => handleCommand('italic')}>
            <FormatItalicIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          title={'Underline'}
          placement={'top'}
          componentsProps={{
            tooltip: {
              sx: {
                color: 'white',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                backgroundColor: 'black',
                '& .MuiTooltip-arrow': {
                  color: 'black'
                }
              }
            }
          }}
        >
          <IconButton onClick={() => handleCommand('underline')}>
            <FormatUnderlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          title={'Bulleted list'}
          placement={'top'}
          componentsProps={{
            tooltip: {
              sx: {
                color: 'white',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                backgroundColor: 'black',
                '& .MuiTooltip-arrow': {
                  color: 'black'
                }
              }
            }
          }}
        >
          <IconButton onClick={() => handleCommand('insertUnorderedList')}>
            <FormatListBulletedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          title={`Strikethrough`}
          placement={'top'}
          componentsProps={{
            tooltip: {
              sx: {
                color: 'white',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                backgroundColor: 'black',
                '& .MuiTooltip-arrow': {
                  color: 'black'
                }
              }
            }
          }}
        >
          <IconButton onClick={() => handleCommand('strikeThrough')}>
            <StrikethroughSIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          title={'Remove format'}
          placement={'top'}
          componentsProps={{
            tooltip: {
              sx: {
                color: 'white',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                backgroundColor: 'black',
                '& .MuiTooltip-arrow': {
                  color: 'black'
                }
              }
            }
          }}
        >
          <IconButton onClick={() => handleCommand('removeFormat')}>
            <FormatClearIcon />
          </IconButton>
        </Tooltip>
        <Separator />
        <BtnVars />
        <Tooltip
          arrow
          title={`Image`}
          placement={'top'}
          componentsProps={{
            tooltip: {
              sx: {
                color: 'white',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                backgroundColor: 'black',
                '& .MuiTooltip-arrow': {
                  color: 'black'
                }
              }
            }
          }}
        >
          <IconButton onClick={() => setDrawerOpen(true)}>
            <ImageIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <ImageDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onInsert={handleInsertImage}
        container={dialogRef?.current}
      />
    </>
  )
}
