import React, { useCallback, useMemo, useState } from 'react'
import EmptyActivityPage from './EmptyActivityPage'
import DataTableContainer from '../../../../common/components/DataTable/DataTableContainer'
import { Box, Button, TextField } from '@mui/material'
import LoadingIndicator from '../../../../common/components/LoadingIndicator'
import { useGetSimulationUsersListQuery, useLazyDownloadUserListCSVQuery } from '../../../../api/simulation/simulation'
import { useParams } from 'react-router-dom'
import useSimulationUsersTable from './useSimulationUsersTable'
import { debounce } from '../../utils'
import { ReactComponent as SearchIcon } from '../../../../common/icons/search.svg'
import ViewActivityModal from './ViewActivityModal'
import { ReactComponent as CsvIcon } from '../../../../common/icons/csv_icon.svg'

export default function Activity() {
  const [searchText, setSearchText] = useState('')
  const [filters, setFilters] = useState({ searchText: '' })
  const { id } = useParams()
  const { data, isLoading: isSimulationLoading } = useGetSimulationUsersListQuery({ id })
  const { open, userId, handleClose, columns, fetchData, totalCount, users, isLoading } = useSimulationUsersTable({
    id
  })
  const [getItems] = useLazyDownloadUserListCSVQuery()

  function downloadCSV() {
    getItems({ id })
      .unwrap()
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response], { type: 'text/csv;charset=utf-8;' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'users_activity.csv')
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
      })
  }

  const debouncedSetParams = useMemo(
    () =>
      debounce(searchText => {
        setFilters(prevParams => ({ ...prevParams, searchText }))
      }, 500),
    [setFilters]
  )

  const handleSearchChange = useCallback(
    e => {
      const value = e.target.value
      setSearchText(value)
      debouncedSetParams(value)
    },
    [debouncedSetParams]
  )

  if (+data?.totalCount === 0) {
    return <EmptyActivityPage />
  }

  if (isLoading || isSimulationLoading) {
    return (
      <Box display={'flex'} height={'100vh'} alignItems={'center'}>
        <LoadingIndicator />
      </Box>
    )
  }

  return (
    <Box pt={'24px'} display={'flex'} flexDirection={'column'} rowGap={'20px'}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} rowGap={'20px'}>
        <Box width='528px'>
          <TextField
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                border: '1px solid #E7E7EE',
                background: '#fff',
                width: '100%'
              }
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
            onChange={handleSearchChange}
            value={searchText}
            size='small'
            placeholder='Search by users'
          />
        </Box>
        <Button
          sx={{
            textTransform: 'none',
            fontSize: '14px',
            color: '#1B1B26',
            fontWeight: 400,
            fontFamily: 'Plus Jakarta Sans',
            textDecoration: 'underline'
          }}
          onClick={downloadCSV}
          startIcon={<CsvIcon />}
          variant={'text'}
        >
          Download users activity (CSV)
        </Button>
      </Box>
      <DataTableContainer
        onRequestData={fetchData}
        filter={filters}
        emptyText={'No users found'}
        config={{
          columns,
          stickyPagination: false,
          stickyHeader: true,
          maxHeight: 'calc(100vh - 340px)'
        }}
        data={users}
        initialSort={{ orderBy: 'firstName', order: 'asc' }}
        totalCount={totalCount}
      />
      {open && <ViewActivityModal open={open} handleClose={handleClose} userId={userId} />}
    </Box>
  )
}
