import React, { useState } from 'react'

import './styles.css'
import { useNavigate } from 'react-router-dom'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material/styles'
import Copyright from 'src/common/components/Copyright'
import { useEffect } from 'react'
import { useQuery } from 'src/hooks'
import authService from '../../services/auth.service'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import userService from 'src/services/user.service'
import settingsService from 'src/services/settings.service'
import { parseJwt } from 'src/heplers'

const InputSelect = styled(Select)(() => ({
  padding: '10px 16px',
  fontSize: '16px',

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E7E7EE'
  },

  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px'
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px'
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px'
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  },

  '& .MuiInputLabel-root': {
    top: '12px'
  }
}))

export const LoginInput = styled(TextField)(() => ({
  border: '1px solid #E7E7EE',
  padding: '10px 16px',
  fontSize: '16px',
  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px'
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px'
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px'
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  }
}))

const AuthLinkPage = () => {
  const [loading, setLoading] = useState(false)
  const [account, setAccount] = useState(null)
  const [form, setForm] = useState({})
  const [error, setError] = useState(null)
  const [errorToken, setErrorToken] = useState(null)
  const [settings, setSettings] = useState({
    countries: [],
    languages: [],
    departments: []
  })
  const [onboardingError, setOnboardingError] = useState({
    department: false,
    country: false,
    langu: false,
    language: false
  })

  const query = useQuery()

  useEffect(() => {
    setLoading(true)
    authService
      .validateToken(query.get('token'))
      .then(resp => {
        setLoading(false)
        const userRole = parseJwt(resp?.accessToken).role
        if (userRole == 'USER') {
          setErrorToken('Access Denied')
          return
        }
        userService.getAccountData().then(resp => {
          setAccount(resp.data)
          if (resp.data.status == 'ACTIVE') {
            navigate('/')
          }
        })

        Promise.all([
          settingsService.getlocations(),
          settingsService.getlanguages(),
          settingsService.getdepartments()
        ]).then(values => {
          const [countriesData, languagesData, departmentsData] = values
          const countries = countriesData.data
          const languages = languagesData.data
          const departments = departmentsData.data
          setSettings({ countries, languages, departments })
        })
      })
      .catch(error => {
        setLoading(false)
        setErrorToken('Access Denied. Please contact your administrator')
        console.error(error)
      })
  }, [])

  const handleNext = () => {
    if (errorToken) {
      navigate('/login')
      return
    }
    setLoading(true)
    if (!form.department || !form.country || !form.language) {
      setOnboardingError({
        ...onboardingError,
        department: !form.department,
        country: !form.country,
        language: !form.language
      })
      setLoading(false)
      return
    }

    userService
      .onboarding({
        ...account,
        department: form.department,
        country: form.country,
        language: form.language
      })
      .then(() => {
        setLoading(false)
        navigate('/')
      })
      .catch(err => {
        console.error(err)
        setError('Something went wrong')
      })
  }

  const navigate = useNavigate()
  return (
    <div className='auth-link-page column'>
      <div className='logo'></div>
      <form onSubmit={() => {}} className='login-form-container mob-login-form-container column'>
        <p className='login-title'>Welcome to Cyberlift! Let&apos;s start the journey to online security</p>

        {error && <p className='error-text'>{error}</p>}

        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            {errorToken && <p style={{ color: '#B40020', marginTop: 30 }}> {errorToken}</p>}
            {account && (
              <div style={{ marginTop: '20px' }}>
                <div className='row' style={{ margin: '10px 0px' }}>
                  <p
                    style={{
                      width: '100px',
                      color: '#101828',
                      opacity: '0.7'
                    }}
                  >
                    Name
                  </p>
                  <p>
                    {account.firstName} {account?.lastName}
                  </p>
                </div>
                <div className='row' style={{ margin: '10px 0px' }}>
                  <p
                    style={{
                      width: '100px',
                      color: '#101828',
                      opacity: '0.7'
                    }}
                  >
                    Email
                  </p>
                  <p>{account.email}</p>
                </div>

                <div style={{ marginTop: '20px' }}>
                  <FormControl fullWidth>
                    <InputLabel id='Department'>Department</InputLabel>
                    <InputSelect
                      required
                      sx={{
                        borderRadius: '20px',
                        mb: '18px'
                      }}
                      error={onboardingError.department}
                      labelId='Department'
                      value={form?.department}
                      label='Department'
                      onChange={e =>
                        setForm({
                          ...form,
                          department: e.target.value
                        })
                      }
                    >
                      {settings?.departments?.map(el => (
                        <MenuItem key={el} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </InputSelect>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id='Location'>Location</InputLabel>
                    <InputSelect
                      required
                      sx={{
                        borderRadius: '20px',
                        mb: '18px'
                      }}
                      error={onboardingError.country}
                      labelId='Location'
                      value={form?.country}
                      label='Location'
                      onChange={e =>
                        setForm({
                          ...form,
                          country: e.target.value
                        })
                      }
                    >
                      {settings?.countries?.map(el => (
                        <MenuItem key={el} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </InputSelect>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id='Language'>Language</InputLabel>
                    <InputSelect
                      sx={{
                        borderRadius: '20px',
                        mb: '18px'
                      }}
                      error={onboardingError.language}
                      labelId='Language'
                      value={form?.language}
                      label='Language'
                      onChange={e =>
                        setForm({
                          ...form,
                          language: e.target.value
                        })
                      }
                    >
                      {settings?.languages?.map(el => (
                        <MenuItem key={el} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </InputSelect>
                  </FormControl>
                </div>
              </div>
            )}
          </>
        )}

        <LoadingButton
          loading={loading}
          onClick={handleNext}
          sx={{
            mt: '64px',
            borderRadius: '14px',
            textTransform: 'none',
            height: '60px',
            backgroundColor: '#6123FF',
            fontFamily: 'Plus Jakarta Sans',
            fontSize: '16px'
          }}
          variant={'contained'}
        >
          {errorToken ? 'Go to Login' : 'Next'}
        </LoadingButton>
      </form>
      <div className='spacer'></div>
      <Copyright />
    </div>
  )
}

export default AuthLinkPage
