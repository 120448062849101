import React from 'react'
import Spacer from 'src/common/components/Spacer'
import { useGetCurrentCompanyTrainingStatsQuery } from '../../api/trainingPackages/trainingPackages'
import Info from 'src/common/components/Info'
import { Box } from '@mui/material'
import Done from '../../common/icons/done'
import SpinnerIcon from '../../common/icons/spiner'
import { ReactComponent as Planned } from '../../common/icons/hourglassIcon.svg'
import Archive from '../../common/icons/archive'

export default function StatsDashboard() {
  const { data: stats } = useGetCurrentCompanyTrainingStatsQuery()

  return (
    <div className='row'>
      <div
        style={{
          padding: '8px 24px',
          borderRadius: '10px',
          border: '1px solid #E7E7EE',
          marginBottom: '16px',
          background: '#fff',
          width: '30%',
          position: 'relative'
        }}
        className='column'
      >
        <div
          style={{
            marginBottom: '10px'
          }}
          className='row row-center'
        >
          <p
            style={{
              fontWeight: 600,
              color: '#76767F'
            }}
          >
            Enrolled users
          </p>
          <Info text={'Who have active training packages'} />
        </div>

        <div className='row row-center'>
          <p
            style={{
              fontFamily: 'Satoshi',
              fontSize: '20px',
              marginRight: '16px'
            }}
          >
            {stats?.enrolledUsersCount || 0}
          </p>

          <div className='progress-hatched row'>
            <div
              className='progress-hatched-inner row row-center'
              style={{
                width: stats?.enrolledUsersPercentage + '%',
                minWidth: '87px'
              }}
            >
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  opacity='0.2'
                  d='M8.25 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25 5.25C5.55761 5.25 3.375 7.43261 3.375 10.125C3.375 12.8174 5.55761 15 8.25 15Z'
                  fill='#1B1B26'
                />
                <path
                  d='M8.25 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25 5.25C5.55761 5.25 3.375 7.43261 3.375 10.125C3.375 12.8174 5.55761 15 8.25 15Z'
                  stroke='#1B1B26'
                  strokeMiterlimit='10'
                />
                <path
                  d='M14.5703 5.43271C15.2408 5.24379 15.9441 5.20075 16.6326 5.3065C17.3212 5.41224 17.9791 5.66431 18.562 6.04572C19.1449 6.42714 19.6393 6.92904 20.012 7.51763C20.3846 8.10622 20.6268 8.76783 20.7221 9.45789C20.8175 10.148 20.764 10.8504 20.565 11.5181C20.366 12.1857 20.0263 12.8029 19.5687 13.3281C19.1111 13.8534 18.5463 14.2745 17.9123 14.5631C17.2782 14.8517 16.5897 15.001 15.8931 15.0011'
                  stroke='#1B1B26'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M1.5 18.5059C2.26138 17.4229 3.27215 16.539 4.44698 15.9288C5.62182 15.3186 6.92623 15.0001 8.25008 15C9.57393 14.9999 10.8784 15.3184 12.0532 15.9285C13.2281 16.5386 14.239 17.4225 15.0004 18.5054'
                  stroke='#1B1B26'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M15.8945 15C17.2185 14.999 18.5232 15.3171 19.6982 15.9273C20.8732 16.5375 21.8838 17.4218 22.6446 18.5054'
                  stroke='#1B1B26'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: '600'
                }}
              >
                {' '}
                {stats?.enrolledUsersPercentage || 0} %
              </p>
            </div>
          </div>
        </div>
      </div>

      <Spacer />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px 24px',
          borderRadius: '10px',
          border: '1px solid #E7E7EE',
          marginBottom: '16px',
          background: '#fff',
          width: 'calc(70% - 20px)',
          position: 'relative'
        }}
        className='column'
      >
        <div className='row'>
          <p
            style={{
              fontWeight: 600,
              color: '#76767F',
              marginRight: '16px'
            }}
          >
            Training packages
          </p>
          <Box bgcolor='rgb(255, 255, 255)' width={'80%'} display={'flex'} flexDirection={'column'} rowGap={1}>
            <div
              style={{
                width: '100%',
                background: '#E7E7EE',
                height: '8px',
                flex: '1',
                borderRadius: '8px',
                overflow: 'hidden'
              }}
              className='row row-center'
            >
              <div
                style={{
                  width: stats?.plannedPackagesPercentage + '%',
                  background: '#c7eff4',
                  height: '12px',
                  borderRadius: '8px',
                  position: 'relative',
                  border: '3px solid #fff'
                }}
              ></div>
              <div
                style={{
                  width: `${stats?.inProgressPackagesPercentage + (stats?.inProgressPackagesPercentage > 0 ? 1 : 0)}%`,
                  background: '#DC87F8',
                  height: '12px',
                  borderRadius: '8px',
                  position: 'relative',
                  border: '3px solid #fff',
                  marginLeft: '-8px'
                }}
              ></div>
              <div
                style={{
                  width: `${stats?.completedPackagesPercentage + (stats?.completedPackagesPercentage > 0 ? 1 : 0)}%`,
                  background: '#59D399',
                  height: '12px',
                  borderRadius: '8px',
                  position: 'relative',
                  border: '3px solid #fff',
                  marginLeft: '-8px'
                }}
              ></div>
              <div
                style={{
                  width: `${stats?.archivedPackagesPercentage + (stats?.archivedPackagesPercentage > 0 ? 1 : 0)}%`,
                  background: '#76767f',
                  height: '12px',
                  borderRadius: '8px',
                  position: 'relative',
                  border: '3px solid #fff',
                  marginLeft: '-8px'
                }}
              ></div>
            </div>
            <Box display={'flex'} columnGap={1} width={'100%'} alignItems={'center'}>
              <Box display={'flex'} columnGap={1} alignItems={'center'}>
                <Planned />
                <p
                  style={{
                    fontSize: '20px',
                    fontFamily: 'Satoshi',
                    marginRight: '4px'
                  }}
                >
                  {stats?.plannedPackagesPercentage}%
                </p>

                <p style={{ fontSize: '14px', color: '#76767F' }}>Planned</p>
              </Box>

              <Box display={'flex'} columnGap={1} alignItems={'center'}>
                <SpinnerIcon />
                <p
                  style={{
                    fontSize: '20px',
                    fontFamily: 'Satoshi',
                    marginRight: '4px'
                  }}
                >
                  {stats?.inProgressPackagesPercentage}%
                </p>
                <p style={{ fontSize: '14px', color: '#76767F' }}>In Progress</p>
              </Box>
              <Box display={'flex'} columnGap={1} alignItems={'center'}>
                <Done />
                <p
                  style={{
                    fontSize: '20px',
                    fontFamily: 'Satoshi',
                    marginRight: '4px'
                  }}
                >
                  {stats?.completedPackagesPercentage}%
                </p>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#76767F'
                  }}
                >
                  Completed
                </p>
              </Box>
              <Box display={'flex'} columnGap={1} alignItems={'center'}>
                <Archive />
                <p
                  style={{
                    fontSize: '20px',
                    fontFamily: 'Satoshi',
                    marginRight: '4px'
                  }}
                >
                  {stats?.archivedPackagesPercentage}%
                </p>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#76767F'
                  }}
                >
                  Archived
                </p>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  )
}
