import React from 'react'

const AttachIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.9995 7.5L7.18934 15.4398C6.90804 15.7211 6.75 16.1026 6.75 16.5005C6.75 16.8983 6.90804 17.2798 7.18934 17.5611C7.47064 17.8424 7.85218 18.0005 8.25 18.0005C8.64783 18.0005 9.02936 17.8424 9.31066 17.5611L18.6208 8.12132C18.8993 7.84274 19.1203 7.51203 19.2711 7.14805C19.4219 6.78407 19.4995 6.39397 19.4995 6C19.4995 5.60603 19.4219 5.21593 19.2711 4.85195C19.1203 4.48797 18.8993 4.15726 18.6208 3.87868C18.3422 3.6001 18.0115 3.37913 17.6475 3.22836C17.2835 3.0776 16.8934 3 16.4995 3C16.1055 3 15.7154 3.0776 15.3514 3.22836C14.9874 3.37913 14.6567 3.6001 14.3781 3.87868L5.06802 13.3185C4.22411 14.1624 3.75 15.307 3.75 16.5005C3.75 17.6939 4.22411 18.8385 5.06802 19.6824C5.91193 20.5264 7.05653 21.0005 8.25 21.0005C9.44348 21.0005 10.5881 20.5264 11.432 19.6824L19.1245 12'
      stroke='#6123FF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default AttachIcon
