import React from 'react'
import { Box, Typography } from '@mui/material'
import { ReactComponent as AiGeneration } from '../../../../common/icons/ai_generation.svg'

export default function CampaignTemplates({ data }) {
  const templatesLength = data?.internalTemplateIds?.length + data?.customTemplateIds?.length

  if (data?.type === 'AUTOMATED_EMAIL') {
    return (
      <Box Box display={'flex'} width={'187px'} minWidth={187} justifyContent={'center'} mr={4}>
        {data?.type === 'AUTOMATED_EMAIL' && (
          <AiGeneration
            style={{
              width: '187px'
            }}
          />
        )}
      </Box>
    )
  }
  return (
    <Box
      display={'flex'}
      height={'40px'}
      rowGap={1}
      mr={4}
      width={187}
      minWidth={187}
      alignItems={'center'}
      sx={{
        background: '#E3F5F780'
      }}
      borderRadius={'10px'}
      padding={'14px 20px'}
    >
      <Typography noWrap fontSize={'18px'} lineHeight={'21.6px'} fontFamily={'Satoshi'} fontWeight={500}>
        {data?.type !== 'MANUAL_EMAIL' && data?.type !== 'AUTOMATED_EMAIL' && '1 Template'}
        {data?.type === 'MANUAL_EMAIL' && +templatesLength + `${templatesLength > 1 ? ' Templates' : ' Template'}`}
      </Typography>
    </Box>
  )
}
