import React from 'react'
import { BootstrapTooltip } from '../../../common/components/Info'
import { useContext } from 'react'
import { SettingsContext } from 'src/App'

const WiseChart = ({ data }) => {
  const maxTotalCountValue = data?.map(el => el.totalCount).sort((a, b) => b - a)[0]
  const oneUnitInPercents = 100 / maxTotalCountValue

  const [{ departments }] = useContext(SettingsContext)

  return (
    <div className='wise-chart'>
      <div
        style={{
          borderBottom: '1px solid #F8F8FB',
          height: '391px'
        }}
        className='row'
      >
        {data.length
          ? data?.map(el => (
              <div key={el.department} style={{ margin: '0px 10px' }} className='column'>
                <div
                  style={{
                    height: '390px',
                    justifyContent: 'flex-end'
                  }}
                  className='wise-chart-bar column'
                >
                  <div className='wise-chart-bar-miss-border'></div>
                  <BootstrapTooltip
                    placement='top'
                    title={
                      <>
                        <p>
                          {el.reportedPercentage}% Reported{' '}
                          <span
                            style={{
                              color: '#FFFFFFB2'
                            }}
                          >
                            {el.reportedCount}
                          </span>
                        </p>
                        <p>
                          {el.phishedPercentage}% Phished{' '}
                          <span
                            style={{
                              color: '#FFFFFFB2'
                            }}
                          >
                            {el.phishedCount}
                          </span>
                        </p>
                        <p>
                          {el.openedPercentage}% Opened{' '}
                          <span
                            style={{
                              color: '#FFFFFFB2'
                            }}
                          >
                            {el.openedCount}
                          </span>
                        </p>
                      </>
                    }
                  >
                    <div
                      style={{
                        height: `${(el?.openedCount || 0) * oneUnitInPercents}%`
                      }}
                      className='wise-chart-bar-miss'
                    ></div>
                  </BootstrapTooltip>
                  <div className='wise-chart-bar-fail-border'></div>
                  <BootstrapTooltip
                    placement='top'
                    title={
                      <>
                        <p>
                          {el.reportedPercentage}% Reported{' '}
                          <span
                            style={{
                              color: '#FFFFFFB2'
                            }}
                          >
                            {el.reportedCount}
                          </span>
                        </p>
                        <p>
                          {el.phishedPercentage}% Phished{' '}
                          <span
                            style={{
                              color: '#FFFFFFB2'
                            }}
                          >
                            {el.phishedCount}
                          </span>
                        </p>
                        <p>
                          {el.openedPercentage}% Opened{' '}
                          <span
                            style={{
                              color: '#FFFFFFB2'
                            }}
                          >
                            {el.openedCount}
                          </span>
                        </p>
                      </>
                    }
                  >
                    <div
                      style={{
                        height: `${el.phishedCount * oneUnitInPercents}%`
                      }}
                      className='wise-chart-bar-fail'
                    ></div>
                  </BootstrapTooltip>
                  <BootstrapTooltip
                    placement='top'
                    title={
                      <>
                        <p>
                          {el.reportedPercentage}% Reported{' '}
                          <span
                            style={{
                              color: '#FFFFFFB2'
                            }}
                          >
                            {el.reportedCount}
                          </span>
                        </p>
                        <p>
                          {el.reportedPercentage}% Phished{' '}
                          <span
                            style={{
                              color: '#FFFFFFB2'
                            }}
                          >
                            {el.phishedCount}
                          </span>
                        </p>
                        <p>
                          {el.openedPercentage}% Opened{' '}
                          <span
                            style={{
                              color: '#FFFFFFB2'
                            }}
                          >
                            {el.openedCount}
                          </span>
                        </p>
                      </>
                    }
                  >
                    <div
                      style={{
                        height: `${el.reportedCount * oneUnitInPercents}%`
                      }}
                      className='wise-chart-bar-success'
                    ></div>
                  </BootstrapTooltip>
                </div>
              </div>
            ))
          : null}
      </div>
      <div style={{ marginTop: '16px', marginBottom: '20px' }} className='row'>
        {data.length
          ? data.map(el => (
              <div key={el.department} style={{ margin: '0px 10px' }} className='column'>
                <div className='wise-chart-bar column'>
                  <p
                    style={{
                      textAlign: 'center',
                      maxWidth: '100px'
                    }}
                  >
                    {el.department}
                  </p>
                </div>
              </div>
            ))
          : departments?.map(el => (
              <div key={el} style={{ margin: '0px 10px' }} className='column'>
                <div className='wise-chart-bar column'>
                  <p
                    style={{
                      textAlign: 'center',
                      maxWidth: '100px'
                    }}
                  >
                    {el}
                  </p>
                </div>
              </div>
            ))}
      </div>
    </div>
  )
}

export default WiseChart
