import React, { useState } from 'react'
import List from './List'
import { ReactComponent as SearchIcon } from '../../../../common/icons/search.svg'
import { Box, TextField } from '@mui/material'
import RadioSelect from '../../../../common/components/RadioSelect'

export default function AllMicrotrainingsField({ input }) {
  const [search, setSearch] = useState('')
  const [language, setLanguage] = useState('English')

  return (
    <Box sx={styles.container}>
      <Box width={'100%'} justifyContent={'space-between'} display={'flex'}>
        <TextField
          size='small'
          placeholder='Search'
          onChange={e => {
            setSearch(e.target.value)
          }}
          value={search}
          variant='outlined'
          InputProps={{ endAdornment: <SearchIcon /> }}
          sx={styles.textField}
        />
        <RadioSelect
          style={{ marginRight: 20 }}
          options={['English', 'Polish']}
          value={language}
          onSelectOption={el => {
            setLanguage(el)
          }}
        />
      </Box>
      <List input={input} search={search} language={language} />
    </Box>
  )
}

const styles = {
  textField: {
    width: '70%',
    '& .MuiInputBase-root': { borderRadius: '14px' }
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    rowGap: '16px',
    alignItems: 'center',
    paddingTop: '28px',
    paddingBottom: '24px'
  }
}
