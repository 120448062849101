export const LoginPageNameMap = {
  COMMON: 'General Login',
  AWS: 'AWS login',
  BITBUCKET: 'Bitbucket Login',
  //DOCUSIGN: 'DocuSign Login',
  DROPBOX: 'Dropbox Login',
  ELASTIC_EMAIL: 'Elastic Login',
  FIGMA: 'Figma Login',
  GITHUB: 'GitHub Login',
  //GITLAB: 'GitLab Login',
  GOOGLE: 'Google Login',
  JIRA: 'Jira Login',
  //ATLASSIAN: 'Atlassian Login',
  GOOGLE_MEET: 'Google Meet',
  MICROSOFT_TEAMS: 'Teams Meet'
}
export const LinkPageMapper = {
  COMMON: 'https://storage.googleapis.com/phising-pages/common_login.html',
  AWS: 'https://storage.googleapis.com/phising-pages/aws_login.html',
  BITBUCKET: 'https://storage.googleapis.com/phising-pages/bitbucket_login.html',
  // DOCUSIGN: 'https://app.cyberlift.io/no-reply/verification/docusign?redirect-link=someTmpltCode,someId,mail@mail.com',
  DROPBOX: 'https://storage.googleapis.com/phising-pages/dropbox_login.html',
  ELASTIC_EMAIL: 'https://storage.googleapis.com/phising-pages/elasticemail_login.html',
  FIGMA: 'https://storage.googleapis.com/phising-pages/figma_login.html',
  GITHUB: 'https://storage.googleapis.com/phising-pages/github_login.html',
  // GITLAB: 'https://app.cyberlift.io/no-reply/verification/gitlab?redirect-link=someTmpltCode,someId,mail@mail.com',
  GOOGLE: 'https://storage.googleapis.com/phising-pages/google_login.html',
  JIRA: 'https://storage.googleapis.com/phising-pages/jira_login.html',
  // ATLASSIAN: 'https://app.cyberlift.io/no-reply/verification/atlassian?redirect-link=someTmpltCode,someId'
  GOOGLE_MEET: 'https://storage.googleapis.com/phising-pages/google_meet.html',
  MICROSOFT_TEAMS: 'https://storage.googleapis.com/phising-pages/microsoft_teams.html'
}
