import { Box, TextField, Typography } from '@mui/material'
import { ReactComponent as SearchIcon } from '../../../../common/icons/search.svg'
import React, { useCallback, useMemo, useState } from 'react'
import DataTableContainer from '../../../../common/components/DataTable/DataTableContainer'
import useUsersHistoryTable from './useUsersHistoryTable'
import LoadingIndicator from '../../../../common/components/LoadingIndicator'
import { useParams } from 'react-router-dom'
import { debounce } from '../../utils'

export default function UsersHistoryTable() {
  const [filters, setFilters] = useState({ searchText: '' })
  const [searchText, setSearchText] = useState('')
  const { id, stepId } = useParams()
  const { statistics, columns, fetchData, totalCount, isLoading } = useUsersHistoryTable({ id, stepId })

  const debouncedSetParams = useMemo(
    () =>
      debounce(searchText => {
        setFilters(prevParams => ({ ...prevParams, searchText }))
      }, 500),
    [setFilters]
  )
  const handleSearchChange = useCallback(
    e => {
      const value = e.target.value
      setSearchText(value)
      debouncedSetParams(value)
    },
    [debouncedSetParams]
  )

  if (isLoading) {
    return (
      <Box display={'flex'} height={'100vh'} alignItems={'center'}>
        <LoadingIndicator />
      </Box>
    )
  }
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      rowGap={2}
      height={'calc(100vh-350px)'}
      p={'32px'}
      bgcolor={'#fff'}
      sx={{ borderRadius: '20px', border: '1px solid #1C1C270D' }}
    >
      <Box display={'flex'} columnGap={'32px'} justifyContent={'space-between'}>
        <Typography fontSize={'24px'} fontWeight={500} fontFamily={'Satoshi'}>
          Users history
        </Typography>
        <Box width='280px'>
          <TextField
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                border: '1px solid #E7E7EE',
                background: '#fff',
                width: '100%'
              }
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
            onChange={handleSearchChange}
            value={searchText}
            size='small'
            placeholder='Search by User Name'
          />
        </Box>
      </Box>
      <DataTableContainer
        onRequestData={fetchData}
        emptyText={'No users found'}
        filter={filters}
        config={{
          columns,
          stickyPagination: false,
          stickyHeader: true,
          maxHeight: 'calc(100vh - 340px)'
        }}
        colorBody={'#fff'}
        initialSort={{ orderBy: 'actionAt', order: 'asc' }}
        data={statistics}
        totalCount={totalCount}
      />
    </Box>
  )
}
