import React from 'react'
import { Box } from '@mui/material'
import CustomTemplateCard from './CustomTemplateCard'

export default function CustomTemplateList({ templates, isHidden }) {
  if (isHidden) {
    return null
  }
  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'} rowGap={1}>
      {templates?.map((template, index) => (
        <CustomTemplateCard template={template} key={index} number={index + 1} />
      ))}
    </Box>
  )
}
