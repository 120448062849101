import TwoColumnLayout from '../../../../common/components/TwoColumnLayout'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import React from 'react'
import LoadingIndicator from '../../../../common/components/LoadingIndicator'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetCommonStepDataQuery, useGetOneSimulationQuery } from '../../../../api/simulation/simulation'
import { ReactComponent as BackIcon } from '../../../../common/icons/back_icon.svg'
import UsersHistoryTable from './UsersHistoryTable'
import TemplatesPreview from '../Activity/TemplatePreview'

export default function StepStatistic() {
  const navigate = useNavigate()

  const { id, stepId } = useParams()
  const { data, isLoading } = useGetCommonStepDataQuery({ id, stepId })
  const { data: simulationData, isLoading: isSimulationLoading } = useGetOneSimulationQuery(id)

  if (isLoading || isSimulationLoading) {
    return (
      <Box display={'flex'} height={'100vh'} alignItems={'center'}>
        <LoadingIndicator />
      </Box>
    )
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      padding={'32px 48px'}
      rowGap={1}
      bgcolor={'#F8F8FB'}
      minHeight={'100vh'}
    >
      <Box display={'flex'} columnGap={3} alignItems={'center'}>
        <Button
          onClick={() => navigate(`/simulation-campaign/${id}`)}
          sx={{
            textTransform: 'none',
            fontWeight: 500,
            fontSize: '16px',
            color: '#6123FF',
            fontFamily: 'Satoshi'
          }}
          startIcon={<BackIcon />}
          variant={'text'}
        >
          Back to Simulation Overview
        </Button>
      </Box>
      <Typography noWrap fontWeight={900} fontFamily={'Satoshi'} fontSize={'36px'}>
        Statistics of {data?.sequenceNumber}
        {getNumberSuffix(data?.sequenceNumber)} Step
      </Typography>
      <Tooltip title={simulationData?.name} placement={'bottom-start'}>
        <Typography noWrap fontWeight={400} fontFamily={'Plus Jakarta Sans'} fontSize={'16px'} pb={'24px'}>
          {simulationData?.name}
        </Typography>
      </Tooltip>
      <TwoColumnLayout
        leftComponent={<UsersHistoryTable />}
        rightComponent={<TemplatesPreview data={data} />}
        type={'oneThirds'}
      />
    </Box>
  )
}

function getNumberSuffix(number) {
  if (number === 1) return 'st'
  if (number === 2) return 'nd'
  if (number === 3) return 'rd'
  return 'th'
}
