import React from 'react'
import { IconButton } from '@mui/material'
import { ReactComponent as CloseIcon } from '../icons/close_icon.svg'
export default function CloseButton({ onClick }) {
  return (
    <IconButton onClick={onClick}>
      <CloseIcon />
    </IconButton>
  )
}
