import axios from 'axios'
import authHeader from './auth-header'
import { compactObject } from 'src/heplers'

const API_URL = window._env_.REACT_APP_API_HOST + '/v1/'

class GroupsService {
  getRules() {
    return axios.get(API_URL + 'group-rules', { headers: authHeader() })
  }

  getGroups(filters) {
    const params = new URLSearchParams(compactObject(filters))

    return axios.get(API_URL + 'groups', { headers: authHeader(), params })
  }

  getGroup(id) {
    return axios.get(API_URL + 'groups/' + id, { headers: authHeader() })
  }

  getGroupsStars(id) {
    return axios.get(API_URL + 'groups/' + id + '/stars', {
      headers: authHeader()
    })
  }

  addGroup(group) {
    return axios.post(API_URL + 'groups', group, { headers: authHeader() })
  }

  updateGroup(group) {
    return axios.put(API_URL + 'groups/' + group.id, group, {
      headers: authHeader()
    })
  }

  archiveGroup(id) {
    return axios.post(API_URL + 'groups/' + id + '/archive', {}, { headers: authHeader() })
  }

  unArchiveGroup(id) {
    return axios.post(API_URL + 'groups/' + id + '/unarchive', {}, { headers: authHeader() })
  }

  getUsersByRules(rules) {
    return axios.post(API_URL + 'group-rules/users/search', rules, {
      headers: authHeader()
    })
  }

  getGroupsSimulationsStats(id) {
    return axios.get(API_URL + 'groups/' + id + '/simulation-stats', {
      headers: authHeader()
    })
  }

  getGroupsSimulations(id) {
    return axios.get(API_URL + 'groups/' + id + '/simulations', {
      headers: authHeader()
    })
  }

  getGroupsTrainingPackagesStats(id) {
    return axios.get(API_URL + 'groups/' + id + '/training-package-stats', {
      headers: authHeader()
    })
  }

  getGroupsTrainingPackages(id) {
    return axios.get(API_URL + 'groups/' + id + '/training-packages', {
      headers: authHeader()
    })
  }

  getGroupsExtEmailStats(id) {
    return axios.get(API_URL + 'groups/' + id + '/external-email-stats', {
      headers: authHeader()
    })
  }

  getGroupsExtEmail(id) {
    return axios.get(API_URL + 'groups/' + id + '/external-emails', {
      headers: authHeader()
    })
  }
}

export default new GroupsService()
