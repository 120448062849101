import React, { useCallback, useMemo, useState } from 'react'
import DataTableContainer from '../../../common/components/DataTable/DataTableContainer'
import { Box, debounce, TextField, Typography } from '@mui/material'
import LoadingIndicator from '../../../common/components/LoadingIndicator'
import { useParams } from 'react-router-dom'
import { ReactComponent as SearchIcon } from '../../../common/icons/search.svg'
import useTrainingsUsersTable from './useTrainingsUsersTable'

export default function TrainingsUsers({ detailsHeight }) {
  const tableHeight = `calc(100vh - ${detailsHeight + 326}px)`
  const [searchText, setSearchText] = useState('')
  const [filters, setFilters] = useState({ searchText: '' })

  const { id } = useParams()

  const { columns, fetchData, totalCount, users, isLoading } = useTrainingsUsersTable({
    id
  })

  const debouncedSetParams = useMemo(
    () =>
      debounce(searchText => {
        setFilters(prevParams => ({ ...prevParams, searchText }))
      }, 500),
    [setFilters]
  )

  const handleSearchChange = useCallback(
    e => {
      const value = e.target.value
      setSearchText(value)
      debouncedSetParams(value)
    },
    [debouncedSetParams]
  )

  if (isLoading) {
    return (
      <Box display={'flex'} height={'100vh'} alignItems={'center'}>
        <LoadingIndicator />
      </Box>
    )
  }

  return (
    <Box width={'100%'} pt={'64px'} display={'flex'} flexDirection={'column'} rowGap={'20px'}>
      <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
        <Typography noWrap fontWeight={900} color={'#1B1B26'} fontFamily={'Satoshi'} fontSize={'24px'} pb={'8px'}>
          Users in packages
        </Typography>
        <Box width='528px'>
          <TextField
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                border: '1px solid #E7E7EE',
                background: '#fff',
                width: '100%'
              }
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
            onChange={handleSearchChange}
            value={searchText}
            size='small'
            placeholder='Search by users'
          />
        </Box>
      </Box>
      <DataTableContainer
        onRequestData={fetchData}
        filter={filters}
        emptyText={'No users found'}
        config={{
          columns,
          stickyPagination: true,
          stickyHeader: true,
          height: tableHeight
        }}
        data={users}
        initialSort={{ orderBy: 'firstName', order: 'asc' }}
        totalCount={totalCount}
      />
    </Box>
  )
}
