import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from '@mui/material'
import { Link, useParams } from 'react-router-dom'

export default function NavTabs({ tabItems }) {
  const params = useParams()
  function getActiveTab() {
    return tabItems.find(tab => tab.to === params['*'])?.value || 0
  }
  const [activeTab, setActiveTab] = useState(getActiveTab())

  useEffect(() => {
    setActiveTab(getActiveTab())
  }, [params['*']])

  return (
    <div>
      <Tabs sx={classes.tabs} value={activeTab} selectionFollowsFocus={true}>
        {tabItems.map(
          (tab, index) =>
            !tab.isHidden && (
              <Tab
                disabled={tab.disabled}
                sx={classes.tab}
                key={index}
                value={tab.value}
                label={tab.label}
                onClick={() => setActiveTab(tab.value)}
                to={tab.to}
                component={tab?.component || Link}
              />
            )
        )}
      </Tabs>
      <div>{tabItems.find(tab => tab.value === activeTab)?.content}</div>
    </div>
  )
}

const classes = {
  tab: {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 600,
    color: '#6123FF',
    padding: '12px 16px',
    fontFamily: 'Plus Jakarta Sans',
    '&.Mui-selected': {
      color: '#1B1B26',
      borderBottom: '2px solid #1B1B26'
    }
  },
  tabs: {
    borderBottom: '1px solid #E7E8EA',
    '& .MuiTabs-indicator': {
      display: 'none'
    }
  }
}
