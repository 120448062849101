import React, { useContext, useEffect } from 'react'

import { PageNumberContext } from '../PageNumberContext'
import { useForm } from 'react-final-form'
import { QueryParamsTemplateContext } from './QueryParamsTemplateContext'
import TemplateEditor from '../TemplateEditor/TemplateEditor'

export default function NewTemplateModal() {
  const { setPage } = useContext(PageNumberContext)

  const form = useForm()
  const values = form.getState().values

  const { setParams, selectedTemplateId, setSelectedTemplateId, isSystemTemplate } =
    useContext(QueryParamsTemplateContext)

  useEffect(() => {
    setParams(prevParams => ({ ...prevParams, update: false }))
  }, [])

  function handleOnCreate(data) {
    setParams(prevParams => ({ ...prevParams, myTemplates: true, update: true }))
    form.change('customTemplateIds', [...new Set([...values.customTemplateIds, data.id])])
    setSelectedTemplateId(null)
    setPage('EMAIL_TEMPLATE')
  }

  return (
    <TemplateEditor
      setSelectedTemplateId={setSelectedTemplateId}
      selectedTemplateId={selectedTemplateId}
      onSubmit={handleOnCreate}
      isSystemTemplate={isSystemTemplate}
    />
  )
}
