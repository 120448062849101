import React, { useState } from 'react'
import { Button } from '@mui/material'
import EditIcon from '../../../common/icons/editIcon'
import TrainingModal from '../NewTraining/TrainingModal'

export default function EditButton({ id, isHidden }) {
  const [open, setOpen] = useState(false)

  function handleClick() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  if (isHidden) {
    return null
  }
  return (
    <>
      <Button
        startIcon={<EditIcon />}
        onClick={handleClick}
        variant={'outlined'}
        sx={{
          height: '40px',
          borderRadius: '12px',
          textTransform: 'none',
          fontSize: '14px',
          fontWeight: 600,
          fontFamily: 'Plus Jakarta Sans',
          bgcolor: '#fff',
          border: '1px solid #E7E7EE',
          color: '#1B1B26',
          '&:hover': {
            border: 'none',
            bgcolor: '#F0E7F9'
          }
        }}
      >
        Edit Package
      </Button>
      {open && <TrainingModal editId={id} title={'Update package'} open={open} handleClose={handleClose} />}
    </>
  )
}
