import React, { useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import { Box, Snackbar, Typography } from '@mui/material'
import CustomizedDialogs from '../../../common/components/CustomDialog'
import ClearableInput from '../../../common/components/ClearableInput'

import {
  usePostSimulationMutation,
  useLazyGetOneSimulationQuery,
  usePutSimulationMutation
} from '../../../api/simulation/simulation'
import GroupUserSelectionField from './GroupUserSelectionField'
import { useNavigate } from 'react-router-dom'
import LoadingIndicator from '../../../common/components/LoadingIndicator'
import customToastWithAlert from '../../../common/components/CustomToastWithAlert'

export default function NewCampaignModal({ editId, handleClose, open }) {
  const isEdit = !!editId

  const [getItems, { data: editData, isLoading }] = useLazyGetOneSimulationQuery()

  const [handleSubmit, { data, isSuccess, isError }] = usePostSimulationMutation()

  const [handleEdit, { isSuccess: isEditSuccess }] = usePutSimulationMutation()

  const navigate = useNavigate()

  const onSubmit = values => {
    if (isEdit) {
      handleEdit({
        id: editId,
        name: values.campaignName,
        groupIds: values.groupUserSelection?.groupIds,
        userIds: values.groupUserSelection?.userIds
      })
      return
    }
    handleSubmit({
      name: values.campaignName,
      groupIds: values.groupUserSelection?.groupIds,
      userIds: values.groupUserSelection?.userIds,
      steps: []
    })
  }
  useEffect(() => {
    if (isSuccess) {
      handleClose()
      customToastWithAlert({
        message: 'Campaign created successfully'
      })
      navigate(`/simulation-campaign/${data.id}`)
    }
    if (isEditSuccess) {
      handleClose()
      customToastWithAlert({
        message: 'Campaign updated successfully'
      })
      navigate(`/simulation-campaign/${editId}`)
    }
  }, [isSuccess, isEditSuccess])

  useEffect(() => {
    if (isEdit) {
      getItems(editId)
    }
  }, [isEdit, editId])

  if (isEdit && isLoading) {
    return <LoadingIndicator />
  }

  return (
    <Form
      onSubmit={onSubmit}
      keepDirtyOnReinitialize
      initialValues={
        isEdit
          ? {
              campaignName: editData?.name,
              groupUserSelection: {
                groupIds: editData?.groupIds,
                userIds: editData?.userIds
              }
            }
          : {}
      }
      render={({ handleSubmit, values, form }) => (
        <CustomizedDialogs
          actionDescription={
            <Box display={'flex'} alignItems={'center'} columnGap={'8px'}>
              <Typography fontWeight={500} fontSize={'24px'} fontFamily={'Satoshi'}>
                {values?.groupUserSelection?.groupIds?.length || values?.groupUserSelection?.userIds?.length}
              </Typography>
              <Typography fontWeight={400} fontSize={'16px'} fontFamily={'Plus Jakarta Sans'}>
                Items Selected
              </Typography>
            </Box>
          }
          actions={[
            {
              disabled:
                !values.campaignName ||
                (!values.groupUserSelection?.groupIds?.length && !values.groupUserSelection?.userIds?.length),
              variant: 'contained',
              handler: handleSubmit,
              text: 'Continue'
            }
          ]}
          open={open}
          handleClose={() => {
            form.reset()
            handleClose(form)
          }}
          title='Info'
        >
          <Field name='campaignName'>
            {({ input }) => (
              <ClearableInput
                sx={{ height: 60, borderRadius: 14, '& .MuiInputBase-root': { borderRadius: '14px' } }}
                {...input}
                placeholder='Campaign Name'
              />
            )}
          </Field>
          <Field name='groupUserSelection' component={GroupUserSelectionField} />
          {isError && (
            <Snackbar
              sx={{ '& .MuiSnackbarContent-root': { backgroundColor: '#303051F2', borderRadius: '8px' } }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={isError}
              autoHideDuration={6000}
              onClose={handleClose}
              message='Failed to create campaign'
            />
          )}
        </CustomizedDialogs>
      )}
    />
  )
}
