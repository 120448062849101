import React, { useContext, useEffect } from 'react'
import { PageNumberContext } from './PageNumberContext'
import ChooseTypeCard from './ChooseTypeCard'
import { Field, useForm, useFormState } from 'react-final-form'
import { Box, TextField, Typography } from '@mui/material'
import GenericSelect from '../../../common/components/GenericSelect'
import { campaignTriggerOptions, messagesType, timelineOptions } from './utils'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

export default function SecondPageForOtherStepsForm() {
  const { values } = useFormState()
  const form = useForm()
  const { setPage } = useContext(PageNumberContext)
  const isSmsTemplate = values?.metadata?.phoneNumber
  const isSlack = values?.type === 'SLACK'
  const emailTemplatesCount = isNaN(values?.internalTemplateIds?.length + values?.customTemplateIds?.length)
    ? 0
    : values?.internalTemplateIds?.length + values?.customTemplateIds?.length

  const cardMapper = {
    AUTOMATED_EMAIL: {
      type: 'AUTOMATED_EMAIL',
      title: 'AI-Generated Email',
      description:
        'Cyberlift will automatically generate a unique and personalized template for each employee using AI',
      isAI: true,
      onChange: () => setPage(1)
    },
    MANUAL_EMAIL: {
      type: 'MANUAL_EMAIL',
      title: 'Custom Email',
      description: 'Select a template from the database or create your own email from scratch to suit your needs',
      isAI: true,
      onChange: () => setPage(1)
    },
    SMS: {
      type: 'SMS',
      title: 'SMS',
      description: 'Send text messages automatically',
      isAI: false,
      onChange: () => setPage(1)
    },
    WHATSAPP: {
      type: 'WHATSAPP',
      title: 'Whatsapp',
      description: 'Automate WhatsApp messages',
      isAI: false,
      onChange: () => setPage(1)
    },
    SLACK: {
      type: 'SLACK',
      title: 'Slack',
      description: 'Post messages to Slack channels',
      isAI: false,
      onChange: () => setPage(1)
    },
    PHONE: {
      type: 'PHONE',
      title: 'Phone Call',
      description: 'Automate phone call setups',
      isAI: false,
      onClick: () => {},
      isDisabled: true
    },
    SMS_TEMPLATE: {
      type: 'SMS_TEMPLATE',
      title: 'Template',
      description: '',
      isAI: false,
      onChange: () => {
        setPage('SMS_TEMPLATE')
      },
      number: isSmsTemplate && 1,
      isDisabled: false
    },
    SLACK_TEMPLATE: {
      type: 'SLACK_TEMPLATE',
      title: 'Template',
      description: '',
      isAI: false,
      number: values?.metadata?.message && 1,
      onChange: () => {
        setPage('SLACK_TEMPLATE')
      }
    },
    EMAIL_TEMPLATE: {
      type: 'EMAIL_TEMPLATE',
      title: 'Template',
      description: '',
      isAI: false,
      onChange: () => {
        setPage('EMAIL_TEMPLATE')
      },
      number: emailTemplatesCount,
      isDisabled: false
    }
  }
  useEffect(() => {
    if (values?.timeline !== 'CUSTOM_DAYS_COUNT') {
      form.change('daysCount', null)
    }
    if (values?.timeline !== 'CUSTOM_DATE') {
      form.change('startDate', null)
    }
  }, [values?.timeline])
  return (
    <Box display={'flex'} flexDirection={'column'} rowGap={3}>
      <ChooseTypeCard {...cardMapper[values?.type]} />
      {isSlack && <ChooseTypeCard {...cardMapper['SLACK_TEMPLATE']} />}
      {messagesType.includes(values?.type) && <ChooseTypeCard {...cardMapper['SMS_TEMPLATE']} />}
      {values?.type === 'MANUAL_EMAIL' && <ChooseTypeCard {...cardMapper['EMAIL_TEMPLATE']} />}
      <Box display={'flex'} flexDirection={'column'} columnGap={1} gap={2}>
        <Typography color={'#76767F'} fontWeight={500} fontSize={'16px'} fontFamily={'Plus Jakarta Sans'}>
          Choose event & timing
        </Typography>
        <Box display={'flex'} columnGap={2}>
          <Field
            name={'triggerAction'}
            render={({ input }) => (
              <GenericSelect
                label={'Event'}
                options={campaignTriggerOptions}
                value={input.value}
                onChange={input.onChange}
              />
            )}
          />
          <Field
            name={'timeline'}
            render={({ input, meta }) => (
              <GenericSelect
                label={'Timing'}
                options={timelineOptions}
                value={input.value}
                onChange={value => {
                  input.onChange(value)
                  if (values?.timeline !== 'CUSTOM_DATE' && values?.timeline !== 'CUSTOM_DAYS_COUNT') {
                    meta?.form?.change('startDate', null)
                  }
                }}
              />
            )}
          />
          {values?.timeline === 'CUSTOM_DATE' && (
            <Field
              name={'startDate'}
              render={({ input, meta }) => {
                return (
                  <DatePicker
                    format={'DD/MM/YYYY'}
                    disablePast={true}
                    minDate={dayjs()}
                    sx={{
                      width: 240,
                      marginRight: '20px',
                      backgroundColor: '#ffffff',
                      '& .MuiOutlinedInput-root': {
                        fontSize: 16,
                        fontWeight: 400,
                        fontFamily: 'Plus Jakarta Sans',
                        borderRadius: '10px'
                      },
                      '& .MuiOutlinedInput-input': {
                        padding: '12.5px 14px'
                      }
                    }}
                    slotProps={{
                      textField: {
                        error: meta.touched && meta.error
                      },
                      inputAdornment: { position: 'start', variant: 'standard' }
                    }}
                    onChangeRaw={e => e.preventDefault()}
                    shouldDisableDate={date => date.isBefore(dayjs(), 'day')}
                    value={dayjs(input.value)}
                    label='Start Date'
                    onChange={date => {
                      const selectedDate = dayjs(date)
                      if (selectedDate.isBefore(dayjs(), 'day')) {
                        input.onChange(dayjs().format('YYYY-MM-DD'))
                      } else {
                        input.onChange(selectedDate.format('YYYY-MM-DD'))
                      }
                    }}
                  />
                )
              }}
            />
          )}
          {values?.timeline === 'CUSTOM_DAYS_COUNT' && (
            <Field
              name={'daysCount'}
              render={({ input }) => (
                <TextField
                  label={'Days Count'}
                  type={'number'}
                  sx={{
                    width: 240,
                    marginRight: '20px',
                    backgroundColor: '#ffffff',
                    '& .MuiOutlinedInput-root': {
                      fontSize: 16,
                      fontWeight: 400,
                      fontFamily: 'Plus Jakarta Sans',
                      borderRadius: '10px'
                    },
                    '& .MuiOutlinedInput-input': {
                      padding: '12.5px 14px'
                    }
                  }}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
