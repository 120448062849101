import React, { useEffect, useMemo } from 'react'
import TemplateEditorForm from './TemplateEditorForm'
import { EditorProvider } from 'react-simple-wysiwyg'
import {
  useLazyGetCustomTemplateByIdQuery,
  useLazyGetSystemTemplateByIdQuery
} from '../../../../api/simulation/simulation'
import LoadingIndicator from '../../../../common/components/LoadingIndicator'

export default function TemplateEditor({ setSelectedTemplateId, onSubmit, selectedTemplateId, isSystemTemplate }) {
  const [getItems, { data: customTemplate, isLoading }] = useLazyGetCustomTemplateByIdQuery()

  const [getSystemTemplate, { data: systemTemplate, isLoading: isSystemTemplateLoading }] =
    useLazyGetSystemTemplateByIdQuery()

  useEffect(() => {
    if (selectedTemplateId && !isSystemTemplate) {
      getItems(selectedTemplateId)
    }
    if (selectedTemplateId && isSystemTemplate) {
      getSystemTemplate(selectedTemplateId)
    }
  }, [selectedTemplateId, isSystemTemplate])

  const data = useMemo(() => {
    if (isSystemTemplate) return systemTemplate
    return customTemplate
  }, [customTemplate, systemTemplate, isSystemTemplate])

  if (isLoading || isSystemTemplateLoading) return <LoadingIndicator />

  return (
    <EditorProvider>
      <TemplateEditorForm
        templateData={data}
        isSystemTemplate={isSystemTemplate}
        setSelectedTemplateId={setSelectedTemplateId}
        selectedTemplateId={selectedTemplateId}
        onSubmit={onSubmit}
      />
    </EditorProvider>
  )
}
