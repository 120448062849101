import { Button, Drawer } from '@mui/material'
import React, { useContext, useState } from 'react'
// import OpenFolderIcon from 'src/common/icons/openFolder'

import './styles.css'

// import ModuleView from 'src/views/ModuleView'
import { useEffect } from 'react'
// import trainingsService from 'src/services/trainings.service'
// import { useNavigate } from 'react-router-dom'
// import Spacer from 'src/common/components/Spacer'
import TabPill from 'src/common/components/TabPill'
// import dayjs from 'dayjs'

import { SearchInput } from 'src/common/components/DataGrid'
import useDebounce, { useQuery } from 'src/hooks'
// import Highlighter from 'react-highlight-words'
// import statsService from 'src/services/stats.service'
import PlusIcon from 'src/common/icons/plusIcon'
// import ViewCampaign from './components/viewCampaign'
import CampaignListItem from './components/CampaignsListItem'
// import { campaigns } from './mock-data'
import CampaignCreate from './components/CampaignCreate'
import CampaignView from './components/CampaignView'
import { CloseDrawer } from 'src/common/icons/closeDrawer'
// import TemplateCreate from '../templates/components/templateCreate'
import simulationCampaignsService from 'src/services/simulation-campaigns.service'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import { SnackBarContext } from 'src/App'
import { useNavigate } from 'react-router-dom'
import { timelineOptions } from '../new-campaign'

const STATUSES = [
  { code: 'IN_PROGRESS', name: 'In Progress' },
  { code: 'COMPLETED', name: 'Completed' },
  { code: 'ARCHIVED', name: 'Archived' }
]

export const SortHeader = ({ name, sort, onSort, width }) => {
  return (
    <div onClick={() => onSort(name)} className='row row-center groups-list-table-sorter' style={{ width: width }}>
      <span
        style={{
          color: name == sort?.name && '#6123FF',
          marginRight: '6px',
          lineHeight: '16px'
        }}
      >
        {name}
      </span>
      {sort?.name == name && (
        <svg
          style={{
            transform: !sort.asc && 'rotate(180deg)',
            transition: '.3s'
          }}
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M7.83325 3.33594V12.8359'
            stroke='#6123FF'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M3.33325 8.33594L7.83325 12.8359L12.3333 8.33594'
            stroke='#6123FF'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}
    </div>
  )
}

const CampaignsGroupByStatus = ({ item, packages, onEdit, onView, handleUpdate, search }) => {
  const [sortedItems, setSortedItems] = useState(null)
  const [sortBy, setSortBy] = useState(null)
  const items = packages.map(el => ({
    ...el,
    usersCount: el?.usersCount
  }))

  const handleSort = field => {
    const fieldset = {
      name: 'name',
      'start date': 'startDate',
      employees: 'usersCount',
      'fail rate': 'simulationsFailRate'
    }
    const fieldName = fieldset[field.toLowerCase()]
    const asc = field == sortBy?.name ? !sortBy.asc : true
    setSortedItems(
      items
        .map(el => ({
          ...el,
          modules: el.trainingModuleIds?.length || 0
        }))
        .sort((a, b) => {
          if (fieldName == 'usersCount' || fieldName == 'simulationsFailRate') {
            if (a[fieldName] < b[fieldName]) {
              return asc ? -1 : 1
            }
            if (a[fieldName] > b[fieldName]) {
              return asc ? 1 : -1
            }
            return 0
          } else {
            if (a[fieldName].toLowerCase() < b[fieldName].toLowerCase()) {
              return asc ? -1 : 1
            }
            if (a[fieldName].toLowerCase() > b[fieldName].toLowerCase()) {
              return asc ? 1 : -1
            }
            return 0
          }
        })
    )
    setSortBy({
      name: field,
      asc: field == sortBy?.name ? !sortBy.asc : true
    })
  }

  const handleSortTimeline = () => {
    const asc = 'Timeline' == sortBy?.name ? !sortBy.asc : true
    setSortedItems(
      items
        .map(el => ({
          ...el,
          modules: el.trainingModuleIds?.length || 0
        }))
        .sort((a, b) => {
          if (
            timelineOptions.find(el => el.value == a.timeline).index <
            timelineOptions.find(el => el.value == b.timeline).index
          ) {
            return asc ? -1 : 1
          }
          if (
            timelineOptions.find(el => el.value == a.timeline).index >
            timelineOptions.find(el => el.value == b.timeline).index
          ) {
            return asc ? 1 : -1
          }
          return 0
        })
    )
    setSortBy({
      name: 'Timeline',
      asc: 'Timeline' == sortBy?.name ? !sortBy.asc : true
    })
  }

  return (
    <div className='campaigns-group'>
      <h2
        style={{
          marginBottom: '8px',
          fontFamily: 'Satoshi',
          fontSize: '24px'
        }}
      >
        {item.name}
      </h2>

      <div className=''>
        <div className='row row-center groups-list-heading'>
          <SortHeader name='Name' sort={sortBy} onSort={handleSort} width={'40%'} />
          <SortHeader name='Start date' sort={sortBy} onSort={handleSort} width={'10%'} />
          <SortHeader name='Timeline' sort={sortBy} onSort={handleSortTimeline} width={'15%'} />
          <SortHeader name='Employees' sort={sortBy} onSort={handleSort} width={'10%'} />
          <SortHeader name='Fail rate' sort={sortBy} onSort={handleSort} width={'25%'} />
        </div>
        {sortedItems
          ? packages &&
            packages.length &&
            sortedItems
              .filter(el => el.status == item.code)
              ?.map(item => (
                <CampaignListItem
                  search={search}
                  key={item.id}
                  item={item}
                  onEditPackage={onEdit}
                  onView={onView}
                  handleUpdate={handleUpdate}
                />
              ))
          : packages &&
            packages.length &&
            packages
              .filter(el => el.status == item.code)
              ?.map(item => (
                <CampaignListItem
                  search={search}
                  key={item.id}
                  item={item}
                  onEditPackage={onEdit}
                  onView={onView}
                  handleUpdate={handleUpdate}
                />
              ))}
      </div>
    </div>
  )
}

const ManualSimulation = () => {
  const [campaigns, setCampaigns] = useState([])
  const [uncheckedTabs, setUncheckedtabs] = useState([])
  const [openCreateCampaign, setOpenCreateCampaign] = useState(false) ///
  const [openViewCampaign, setOpenViewCampaign] = useState(false)

  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [selectedId, setSelectedId] = useState(null)
  const [search, setSearch] = useState('')
  const debouncedSearchTerm = useDebounce(search, 500)
  const { setSuccessMessage } = useContext(SnackBarContext)

  const query = useQuery()

  const onFilter = criteria => {
    simulationCampaignsService.getSimulationCampaigns(criteria.searchText).then(resp => {
      setCampaigns(resp.data)
    })
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      onFilter({ searchText: search })
    } else {
      onFilter({ searchText: '' })
    }
  }, [debouncedSearchTerm])

  const handleSelectItem = id => {
    navigate('/edit-campaign?id=' + id)
  }

  const handleViewPackage = id => {
    setSelectedId(id)
    setOpenViewCampaign(true)
  }

  const handleUpdate = eventType => {
    setLoading(true)
    simulationCampaignsService
      .getSimulationCampaigns()
      .then(resp => {
        setLoading(false)
        setCampaigns(resp.data)

        if (eventType == 'archive') {
          setSuccessMessage('Successfully archived campaign')
        }

        if (eventType == 'publish') {
          setSuccessMessage('Successfully activated campaign')
        }
      })
      .catch(err => {
        setLoading(false)
        console.error(err)
      })
  }

  const handleArchive = (id, archived) => {
    if (archived) {
      simulationCampaignsService.unarchiveSimulationCampaign(id).then(() => {
        handleUpdate('publish')
      })
    } else {
      simulationCampaignsService.archiveSimulationCampaign(id).then(() => {
        handleUpdate('archive')
      })
    }
  }

  // const handleBack = () => {
  //   console.log('back');
  //   setOpenCreateCampaign(false)
  //    setOpenViewCampaign(true)
  // }

  useEffect(() => {
    if (history.state.usr?.campaignAction) {
      setSuccessMessage('Manual Campaign ' + history.state.usr.campaignAction)
      history.pushState({ campaignAction: null }, null, null)
    }
    if (query.get('showCreateNew')) {
      setOpenCreateCampaign(true)
    }
    setLoading(true)
    simulationCampaignsService
      .getSimulationCampaigns()
      .then(resp => {
        setLoading(false)
        setCampaigns(resp.data)
      })
      .catch(err => {
        setLoading(false)
        console.error(err)
      })
  }, [])

  const tabs = [
    {
      id: 2,
      text: 'In Progress',
      itemsCount: campaigns?.filter(el => el.status == 'IN_PROGRESS').length || 0
    },
    {
      id: 4,
      text: 'Completed',
      itemsCount: campaigns?.filter(el => el.status == 'COMPLETED').length || 0
    },
    {
      id: 3,
      text: 'Archived',
      itemsCount: campaigns?.filter(el => el.status == 'ARCHIVED').length || 0
    }
  ]

  const handleTabPillSelect = name => {
    setUncheckedtabs(uncheckedTabs.includes(name) ? uncheckedTabs.filter(el => el !== name) : [...uncheckedTabs, name])
  }

  const navigate = useNavigate()

  return (
    <div style={{ padding: '24px' }}>
      <div style={{ marginBottom: '32px' }} className='row row-center'>
        <h1 style={{ marginBottom: '0', marginRight: 20 }}>Manual Simulation</h1>
        {/* <Spacer /> */}
        <SearchInput
          sx={{
            width: '400px',
            margin: '0',
            marginRight: '20px',
            flex: 1
          }}
          onChange={e => setSearch(e.target.value)}
          value={search}
          size='small'
          placeholder='Search'
        />
        {/* <Spacer /> */}
        <Button
          variant={'contained'}
          sx={{
            background: '#6123FF',
            borderRadius: '12px',
            fontFamily: 'Plus Jakarta Sans',
            color: '#fff',
            textTransform: 'none',
            width: 270
          }}
          startIcon={<PlusIcon color='#ffffff' />}
          onClick={() => {
            navigate('/new-campaign')
          }}
        >
          Create New Campaign
        </Button>
      </div>

      <div className='row row-center' style={{ marginTop: '24px', marginBottom: '30px' }}>
        {tabs.map(el => (
          <TabPill
            key={el.id}
            checked={!uncheckedTabs.includes(el.text)}
            onSelect={handleTabPillSelect}
            text={el.text}
            amount={el.itemsCount}
          />
        ))}
      </div>

      <div
        className='manual-simulations-content'
        style={{
          padding: '30px 32px',
          margin: '0px -23px -55px -32px',
          height: 'calc(100vh - 178px)',
          overflow: 'auto'
        }}
      >
        {loading && <LoadingIndicator />}
        {!loading && campaigns.length == 0 && uncheckedTabs.length !== 0 && (
          <div style={{ alignItems: 'center', marginTop: '100px' }} className='column'>
            <svg width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                opacity='0.2'
                d='M71 20H27.5556C26.1442 20 25 21.6281 25 23.6364V96.3636C25 98.3719 26.1442 100 27.5556 100H68.4444C69.8558 100 71 98.3719 71 96.3636V69.697V20Z'
                fill='#E7E7EE'
              />
              <path
                d='M90 18.75H28.75C26.6789 18.75 25 20.4289 25 22.5V97.5C25 99.5711 26.6789 101.25 28.75 101.25H88.75C90.8211 101.25 92.5 99.5711 92.5 97.5V70'
                stroke='#E7E7EE'
                strokeWidth='5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path d='M60 55H80' stroke='#E7E7EE' strokeWidth='5' strokeLinecap='round' strokeLinejoin='round' />
              <path d='M60 45H105' stroke='#E7E7EE' strokeWidth='5' strokeLinecap='round' strokeLinejoin='round' />
              <path d='M60 35H115' stroke='#E7E7EE' strokeWidth='5' strokeLinecap='round' strokeLinejoin='round' />
            </svg>

            <p
              style={{
                color: '#76767F',
                textAlign: 'center'
              }}
            >
              Here will be stored all the manual simulations <br /> performed by you
            </p>
          </div>
        )}

        {!loading &&
          campaigns?.filter(pack => !uncheckedTabs.includes(STATUSES.find(item => item.code == pack.status)?.name))
            .length == 0 && (
            <div style={{ alignItems: 'center', marginTop: '100px' }} className='column'>
              <svg width='221' height='80' viewBox='0 0 221 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect y='10' width='221' height='60' rx='30' fill='#F4F5F9' />
                <path
                  d='M54.375 32.125L38.625 47.8743L30.75 40'
                  stroke='#BABBC2'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <rect x='69' y='31' width='80' height='18' fill='#E7E7EE' />
                <rect x='161' y='22' width='36' height='36' rx='18' fill='white' />
              </svg>

              <p
                style={{
                  color: '#76767F',
                  textAlign: 'center'
                }}
              >
                Select the simulations you want
                <br />
                to be displayed
              </p>
            </div>
          )}

        {STATUSES.map(
          el =>
            !uncheckedTabs.includes(el.name) &&
            campaigns?.filter(pack => pack.status == el.code).length > 0 && (
              <CampaignsGroupByStatus
                key={el.code}
                item={el}
                packages={campaigns}
                onEdit={handleSelectItem}
                onView={handleViewPackage}
                handleUpdate={handleUpdate}
                search={search}
              />
            )
        )}
      </div>

      <Drawer
        sx={{ '& .MuiDrawer-paper': { background: '#F8F8FB' } }}
        anchor='right'
        open={openCreateCampaign}
        onClose={() => {
          setOpenCreateCampaign(false)
          setIsEdit(false)
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: '24px',
            right: '992px',
            cursor: 'pointer'
          }}
          onClick={() => {
            setOpenCreateCampaign(false)
            setIsEdit(false)
          }}
        >
          <CloseDrawer />
        </div>
        <CampaignCreate
          campaignsCount={campaigns?.length}
          // onCancelEdit={handleBack}
          handleClose={event => {
            setOpenCreateCampaign(false)
            setIsEdit(false)
            handleUpdate()
            if (event) {
              setSuccessMessage('Manual Campaign ' + event)
            }
          }}
          selectedId={selectedId}
          isEdit={isEdit}
        />
      </Drawer>

      <CampaignView
        open={openViewCampaign}
        onClose={() => setOpenViewCampaign(false)}
        selectedId={selectedId}
        onEdit={handleSelectItem}
        onArchive={handleArchive}
      />
    </div>
  )
}

export default ManualSimulation
