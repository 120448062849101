import React from 'react'

export const FailRateChip = ({ value, direction = 'up' }) => {
  return (
    <div
      style={{
        alignItems: 'center',
        padding: '6px 0px'
      }}
      className='row'
    >
      {direction == 'dn' ? (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M6 6L17 17' stroke='#B40020' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M8 17.75H17.75V8' stroke='#B40020' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
      ) : (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <mask id='mask0_1109_53057' maskUnits='userSpaceOnUse' x='2' y='2' width='20' height='20'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M17.1366 2.5H6.84245H6.84244C6.2784 2.49999 5.79804 2.49997 5.40364 2.52916C4.98936 2.55982 4.5862 2.62696 4.20088 2.80483C3.61979 3.07308 3.14791 3.50106 2.85208 4.02708C2.65579 4.37612 2.58166 4.74122 2.54781 5.11652C2.5156 5.47375 2.51561 5.90885 2.51563 6.41985V6.41986V10.2777C2.51563 17.1439 8.2698 20.2031 10.6085 21.1622L10.6125 21.1639C10.8683 21.2688 11.077 21.3544 11.4673 21.4151C11.634 21.441 11.837 21.4474 11.9897 21.4474C12.1424 21.4474 12.3454 21.441 12.5121 21.4151C12.9007 21.3546 13.1085 21.2694 13.3616 21.1656L13.3658 21.1639L13.3698 21.1622C15.7085 20.2032 21.463 17.1439 21.463 10.2777V6.41671V6.41666C21.463 5.90671 21.463 5.4723 21.4308 5.11554C21.397 4.74068 21.3229 4.37589 21.1268 4.02708C20.8306 3.50048 20.3577 3.07278 19.7773 2.80483C19.3921 2.62701 18.9892 2.55983 18.575 2.52916C18.1808 2.49997 17.7006 2.49999 17.1366 2.5Z'
              fill='white'
            />
          </mask>
          <g mask='url(#mask0_1109_53057)'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M17.1366 2.5H6.84245H6.84244C6.2784 2.49999 5.79804 2.49997 5.40364 2.52916C4.98936 2.55982 4.5862 2.62696 4.20088 2.80483C3.61979 3.07308 3.14791 3.50106 2.85208 4.02708C2.65579 4.37612 2.58166 4.74122 2.54781 5.11652C2.5156 5.47375 2.51561 5.90885 2.51563 6.41985V6.41986V10.2777C2.51563 17.1439 8.2698 20.2031 10.6085 21.1622L10.6125 21.1639C10.8683 21.2688 11.077 21.3544 11.4673 21.4151C11.634 21.441 11.837 21.4474 11.9897 21.4474C12.1424 21.4474 12.3454 21.441 12.5121 21.4151C12.9007 21.3546 13.1085 21.2694 13.3616 21.1656L13.3658 21.1639L13.3698 21.1622C15.7085 20.2032 21.463 17.1439 21.463 10.2777V6.41671V6.41666C21.463 5.90671 21.463 5.4723 21.4308 5.11554C21.397 4.74068 21.3229 4.37589 21.1268 4.02708C20.8306 3.50048 20.3577 3.07278 19.7773 2.80483C19.3921 2.62701 18.9892 2.55983 18.575 2.52916C18.1808 2.49997 17.7006 2.49999 17.1366 2.5Z'
              fill='#F42525'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M17.1366 2.5H6.84245H6.84244C6.2784 2.49999 5.79804 2.49997 5.40364 2.52916C4.98936 2.55982 4.5862 2.62696 4.20088 2.80483C3.61979 3.07308 3.14791 3.50106 2.85208 4.02708C2.65579 4.37612 2.58166 4.74122 2.54781 5.11652C2.5156 5.47375 2.51561 5.90885 2.51563 6.41985V6.41986V10.2777C2.51563 17.1439 8.2698 20.2031 10.6085 21.1622L10.6125 21.1639C10.8683 21.2688 11.077 21.3544 11.4673 21.4151C11.634 21.441 11.837 21.4474 11.9897 21.4474C12.1424 21.4474 12.3454 21.441 12.5121 21.4151C12.9007 21.3546 13.1085 21.2694 13.3616 21.1656L13.3658 21.1639L13.3698 21.1622C15.7085 20.2032 21.463 17.1439 21.463 10.2777V6.41671V6.41666C21.463 5.90671 21.463 5.4723 21.4308 5.11554C21.397 4.74068 21.3229 4.37589 21.1268 4.02708C20.8306 3.50048 20.3577 3.07278 19.7773 2.80483C19.3921 2.62701 18.9892 2.55983 18.575 2.52916C18.1808 2.49997 17.7006 2.49999 17.1366 2.5Z'
              fill='url(#paint0_linear_1109_53057)'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M17.1366 2.5H6.84245H6.84244C6.2784 2.49999 5.79804 2.49997 5.40364 2.52916C4.98936 2.55982 4.5862 2.62696 4.20088 2.80483C3.61979 3.07308 3.14791 3.50106 2.85208 4.02708C2.65579 4.37612 2.58166 4.74122 2.54781 5.11652C2.5156 5.47375 2.51561 5.90885 2.51563 6.41985V6.41986V10.2777C2.51563 17.1439 8.2698 20.2031 10.6085 21.1622L10.6125 21.1639C10.8683 21.2688 11.077 21.3544 11.4673 21.4151C11.634 21.441 11.837 21.4474 11.9897 21.4474C12.1424 21.4474 12.3454 21.441 12.5121 21.4151C12.9007 21.3546 13.1085 21.2694 13.3616 21.1656L13.3658 21.1639L13.3698 21.1622C15.7085 20.2032 21.463 17.1439 21.463 10.2777V6.41671V6.41666C21.463 5.90671 21.463 5.4723 21.4308 5.11554C21.397 4.74068 21.3229 4.37589 21.1268 4.02708C20.8306 3.50048 20.3577 3.07278 19.7773 2.80483C19.3921 2.62701 18.9892 2.55983 18.575 2.52916C18.1808 2.49997 17.7006 2.49999 17.1366 2.5Z'
              fill='url(#paint1_linear_1109_53057)'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M17.1366 2.5H6.84245H6.84244C6.2784 2.49999 5.79804 2.49997 5.40364 2.52916C4.98936 2.55982 4.5862 2.62696 4.20088 2.80483C3.61979 3.07308 3.14791 3.50106 2.85208 4.02708C2.65579 4.37612 2.58166 4.74122 2.54781 5.11652C2.5156 5.47375 2.51561 5.90885 2.51563 6.41985V6.41986V10.2777C2.51563 17.1439 8.2698 20.2031 10.6085 21.1622L10.6125 21.1639C10.8683 21.2688 11.077 21.3544 11.4673 21.4151C11.634 21.441 11.837 21.4474 11.9897 21.4474C12.1424 21.4474 12.3454 21.441 12.5121 21.4151C12.9007 21.3546 13.1085 21.2694 13.3616 21.1656L13.3658 21.1639L13.3698 21.1622C15.7085 20.2032 21.463 17.1439 21.463 10.2777V6.41671V6.41666C21.463 5.90671 21.463 5.4723 21.4308 5.11554C21.397 4.74068 21.3229 4.37589 21.1268 4.02708C20.8306 3.50048 20.3577 3.07278 19.7773 2.80483C19.3921 2.62701 18.9892 2.55983 18.575 2.52916C18.1808 2.49997 17.7006 2.49999 17.1366 2.5Z'
              fill='url(#paint2_linear_1109_53057)'
            />
            <g opacity='0.3'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.4457 14.4413L2.58891 -11.7109H-12.6406L6.15065 33.2891H22.7278L14.4457 14.4413Z'
                fill='url(#paint3_linear_1109_53057)'
              />
            </g>
          </g>
          <defs>
            <linearGradient
              id='paint0_linear_1109_53057'
              x1='19.0113'
              y1='-13.8816'
              x2='4.9652'
              y2='-12.6322'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#F5C51B' />
              <stop offset='1' stopColor='#F57D1B' />
            </linearGradient>
            <linearGradient
              id='paint1_linear_1109_53057'
              x1='19.0113'
              y1='-13.8816'
              x2='4.9652'
              y2='-12.6322'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#FF942E' />
              <stop offset='1' stopColor='#FF4D2E' />
            </linearGradient>
            <linearGradient
              id='paint2_linear_1109_53057'
              x1='19.0113'
              y1='-13.8816'
              x2='4.9652'
              y2='-12.6322'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#F5C51B' />
              <stop offset='1' stopColor='#F57D1B' />
            </linearGradient>
            <linearGradient
              id='paint3_linear_1109_53057'
              x1='18.1514'
              y1='-50.6172'
              x2='-8.14698'
              y2='-48.7787'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#F5C51B' />
              <stop offset='1' stopColor='#F57D1B' />
            </linearGradient>
          </defs>
        </svg>
      )}
      <p
        style={{
          marginLeft: '8px',
          fontWeight: 500,
          fontSize: '24px',
          lineHeight: '120%'
        }}
      >
        {Math.round(value * 100)}%
      </p>
    </div>
  )
}
