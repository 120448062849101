import React from 'react'
import './style.css'

const LoadingIndicator = ({ text, size = 'med' }) => {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div className='column column-center'>
        <svg
          className='rotating'
          style={{}}
          width={size == 'small' ? '32' : '64'}
          height={size == 'small' ? '32' : '64'}
          viewBox='0 0 64 64'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M32 8V16' stroke='#76767F' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M56 32H48' stroke='#76767F' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path
            d='M48.9694 48.9694L43.3125 43.3125'
            stroke='#76767F'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path d='M32 56V48' stroke='#76767F' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path
            d='M15.0312 48.9694L20.6881 43.3125'
            stroke='#76767F'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path d='M8 32H16' stroke='#76767F' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path
            d='M15.0312 15.0417L20.6881 20.6985'
            stroke='#76767F'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        <p style={{ color: '#5F5F68', marginTop: '20px' }}>{text ? text : 'Loading'}</p>
      </div>
    </div>
  )
}

export default LoadingIndicator
