import { IconButton, Menu, MenuItem } from '@mui/material'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import Highlighter from 'react-highlight-words'
import { FailRateIcon } from './failRateIcon'
import { TimelineCell } from './timelineCell'
import simulationCampaignsService from 'src/services/simulation-campaigns.service'
import ArchiveDialog from 'src/common/components/ArchiveDialog'

const CampaignItemIcon = ({ status }) => {
  if (status == 'IN_PROGRESS') {
    return (
      <svg width='44' height='48' viewBox='0 0 44 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect y='3' width='32' height='44' rx='2' fill='#F2F5FF' />
        <rect x='12' y='9' width='32' height='2' fill='#1B1B26' />
        <rect x='12' y='12.5' width='24' height='2' fill='#1B1B26' />
        <rect x='12' y='16' width='16' height='2' fill='#1B1B26' />
      </svg>
    )
  }
  if (status == 'COMPLETED') {
    return (
      <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect width='32' height='44' rx='2' fill='#F2F5FF' />
        <rect x='12' y='6' width='32' height='2' fill='url(#paint0_linear_2359_200536)' />
        <rect x='12' y='9.5' width='24' height='2' fill='url(#paint1_linear_2359_200536)' />
        <rect x='12' y='13' width='16' height='2' fill='url(#paint2_linear_2359_200536)' />
        <defs>
          <linearGradient
            id='paint0_linear_2359_200536'
            x1='39.8594'
            y1='4.27083'
            x2='31.9561'
            y2='15.5183'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#00BA97' />
            <stop offset='1' stopColor='#00BC62' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_2359_200536'
            x1='32.8946'
            y1='7.77083'
            x2='24.5119'
            y2='16.7181'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#00BA97' />
            <stop offset='1' stopColor='#00BC62' />
          </linearGradient>
          <linearGradient
            id='paint2_linear_2359_200536'
            x1='25.9297'
            y1='11.2708'
            x2='17.9932'
            y2='16.9182'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#00BA97' />
            <stop offset='1' stopColor='#00BC62' />
          </linearGradient>
        </defs>
      </svg>
    )
  }
  if (status == 'ARCHIVED') {
    return (
      <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect width='32' height='44' rx='2' fill='white' />
        <g clipPath='url(#clip0_2359_200599)'>
          <rect width='32' height='44' rx='2' fill='white' />
          <g opacity='0.2'>
            <rect x='58.853' y='26.7891' width='64' height='5' transform='rotate(-135 58.853 26.7891)' fill='#CFCFDD' />
            <rect
              x='53.1963'
              y='32.4453'
              width='64'
              height='5'
              transform='rotate(-135 53.1963 32.4453)'
              fill='#CFCFDD'
            />
            <rect
              x='47.5396'
              y='38.1016'
              width='64'
              height='5'
              transform='rotate(-135 47.5396 38.1016)'
              fill='#CFCFDD'
            />
            <rect
              x='41.8828'
              y='43.7617'
              width='64'
              height='5'
              transform='rotate(-135 41.8828 43.7617)'
              fill='#CFCFDD'
            />
            <rect x='36.2256' y='49.418' width='64' height='5' transform='rotate(-135 36.2256 49.418)' fill='#CFCFDD' />
            <rect
              x='30.5688'
              y='55.0742'
              width='64'
              height='5'
              transform='rotate(-135 30.5688 55.0742)'
              fill='#CFCFDD'
            />
            <rect
              x='24.9121'
              y='60.7305'
              width='64'
              height='5'
              transform='rotate(-135 24.9121 60.7305)'
              fill='#CFCFDD'
            />
            <rect
              x='19.2554'
              y='66.3867'
              width='64'
              height='5'
              transform='rotate(-135 19.2554 66.3867)'
              fill='#CFCFDD'
            />
          </g>
        </g>
        <g opacity='0.5'>
          <rect x='12' y='6' width='32' height='2' fill='#14143B' />
          <rect x='12' y='9.5' width='24' height='2' fill='#14143B' />
        </g>
        <defs>
          <clipPath id='clip0_2359_200599'>
            <rect width='32' height='44' rx='2' fill='white' />
          </clipPath>
        </defs>
      </svg>
    )
  }
  return null
}

export const timelineOptions = [
  {
    name: 'immediately',
    value: 'IMMEDIATELY'
  },
  {
    name: '1 day',
    value: 'THROUGHOUT_DAY'
  },
  {
    name: '1 week',
    value: 'THROUGHOUT_WEEK'
  },
  {
    name: '1 months',
    value: 'THROUGHOUT_MONTH'
  },
  {
    name: '3 months',
    value: 'THROUGHOUT_3_MONTHS'
  },
  {
    name: '6 months',
    value: 'THROUGHOUT_6_MONTHS'
  },
  {
    name: '1 year',
    value: 'THROUGHOUT_YEAR'
  }
]

const CampaignListItem = ({ item, onEditPackage = () => {}, onView = () => {}, handleUpdate = () => {}, search }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false)

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleArchive = () => {
    simulationCampaignsService.archiveSimulationCampaign(item.id).then(() => {
      handleUpdate('archive')
      setAnchorEl(null)
    })
  }

  const handleUnarchive = () => {
    simulationCampaignsService.unarchiveSimulationCampaign(item.id).then(() => {
      handleUpdate('publish')
      setAnchorEl(null)
    })
  }

  const totalTemplatesCount = item.templatesCount

  return (
    <div key={item.id} className='manual-simulation-item column'>
      <div className='row row-center'>
        <div className='row' style={{ width: '40%' }}>
          <div style={{ marginRight: 10 }}>
            <CampaignItemIcon status={item.status} />
          </div>
          <div className='column'>
            <p style={{ fontSize: '14px', marginRight: 20 }} className='package-description'>
              {totalTemplatesCount &&
                (totalTemplatesCount == 1 ? totalTemplatesCount + ' Template' : totalTemplatesCount + ' Templates')}
            </p>
            <p
              style={{
                fontWeight: '600',
                marginBottom: '6px',
                cursor: 'pointer',
                marginRight: 20
              }}
              className='package-description'
              onClick={() => onView(item.id)}
            >
              <Highlighter
                highlightClassName='highlight-text'
                searchWords={[search]}
                autoEscape={true}
                textToHighlight={item.name}
              />
            </p>
          </div>
        </div>
        <div style={{ width: '10%' }}>{dayjs(item.startDate).format('MMM DD, YYYY')}</div>
        <div
          style={{
            width: '15%',
            display: 'flex',
            alignItems: 'flex-start'
          }}
        >
          <TimelineCell value={timelineOptions.find(el => el.value === item.timeline)?.name ?? ''} />
        </div>
        <div style={{ width: '10%' }}>
          <p className='sato24'>{item.usersCount}</p>
        </div>
        <div style={{ width: '25%', justifyContent: 'flex-end' }} className='row row-center'>
          <div className='progress-hatched row'>
            <div
              className={`progress-hatched-inner row row-center ${item.simulationsFailRate < 50 ? ' progress-hatched-inner-green' : ''}${item.simulationsFailRate >= 50 ? ' progress-hatched-inner-warning' : ''}`}
              style={{
                width: item.simulationsFailRate + '%',
                minWidth: '87px'
              }}
            >
              <FailRateIcon variant={item.simulationsFailRate} />
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: '600'
                }}
              >
                {' '}
                {item.simulationsFailRate} %
              </p>
            </div>
          </div>
          <IconButton onClick={handleOpenMenu}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12 13.125C12.6213 13.125 13.125 12.6213 13.125 12C13.125 11.3787 12.6213 10.875 12 10.875C11.3787 10.875 10.875 11.3787 10.875 12C10.875 12.6213 11.3787 13.125 12 13.125Z'
                fill='#1B1B26'
              />
              <path
                d='M12 7.125C12.6213 7.125 13.125 6.62132 13.125 6C13.125 5.37868 12.6213 4.875 12 4.875C11.3787 4.875 10.875 5.37868 10.875 6C10.875 6.62132 11.3787 7.125 12 7.125Z'
                fill='#1B1B26'
              />
              <path
                d='M12 19.125C12.6213 19.125 13.125 18.6213 13.125 18C13.125 17.3787 12.6213 16.875 12 16.875C11.3787 16.875 10.875 17.3787 10.875 18C10.875 18.6213 11.3787 19.125 12 19.125Z'
                fill='#1B1B26'
              />
            </svg>
          </IconButton>
          <Menu
            sx={{
              '& .MuiPaper-root': {
                backgroundColor: '#303051',
                color: '#ffffff',
                width: '200px',
                borderRadius: '12px'
              },
              '& li': {
                opacity: '0.7',
                fontFamily: 'Plus Jakarta Sans',
                paddingLeft: '24px'
              },
              '& li:hover': {
                opacity: '1'
              }
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem
              onClick={() => {
                onView(item.id)
                setAnchorEl(null)
              }}
            >
              <svg
                style={{ marginRight: '10px' }}
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g opacity='0.76'>
                  <path
                    opacity='0.2'
                    d='M12 5.25C4.5 5.25 1.5 12.0007 1.5 12.0007C1.5 12.0007 4.5 18.75 12 18.75C19.5 18.75 22.5 12.0007 22.5 12.0007C22.5 12.0007 19.5 5.25 12 5.25ZM12 15.7508C11.2583 15.7508 10.5333 15.5308 9.91661 15.1188C9.29993 14.7067 8.81928 14.1211 8.53545 13.4358C8.25162 12.7506 8.17736 11.9966 8.32205 11.2692C8.46675 10.5418 8.8239 9.87357 9.34835 9.34913C9.8728 8.82468 10.541 8.46753 11.2684 8.32283C11.9958 8.17814 12.7498 8.2524 13.4351 8.53623C14.1203 8.82006 14.706 9.30071 15.118 9.91739C15.5301 10.5341 15.75 11.2591 15.75 12.0008C15.75 12.9953 15.3549 13.9492 14.6516 14.6524C13.9484 15.3557 12.9946 15.7508 12 15.7508Z'
                    fill='white'
                  />
                  <path
                    d='M12 5.25C4.5 5.25 1.5 12.0007 1.5 12.0007C1.5 12.0007 4.5 18.75 12 18.75C19.5 18.75 22.5 12.0007 22.5 12.0007C22.5 12.0007 19.5 5.25 12 5.25Z'
                    stroke='white'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z'
                    stroke='white'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </g>
              </svg>
              View Campaign
            </MenuItem>
            {['IN_PROGRESS'].includes(item.status) && (
              <MenuItem
                onClick={() => {
                  // onEdit(item.id)
                  onEditPackage(item.id)
                  setAnchorEl(null)
                }}
              >
                <svg
                  style={{ marginRight: '10px' }}
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g opacity='0.6'>
                    <path
                      opacity='0.2'
                      d='M12.75 6.00184L18 11.2518L20.4697 8.78217C20.6103 8.64152 20.6893 8.45075 20.6893 8.25184C20.6893 8.05293 20.6103 7.86216 20.4697 7.72151L16.2803 3.53217C16.1397 3.39152 15.9489 3.3125 15.75 3.3125C15.5511 3.3125 15.3603 3.39152 15.2197 3.53217L12.75 6.00184Z'
                      fill='white'
                    />
                    <path d='M12.75 6L18 11.25' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
                    <path
                      d='M9 20.2518H4.5C4.30109 20.2518 4.11032 20.1728 3.96967 20.0322C3.82902 19.8915 3.75 19.7008 3.75 19.5018V15.3125C3.75 15.214 3.7694 15.1165 3.80709 15.0255C3.84478 14.9345 3.90003 14.8518 3.96967 14.7822L15.2197 3.53217C15.3603 3.39152 15.5511 3.3125 15.75 3.3125C15.9489 3.3125 16.1397 3.39152 16.2803 3.53217L20.4697 7.72151C20.6103 7.86216 20.6893 8.05293 20.6893 8.25184C20.6893 8.45075 20.6103 8.64152 20.4697 8.78217L9 20.2518Z'
                      stroke='white'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                </svg>
                Edit
              </MenuItem>
            )}
            {['ARCHIVED'].includes(item.status) && (
              <MenuItem
                onClick={() => {
                  setOpenArchiveDialog(true)
                  setAnchorEl(null)
                }}
              >
                <svg
                  style={{ marginRight: '10px' }}
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M7.5 15.75L18 5.25' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
                  <path d='M18 14.625V5.25H8.625' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
                  <path d='M3.75 20.25H20.25' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
                Unarchive
              </MenuItem>
            )}
            {['IN_PROGRESS', 'COMPLETED'].includes(item.status) && (
              <MenuItem
                onClick={() => {
                  setOpenArchiveDialog(true)
                  setAnchorEl(null)
                }}
              >
                <svg
                  style={{ marginRight: '10px' }}
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    opacity='0.2'
                    d='M20.25 6.75V19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V6.75H20.25Z'
                    fill='white'
                  />
                  <path
                    d='M19.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V6.75L5.25 3.75H18.75L20.25 6.75V19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25Z'
                    stroke='white'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M8.82031 14.0625L12.0023 17.2445L15.1842 14.0625'
                    stroke='white'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path d='M12 9.75V17.25' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
                  <path d='M3.75 6.75H20.25' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
                Archive
              </MenuItem>
            )}
          </Menu>
        </div>
      </div>
      <ArchiveDialog
        open={openArchiveDialog}
        unarchive={item?.status == 'ARCHIVED'}
        str={item?.name || 'Manual Phishing Simulation Campaign'}
        description={
          item?.status == 'ARCHIVED'
            ? 'This campaign will be unarchived. Users will now start receiving phishing simulations through this campaign.'
            : 'This campaign will be stopped and archived. Users will not receive any phishing simulations through this campaign. This action can be reversed.'
        }
        onAction={item?.status == 'ARCHIVED' ? handleUnarchive : handleArchive}
        onClose={() => setOpenArchiveDialog(false)}
      />
    </div>
  )
}

export default CampaignListItem
