import React from 'react'

import { Typography } from '@mui/material'

export default function FormFieldTitle({ title }) {
  return (
    <Typography fontWeight={500} fontSize={24} fontFamily={'Satoshi'} color={'#1B1B26'}>
      {title}
    </Typography>
  )
}
