import React from 'react'
import { Field } from 'react-final-form'
import { TextField } from '@mui/material'

export default function EditorInput({ name, label, validate }) {
  return (
    <Field
      name={name}
      validate={validate}
      render={({ input, meta }) => (
        <TextField
          {...input}
          label={label}
          variant={'standard'}
          fullWidth
          InputLabelProps={{
            shrink: false,
            sx: {
              fontFamily: 'Plus Jakarta Sans',
              fontSize: '14px',
              fontWeight: 400,
              padding: '0px 12px',
              top: input.value ? '5px' : '35%',
              color: '#76767F',
              transform: input.value ? 'translateY(0)' : 'translateY(-30%)',
              '&.Mui-focused': {
                top: '5px',
                transition: 'smooth',
                background: 'transparent',
                transform: 'translateY(0)',
                color: '#76767F',
                fontSize: '14px'
              }
            }
          }}
          InputProps={{
            sx: {
              fontFamily: 'Plus Jakarta Sans',
              fontSize: '16px',
              fontWeight: 400,
              borderRadius: '14px',
              padding: '0 16px',
              '&.Mui-focused': {
                borderColor: '#76767F'
              }
            }
          }}
          sx={{
            '& .MuiInputBase-root': {
              display: 'flex',
              height: 25,
              padding: '0 16px'
            },
            '& .MuiInputBase-input': {
              marginTop: '4px',
              padding: '0px'
            }
          }}
          error={meta.error && meta.touched}
          helperText={meta.error && meta.touched ? meta.error : ''}
        />
      )}
    />
  )
}
