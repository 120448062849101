import React, { useContext } from 'react'
import { Box, Button, Typography } from '@mui/material'

import { PageNumberContext } from '../../../context/PageNumberContext'
import WrapperInfoCard from './InfoCards/WrapperInfoCard'
import FirstStepCard from './InfoCards/FirstStepCard'

import { Field } from 'react-final-form'

import GroupUserSelectionField from '../../manual-simulation-v2/NewCampaign/GroupUserSelectionField'
import SecondStepCard from './InfoCards/SecondStepCard'

const buttonStyles = {
  borderRadius: '12px',
  fontFamily: 'Plus Jakarta Sans',
  color: '#fff',
  textTransform: 'none',
  border: 'none',
  width: 200,
  '&:hover': {
    background: '#1565c0'
  }
}

export default function ThirdPageForm({ handleSubmit, values }) {
  const { setPage } = useContext(PageNumberContext)

  return (
    <Box display='flex' flexDirection='column' pt={3} rowGap={3} width='100%'>
      <Typography fontFamily={'Satoshi'} fontWeight={500} fontSize={'24px'} color={'#1B1B26'}>
        Training info
      </Typography>
      <WrapperInfoCard onChange={() => setPage(1)}>
        <FirstStepCard />
      </WrapperInfoCard>
      <WrapperInfoCard onChange={() => setPage(2)}>
        <SecondStepCard />
      </WrapperInfoCard>
      <Field name='groupUserSelection' component={GroupUserSelectionField} />
      <Box
        width='100%'
        display='flex'
        columnGap={2}
        justifyContent='space-between'
        sx={{ position: 'sticky', bottom: 0, background: 'transparent' }}
      >
        <Box sx={{ padding: '16px' }}>
          <Button sx={{ ...buttonStyles, background: '#6123FF' }} autoFocus onClick={() => setPage(2)}>
            Back
          </Button>
        </Box>
        <Box sx={{ padding: '16px' }}>
          <Button
            disabled={isDisabled(values)}
            sx={{ ...buttonStyles, background: !isDisabled(values) && '#6123FF' }}
            autoFocus
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

function isDisabled(values) {
  return !values.groupUserSelection?.groupIds?.length && !values.groupUserSelection?.userIds?.length
}
