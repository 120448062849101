import React, { useContext, useEffect, useState } from 'react'
import { Box, Divider, Menu, Typography } from '@mui/material'
import CloseButton from '../../../common/components/CloseButton'
import FilterMenuListItem from './FilterMenuListItem'
import { QueryParamsContext } from '../QueryParamsContext'
import { useGetSimulationFilterCountersQuery } from '../../../api/simulation/simulation'

const FILTER_OPTIONS = ['IN_PROGRESS', 'COMPLETED', 'ARCHIVED']

export default function FilterMenuList({ open, anchorEl, handleClose }) {
  const { params, setParams } = useContext(QueryParamsContext)
  const { data, isLoading } = useGetSimulationFilterCountersQuery({})

  const [filterChecked, setFilterChecked] = useState(params.statuses)

  const allChecked = filterChecked.length === FILTER_OPTIONS.length

  function filterCheckedHandler(event) {
    const { checked, name } = event.target
    if (checked) {
      setFilterChecked(prev => {
        const newChecked = [...prev, name]
        if (newChecked.length === FILTER_OPTIONS.length) {
          return FILTER_OPTIONS
        }
        return newChecked
      })
    } else {
      setFilterChecked(prev => prev.filter(item => item !== name))
    }
  }

  function handleFilterCheckAll(event) {
    const { checked } = event.target
    if (checked) {
      setFilterChecked(FILTER_OPTIONS)
    } else {
      setFilterChecked([])
    }
  }

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      statuses: filterChecked
    }))
  }, [filterChecked, setParams])

  if (isLoading) {
    return ''
  }

  const countAllStatuses = data?.statusCounters?.reduce((acc, item) => acc + item.count, 0)

  return (
    <Menu
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: '#ffffff',
          color: '#000000',
          width: '323px',
          borderRadius: '12px',
          padding: '24px'
        },
        '& li': {
          opacity: '0.7',
          fontFamily: 'Plus Jakarta Sans',
          padding: 0
        },
        '& li:hover': {
          opacity: '1'
        }
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography color={'#1B1B26'} fontFamily={'Satoshi'} fontSize={24} fontWeight={900}>
            Simulation filter
          </Typography>
          <CloseButton onClick={handleClose} />
        </Box>
        <Typography
          sx={{
            opacity: '0.5',
            paddingBottom: '24px'
          }}
          color={'#1B1B26'}
          fontFamily={'Plus Jakarta Sans'}
          fontSize={14}
          fontWeight={500}
        >
          Choose a simulation campaign status
        </Typography>
      </Box>
      <FilterMenuListItem
        checked={allChecked}
        text={`All status, ${countAllStatuses}`}
        name={'ALL'}
        onClick={handleFilterCheckAll}
      />
      <Divider variant={'horizontal'} />
      {data?.statusCounters?.map(option => (
        <FilterMenuListItem
          key={option.status}
          checked={filterChecked.includes(option.status)}
          text={`${filterNameMapper[option.status]}, ${option.count}`}
          name={option.status}
          onClick={filterCheckedHandler}
        />
      ))}
    </Menu>
  )
}

export const filterNameMapper = {
  IN_PROGRESS: 'In progress',
  COMPLETED: 'Completed',
  ARCHIVED: 'Archived'
}
