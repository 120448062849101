import { Box, Typography } from '@mui/material'
import React from 'react'
import { iconMapper, statusNameMapper } from './StatusColumn'

export default function StatusLabel({ status, stepsCompletedCount, stepsTotalCount }) {
  const Icon = iconMapper[status]
  return (
    <Box
      display={'flex'}
      height={'28px'}
      width={'fit-content'}
      rowGap={1}
      alignItems={'center'}
      sx={{
        background:
          (status === 'COMPLETED' &&
            'linear-gradient(264.92deg, rgba(0, 186, 151, 0.1) 4.82%, rgba(0, 188, 98, 0.1) 73.44%)') ||
          (status === 'IN_PROGRESS' && '#F7F6D9') ||
          'rgb(224, 224, 224)'
      }}
      borderRadius={'8px'}
      padding={'4px 14px'}
    >
      <div
        style={{
          display: 'flex',
          columnGap: 8,
          alignItems: 'center',
          color: status === 'COMPLETED' ? '#00BA97' : status === 'IN_PROGRESS' ? '#1B1B26' : '#76767F',
          fontFamily: 'Plus Jakarta Sans',
          fontSize: 14,
          fontWeight: 600
        }}
      >
        {Icon}
        <Typography fontSize={'14px'} fontWeight={600} fontFamily={'Plus Jakarta Sans'} color={'#1B1B26'}>
          {statusNameMapper[status]}
        </Typography>
      </div>
      <Typography fontSize={'14px'} fontWeight={600} fontFamily={'Plus Jakarta Sans'} color={'#1B1B26'}>
        ,{stepsCompletedCount} / {stepsTotalCount} steps
      </Typography>
    </Box>
  )
}
