import React, { useState } from 'react'
import NavTabs from '../../../common/components/NavTabs'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetOneSimulationQuery } from '../../../api/simulation/simulation'
import { Box, Button, Typography } from '@mui/material'
import LoadingIndicator from '../../../common/components/LoadingIndicator'
import Overview from './Overview/Overview'
import { PageNumberContext } from './PageNumberContext'
import Activity from './Activity/Activity'
import { ReactComponent as BackIcon } from '../../../common/icons/back_icon.svg'
import StatusComponent from '../SimulationTable/StatusComponent'

const tabsConfig = ({ data }) => [
  {
    label: 'Overview',
    content: <Overview data={data} />,
    value: 0,
    to: `overview`
  },
  {
    label: 'Activity',
    content: <Activity />,
    value: `activity`,
    to: 'activity'
  }
]

export default function CampaignDetails() {
  const navigate = useNavigate()

  const { id } = useParams()
  const { data, isLoading } = useGetOneSimulationQuery(id)
  const [page, setPage] = useState(1)
  if (isLoading) {
    return (
      <Box display={'flex'} height={'100vh'} alignItems={'center'}>
        <LoadingIndicator />
      </Box>
    )
  }

  return (
    <PageNumberContext.Provider value={{ page, setPage }}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        padding={'32px 48px'}
        rowGap={1}
        bgcolor={'#F8F8FB'}
        minHeight={'100vh'}
      >
        <Box display={'flex'} columnGap={3} alignItems={'center'}>
          <Button
            onClick={() => navigate(`/simulation-campaign/`)}
            sx={{
              fontWeight: 500,
              textTransform: 'none',
              fontSize: '16px',
              color: '#6123FF',
              fontFamily: 'Satoshi'
            }}
            startIcon={<BackIcon />}
            variant={'text'}
          >
            Back to Simulation Campaigns
          </Button>
          <StatusComponent status={data?.status} />
        </Box>
        <Typography noWrap fontWeight={900} fontFamily={'Satoshi'} fontSize={'36px'} pb={'8px'}>
          {data?.name}
        </Typography>
        <NavTabs tabItems={tabsConfig({ data: data, isLoading: false })} />
      </Box>
    </PageNumberContext.Provider>
  )
}
