import React, { useEffect, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { Box } from '@mui/material'
import { Button } from '../../../../common/components/Button'
import ModalTrainingModules from './ModalTrainingModules'

export const ListItem = ({ setActiveModulesCount, input, item, search }) => {
  const [isShowing, setIsShowing] = useState(false)

  const totalModules = item?.modules?.length

  const initActiveModulesIdsArray = item?.modules
    ?.map(item => {
      return input.value?.includes(item.trainingModuleId) ? item.trainingModuleId : null
    })
    .filter(Boolean)

  const [activeModulesArray, setActiveModulesArray] = useState(initActiveModulesIdsArray)

  function handleShow() {
    setIsShowing(true)
  }
  function handleClose() {
    setIsShowing(false)
  }

  useEffect(() => {
    setActiveModulesCount(prev => ({ ...prev, [item.trainingId]: activeModulesArray.length }))
  }, [activeModulesArray])
  return (
    <Box sx={styles.container} key={item.topic}>
      <Box className='row' sx={styles.row}>
        <div style={styles.topic}>
          <Highlighter
            highlightClassName='highlight-text'
            searchWords={[search]}
            autoEscape
            textToHighlight={`${item?.topic || 'unknown'} `}
          />
        </div>
        <div style={styles.actions}>
          <Box width={'70%'}>
            {activeModulesArray?.length}/{totalModules}
          </Box>
          <Button onClick={handleShow} variant='text' sx={styles.button}>
            Show
          </Button>
        </div>
      </Box>
      {isShowing && (
        <ModalTrainingModules
          setActiveModulesArray={setActiveModulesArray}
          isShowing={isShowing}
          onClose={handleClose}
          input={input}
          modulesList={item.modules}
        />
      )}
    </Box>
  )
}
const styles = {
  container: {
    width: '100%',
    display: 'flex',
    height: '72px',
    borderBottom: '1px solid #E7E7EE',
    alignItems: 'center'
  },
  row: {
    width: '100%',
    alignItems: 'center'
  },
  topic: {
    width: '80%',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Plus Jakarta Sans',
    color: '#1B1B26'
  },
  actions: {
    width: '18%',
    display: 'flex',
    justifyContent: 'center',
    columnGap: '8px',
    fontSize: '24px',
    fontWeight: 500,
    fontFamily: 'Plus Jakarta Sans',
    color: '#1B1B26'
  },
  button: {
    textTransform: 'capitalize',
    color: '#6123FF',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Plus Jakarta Sans'
  }
}
