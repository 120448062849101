import { createContext } from 'react'

export const QueryParamsTemplateContext = createContext({
  params: {},
  setParams: () => {},
  selectedTemplateId: null,
  setSelectedTemplateId: () => {},
  isSystemTemplate: false,
  setIsSystemTemplate: () => {}
})
