export const SIMULATIONS = {
  SIMULATION: 'SIMULATION',
  TEMPLATES: 'TEMPLATES',
  USERS: 'USERS',
  COUNTERS: 'COUNTERS'
}

export const TRAINING_PACKAGES = {
  LIST: 'LIST',
  TRAINING_PACKAGE: 'TRAINING_PACKAGE',
  MODULES: 'MODULES',
  USERS: 'USERS',
  STATS: 'STATS'
}
