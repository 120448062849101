import React, { useState } from 'react'
import { Button } from '@mui/material'
import { ReactComponent as FilterIcon } from '../../../../common/icons/filter_icon.svg'
import FilterMenuList from './FilterMenuList'

export default function StatusFilter({ statuses, onChange, uncheckedTabs, setUncheckedtabs }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const title =
    uncheckedTabs.length == 0
      ? 'All Status'
      : uncheckedTabs.includes('Running') && uncheckedTabs.length == 1
        ? 'Paused'
        : uncheckedTabs.includes('Paused') && uncheckedTabs.length == 1
          ? 'Running'
          : 'Select status'

  return (
    <div>
      <Button
        sx={{
          background: '#EDEDF2',
          borderRadius: '24px',
          fontSize: '16px',
          fontWeight: 600,
          fontFamily: 'Plus Jakarta Sans',
          color: '#1C1C27',
          textTransform: 'none',
          border: 'none',
          width: 'fit-content',
          '&:hover': {
            backgroundColor: '#6123ff1f'
          },
          marginLeft: '20px'
        }}
        variant={'contained'}
        startIcon={<FilterIcon />}
        disableElevation
        onClick={handleClick}
      >
        {title}
      </Button>
      <FilterMenuList
        uncheckedTabs={uncheckedTabs}
        setUncheckedtabs={setUncheckedtabs}
        statuses={statuses}
        onChange={onChange}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </div>
  )
}
