import React from 'react'
import { ReactComponent as InfoIcon } from '../icons/info_icon.svg'
import { Tooltip } from '@mui/material'

export default function InfoTooltip({ title }) {
  return (
    <Tooltip title={title} placement={'top-start'}>
      <InfoIcon />
    </Tooltip>
  )
}
