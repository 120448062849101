import axios from 'axios'
import authHeader from './auth-header'
import api from './api.service'

const API_URL = window._env_.REACT_APP_API_HOST

class SettingsService {
  getlocations() {
    return api.get(API_URL + '/v1/countries', { headers: authHeader() })
  }
  getlanguages() {
    return api.get(API_URL + '/v1/languages', { headers: authHeader() })
  }
  getdepartments() {
    return api.get(API_URL + '/v1/departments', { headers: authHeader() })
  }

  getConnectors() {
    return axios.get(API_URL + '/v1/connectors', { headers: authHeader() })
  }
  disconnect(provider) {
    return axios.post(API_URL + '/v1/connectors/' + provider + '/disconnect', {}, { headers: authHeader() })
  }

  getGoogleAuthUrl() {
    return axios.get(API_URL + `/v1/google/authorization-url`, {
      headers: authHeader()
    })
  }
  getSlackAuthUrl() {
    return axios.get(API_URL + `/v1/slack/authorization-url?redirectUrl=${window.location.hostname}`, {
      headers: authHeader()
    })
  }
  getSlackSimAuthUrl() {
    return axios.get(API_URL + `/v1/slack/simulation/authorization-url?redirectUrl=${window.location.hostname}`, {
      headers: authHeader()
    })
  }
  getGoogleAuthSsoUrl() {
    return axios.get(
      API_URL + `/v1/google/sso/authorization-url?redirect=ADMIN&redirectUrl=${window.location.hostname}`,
      { headers: {} }
    )
  }
  getOutlookAuthUrl() {
    return axios.get(API_URL + '/v1/outlook/authorization-url', {
      headers: authHeader()
    })
  }
  getOutlookAuthSsoUrl() {
    return axios.get(
      API_URL + `/v1/outlook/sso/authorization-url?redirect=ADMIN&redirectUrl=${window.location.hostname}`,
      { headers: {} }
    )
  }
  resyncOutlook() {
    return axios.post(API_URL + '/v1/outlook/sync', {}, { headers: authHeader() })
  }
  connectOkta(domain, clientId, secretId, group) {
    return axios.post(
      API_URL + `/v1/okta/connect`,
      { clientDomain: domain, clientId, clientSecret: secretId, group },
      { headers: authHeader() }
    )
  }
  connectOktaSso(domain, clientId, secretId) {
    return axios.post(
      API_URL + `/v1/okta/sso/connect`,
      { clientDomain: domain, clientId, clientSecret: secretId },
      { headers: authHeader() }
    )
  }
  getOktaAuthUrl(domain, clientId, secretId) {
    return axios.get(
      API_URL + `/v1/okta/authorization-url?redirect=ADMIN&domain=${domain}&clientId=${clientId}&secretId=${secretId}`,
      { headers: authHeader() }
    )
  }
  getOktaAuthSsoUrl(email = '') {
    return axios.get(
      API_URL + `/v1/okta/sso/authorization-url?redirect=ADMIN&redirectUrl=${window.location.hostname}&email=${email}`,
      { headers: {} }
    )
  }
  getADHealth() {
    return axios.get(API_URL + '/v1/ad/health', { headers: authHeader() })
  }
  getADInfo() {
    return axios.get(API_URL + '/v1/ad/update-info', { headers: authHeader() })
  }
  getOutlookWorkflowsAuthUrl() {
    return axios.get(API_URL + '/v1/microsoft-alerts/authorization', {
      headers: authHeader()
    })
  }

  getOnboardingStatus() {
    return axios.get(API_URL + '/v1/company-configurations/self-onboarding', { headers: authHeader() })
  }
  setOnboardingStatus(status) {
    return axios.put(API_URL + '/v1/company-configurations/self-onboarding', status, { headers: authHeader() })
  }

  getPluginStatus() {
    return axios.get(API_URL + '/v1/company-configurations/plugin', {
      headers: authHeader()
    })
  }
  getAssistantStaus() {
    return axios.get(API_URL + '/v1/company-configurations/security-assistant', {
      headers: authHeader()
    })
  }
  setAssistantStaus(data) {
    return axios.put(API_URL + '/v1/company-configurations/security-assistant', data, {
      headers: authHeader()
    })
  }
  setPluginStatus(status) {
    return axios.put(
      API_URL + '/v1/company-configurations/plugin',
      { copilotEnabled: status },
      { headers: authHeader() }
    )
  }
  getIntegrations() {
    return axios.get(API_URL + '/v1/integrations', {
      headers: authHeader()
    })
  }
  disconnectSlack(type = 'SLACK') {
    return axios.delete(API_URL + '/v1/integrations?type=' + type, {
      headers: authHeader()
    })
  }
  getWorkTime() {
    return axios.get(API_URL + '/v1/company-configurations/work-time', {
      headers: authHeader()
    })
  }
  setWorkTime(newWt) {
    return axios.put(API_URL + '/v1/company-configurations/work-time', newWt, { headers: authHeader() })
  }
  resyncOkta() {
    return axios.post(API_URL + '/v1/okta/sync?provider=OKTA', {}, { headers: authHeader() })
  }
}

export default new SettingsService()
