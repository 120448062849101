import React, { useState } from 'react'
import { Box, Button, Menu, MenuItem } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'

export default function PhishingButton({ config, isHidden }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  if (isHidden) return null
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Button
        sx={{
          fontWeight: 500,
          height: '28px',
          fontSize: '16px',
          fontFamily: 'Plus Jakarta Sans',
          color: '#ffffff',
          textTransform: 'none',
          background: 'linear-gradient(268.19deg, #0F88FF 4.49%, #14B0FF 113.16%)'
        }}
        startIcon={<AddIcon />}
        onClick={handleClick}
      >
        Add Phishing
      </Button>
      <Menu
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#1B1B26',
            color: '#ffffff',
            width: '200px',
            borderRadius: '12px'
          },
          '& li': {
            opacity: '0.7',
            fontFamily: 'Plus Jakarta Sans',
            paddingLeft: '24px'
          },
          '& li:hover': {
            opacity: '1'
          }
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {config.map((item, index) => {
          if (item?.isHidden) return null
          return (
            <MenuItem
              key={index}
              onClick={() => {
                item.onClick()
                handleClose()
              }}
            >
              <div
                style={{
                  display: 'flex',
                  columnGap: 8,
                  alignItems: 'center'
                }}
              >
                {' '}
                {item.icon}
                {item.name}
              </div>
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}
