import { Box } from '@mui/material'
import { Field } from 'react-final-form'
import CustomCheckbox from '../../../common/components/CustomCheckbox'
import React from 'react'
import Labels from './Labels'

export default function TrainingsOptions() {
  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
        <Box display={'flex'} width={'50%'}>
          <Field name={'mandatory'}>
            {({ input }) => (
              <Box display={'flex'} columnGap={1} alignItems={'center'}>
                <CustomCheckbox
                  onClick={() => {
                    handleCheck(input)
                  }}
                  checked={input.value}
                />
                <Labels>The training is mandatory</Labels>
              </Box>
            )}
          </Field>
        </Box>
        <Box display={'flex'} width={'50%'}>
          <Field name={'sendNotifications'}>
            {({ input }) => (
              <Box display={'flex'} columnGap={1} alignItems={'center'}>
                <CustomCheckbox
                  onClick={() => {
                    handleCheck(input)
                  }}
                  checked={input.value}
                />
                <Labels>Send notifications & reminders</Labels>
              </Box>
            )}
          </Field>
        </Box>
      </Box>
      <Box display={'flex'} width={'100%'}>
        <Box display={'flex'} width={'50%'}>
          <Field name={'retrainingAllowed'}>
            {({ input }) => (
              <Box display={'flex'} columnGap={1} alignItems={'center'}>
                <CustomCheckbox
                  onClick={() => {
                    handleCheck(input)
                  }}
                  checked={input.value}
                />
                <Labels>Employees can retake the training</Labels>
              </Box>
            )}
          </Field>
        </Box>
        <Box display={'flex'} width={'50%'}>
          <Field name={'sendViaBot'}>
            {({ input }) => (
              <Box display={'flex'} columnGap={1} alignItems={'center'}>
                <CustomCheckbox
                  onClick={() => {
                    handleCheck(input)
                  }}
                  checked={input.value}
                />
                <Labels>Send via Cyberlift bot on Slack or Teams</Labels>
              </Box>
            )}
          </Field>
        </Box>
      </Box>
    </Box>
  )
}
function handleCheck(input) {
  input.onChange(!input.value)
}
