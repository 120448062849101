import React from 'react'

export const ReportedEmailStatus = ({ status }) => {
  switch (status) {
    case 'SPAM':
      return (
        <div style={{ width: '20%' }} className='row row-center'>
          <svg
            style={{ marginRight: '10px' }}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              opacity='0.1'
              d='M20.25 9V18C20.25 18.1989 20.171 18.3897 20.0303 18.5303C19.8897 18.671 19.6989 18.75 19.5 18.75H4.5C4.30109 18.75 4.11032 18.671 3.96967 18.5303C3.82902 18.3897 3.75 18.1989 3.75 18V9H20.25Z'
              fill='#76767F'
            />
            <path
              d='M21 5.25H3C2.58579 5.25 2.25 5.58579 2.25 6V8.25C2.25 8.66421 2.58579 9 3 9H21C21.4142 9 21.75 8.66421 21.75 8.25V6C21.75 5.58579 21.4142 5.25 21 5.25Z'
              stroke='#76767F'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M20.25 9V18C20.25 18.1989 20.171 18.3897 20.0303 18.5303C19.8897 18.671 19.6989 18.75 19.5 18.75H4.5C4.30109 18.75 4.11032 18.671 3.96967 18.5303C3.82902 18.3897 3.75 18.1989 3.75 18V9'
              stroke='#76767F'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path d='M9.75 12.75H14.25' stroke='#76767F' strokeLinecap='round' strokeLinejoin='round' />
          </svg>

          <p style={{ color: '#76767F' }}>Spam</p>
        </div>
      )

    case 'FRAUDULENT':
      return (
        <div style={{ width: '20%' }} className='row row-center'>
          <svg
            style={{ marginRight: '10px' }}
            width='16'
            height='22'
            viewBox='0 0 16 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.5 11.5L11 1L10 8L15.5 10.5L5 21L6.5 14L0.5 11.5Z'
              fill='#DB0F34'
              fillOpacity='0.1'
              stroke='#DB0F34'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>

          <p style={{ color: '#DB0F34' }}>Fraudulent</p>
        </div>
      )

    case 'SAFE':
      return (
        <div style={{ width: '20%' }} className='row row-center'>
          <svg
            style={{ marginRight: '10px' }}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              opacity='0.1'
              d='M3.75 10V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5V10C20.25 17.8764 13.5651 20.486 12.2303 20.9285C12.0811 20.9798 11.9189 20.9798 11.7697 20.9285C10.4349 20.486 3.75 17.8764 3.75 10Z'
              fill='#00BC62'
            />
            <path
              d='M3.75 10V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5V10C20.25 17.8764 13.5651 20.486 12.2303 20.9285C12.0811 20.9798 11.9189 20.9798 11.7697 20.9285C10.4349 20.486 3.75 17.8764 3.75 10Z'
              stroke='#00BC62'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M16.125 9L10.625 14.25L7.875 11.625'
              stroke='#00BC62'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>

          <p style={{ color: '#00BA97' }}>Safe</p>
        </div>
      )

    default:
      return <div style={{ width: '20%' }} className='row row-center'></div>
  }
}
