import React, { useEffect, useState } from 'react'
import { TextField, InputAdornment, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export default function ClearableInput(props) {
  const [value, setValue] = useState('')

  const handleClear = () => {
    setValue('')
    props.onChange && props.onChange({ target: { value: '' } })
  }

  const handleChange = e => {
    setValue(e.target.value)
    props.onChange && props.onChange(e)
  }

  useEffect(() => {
    setValue(props.value)
  }, [props.value])
  return (
    <TextField
      {...props}
      fullWidth={true}
      value={value}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={handleClear}>{value && <CloseIcon />}</IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}
