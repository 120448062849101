import React, { useEffect, useMemo, useState } from 'react'
import useSortableList from './useSortableList'
import { ListItem } from './ListItem'
import { SortHeader } from '../../../groups'
import { Box } from '@mui/material'
import { useLazyGetAllModulesQuery } from '../../../../api/trainingPackages/trainingPackages'

const List = ({ search, input, language }) => {
  const [getItems, { data }] = useLazyGetAllModulesQuery()
  const [activeModulesCount, setActiveModulesCount] = useState({})

  const itemsWithActiveModules = useMemo(
    () =>
      data?.map(item => ({
        ...item,
        activeModules: activeModulesCount[item.trainingId] || 0
      })),
    [data, activeModulesCount]
  )

  const { sortedItems, handleSort, sortBy } = useSortableList(itemsWithActiveModules || [], activeModulesCount)

  useEffect(() => {
    getItems(language)
  }, [language, getItems])

  const filteredItems = sortedItems?.filter(item => item.topic.toLowerCase().includes(search.toLowerCase()))

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <div className='row row-center groups-list-heading'>
        <SortHeader name='Name' sort={sortBy} onSort={handleSort} width={'80%'} />
        <SortHeader name='Active modules' sort={sortBy} onSort={handleSort} width={'15%'} />
      </div>
      {filteredItems?.map(item => (
        <ListItem
          setActiveModulesCount={setActiveModulesCount}
          input={input}
          key={item.trainingId}
          item={item}
          search={search}
        />
      ))}
    </Box>
  )
}

export default List
