import React from 'react'
import Logo from 'src/common/logo'
import Copyright from '../Copyright'
import Spacer from '../Spacer'

export const LargerScreenMobile = () => {
  return (
    <div className='column column-center large-screen-mobile'>
      <Logo />
      <Spacer />
      <p
        className='gradient-text'
        style={{
          fontFamily: 'Satoshi',
          color: '#ffffff',
          fontSize: '96px',
          fontWeight: '500'
        }}
      >
        Oops!
      </p>
      <p
        style={{
          fontFamily: 'Satoshi',
          fontSize: '24px',
          color: '#6858D9',
          textAlign: 'center'
        }}
      >
        So sorry but the current Cyberlift is best experienced on a larger screen.
      </p>
      <Spacer />
      <Copyright />
    </div>
  )
}
