import React from 'react'

const ViewIcon = ({ disabled, color = '#6123FF', size = '25' }) => (
  <svg width={size} height={size} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.2'
      d='M12 5.25C4.5 5.25 1.5 12.0007 1.5 12.0007C1.5 12.0007 4.5 18.75 12 18.75C19.5 18.75 22.5 12.0007 22.5 12.0007C22.5 12.0007 19.5 5.25 12 5.25ZM12 15.7508C11.2583 15.7508 10.5333 15.5308 9.91661 15.1188C9.29993 14.7067 8.81928 14.1211 8.53545 13.4358C8.25162 12.7506 8.17736 11.9966 8.32205 11.2692C8.46675 10.5418 8.8239 9.87357 9.34835 9.34913C9.8728 8.82468 10.541 8.46753 11.2684 8.32283C11.9958 8.17814 12.7498 8.2524 13.4351 8.53623C14.1203 8.82006 14.706 9.30071 15.118 9.91739C15.5301 10.5341 15.75 11.2591 15.75 12.0008C15.75 12.9953 15.3549 13.9492 14.6516 14.6524C13.9484 15.3557 12.9946 15.7508 12 15.7508Z'
      fill={disabled ? '#a1a1a1' : color}
    />
    <path
      d='M12 5.25C4.5 5.25 1.5 12.0007 1.5 12.0007C1.5 12.0007 4.5 18.75 12 18.75C19.5 18.75 22.5 12.0007 22.5 12.0007C22.5 12.0007 19.5 5.25 12 5.25Z'
      stroke={disabled ? '#a1a1a1' : color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z'
      stroke={disabled ? '#a1a1a1' : color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ViewIcon
