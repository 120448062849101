import React, { useContext, useState } from 'react'

import EditIcon from '../../../common/icons/editIcon'
import Archive from '../../../common/icons/archive'
import { useNavigate } from 'react-router-dom'

import {
  useArchiveTrainingPackageMutation,
  useLazyGetTrainingPackagesQuery
} from '../../../api/trainingPackages/trainingPackages'
import { FilterParamsContext } from '../FilterParamsContext'
import PercentageComponent from './PercentageComponent'
import StatusComponent from './StatusComponent'
import ActionButtons from '../../../common/components/ActionButtons'
import NameColumn from './NameColumn'
import { Typography } from '@mui/material'
import dayjs from 'dayjs'
import ViewIcon from '../../../common/icons/viewIcon'

export default function useTrainingTable() {
  const [isEditShowing, setIsEditShowing] = useState(false)
  const [isArchiveShowing, setIsArchiveShowing] = useState(false)
  const [sharedId, setSharedId] = useState(null)

  const [getTraining, { data, error, isLoading }] = useLazyGetTrainingPackagesQuery()
  const [archiveTraining] = useArchiveTrainingPackageMutation()

  const { filter } = useContext(FilterParamsContext)
  const navigate = useNavigate()

  function handleOpen(id) {
    setIsEditShowing(true)
    setSharedId(id)
  }

  function handleClose() {
    setIsEditShowing(false)
    setSharedId(null)
  }

  function handleArchiveOpen(row) {
    setSharedId(row)
    setIsArchiveShowing(true)
  }

  function handleArchiveClose() {
    setIsArchiveShowing(false)
    setSharedId(null)
  }
  function handleArchiveTraining() {
    archiveTraining(sharedId.id)
    handleArchiveClose()
  }
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      stickyLeft: true,
      width: 400,
      align: 'left',
      renderComponent: (value, row) => <NameColumn key={row.id} data={row} />
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      align: 'left',
      minWidth: 150,
      renderComponent: (value, row) => <StatusComponent key={row.id} status={row.status} />
    },
    {
      field: 'type',
      headerName: 'Training Type',
      sortable: true,
      align: 'left',
      maxWidth: 120,
      renderComponent: (value, row) => (
        <Typography fontFamily={'Plus Jakarta Sans'} fontWeight={600} fontSize={14} color={'#1B1B26'}>
          {trainingTypeMapper[row.type]}
        </Typography>
      )
    },
    {
      field: 'startDate',
      headerName: 'Period',
      sortable: true,
      align: 'left',
      maxWidth: 180,
      renderComponent: (value, row) => (
        <Typography fontFamily={'Plus Jakarta Sans'} fontWeight={600} fontSize={14} color={'#1B1B26'}>
          {row.startDate && dayjs(row.startDate).format('MMM DD, YYYY')} -
          {row.endDate && dayjs(row.endDate).format('MMM DD, YYYY')}
        </Typography>
      )
    },
    {
      field: 'completedPercentage',
      headerName: 'Completed Rate (%)',
      sortable: true,
      align: 'left',
      maxWidth: 150,
      renderComponent: (value, row) => <PercentageComponent percentage={row.completedPercentage} />
    },
    { field: 'plannedCount', headerName: 'Planned', sortable: true, align: 'left', maxWidth: 120 },
    { field: 'assignedCount', headerName: 'Assigned', sortable: true, align: 'left', maxWidth: 120 },
    {
      field: 'actions',
      headerName: '',
      stickyRight: true,
      maxWidth: '40px',
      align: 'left',
      renderComponent: (value, row) => (
        <ActionButtons
          config={[
            {
              name: 'View Training',
              icon: <ViewIcon color={'#76767F'} />,
              onClick: () => {
                return navigate(`/training-packages/${row.id}`)
              }
            },
            {
              name: 'Edit',
              icon: <EditIcon color={'#76767F'} />,
              onClick: () => handleOpen(row.id),
              isHidden: row.status === 'ARCHIVED' || row.status === 'COMPLETED'
            },
            {
              name: 'Archive',
              icon: <Archive />,
              onClick: () => handleArchiveOpen(row),
              isHidden: row.status === 'ARCHIVED'
            }
          ]}
        />
      )
    }
  ]
  function fetchData({ page, size, sort, params }) {
    getTraining({ page, size, sort, ...params })
  }

  const trainings = data?.data
  const totalCount = data?.totalCount
  return {
    columns,
    fetchData,
    trainings,
    error,
    totalCount,
    params: filter,
    isEditShowing,
    isArchiveShowing,
    handleClose,
    handleArchiveTraining,
    handleArchiveOpen,
    handleArchiveClose,
    sharedId,
    isLoading: isLoading
  }
}

const trainingTypeMapper = {
  MODULES: 'Modules'
}
