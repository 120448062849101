import React from 'react'
import { TableBody, TableRow, TableCell } from '@mui/material'
import CustomCheckbox from '../CustomCheckbox'

export default function DataTableBody({
  config,
  data,
  selectionEnabled,
  isSelected,
  toggleRowSelection,
  colorBody = '#F8F8FB'
}) {
  return (
    <TableBody>
      {data?.map(row => (
        <TableRow
          key={row.id}
          sx={{
            background: colorBody,
            '&:hover': {
              backgroundColor: `#F4F4F4`
            }
          }}
        >
          {selectionEnabled && (
            <TableCell padding='checkbox'>
              <CustomCheckbox
                type='checkbox'
                checked={isSelected(row.id)}
                onChange={() => toggleRowSelection(row.id)}
              />
            </TableCell>
          )}
          {config.map(col => (
            <TableCell
              key={col.field}
              align={col.align || 'center'}
              sx={{
                position: col.stickyLeft ? 'sticky' : col.stickyRight ? 'sticky' : 'static',
                left: col.stickyLeft ? 0 : undefined,
                right: col.stickyRight ? 0 : undefined,
                backgroundColor: (col.stickyRight && 'white') || (col.stickyLeft && '#F8F8FB'),
                zIndex: col.stickyLeft || col.stickyRight ? 11 : undefined,
                maxWidth: col.maxWidth || undefined,
                minWidth: col.minWidth || undefined,
                width: col.width || undefined,
                padding: '16px 12px 24px 12px',
                fontFamily: 'Satoshi',
                fontSize: 18,
                fontWeight: 500,
                verticalAlign: 'top'
              }}
            >
              {col.renderComponent
                ? col.renderComponent(row[col.field], row)
                : row[col.field] === 0
                  ? '-'
                  : row[col.field]}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  )
}
