import React from 'react'

const DeleteIcon = ({ color }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.2'
        d='M18.75 5.25V19.5C18.75 19.6989 18.671 19.8897 18.5303 20.0303C18.3897 20.171 18.1989 20.25 18 20.25H6C5.80109 20.25 5.61032 20.171 5.46967 20.0303C5.32902 19.8897 5.25 19.6989 5.25 19.5V5.25L18.75 5.25Z'
        fill={color ? color : '#B40020'}
      />
      <path
        d='M20.25 5.25L3.75 5.25001'
        stroke={color ? color : '#B40020'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8.25 2.25H15.75' stroke={color ? color : '#B40020'} strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M18.75 5.25V19.5C18.75 19.6989 18.671 19.8897 18.5303 20.0303C18.3897 20.171 18.1989 20.25 18 20.25H6C5.80109 20.25 5.61032 20.171 5.46967 20.0303C5.32902 19.8897 5.25 19.6989 5.25 19.5V5.25'
        stroke={color ? color : '#B40020'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export default DeleteIcon
