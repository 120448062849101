import React, { useContext, useEffect, useState } from 'react'
import { DashBoardSectionBlock, DashBoardSectionBlockSubTitle, DashBoardSectionBlockTitle, Row } from '..'
import Spacer from 'src/common/components/Spacer'
import { useNavigate } from 'react-router-dom'
import { SearchInput } from 'src/common/components/SearchInput'
import { SortHeader } from 'src/pages/groups'
import UserAvatar from 'src/common/components/UserAvatar'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import { DashBoardDateContext, UserViewContext } from 'src/App'
import useDebounce from 'src/hooks'
import statsService from 'src/services/stats.service'
import dayjs from 'dayjs'
import workflowsService from 'src/services/workflows.service'
import { PeriodPicker } from '../components/periodPicker'
import { Paginator } from 'src/pages/workflows/total-logs'

const defaultFilters = {
  page: 0,
  size: 25
}

export function RiskyBehaviors() {
  const navigate = useNavigate()

  const [timePeriod] = useContext(DashBoardDateContext)
  const [, setUserViewId] = useContext(UserViewContext)

  const [loading, setloading] = useState(true)
  const [repeatFailUsers, setRepeatFailUsers] = useState([])
  const [search, setSearch] = useState('')

  const [workflowStats, setWorkflowStats] = useState(null)

  const [total, setTotal] = useState(0)

  const [sortBy, setSortBy] = useState({
    name: 'User name',
    asc: false
  })

  const [filters, setFilters] = useState(defaultFilters)

  const handleFilter = param => {
    const newParam = param?.status ? { status: param.status.toUpperCase() } : param?.sort ? { sort: param.sort } : param
    setFilters({ ...filters, ...newParam })
  }

  const handleSort = field => {
    const fieldset = {
      'User name': 'userFirstName',
      Workflow: 'workflowName',
      Trigger: 'workflowTriggerName',
      'Time and Date': 'actionAt'
    }

    const fieldName = fieldset[field]
    setSortBy({
      name: field,
      asc: field == sortBy?.name ? !sortBy.asc : true
    })

    handleFilter({ sort: `${fieldName},${field == sortBy?.name ? (sortBy.asc ? 'desc' : 'asc') : 'asc'}` })
  }
  const timePeriod3Month = {
    timePeriod: 'CUSTOM',
    startDate: dayjs(timePeriod?.endDate).subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
    endDate: timePeriod?.endDate
  }

  useEffect(() => {
    setloading(true)
    Promise.all([
      workflowsService.getWorkflowsLogs({ ...filters, ...timePeriod }),
      statsService.getCurrentCompanyWorkflowStats(timePeriod3Month),
      statsService.getCurrentCompanyWorkflowStatsMonthly(timePeriod3Month)
    ])
      .then(values => {
        const [usersResp, companyWorkflowStatsResp, companyWorkflowStatsMonthlyResp] = values
        setRepeatFailUsers(usersResp.data)
        setTotal(parseInt(usersResp.headers['x-total-count']))
        setWorkflowStats({
          chart: companyWorkflowStatsMonthlyResp.data,
          categories: companyWorkflowStatsResp.data
        })
        setloading(false)
      })
      .catch(err => {
        console.error(err)
        setloading(false)
      })
  }, [timePeriod])

  const getLogs = () => {
    Promise.all([workflowsService.getWorkflowsLogs({ ...filters, ...timePeriod })])
      .then(values => {
        const [usersResp] = values
        setRepeatFailUsers(usersResp.data)
        setTotal(parseInt(usersResp.headers['x-total-count']))
      })
      .catch(err => {
        console.error(err)
      })
  }

  useEffect(() => {
    getLogs()
  }, [filters])

  const debouncedSearchTerm = useDebounce(search, 500)

  useEffect(() => {
    if (debouncedSearchTerm) {
      handleFilter({ searchText: search })
    } else {
      handleFilter({ searchText: '' })
    }
  }, [debouncedSearchTerm])

  const riskyCategoriesData = workflowStats?.categories

  function RiskyUsersPercentSinceTime() {
    const current = workflowStats?.chart[workflowStats?.chart.length - 1]?.riskyUsersCount
    const prev = workflowStats?.chart[workflowStats?.chart.length - 3]?.riskyUsersCount

    if (current == prev) {
      return (
        <span className={'green-text'} style={{ marginRight: 6 }}>
          0% Up
        </span>
      )
    }
    if (current > prev) {
      return (
        <div className='row row-center'>
          <svg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M7.19437 0.2L13.4184 6.408L0.970374 6.408L7.19437 0.2Z' fill='#ED4563' />
          </svg>
          <span className={'red-text'} style={{ marginRight: 6 }}>
            {prev == 0 ? '100' : Math.round((current * 100) / prev)}%
          </span>
          <span style={{ marginRight: 6 }}>Up</span>
        </div>
      )
    }
    if (current < prev) {
      return (
        <div className='row row-center'>
          <svg
            style={{ transform: 'rotate(180deg)', marginRight: 8 }}
            width='14'
            height='7'
            viewBox='0 0 14 7'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M7.19437 0.2L13.4184 6.408L0.970374 6.408L7.19437 0.2Z' fill='#00bc62' />
          </svg>
          <span className={'green-text'} style={{ marginRight: 6 }}>
            {current == 0 ? '100' : Math.round((current * 100) / prev)}%
          </span>
          <span style={{ marginRight: 6 }}>Less</span>
        </div>
      )
    }
  }

  return (
    <div
      className='column'
      style={{
        backgroundColor: '#F8F8FB',
        height: '100vh',
        overflowY: 'auto',
        padding: '24px'
      }}
    >
      <div className='container-1030' style={{ height: '100vh', paddingBottom: 20 }}>
        <div className='row row-center' onClick={() => navigate('/')} style={{ marginBottom: 14, cursor: 'pointer' }}>
          <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M16 8.25H1.75' stroke='#6123FF' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <path
              d='M8.5 15L1.75 8.25L8.5 1.5'
              stroke='#6123FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>

          <p style={{ color: '#6123FF', marginLeft: 8 }}>Back to Dashboard</p>
        </div>
        <div className='row row-center' style={{ marginBottom: 20 }}>
          <h1 style={{ fontSize: 36, margin: '0px 10px 0px 0px' }}>Risky behaviors</h1>

          <PeriodPicker />
          <div className='row'></div>
        </div>

        <DashBoardSectionBlock style={{ width: '100%', marginBottom: 16 }}>
          <Row
            style={{
              padding: '0px 0px 30px 0px'
            }}
          >
            <div className='column' style={{ marginBottom: 0 }}>
              <DashBoardSectionBlockTitle>Risky behaviors by category</DashBoardSectionBlockTitle>
              <DashBoardSectionBlockSubTitle>
                <RiskyUsersPercentSinceTime /> since for last 3 month
              </DashBoardSectionBlockSubTitle>
            </div>
          </Row>

          <Row
            style={{
              marginBottom: 10,
              flexWrap: 'wrap'
            }}
          >
            {riskyCategoriesData
              ?.sort((a, b) => {
                if (a.riskyUsersPercentage < b.riskyUsersPercentage) {
                  return 1
                }
                if (a.riskyUsersPercentage > b.riskyUsersPercentage) {
                  return -1
                }
                return 0
              })
              .map(el => (
                <div key={el.id} className='column risky-element' style={{ width: '30%' }}>
                  <Row
                    style={{
                      marginBottom: '20px',
                      height: '50px',
                      alignItems: 'flex-end'
                    }}
                  >
                    <p
                      className='text-shrink-2'
                      style={{
                        fontWeight: 500,
                        maxWidth: '200px'
                      }}
                    >
                      {el.name}
                    </p>
                    <Spacer />
                    <p
                      style={{
                        lineHeight: '30px'
                      }}
                      className='big-num-36'
                    >
                      {el.riskyUsersPercentage > 100 ? 100 : el.riskyUsersPercentage}%
                    </p>
                  </Row>

                  <div
                    style={{
                      width: '100%',
                      height: 10,
                      background: '#1B1B2626',
                      borderRadius: 10,
                      marginBottom: 15
                    }}
                  >
                    <div
                      style={{
                        width: el.riskyUsersPercentage > 100 ? 100 : el.riskyUsersPercentage + '%',
                        height: 10,
                        background: 'linear-gradient(90deg, #EF5C77 0%, #F44C6B 100%)',
                        borderRadius: 10
                      }}
                    ></div>
                  </div>

                  <div className='row row-center'>
                    {el.service == 'CYBERLIFT' && <img src={'images/wf3.png'} width={28} alt='' />}
                    {el.service == 'HAVE_I_BEEN_PWNED' && <img src={'images/wf4.png'} width={28} alt='' />}
                    {el.service == 'GMAIL' && <img src={'images/wf5.png'} width={28} alt='' />}
                    {el.service == 'OUTLOOK' && <img src={'images/wf6.png'} width={28} alt='' />}
                    <p
                      style={{
                        fontSize: 13,
                        color: '#76767F',
                        marginLeft: 12,
                        textTransform: 'capitalize',
                        marginRight: 20
                      }}
                    >
                      {el && el.service.replaceAll('_', ' ').toLowerCase()}
                    </p>
                  </div>
                </div>
              ))}
          </Row>
        </DashBoardSectionBlock>
        <DashBoardSectionBlock style={{ width: '100%' }}>
          <Row>
            <div className='column' style={{ marginBottom: 48 }}>
              <DashBoardSectionBlockTitle style={{ fontSize: 24 }}>Risky by users</DashBoardSectionBlockTitle>
              {/* <DashBoardSectionBlockSubTitle>30% Up since for last 3 month</DashBoardSectionBlockSubTitle> */}
            </div>
            <Spacer />
            <SearchInput placeholder='Search by users' value={search} onChange={setSearch} />
          </Row>

          {loading ? (
            <LoadingIndicator />
          ) : (
            <div className='column'>
              <div className='row groups-list-heading' style={{ marginBottom: 8 }}>
                <SortHeader name={'User name'} sort={sortBy} onSort={handleSort} width={'25%'} />
                <SortHeader name={'Trigger'} sort={sortBy} onSort={handleSort} width={'30%'} />
                <SortHeader name={'Workflow'} sort={sortBy} onSort={handleSort} width={'30%'} />
                <SortHeader name={'Time and Date'} sort={sortBy} onSort={handleSort} width={'15%'} />
              </div>
              <div className='column' style={{ height: 'calc(100vh - 300px)', overflow: 'auto' }}>
                {repeatFailUsers.length == 0 && (
                  <div style={{ alignItems: 'center', marginTop: '100px' }} className='column'>
                    <div
                      style={{
                        position: 'relative',
                        width: 785,
                        height: 457,
                        justifyContent: 'center',
                        margin: '-100px 0px'
                      }}
                      className='column column-center'
                    >
                      <p style={{ color: '#76767F', textAlign: 'center' }}>
                        The search did not return any results,
                        <br /> try another query
                      </p>
                    </div>
                  </div>
                )}

                {repeatFailUsers.length > 0 &&
                  repeatFailUsers.map((el, i) => (
                    <div
                      onClick={() => setUserViewId(el.userId)}
                      key={el.id}
                      style={{
                        cursor: 'pointer',
                        paddingBottom: 10,
                        borderBottom: '1px solid #E7E8EAb3',
                        marginBottom: 10
                      }}
                      className='row row-center'
                    >
                      <div className='row row-center' style={{ width: '25%' }}>
                        <p style={{ marginRight: 10 }} className='secondary-text-16'>
                          #{filters.page * filters.size + i + 1}
                        </p>
                        <UserAvatar
                          style={{
                            width: '36px',
                            height: '36px'
                          }}
                          firstName={el?.userFirstName}
                          lastName={el?.userLastName}
                        />
                        <p
                          className='text-ellipsis'
                          style={{
                            fontWeight: 600,
                            marginRight: 10
                          }}
                        >
                          {el?.userFirstName || 'unknown'} {el?.userLastName || 'unknown'}
                        </p>
                      </div>
                      <div className='row row-center' style={{ width: '30%' }}>
                        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12Z'
                            fill='#ED4563'
                            stroke='#ED4563'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.999 11V16M12.0488 8V8.1L11.9492 8.1002V8H12.0488Z'
                            stroke='white'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>

                        <p style={{ color: '#EC3C5B', marginLeft: 10 }}>{el?.workflowTriggerName}</p>
                      </div>
                      <div className='row row-center' style={{ width: '30%' }}>
                        <p>{el?.workflowName}</p>
                      </div>
                      <div
                        className='column'
                        style={{
                          width: '15%',
                          overflow: 'hidden'
                        }}
                      >
                        <p style={{ fontWeight: 600, fontSize: 14 }}>
                          {dayjs.utc(el?.actionAt).local().format('HH:mm')}
                        </p>
                        <p style={{ fontSize: 13 }}>{dayjs.utc(el?.actionAt).local().format('DD MMM YYYY')}</p>
                      </div>

                      <Spacer />
                    </div>
                  ))}
              </div>
            </div>
          )}
        </DashBoardSectionBlock>
        <div
          className='row row-center'
          style={{
            width: '100%',
            margin: '-36px 0px 20px 0px',
            background: '#EFEFF6',
            borderRadius: '0px 0px 20px 20px',
            overflow: 'hidden',
            padding: '0px 10px 10px 20px'
          }}
        >
          <Paginator total={total} perPage={filters.size} page={filters.page} onChange={handleFilter} />
        </div>
      </div>
    </div>
  )
}
