import React, { useState } from 'react'
import { Button, Popover, Link, Typography } from '@mui/material'

export default function EnableButtonWithPopover() {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <Button
        sx={{
          fontFamily: 'Plus Jakarta Sans',
          color: '#6123FF',
          textTransform: 'none',
          border: '1px solid #6123FF'
        }}
        aria-describedby={id}
        variant='outlined'
        onClick={handleClick}
      >
        Enable
      </Button>
      <Popover
        id={id}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#1B1B26',
            color: '#ffffff',
            width: '400px',
            borderRadius: '12px'
          }
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Typography sx={{ p: 2 }} fontSize={'12px'}>
          To enable this feature, please contact us at
          <Link href={'mailto:support@cyberlift.ai'}> support@cyberlift.ai</Link>. We will assist you with the necessary
          steps for activation.
        </Typography>
      </Popover>
    </div>
  )
}
