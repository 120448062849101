import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Box, Skeleton, TextField, Typography } from '@mui/material'
import { QueryParamsTemplateContext } from './QueryParamsTemplateContext'
import { useGetTemplatesServicesQuery, useGetTemplatesTypesQuery } from '../../../../api/simulation/simulation'
import { debounce } from '../../utils'
import CustomCheckbox from '../../../../common/components/CustomCheckbox'
import CollapsedFilterListWithInnerScroll from '../CollapsedList'

const SkeletonFilter = () => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      rowGap={'16px'}
      height={'calc(90vh - 250px)'}
      bgcolor={'#F3F3F7'}
      width={'285px'}
    >
      <Skeleton variant={'text'} height={'35px'} />
      <Skeleton variant={'text'} height={'35px'} />
      <Skeleton variant={'text'} height={'35px'} />
      <Skeleton variant={'text'} height={'35px'} />
      <Skeleton variant={'text'} height={'35px'} />
      <Skeleton variant={'text'} height={'35px'} />
    </Box>
  )
}

export default function TemplatesFilter() {
  const { params, setParams } = useContext(QueryParamsTemplateContext)

  const [searchText, setSearchText] = useState(params.searchText)

  const { data: types, isLoading: isTypesLoading } = useGetTemplatesTypesQuery()
  const { data: services, isLoading: isServicesLoading } = useGetTemplatesServicesQuery()

  const debouncedSetParams = useMemo(
    () =>
      debounce(searchText => {
        setParams(prevParams => ({ ...prevParams, searchText }))
      }, 500),
    [setParams]
  )

  const handleSearchChange = useCallback(
    e => {
      const value = e.target.value
      setSearchText(value)
      debouncedSetParams(value)
    },
    [debouncedSetParams]
  )

  if (isTypesLoading || isServicesLoading) {
    return <SkeletonFilter />
  }
  function onChange(e, filterName) {
    const { checked, name } = e.target
    setParams(prevParams => {
      if (checked) {
        return {
          ...prevParams,
          [filterName]: [...prevParams[filterName], name]
        }
      }
      return {
        ...prevParams,
        [filterName]: prevParams[filterName].filter(item => item !== name)
      }
    })
  }

  function myTemplates() {
    setParams(prevParams => {
      return {
        ...prevParams,
        myTemplates: !prevParams.myTemplates
      }
    })
  }

  return (
    <Box bgcolor={'#F3F3F7'} width={'270px'} pt={'16px'} pl={'16px'} pr={'8px'}>
      <Box width='220px'>
        <TextField
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
              border: '1px solid #E7E7EE',
              background: '#fff',
              width: '100%'
            }
          }}
          onChange={handleSearchChange}
          value={searchText}
          size='small'
          placeholder='Search'
        />
      </Box>
      <Box p={'16px'}>
        <CustomCheckbox name={'myTemplates'} checked={params.myTemplates} onChange={myTemplates} />
        My Templates
      </Box>
      <Box display={'flex'} flexDirection={'column'} p={'16px'}>
        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: 16, fontWeight: 500, color: '#1C1C27' }}>
          Types
        </Typography>
        {types.map(type => (
          <Box
            key={type}
            sx={{
              color: '#1C1C27'
            }}
          >
            <CustomCheckbox name={type} onChange={e => onChange(e, 'types')} />
            {type}
          </Box>
        ))}
      </Box>
      <Box display={'flex'} flexDirection={'column'} p={'16px'} height={'calc(95vh - 520px)'}>
        <CollapsedFilterListWithInnerScroll title={'Services'}>
          {services.map(service => (
            <Box
              key={service}
              sx={{
                color: '#1C1C27'
              }}
            >
              <CustomCheckbox name={service} onChange={e => onChange(e, 'services')} />
              {service}
            </Box>
          ))}
        </CollapsedFilterListWithInnerScroll>
      </Box>
    </Box>
  )
}
