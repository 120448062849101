import React from 'react'
import CustomizedDialogs from '../../../../common/components/CustomDialog'
import ModuleRow from './ModuleRow'
import { Box } from '@mui/material'

export default function ModalTrainingModules({ setActiveModulesArray, input, isShowing, onClose, modulesList }) {
  return (
    <CustomizedDialogs title={'Active Modules'} open={isShowing} handleClose={onClose}>
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        gap={'8px'}
        height={'calc(60vh - 120px)'}
        sx={{
          '::-webkit-scrollbar': {
            width: '6px',
            height: '6px'
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#DBDBE6',
            borderRadius: '2px',
            '&:hover': {
              backgroundColor: '#DBDBE6'
            }
          },
          '::-webkit-scrollbar-track': {
            backgroundColor: 'transparent'
          },
          scrollbarWidth: 'thin',
          scrollbarColor: '#DBDBE6 transparent'
        }}
        overflow={'auto'}
      >
        {modulesList?.map(module => (
          <ModuleRow
            setActiveModulesArray={setActiveModulesArray}
            input={input}
            key={module.trainingModuleId}
            name={module.module}
            id={module.trainingModuleId}
            active={input.value?.includes(module.trainingModuleId)}
          />
        ))}
      </Box>
    </CustomizedDialogs>
  )
}
