import React from 'react'
import { Box, Button } from '@mui/material'
import EditIcon from '../../../../common/icons/editIcon'

const cardStyles = {
  width: '100%',
  display: 'flex',
  columnGap: '30px',
  borderRadius: '14px',
  background: 'linear-gradient(89.54deg, #ECECF4 0.95%, #ECECF4 98.2%)',
  border: '1px solid #E7E8EA',
  padding: '18px 24px 24px'
}

export default function WrapperInfoCard({ children, onChange }) {
  return (
    <Box sx={cardStyles}>
      <Box width={`calc(100% - 110px)`}>{children}</Box>
      <Box width={`90px`}>
        {onChange && (
          <Button
            startIcon={<EditIcon color={'#6123FF'} />}
            sx={{
              fontWeight: 600,
              fontSize: 16,
              fontFamily: 'Plus Jakarta Sans',
              color: '#6123FF',
              textTransform: 'capitalize'
            }}
            onClick={onChange}
            variant={'text'}
          >
            Change
          </Button>
        )}
      </Box>
    </Box>
  )
}
