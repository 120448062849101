import React from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { ReactComponent as EmailIcon } from '../../../common/icons/envelopIcon.svg'
import { ReactComponent as ManualEmailIcon } from '../../../common/icons/articleIcon.svg'
import { ReactComponent as SMSIcon } from '../../../common/icons/SMSIcon.svg'
import { ReactComponent as WhatsUpIcon } from '../../../common/icons/whatsUpIcon.svg'
import { ReactComponent as SlackIcon } from '../../../common/icons/slackIcon.svg'
import { ReactComponent as PhoneIcon } from '../../../common/icons/phoneIcon.svg'
import { ReactComponent as AIIcon } from '../../../common/icons/aiPoweredIcon.svg'
import { ReactComponent as ChevronRightIcon } from '../../../common/icons/chevron_right.svg'
import EditIcon from '../../../common/icons/editIcon'
import InfoTooltip from '../../../common/components/InfoTooltip'
import EnableButtonWithPopover from './EnableButtonWithPopover'

const iconMapper = {
  AUTOMATED_EMAIL: EmailIcon,
  MANUAL_EMAIL: ManualEmailIcon,
  SMS: SMSIcon,
  WHATSAPP: WhatsUpIcon,
  SLACK: SlackIcon,
  PHONE: PhoneIcon
}

function RoundBoxWithNumberInside({ number }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '36px',
        height: '36px',
        borderRadius: '50%',
        background: 'linear-gradient(90deg, rgba(0,229,157,0.9528186274509804) 100%, rgba(2,158,113,1) 100%)',
        color: '#DFF2EF',
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: 16
      }}
    >
      {number}
    </Box>
  )
}

export default function ChooseTypeCard({
  type,
  title,
  description,
  isAI = false,
  onChange,
  onClick,
  infoText,
  isCanEnabled,
  number = 0,
  isDisabled = false
}) {
  const Icon = iconMapper[type] || (() => <RoundBoxWithNumberInside number={number} />)

  return (
    <Box
      sx={{
        display: 'flex',
        padding: '8px 32px',
        justifyContent: 'space-between',
        fontFamily: 'Plus Jakarta Sans',
        width: '100%',
        borderRadius: '14px',
        backgroundColor: isDisabled ? '#F4F4F4' : '#FFFFFF',
        border: '1px solid #E7E7E7',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#F4F4F4'
        }
      }}
      onClick={onClick}
    >
      <Box display={'flex'} columnGap={2} alignItems={'center'}>
        <Icon
          style={{
            height: '36px',
            width: '36px'
          }}
        />
        <Box display={'flex'} flexDirection={'column'} rowGap={0.5}>
          <Box display={'flex'} alignItems={'center'} columnGap={1}>
            <Typography fontWeight={500} fontSize={20} fontFamily={'Satoshi'}>
              {title}
            </Typography>

            {isAI && <AIIcon />}
          </Box>
          <Box display={'flex'} alignItems={'center'} columnGap={1}>
            <Typography fontWeight={500} fontSize={14} fontFamily={'Plus Jakarta Sans'} color={'#76767F'}>
              {description}
            </Typography>
            {infoText && <InfoTooltip title={infoText} />}
          </Box>
        </Box>
      </Box>
      {!onChange && !isCanEnabled && (
        <IconButton onClick={onClick} disabled={isDisabled}>
          <ChevronRightIcon />
        </IconButton>
      )}
      {isCanEnabled && (
        <Box display={'flex'} alignItems={'center'}>
          <EnableButtonWithPopover />
        </Box>
      )}
      {onChange && (
        <Button
          startIcon={<EditIcon color={'#6123FF'} />}
          sx={{
            fontWeight: 600,
            fontSize: 16,
            fontFamily: 'Plus Jakarta Sans',
            color: '#6123FF',
            textTransform: 'capitalize'
          }}
          onClick={onChange}
          variant={'text'}
        >
          Change
        </Button>
      )}
    </Box>
  )
}
