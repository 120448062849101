import React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

export default function ProjectNameColumn({ row }) {
  return (
    <Box display={'flex'} flexDirection={'column'} width={250}>
      <Tooltip title={row.name} placement={'top'}>
        <Typography
          noWrap
          fontFamily={'Plus Jakarta Sans'}
          fontWeight={600}
          fontSize={14}
          color={row.status === 'ARCHIVED' ? '#76767F' : '#1B1B26'}
          component={Link}
          sx={{ textDecoration: 'none' }}
          to={`/simulation-campaign/${row.id}`}
        >
          {row.name}
        </Typography>
      </Tooltip>
      <Box display={'flex'} columnGap={1} alignItems={'center'}>
        <Typography fontFamily={'Plus Jakarta Sans'} fontWeight={400} fontSize={13} color={'#6123FF'}>
          {row.stepsCount} steps
        </Typography>
        <Typography fontWeight={400} fontSize={13} color={'#76767F'}>
          •
        </Typography>
        <Typography noWrap fontFamily={'Plus Jakarta Sans'} fontWeight={400} fontSize={13} color={'#1B1B26'}>
          {row.startDate ? dayjs(row.startDate).format('MMMM DD, YYYY') : ''}
        </Typography>
      </Box>
    </Box>
  )
}
