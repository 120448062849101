import React from 'react'
import SpinnerIcon from '../../../common/icons/spiner'
import DoneIcon from '../../../common/icons/done'
import Archive from '../../../common/icons/archive'
export default function StatusComponent({ status }) {
  const Icon = iconMapper[status]
  return (
    <div
      style={{
        display: 'flex',
        columnGap: 8,
        alignItems: 'center',
        color: status === 'COMPLETED' ? '#00BA97' : status === 'IN_PROGRESS' ? '#1B1B26' : '#76767F',
        fontFamily: 'Plus Jakarta Sans',
        fontSize: 14,
        fontWeight: 600
      }}
    >
      {Icon}
      {statusNameMapper[status]}
    </div>
  )
}

const statusNameMapper = {
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In Progress',
  ARCHIVED: 'Archived'
}

const iconMapper = {
  COMPLETED: <DoneIcon />,
  IN_PROGRESS: <SpinnerIcon />,
  ARCHIVED: <Archive />
}
