import { useState } from 'react'

export const usePagination = (initialPage, initialRowsPerPage) => {
  const [page, setPage] = useState(initialPage)
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage)

  const handleChangePage = newPage => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = newRowsPerPage => {
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  return { page, size: rowsPerPage, handleChangePage, handleChangeRowsPerPage }
}
