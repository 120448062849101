import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { SnackBarContext } from 'src/App'
import Spacer from 'src/common/components/Spacer'
import CyberSwitch from 'src/common/components/Switch'
import settingsService from 'src/services/settings.service'

const ConnectorIcon = ({ type }) => {
  if (type == 'GOOGLE') {
    return (
      <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M29.403 15.3598C29.403 14.3467 29.305 13.301 29.1416 12.3206H14.9915V18.1048H23.096C22.7692 19.9675 21.6908 21.6015 20.0895 22.6472L24.926 26.4053C27.7691 23.7583 29.403 19.9022 29.403 15.3598Z'
          fill='#4280EF'
        />
        <path
          d='M14.9915 30C19.0437 30 22.4424 28.6602 24.926 26.3726L20.0895 22.6472C18.7496 23.5622 17.0176 24.0851 14.9915 24.0851C11.07 24.0851 7.76946 21.4381 6.56033 17.9088L1.59311 21.7322C4.14208 26.7975 9.30538 30 14.9915 30Z'
          fill='#34A353'
        />
        <path
          d='M6.56033 17.876C5.93942 16.0133 5.93942 13.9872 6.56033 12.1245L1.59311 8.26837C-0.531035 12.5167 -0.531035 17.5166 1.59311 21.7322L6.56033 17.876Z'
          fill='#F6B704'
        />
        <path
          d='M14.9915 5.94817C17.1157 5.91549 19.2071 6.73247 20.7431 8.20303L25.024 3.88938C22.3117 1.34041 18.717 -0.0321088 14.9915 0.000570345C9.30538 0.000570345 4.14208 3.20312 1.59311 8.26839L6.56033 12.1245C7.76946 8.5625 11.07 5.94817 14.9915 5.94817Z'
          fill='#E54335'
        />
      </svg>
    )
  }
  if (type == 'SLACK' || type == 'SLACK_SIMULATION') {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 2447.6 2452.5'>
        <g clipRule='evenodd' fillRule='evenodd'>
          <path
            d='m897.4 0c-135.3.1-244.8 109.9-244.7 245.2-.1 135.3 109.5 245.1 244.8 245.2h244.8v-245.1c.1-135.3-109.5-245.1-244.9-245.3.1 0 .1 0 0 0m0 654h-652.6c-135.3.1-244.9 109.9-244.8 245.2-.2 135.3 109.4 245.1 244.7 245.3h652.7c135.3-.1 244.9-109.9 244.8-245.2.1-135.4-109.5-245.2-244.8-245.3z'
            fill='#36c5f0'
          />
          <path
            d='m2447.6 899.2c.1-135.3-109.5-245.1-244.8-245.2-135.3.1-244.9 109.9-244.8 245.2v245.3h244.8c135.3-.1 244.9-109.9 244.8-245.3zm-652.7 0v-654c.1-135.2-109.4-245-244.7-245.2-135.3.1-244.9 109.9-244.8 245.2v654c-.2 135.3 109.4 245.1 244.7 245.3 135.3-.1 244.9-109.9 244.8-245.3z'
            fill='#2eb67d'
          />
          <path
            d='m1550.1 2452.5c135.3-.1 244.9-109.9 244.8-245.2.1-135.3-109.5-245.1-244.8-245.2h-244.8v245.2c-.1 135.2 109.5 245 244.8 245.2zm0-654.1h652.7c135.3-.1 244.9-109.9 244.8-245.2.2-135.3-109.4-245.1-244.7-245.3h-652.7c-135.3.1-244.9 109.9-244.8 245.2-.1 135.4 109.4 245.2 244.7 245.3z'
            fill='#ecb22e'
          />
          <path
            d='m0 1553.2c-.1 135.3 109.5 245.1 244.8 245.2 135.3-.1 244.9-109.9 244.8-245.2v-245.2h-244.8c-135.3.1-244.9 109.9-244.8 245.2zm652.7 0v654c-.2 135.3 109.4 245.1 244.7 245.3 135.3-.1 244.9-109.9 244.8-245.2v-653.9c.2-135.3-109.4-245.1-244.7-245.3-135.4 0-244.9 109.8-244.8 245.1 0 0 0 .1 0 0'
            fill='#e01e5a'
          />
        </g>
      </svg>
    )
  }
  if (type == 'OUTLOOK') {
    return (
      <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_782_126058)'>
          <path
            d='M28.5959 2.00006H11.404C10.6286 2.00006 10 2.62868 10 3.40408V5.00008L19.69 8.00011L29.9999 5.00008V3.40408C29.9999 2.62868 29.3713 2.00006 28.5959 2.00006Z'
            fill='#0364B8'
          />
          <path
            d='M31.6499 17.4049C31.7964 16.9445 31.9133 16.4753 31.9999 15.9999C31.9996 15.7623 31.8727 15.5428 31.6668 15.424L31.6538 15.4159L31.6497 15.4139L20.8118 9.23996C20.765 9.20968 20.7166 9.18184 20.6667 9.15702C20.2466 8.94839 19.7528 8.94839 19.3327 9.15702C19.2829 9.18156 19.2344 9.20896 19.1876 9.23895L8.34964 15.4149L8.34562 15.4169L8.33357 15.424C8.12738 15.5425 8.00011 15.7622 7.99953 15.9999C8.0862 16.4753 8.203 16.9445 8.3495 17.4049L19.8415 25.81L31.6495 17.4049H31.6499Z'
            fill='#0A2767'
          />
          <path
            d='M23.9999 4.99994H16.9999L14.9789 7.99996L16.9999 11L23.9999 17H29.9999V11L23.9999 4.99994Z'
            fill='#28A8EA'
          />
          <path d='M10 4.99994H17V11H10V4.99994Z' fill='#0078D4' />
          <path d='M23.9999 4.99994H29.9999V11H23.9999V4.99994Z' fill='#50D9FF' />
          <path
            d='M23.9999 17L16.9999 11H9.99989V17L16.9999 23.0001L27.8319 24.7681L23.9999 17.0002V17Z'
            fill='#0364B8'
          />
          <path d='M17 11H24V17H17V11Z' fill='#0078D4' />
          <path d='M10 17.0001H17V23.0001H10V17.0001Z' fill='#064A8C' />
          <path d='M23.9999 17.0001H29.9999V23.0001H23.9999V17.0001Z' fill='#0078D4' />
          <path
            opacity='0.5'
            d='M20.1899 25.218L8.39685 16.6179L8.89188 15.748C8.89188 15.748 19.6369 21.868 19.8009 21.9599C19.9365 22.0142 20.0886 22.0099 20.2209 21.9479L31.1539 15.7178L31.6499 16.5868L20.1899 25.2178V25.218Z'
            fill='#0A2767'
          />
          <path
            d='M31.667 16.577L31.6529 16.5851L31.6499 16.5871L20.8119 22.761C20.3743 23.0433 19.8213 23.0777 19.3519 22.852L23.1259 27.9129L31.3798 29.71V29.714C31.7693 29.4322 32 28.9806 31.9999 28.4999V15.9999C31.9999 16.238 31.873 16.458 31.6668 16.5769L31.667 16.577Z'
            fill='#1490DF'
          />
          <path
            opacity='0.05'
            d='M32 28.4999V27.762L22.017 22.074L20.812 22.7611C20.3744 23.0433 19.8214 23.0777 19.3521 22.852L23.1261 27.9129L31.38 29.71V29.714C31.7694 29.4322 32.0001 28.9806 32 28.4999Z'
            fill='black'
          />
          <path
            opacity='0.1'
            d='M31.9499 28.8829L21.0069 22.65L20.8119 22.76C20.3744 23.0425 19.8214 23.0774 19.3519 22.852L23.1259 27.9129L31.3798 29.7099V29.714C31.6598 29.5112 31.8611 29.2181 31.9498 28.884V28.883L31.9499 28.8829Z'
            fill='black'
          />
          <path
            d='M8.34993 16.59V16.5799H8.33989L8.3099 16.56C8.11548 16.4406 7.99782 16.2281 7.99997 15.9999V28.4999C7.99882 29.3273 8.66862 29.9988 9.49596 29.9999C9.49725 29.9999 9.49869 29.9999 9.49998 29.9999H30.5C30.625 29.9987 30.7492 29.9819 30.87 29.95C30.9327 29.9391 30.9934 29.9189 31.05 29.89C31.0712 29.888 31.0917 29.8811 31.11 29.8701C31.1917 29.8367 31.2691 29.7929 31.34 29.7401C31.3599 29.73 31.37 29.7301 31.38 29.7101L8.35008 16.5901L8.34993 16.59Z'
            fill='#28A8EA'
          />
          <path
            opacity='0.1'
            d='M18 24.667V8.33299C17.9978 7.59777 17.4022 7.00215 16.667 7H10.03V14.456L8.35007 15.4141L8.34505 15.4161L8.333 15.4231C8.1268 15.5421 7.99996 15.762 7.99996 16.0001V26.0001H16.667C17.4022 25.9979 17.9978 25.4023 18 24.6671V24.667Z'
            fill='black'
          />
          <path
            opacity='0.2'
            d='M17 25.6669V9.33293C16.9979 8.59771 16.4023 8.00209 15.667 7.99994H10.03V14.456L8.35007 15.414L8.34505 15.4161L8.333 15.4231C8.1268 15.542 7.99996 15.762 7.99996 16V27H15.6669C16.4021 26.9979 16.9977 26.4023 16.9999 25.667L17 25.6669Z'
            fill='black'
          />
          <path
            opacity='0.2'
            d='M17 23.667V9.33311C16.9979 8.59789 16.4023 8.00227 15.667 8.00012H10.03V14.4562L8.35007 15.4142L8.34505 15.4162L8.333 15.4233C8.1268 15.5422 7.99996 15.7622 7.99996 16.0002V25.0002H15.6669C16.4021 24.998 16.9977 24.4024 16.9999 23.6672L17 23.667Z'
            fill='black'
          />
          <path
            opacity='0.2'
            d='M15.9999 23.667V9.33311C15.9978 8.59789 15.4022 8.00227 14.6669 8.00012H10.03V14.4562L8.35007 15.4142L8.34505 15.4162L8.333 15.4233C8.1268 15.5422 7.99996 15.7622 7.99996 16.0002V25.0002H14.6669C15.4022 24.998 15.9978 24.4024 15.9999 23.6672V23.667Z'
            fill='black'
          />
          <path
            d='M1.33299 7.99994H14.6669C15.4032 7.99994 15.9999 8.5967 15.9999 9.33293V22.6669C15.9999 23.4031 15.4032 23.9999 14.6669 23.9999H1.33299C0.596762 23.9999 0 23.4031 0 22.6669V9.33293C0 8.5967 0.596762 7.99994 1.33299 7.99994Z'
            fill='#0078D4'
          />
          <path
            d='M3.86697 13.468C4.22124 12.7131 4.79303 12.0813 5.50903 11.654C6.30223 11.1999 7.20548 10.9736 8.11906 11C8.96506 10.9816 9.79972 11.1961 10.5321 11.62C11.2214 12.0307 11.7765 12.6327 12.1301 13.353C12.5152 14.147 12.707 15.0208 12.6901 15.9031C12.7086 16.8246 12.511 17.7377 12.1132 18.5691C11.752 19.3151 11.1801 19.9388 10.4682 20.3631C9.70717 20.8004 8.8408 21.0208 7.96323 21C7.09872 21.0205 6.24512 20.8037 5.49526 20.373C4.80035 19.9618 4.23874 19.359 3.8773 18.6369C3.4896 17.8546 3.29517 16.9909 3.31024 16.1179C3.29345 15.2039 3.48386 14.2979 3.86726 13.4678L3.86697 13.468ZM5.61693 17.7259C5.80562 18.2032 6.1256 18.6171 6.53999 18.9199C6.96212 19.2153 7.46791 19.3679 7.98303 19.3549C8.53173 19.3763 9.07267 19.2188 9.52408 18.906C9.93374 18.6034 10.2457 18.1871 10.421 17.709C10.6177 17.1762 10.7147 16.6119 10.707 16.044C10.7132 15.4708 10.6218 14.9007 10.4369 14.358C10.2739 13.8699 9.97319 13.4393 9.571 13.118C9.1315 12.7896 8.59228 12.6225 8.04401 12.6451C7.5177 12.6317 7.00058 12.7854 6.56696 13.0841C6.14454 13.3875 5.8171 13.8047 5.62296 14.2871C5.19336 15.3931 5.19092 16.6194 5.61593 17.7271L5.61693 17.7261V17.7259Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_782_126058'>
            <rect width='32' height='32' fill='white' />
          </clipPath>
        </defs>
      </svg>
    )
  }
  if (type == 'THUNDERBIRD') {
    return (
      <svg width='32' height='32' viewBox='0 0 69 67' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M28.8071 11.779H28.813C30.9741 4.20286 40.3911 0.504761 49.9875 0.504761C56.6189 0.504761 62.5717 2.60995 66.6526 5.95211C64.2172 6.06728 61.8125 6.5465 59.5183 7.37393C62.8158 8.60301 65.6486 10.4939 67.7479 12.8331C66.164 12.5597 64.5579 12.4379 62.9511 12.4693C66.9003 18.206 69.0102 25.0144 68.999 31.9853C68.999 50.992 53.6341 66.4 34.6805 66.4C16.0191 66.4 0.361992 50.7315 0.361992 31.9853C0.361992 29.0247 0.754202 25.9677 1.51607 23.1004C1.7161 22.4987 1.99457 21.9215 2.36423 21.7101C2.82704 21.4456 3.24867 22.2342 3.31633 22.4908C3.81816 24.3757 4.49335 26.2098 5.33328 27.9696C5.25974 24.0404 6.9335 20.4613 9.23578 17.366C10.7713 15.3021 12.195 13.3896 12.852 7.87048C12.8961 7.49979 13.2461 7.23332 13.6001 7.34934C18.5969 8.98944 21.2679 17.3335 20.8531 24.3108C23.6133 24.7071 23.6005 21.8153 23.6005 21.8153C22.7181 19.0955 23.3064 14.0405 28.7973 11.779H28.8071Z'
          fill='url(#paint0_linear_64_16)'
        />
        <path
          opacity='0.9'
          d='M67.8763 23.2214C68.7108 42.1494 53.1939 58.4689 34.2726 58.4689C16.5594 58.4689 2.04458 44.7394 0.731653 27.3138C0.497405 28.9456 0.374252 30.5914 0.362976 32.24C0.497309 50.8849 16.1162 66.4 34.6815 66.4C53.6351 66.4 69 50.992 69 31.9853C69 28.9568 68.6088 26.0198 67.8763 23.2214Z'
          fill='url(#paint1_radial_64_16)'
        />
        <path
          d='M33.8441 14.2873C33.4745 13.6315 31.7674 12.661 31.0212 12.4919C33.8451 3.42116 48.2314 0.63652 57.0366 2.24123C60.7008 2.90985 65.2642 4.9118 66.6526 5.95211C62.5717 2.60995 56.6179 0.504761 49.9876 0.504761C40.3911 0.504761 30.9741 4.20286 28.813 11.779H28.7973C23.3064 14.0405 22.7181 19.0975 23.6005 21.8163C24.4477 18.5724 28.4787 14.5862 33.8441 14.2873Z'
          fill='url(#paint2_radial_64_16)'
        />
        <path
          d='M43.8171 8.02293C36.1082 9.54402 33.5892 10.0416 31.0094 12.5007C33.9069 4.8086 41.303 3.24912 50.116 6.75549C48.0185 7.18905 45.9188 7.61186 43.8171 8.02391V8.02293Z'
          fill='url(#paint3_linear_64_16)'
        />
        <path
          d='M2.22501 22.0473C0.11883 30.6943 1.74651 40.8574 11.3145 49.3863C8.46607 46.2625 4.98814 34.7286 12.6627 26.4888C13.1795 25.9332 14.0688 26.3413 14.0973 27.1004C14.7297 44.2251 28.5091 54.6842 44.3956 52.7285C39.4733 52.4512 23.1946 46.7335 35.3041 44.4719C41.6334 43.289 51.5574 41.4356 51.5574 32.5055C51.5574 18.0297 40.396 13.7976 33.6274 14.4269C28.9954 14.8576 24.8723 17.8055 23.6035 21.8143C24.0908 23.3944 22.1484 24.5006 20.8531 24.3148C21.2688 17.3384 18.5979 8.98942 13.6001 7.34936C13.2471 7.23333 12.8961 7.4998 12.852 7.87049C12.195 13.3896 10.7713 15.3021 9.23677 17.366C6.93351 20.4623 5.25974 24.0404 5.33328 27.9696C4.49336 26.2099 3.81817 24.3757 3.31634 22.4908C3.26045 22.2784 2.95354 21.6855 2.5829 21.658C2.38189 21.6432 2.27501 21.8399 2.22501 22.0473Z'
          fill='url(#paint4_radial_64_16)'
        />
        <path
          d='M31.0516 45.348C40.3705 52.936 59.1114 47.2468 59.1114 28.7975C51.5456 40.2979 41.909 48.231 31.0526 45.348H31.0516Z'
          fill='url(#paint5_linear_64_16)'
        />
        <path
          d='M12.6627 26.4888C12.7475 26.3937 12.8542 26.321 12.9736 26.2772C13.093 26.2332 13.2212 26.2194 13.3471 26.237C6.47756 34.6391 12.0185 49.3952 15.821 53.0205C16.0338 53.6242 12.2137 50.4846 11.6871 49.7501C8.79455 47.289 4.64887 35.0934 12.6627 26.4888Z'
          fill='url(#paint6_linear_64_16)'
        />
        <path
          d='M34.6805 46.0432C44.0014 46.0432 51.5583 39.8604 51.5583 32.2331C51.5583 24.6058 44.0014 18.423 34.6805 18.423C26.7284 18.423 17.7987 23.6107 17.8027 32.4347C17.8066 46.0697 32.1713 53.9143 44.4162 52.7265C43.4964 52.6193 37.7584 52.3135 33.8804 47.9144C33.5303 47.5181 32.9234 46.8259 33.1989 46.3765C33.4735 45.9272 34.2304 46.0422 34.6795 46.0422L34.6805 46.0432Z'
          fill='url(#paint7_linear_64_16)'
        />
        <path
          opacity='0.6'
          d='M49.7199 25.9578L36.3808 38.7581C35.1973 39.6018 33.9373 39.6627 32.694 38.8977L19.6108 26.005C19.9841 25.4035 20.4036 24.8321 20.8658 24.2961L22.2386 25.5842C25.6744 28.8133 28.4522 31.4219 32.3674 34.7729C34.1344 36.2852 34.6835 36.2557 36.417 34.7729C40.8961 30.9381 44.172 28.0375 48.4305 24.2233C48.905 24.7667 49.3359 25.347 49.7189 25.9588L49.7199 25.9578Z'
          fill='white'
        />
        <mask id='mask0_64_16' maskUnits='userSpaceOnUse' x='17' y='25' width='35' height='28'>
          <path
            d='M51.5584 32.2331C51.5584 39.8604 44.0014 46.0432 34.6805 46.0432C34.2304 46.0432 33.4745 45.9272 33.1989 46.3775C32.9234 46.8259 33.5304 47.5181 33.8804 47.9144C37.524 52.0481 42.8101 52.5682 44.1975 52.7049L44.4162 52.7265C32.1713 53.9143 17.8066 46.0698 17.8027 32.4347C17.7899 30.1645 18.4199 27.9373 19.6196 26.0119L32.7665 37.9863C33.7019 38.8378 35.2777 38.8378 36.2131 37.9863L49.6091 25.7848C50.8543 27.71 51.5584 29.9047 51.5584 32.2331Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_64_16)'>
          <path opacity='0.7' d='M13.8933 16.3051H56.5532V55.0923H13.8933V16.3051Z' fill='url(#paint8_linear_64_16)' />
          <g filter='url(#filter0_f_64_16)'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M30.7623 38.0669C28.2767 35.473 20.8325 26.8546 20.8325 26.8546L21.4081 26.8801L33.0626 35.6087C33.9353 36.2449 35.1806 36.238 36.0454 35.5939L47.4735 26.8919L48.0794 26.8438C48.0794 26.8438 40.8784 35.2576 38.1202 38.0295C35.362 40.8014 33.2479 40.6607 30.7623 38.0669Z'
              fill='#458FCD'
            />
          </g>
        </g>
        <path
          d='M37.0485 11.5145C38.8546 10.9442 38.6958 9.1517 38.6958 9.1517C38.6958 9.1517 37.7927 8.08484 36.0032 8.67579C34.3285 9.22938 34.0686 10.426 34.0686 10.426C34.0686 10.426 34.9835 12.1664 37.0485 11.5145Z'
          fill='white'
        />
        <defs>
          <filter
            id='filter0_f_64_16'
            x='-11.1675'
            y='-5.15625'
            width='91.2469'
            height='77.2168'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
            <feGaussianBlur stdDeviation='16' result='effect1_foregroundBlur_64_16' />
          </filter>
          <linearGradient
            id='paint0_linear_64_16'
            x1='12.2735'
            y1='11.8478'
            x2='60.7787'
            y2='56.9648'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#1B91F3' />
            <stop offset='1' stopColor='#0B68CB' />
          </linearGradient>
          <radialGradient
            id='paint1_radial_64_16'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(12.1842 27.418) rotate(66.5762) scale(29.0881 27.8122)'
          >
            <stop offset='0.53' stopColor='#0B4186' stopOpacity='0' />
            <stop offset='1' stopColor='#0B4186' stopOpacity='0.45' />
          </radialGradient>
          <radialGradient
            id='paint2_radial_64_16'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(35.563 15.4663) rotate(-127.919) scale(6.21463 10.2768)'
          >
            <stop stopColor='#EF3ACC' stopOpacity='0' />
            <stop offset='1' stopColor='#EF3ACC' stopOpacity='0.64' />
          </radialGradient>
          <linearGradient
            id='paint3_linear_64_16'
            x1='25.7351'
            y1='23.4318'
            x2='43.1974'
            y2='4.04695'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#0F5DB0' />
            <stop offset='1' stopColor='#0F5DB0' stopOpacity='0' />
          </linearGradient>
          <radialGradient
            id='paint4_radial_64_16'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(21.7375 50.7364) rotate(-64.325) scale(45.3755 55.9544)'
          >
            <stop offset='0.02' stopColor='#094188' />
            <stop offset='0.97' stopColor='#0B4186' stopOpacity='0' />
          </radialGradient>
          <linearGradient
            id='paint5_linear_64_16'
            x1='56.2443'
            y1='37.5025'
            x2='48.1111'
            y2='57.4166'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#E247C4' stopOpacity='0' />
            <stop offset='1' stopColor='#E247C4' stopOpacity='0.64' />
          </linearGradient>
          <linearGradient
            id='paint6_linear_64_16'
            x1='6.05005'
            y1='19.6294'
            x2='13.2191'
            y2='48.4025'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0.1' stopColor='#EF3ACC' />
            <stop offset='1' stopColor='#EF3ACC' stopOpacity='0' />
          </linearGradient>
          <linearGradient
            id='paint7_linear_64_16'
            x1='34.6805'
            y1='23.48'
            x2='34.6805'
            y2='52.5849'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='white' />
            <stop offset='0.91' stopColor='#BEE1FE' />
            <stop offset='1' stopColor='#96CEFD' />
          </linearGradient>
          <linearGradient
            id='paint8_linear_64_16'
            x1='35.2237'
            y1='39.9499'
            x2='35.2237'
            y2='52.1425'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#BCE0FD' />
            <stop offset='1' stopColor='#88CCFC' />
          </linearGradient>
        </defs>
      </svg>
    )
  }
  if (type == 'TEAMS' || type == 'TEAMS_SIMULATION') {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' height='32' width='50' viewBox='-334.32495 -518.3335 2897.4829 3110.001'>
        <path
          d='M1554.637 777.5h575.713c54.391 0 98.483 44.092 98.483 98.483v524.398c0 199.901-162.051 361.952-361.952 361.952h-1.711c-199.901.028-361.975-162-362.004-361.901V828.971c.001-28.427 23.045-51.471 51.471-51.471z'
          fill='#5059C9'
        />
        <circle r='233.25' cy='440.583' cx='1943.75' fill='#5059C9' />
        <circle r='336.917' cy='336.917' cx='1218.083' fill='#7B83EB' />
        <path
          d='M1667.323 777.5H717.01c-53.743 1.33-96.257 45.931-95.01 99.676v598.105c-7.505 322.519 247.657 590.16 570.167 598.053 322.51-7.893 577.671-275.534 570.167-598.053V877.176c1.245-53.745-41.268-98.346-95.011-99.676z'
          fill='#7B83EB'
        />
        <path
          d='M1244 777.5v838.145c-.258 38.435-23.549 72.964-59.09 87.598a91.856 91.856 0 01-35.765 7.257H667.613c-6.738-17.105-12.958-34.21-18.142-51.833a631.287 631.287 0 01-27.472-183.49V877.02c-1.246-53.659 41.198-98.19 94.855-99.52z'
          opacity='.1'
        />
        <path
          d='M1192.167 777.5v889.978a91.802 91.802 0 01-7.257 35.765c-14.634 35.541-49.163 58.833-87.598 59.09H691.975c-8.812-17.105-17.105-34.21-24.362-51.833-7.257-17.623-12.958-34.21-18.142-51.833a631.282 631.282 0 01-27.472-183.49V877.02c-1.246-53.659 41.198-98.19 94.855-99.52z'
          opacity='.2'
        />
        <path
          d='M1192.167 777.5v786.312c-.395 52.223-42.632 94.46-94.855 94.855h-447.84A631.282 631.282 0 01622 1475.177V877.02c-1.246-53.659 41.198-98.19 94.855-99.52z'
          opacity='.2'
        />
        <path
          d='M1140.333 777.5v786.312c-.395 52.223-42.632 94.46-94.855 94.855H649.472A631.282 631.282 0 01622 1475.177V877.02c-1.246-53.659 41.198-98.19 94.855-99.52z'
          opacity='.2'
        />
        <path
          d='M1244 509.522v163.275c-8.812.518-17.105 1.037-25.917 1.037-8.812 0-17.105-.518-25.917-1.037a284.472 284.472 0 01-51.833-8.293c-104.963-24.857-191.679-98.469-233.25-198.003a288.02 288.02 0 01-16.587-51.833h258.648c52.305.198 94.657 42.549 94.856 94.854z'
          opacity='.1'
        />
        <path
          d='M1192.167 561.355v111.442a284.472 284.472 0 01-51.833-8.293c-104.963-24.857-191.679-98.469-233.25-198.003h190.228c52.304.198 94.656 42.55 94.855 94.854z'
          opacity='.2'
        />
        <path
          d='M1192.167 561.355v111.442a284.472 284.472 0 01-51.833-8.293c-104.963-24.857-191.679-98.469-233.25-198.003h190.228c52.304.198 94.656 42.55 94.855 94.854z'
          opacity='.2'
        />
        <path
          d='M1140.333 561.355v103.148c-104.963-24.857-191.679-98.469-233.25-198.003h138.395c52.305.199 94.656 42.551 94.855 94.855z'
          opacity='.2'
        />
        <linearGradient
          gradientTransform='matrix(1 0 0 -1 0 2075.333)'
          y2='394.261'
          x2='942.234'
          y1='1683.073'
          x1='198.099'
          gradientUnits='userSpaceOnUse'
          id='a'
        >
          <stop offset='0' stopColor='#5a62c3' />
          <stop offset='.5' stopColor='#4d55bd' />
          <stop offset='1' stopColor='#3940ab' />
        </linearGradient>
        <path
          d='M95.01 466.5h950.312c52.473 0 95.01 42.538 95.01 95.01v950.312c0 52.473-42.538 95.01-95.01 95.01H95.01c-52.473 0-95.01-42.538-95.01-95.01V561.51c0-52.472 42.538-95.01 95.01-95.01z'
          fill='url(#a)'
        />
        <path d='M820.211 828.193h-189.97v517.297h-121.03V828.193H320.123V727.844h500.088z' fill='#FFF' />
      </svg>
    )
  }
}

const pluginsForClient = [
  {
    title: 'Google',
    connectorType: 'GOOGLE',
    link: 'https://workspace.google.com/marketplace/app/cyberlift_report_email/341007570068'
  },
  {
    title: 'Outlook',
    connectorType: 'OUTLOOK',
    link: 'https://appsource.microsoft.com/pl-pl/product/office/wa200006796'
  },
  {
    title: 'Thunderbird',
    connectorType: 'THUNDERBIRD',
    link: 'https://drive.google.com/file/d/14aY3o0-3nr_mn7lwtYeu-aDoMp5vRuk3/view?usp=drive_link'
  }
]

const communicationToolsInitial = [
  {
    title: 'Slack',
    connectorType: 'SLACK'
  },
  {
    title: 'Teams',
    connectorType: 'TEAMS'
  }
]

const simulationToolsInitial = [
  {
    title: 'Slack',
    connectorType: 'SLACK_SIMULATION'
  },
  {
    title: 'Teams',
    connectorType: 'TEAMS_SIMULATION'
  }
]

export const PluginsAndBot = () => {
  dayjs.extend(utc)

  const [open, setOpen] = useState(false)
  const [type, setType] = useState('')
  const [pluginEnabled, setPluginEnabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [communicationTools, setCommunicationTools] = useState(communicationToolsInitial)
  const [simTools, setSimTools] = useState(simulationToolsInitial)

  const { query, handleConnectTo, updateConnectors } = useOutletContext()
  const { setSuccessMessage, setErrorMessage } = useContext(SnackBarContext)

  const getData = () => {
    setLoading(true)
    Promise.all([settingsService.getPluginStatus(), settingsService.getIntegrations()])
      .then(values => {
        setLoading(false)
        const [pluginResp, integrationsResp] = values
        setPluginEnabled(pluginResp.data.copilotEnabled || false)
        setCommunicationTools(
          communicationToolsInitial.map(el =>
            integrationsResp.data.map(item => item.type).includes(el.connectorType)
              ? {
                  ...el,
                  connected: true,
                  updatedAt: integrationsResp.data.find(item => item.type == el.connectorType).updatedAt
                }
              : el
          )
        )
        setSimTools(
          simulationToolsInitial.map(el =>
            integrationsResp.data.map(item => item.type).includes(el.connectorType)
              ? {
                  ...el,
                  connected: true,
                  updatedAt: integrationsResp.data.find(item => item.type == el.connectorType).updatedAt
                }
              : el
          )
        )
      })
      .catch(() => {
        setErrorMessage('Something went wrong')
      })
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (query.get('successSync')) {
      setSuccessMessage('Cyberlift bot successfully installed and users synchronized')
    }
  }, [])

  const disconnectSlack = () => {
    settingsService
      .disconnectSlack(type)
      .then(() => {
        getData()
      })
      .catch(err => {
        console.error(err)
        setErrorMessage('Something went wrong')
      })
  }

  const onClose = () => {
    setOpen(false)
  }

  const onAction = () => {
    disconnectSlack()
    setOpen(false)
    // settingsService.disconnect(selectedConnector).then(() => {
    //   updateConnectors()
    //   setOpen(false)
    //   setSuccessMessage('Provider disconnected')
    // }).catch(err => {
    //   console.error(err);
    //   setErrorMessage('Something went wrong')
    // })
  }

  const enablePlugin = () => {
    setLoading(true)
    settingsService
      .setPluginStatus(!pluginEnabled)
      .then(() => {
        setLoading(false)
        setPluginEnabled(!pluginEnabled)
        setSuccessMessage(pluginEnabled ? 'Cyberlift AI for Plugins disabled' : 'Cyberlift AI for Plugins enabled')
      })
      .catch(err => {
        console.error(err)
      })
  }

  return (
    <div className='settings-section'>
      <h2>Cyberlift AI for Plugins</h2>
      <div className='row row-center' style={{ margin: '10px 0px 20px 0px' }}>
        <CyberSwitch checked={pluginEnabled} onChange={enablePlugin} />

        <p style={{ marginLeft: 10, color: '#444444' }}>{pluginEnabled ? 'Enabled' : 'Disabled'}</p>
      </div>

      <h2 id='integrations-section'>Available Email Plugins</h2>
      <p style={{ maxWidth: '730px', lineHeight: '24px' }}>
        With the email plugin, employees can easily report suspicious emails directly using the Cyberlift button in
        their inbox.
      </p>
      <div
        style={{
          marginTop: '20px',
          flexWrap: 'wrap',
          width: '100%',
          marginBottom: 20
        }}
        className='row'
      >
        {pluginsForClient?.map(el => {
          return (
            <div
              className='column'
              key={el.connectorType}
              style={{
                alignItems: 'flex-start',
                border:
                  query.get('errorSync') && query.get('errorSyncType') == el.connectorType
                    ? '1px solid #B40020'
                    : '1px solid #E7E7EE',
                padding: '17px 21px',
                width: 322,
                borderRadius: 14,
                marginRight: 14,
                marginBottom: 16,
                height: 150
              }}
            >
              <div style={{ marginBottom: 7 }}>
                <ConnectorIcon type={el.connectorType} />
              </div>

              <p style={{ fontWeight: 600 }}>{el.title}</p>

              <Spacer />
              {el.link ? (
                <Link
                  disabled={true}
                  style={{
                    fontFamily: 'Plus Jakarta Sans',
                    fontSize: '15px',
                    color: '#6123FF',
                    textTransform: 'none',
                    padding: 0,
                    textDecoration: 'none'
                  }}
                  target='_blank'
                  to={el.link}
                >
                  Install
                </Link>
              ) : (
                <p style={{ color: 'rgba(0, 0, 0, 0.26)' }}>Install</p>
              )}
            </div>
          )
        })}
      </div>

      <h2 id='integrations-section'>Communication Tools</h2>
      <p style={{ maxWidth: '730px', lineHeight: '24px' }}>
        Link Cyberlift with your external communication tools (Slack or Teams). This will enable direct notifications
        and training sessions via our bot, ensuring users stay informed and up-to-date seamlessly.
      </p>
      <div style={{ marginTop: '20px', marginBottom: 20, flexWrap: 'wrap', width: '100%' }} className='row'>
        {communicationTools?.map(el => {
          return (
            <div
              className='column'
              key={el.connectorType}
              style={{
                alignItems: 'flex-start',
                border:
                  query.get('errorSync') && query.get('errorSyncType') == el.connectorType
                    ? '1px solid #B40020'
                    : '1px solid #E7E7EE',
                padding: '17px 21px',
                width: 322,
                borderRadius: 14,
                marginRight: 14,
                marginBottom: 16,
                minHeight: 150
              }}
            >
              <div style={{ marginBottom: 17 }}>
                <ConnectorIcon type={el.connectorType} />
              </div>

              <p style={{ fontWeight: 600 }}>{el.title}</p>

              {el.connected ? (
                <div className='column' style={{ alignItems: 'flex-start' }}>
                  <div
                    style={{
                      color: '#00BC62',
                      marginTop: 10
                    }}
                    key={el.connectorType}
                    className='row row-center'
                  >
                    <p>Connected</p>
                    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M13.5 4.5L6.5 11.4997L3 8'
                        stroke='#00BC62'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  <p
                    style={{
                      fontSize: 13,
                      opacity: 0.7,
                      marginTop: 6
                    }}
                  >
                    {/*Last sync: {dayjs.utc(el.updatedAt).local().format('DD MMM YYYY HH:mm')}*/}
                  </p>
                  <Button
                    disabled={el.connectorType == 'TEAMS'}
                    key={el.connectorType + 'btn'}
                    sx={{
                      mt: '10px',
                      fontFamily: 'Plus Jakarta Sans',
                      fontSize: '15px',
                      color: '#6123FF',
                      textTransform: 'none',
                      padding: 0
                    }}
                    onClick={() => {
                      setOpen(true)
                      setType('SLACK')
                    }}
                  >
                    Disconnect
                  </Button>
                </div>
              ) : (
                <Button
                  disabled={el.connectorType == 'TEAMS'}
                  key={el.connectorType + 'btn'}
                  sx={{
                    mt: '10px',
                    fontFamily: 'Plus Jakarta Sans',
                    fontSize: '15px',
                    color: '#6123FF',
                    textTransform: 'none',
                    padding: 0
                  }}
                  onClick={() => handleConnectTo(el.connectorType)}
                >
                  Connect
                </Button>
              )}
              {query.get('errorSync') && query.get('errorSyncType') == el.connectorType && (
                <p className='error-text' style={{ marginTop: 20, marginLeft: 0 }}>
                  {query.get('errorSync')}
                </p>
              )}
              <Spacer />
            </div>
          )
        })}
      </div>

      <h2 id='integrations-section'>Simulation Tools</h2>
      <p style={{ maxWidth: '730px', lineHeight: '24px' }}>
        Integrate Cyberlift with your external communication platforms (Slack or Teams) to simulate realistic phishing
        attempts. This feature empowers users to regularly train and stay current on security best practices.
      </p>
      <br></br>
      <p style={{ maxWidth: '700px', lineHeight: '20px', fontStyle: 'italic' }}>
        <strong>Note:</strong>
        <br></br>
        Please be aware that after clicking the Connect button, you will need to sign in with the real account
        from(Slack or Teams) that will be used to send phishing simulations.
      </p>
      <div style={{ marginTop: '20px', flexWrap: 'wrap', width: '100%' }} className='row'>
        {simTools?.map(el => {
          return (
            <div
              className='column'
              key={el.connectorType}
              style={{
                alignItems: 'flex-start',
                border:
                  query.get('errorSync') && query.get('errorSyncType') == el.connectorType
                    ? '1px solid #B40020'
                    : '1px solid #E7E7EE',
                padding: '17px 21px',
                width: 322,
                borderRadius: 14,
                marginRight: 14,
                marginBottom: 16,
                minHeight: 150
              }}
            >
              <div style={{ marginBottom: 17 }}>
                <ConnectorIcon type={el.connectorType} />
              </div>

              <p style={{ fontWeight: 600 }}>{el.title}</p>

              {el.connected ? (
                <div className='column' style={{ alignItems: 'flex-start' }}>
                  <div
                    style={{
                      color: '#00BC62',
                      marginTop: 10
                    }}
                    key={el.connectorType}
                    className='row row-center'
                  >
                    <p>Connected</p>
                    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M13.5 4.5L6.5 11.4997L3 8'
                        stroke='#00BC62'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  <p
                    style={{
                      fontSize: 13,
                      opacity: 0.7,
                      marginTop: 6
                    }}
                  >
                    {/*Last sync: {dayjs.utc(el.updatedAt).local().format('DD MMM YYYY HH:mm')}*/}
                  </p>
                  <Button
                    key={el.connectorType + 'btn'}
                    sx={{
                      mt: '10px',
                      fontFamily: 'Plus Jakarta Sans',
                      fontSize: '15px',
                      color: '#6123FF',
                      textTransform: 'none',
                      padding: 0
                    }}
                    onClick={() => {
                      setOpen(true)
                      setType('SLACK_SIMULATION')
                    }}
                  >
                    Disconnect
                  </Button>
                </div>
              ) : (
                <Button
                  disabled={el.connectorType == 'TEAMS_SIMULATION'}
                  key={el.connectorType + 'btn'}
                  sx={{
                    mt: '10px',
                    fontFamily: 'Plus Jakarta Sans',
                    fontSize: '15px',
                    color: '#6123FF',
                    textTransform: 'none',
                    padding: 0
                  }}
                  onClick={() => handleConnectTo(el.connectorType)}
                >
                  Connect
                </Button>
              )}
              {query.get('errorSync') && query.get('errorSyncType') == el.connectorType && (
                <p className='error-text' style={{ marginTop: 20, marginLeft: 0 }}>
                  {query.get('errorSync')}
                </p>
              )}
              <Spacer />
            </div>
          )
        })}
      </div>

      <Dialog
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '22px',
            paddingTop: '8px',
            maxWidth: 'unset',
            width: '684px'
          }
        }}
        open={open}
        onClose={onClose}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Satoshi',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '35px',
            lineHeight: '110%',
            color: '#1B1B26'
          }}
        >
          Disconnect?
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              fontSize: '14px',
              marginBottom: '80px',
              lineHeight: '22px'
            }}
          >
            Are you sure you want to disconnect this provider?
          </p>
          <div className='row'>
            <Button
              sx={{
                padding: '10px 0px',
                fontFamily: 'Plus Jakarta Sans',
                textTransform: 'none',
                borderRadius: '8px',
                borderColor: '#D0D0D8',
                color: '#1B1B26'
              }}
              variant={'outlined'}
              fullWidth
              onClick={onClose}
            >
              Cancel
            </Button>
            <div style={{ width: '20px' }}></div>
            <Button
              onClick={onAction}
              sx={{
                padding: '10px 0px',
                fontFamily: 'Plus Jakarta Sans',
                textTransform: 'none',
                borderRadius: '8px',
                background: '#6123FF'
              }}
              variant={'contained'}
              fullWidth
            >
              Yes
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
