import React from 'react'
import { ReactComponent as PlayImage } from '../../common/images/play.svg'
import { Box, Container, Typography } from '@mui/material'
import NewCampaignButton from './NewCampaign/NewCampaignButton'

export default function EmptyPage() {
  return (
    <Container
      maxWidth={'md'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '80vh'
      }}
    >
      <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <PlayImage />
      </Box>
      <Box width={'100%'} display={'flex'} flexDirection={'column'} rowGap={1} pb={3} alignItems={'center'}>
        <Typography variant='body1' fontWeight={700} fontFamily={'Plus Jakarta Sans'}>
          Manage Your Manual Simulation Campaigns
        </Typography>
        <Typography textAlign={'center'} variant='body1' fontWeight={500} fontFamily={'Plus Jakarta Sans'}>
          This is your hub for creating and managing manual simulation campaigns. Here, you can activate or archive
          campaigns and track real-time metrics, including the Compromise Rate for each campaign. This screen ensures
          you have full control over your phishing simulations and their outcomes.
        </Typography>
      </Box>
      <NewCampaignButton variant={'outlined'} />
    </Container>
  )
}
