import React, { useRef, useEffect } from 'react'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import { useOnScreen } from 'src/hooks'

export const LoadNextPage = ({ onHandleNextPage }) => {
  const ref = useRef(null)
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    if (isVisible) {
      onHandleNextPage()
    }
  }, [isVisible])

  return (
    <div ref={ref} className='column column-center'>
      <LoadingIndicator size='small' />
    </div>
  )
}
