import React from 'react'
import { TableHead, TableRow, TableCell, TableSortLabel, Box } from '@mui/material'
import CustomCheckbox from '../CustomCheckbox'

export default function DataTableHeader({
  config,
  order,
  orderBy,
  onRequestSort,
  selectionEnabled,
  isAllSelected,
  toggleSelectAll
}) {
  const handleSort = property => {
    const isAsc = orderBy === property && order === 'asc'
    onRequestSort(property, isAsc ? 'desc' : 'asc')
  }

  return (
    <TableHead sx={{ borderRadius: 12 }}>
      <TableRow>
        {selectionEnabled && (
          <TableCell
            size={'small'}
            sx={{
              left: 0,
              right: 0,
              zIndex: 12,
              background: 'rgba(239,240,251,255)',
              color: '#76767F',
              fontSize: '14px',
              fontWeight: 600,
              height: '22px',
              '& .MuiTableCell-root': {
                padding: '0px'
              }
            }}
            padding='checkbox'
          >
            <CustomCheckbox type='checkbox' checked={isAllSelected} onChange={e => toggleSelectAll(e.target.checked)} />
          </TableCell>
        )}
        {config.map(col => (
          <TableCell
            size={'small'}
            key={col.field}
            align={col.align || 'center'}
            sx={{
              verticalAlign: 'top',
              left: col.stickyLeft ? 0 : undefined,
              right: col.stickyRight ? 0 : undefined,
              maxWidth: col.maxWidth || undefined,
              minWidth: col.minWidth || undefined,
              width: col.width || undefined,
              zIndex: col.stickyLeft || col.stickyRight ? 12 : 1,
              background: 'rgba(239,240,251,255)',
              color: '#76767F',
              fontSize: '14px',
              borderBottom: 'none',
              fontWeight: 600,
              height: '22px',
              padding: '6px 12px',

              '&:first-child': {
                borderBottomLeftRadius: 12
              },
              '&:last-child': {
                borderTopRightRadius: 12,
                borderBottomRightRadius: 12
              }
            }}
          >
            <Box
              width={col.width || '100%'}
              onClick={() => col.sortable && handleSort(col.field)}
              sx={{
                cursor: col.sortable ? 'pointer' : 'default',
                display: 'flex',
                alignContent: col.align || 'center'
              }}
            >
              {col.headerName}
              {col.sortable && (
                <TableSortLabel
                  active={orderBy === col.field}
                  direction={orderBy === col.field ? order : 'asc'}
                  onClick={() => handleSort(col.field)}
                />
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
