import React from 'react'
import { Field } from 'react-final-form'
import ClearableInput from '../../../common/components/ClearableInput'
import { Box } from '@mui/material'
import FormFieldTitle from './FormFieldTitle'
import TrainingsOptions from './TrainingsOptions'

const inputStyles = {
  height: 60,
  backgroundColor: '#fff',
  borderRadius: '14px',
  '& .MuiInputBase-root': { borderRadius: '14px', border: 'none' },
  '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
}

export default function TrainingInfo() {
  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' flexDirection='column' rowGap={2}>
        <FormFieldTitle title='Training info' />
        <Field name='name'>
          {({ input }) => <ClearableInput variant='outlined' sx={inputStyles} {...input} placeholder='Campaign Name' />}
        </Field>
        <Field name='description'>
          {({ input }) => <ClearableInput sx={inputStyles} {...input} placeholder='Description' />}
        </Field>
        <TrainingsOptions />
      </Box>
    </Box>
  )
}
