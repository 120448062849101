import React from 'react'
import { Box } from '@mui/material'
import EditorInput from './EditorInput'
import EditorSelect from './EditorSelect'
import { useGetCustomTemplatesDomainsQuery } from '../../../../api/simulation/simulation'
import LoadingIndicator from '../../../../common/components/LoadingIndicator'

export default function EditorEmailRow() {
  const { data, isLoading } = useGetCustomTemplatesDomainsQuery()

  const options = data?.map(domain => ({
    value: domain,
    label: '@' + domain
  }))
  if (isLoading)
    return (
      <Box display={'flex'} justifyContent={'center'} rowGap={2} width={'100%'}>
        <LoadingIndicator />
      </Box>
    )
  return (
    <Box display={'flex'} rowGap={2} width={'100%'} justifyContent={'space-between'}>
      <Box display={'flex'} flexDirection={'column'} width={'60%'}>
        <EditorInput name={'emailPrefix'} label={'Sender Email Name'} validate={emailPrefixValidation} />
      </Box>
      <Box display={'flex'} flexDirection={'column'} width={'30%'}>
        <EditorSelect
          name={'emailDomain'}
          label={'Select Domain'}
          validate={values => (!values ? 'Domain is required' : undefined)}
          options={options || []}
        />
      </Box>
    </Box>
  )
}

function emailPrefixValidation(value) {
  if (!value) return 'Sender email name is required'
  if (!/^[a-zA-Z0-9_.-]*$/.test(value)) return 'Invalid email name'
  return undefined
}
