import React from 'react'
import { toast } from 'react-toastify'
import { Box } from '@mui/material'

export default function customToastWithAlert({ message, type }) {
  const toastStyle = {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    flexWrap: 'wrap',
    wordBreak: 'break-word'
  }

  return toast(<Box sx={toastStyle}>{message}</Box>, {
    type,
    autoClose: 5000,
    hideProgressBar: false,
    position: 'bottom-center',
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark'
  })
}
