import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import workflowsService from 'src/services/workflows.service'

import './styles.css'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import { PeriodPicker } from '../dashboard-new/components/periodPicker'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { SortHeader } from '.'
import { actionOptions } from './components/WorkflowCreate'
import CustomTablePagination from 'src/common/components/DataTable/CustomTablePagination'

// const RowsPerPage = ({ perPage, onChange }) => {
//   const [open, setOpen] = useState(false)
//   const options = [50, 150, 200, 250]
//   return (
//     <div style={{ cursor: 'pointer', position: 'relative' }}>
//       <div onClick={() => setOpen(!open)} className='row row-center'>
//         <div className='row row-center' style={{ background: '#1B1B2612', padding: '4px 8px', borderRadius: 8, marginRight: 8 }}><p style={{ fontSize: 12, fontWeight: 500 }}>{perPage}</p>
//           <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
//             <path d='M3 5.33337L8 10.3334L13 5.33337' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
//           </svg>
//         </div>
//         <p
//           style={{
//             color: '#76767F',
//             fontSize: '13px',
//             marginRight: '5px'
//           }}
//         >
//           Logs per page
//         </p>

//       </div>
//       {open && (
//         <div className='poagintion-popup' style={{ bottom: 0 }}>
//           {options.map(el => (
//             <div
//               key={el}
//               className={`poagintion-popup-el ${perPage == el && 'poagintion-popup-el-active'}`}
//               onClick={() => {
//                 onChange({ size: parseInt(el) })
//                 setOpen(false)
//               }}
//             >
//               {el}
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   )
// }
export const Paginator = ({ page = 0, onChange, total, perPage }) => {
  const pageChange = e => {
    onChange({ page: e })
  }
  const perPageChange = e => {
    onChange({ page: 0, size: e })
  }

  return (
    <div style={{ cursor: 'pointer', position: 'relative', width: '100%' }}>
      <CustomTablePagination
        totalCount={total}
        page={page}
        rowsPerPage={perPage}
        onRequestPageChange={pageChange}
        onRequestRowsPerPageChange={perPageChange}
      />

      {/* <div className='row row-center'>
        <p
          style={{
            color: '#76767F',
            fontSize: '13px',
            marginRight: '5px'
          }}
        >
          Page
        </p>
        <div onClick={() => setOpen(!open)} className='row row-center' style={{ background: '#1B1B2612', padding: '4px 8px', borderRadius: 8, marginRight: 8 }}><p style={{ fontSize: 12, fontWeight: 500 }}>{page + 1} of {Math.ceil(total / perPage)} </p>
          <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M3 5.33337L8 10.3334L13 5.33337' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        </div>

        <div onClick={() => { page !== 0 && onChange({ page: parseInt(page - 1) }) }} style={{ padding: '3px 18px', borderRadius: 6, border: '1px solid #E7E7EEA6', background: '#fff', cursor: 'pointer', marginRight: 10 }}>
          <svg width="6" height="13" viewBox="0 0 6 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1751_54647)">
              <path d="M1.23796 6.57141L4.57129 2.82141L4.57129 10.3214L1.23796 6.57141Z" fill="#1B1B26" />
            </g>
            <defs>
              <clipPath id="clip0_1751_54647">
                <rect width="12.8571" height="5.71429" fill="white" transform="matrix(0 -1 -1 0 6 13)" />
              </clipPath>
            </defs>
          </svg>

        </div>
        <div onClick={() => { page !== (pagesCount - 1) && onChange({ page: parseInt(page + 1) }) }} style={{ padding: '3px 18px', borderRadius: 6, border: '1px solid #E7E7EEA6', background: '#fff', cursor: 'pointer' }}>
          <svg width="6" height="13" viewBox="0 0 6 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1751_54650)">
              <path d="M4.76204 6.57141L1.42871 2.82141L1.42871 10.3214L4.76204 6.57141Z" fill="#1B1B26" />
            </g>
            <defs>
              <clipPath id="clip0_1751_54650">
                <rect width="12.8571" height="5.71429" fill="white" transform="matrix(0 -1 1 0 0 13)" />
              </clipPath>
            </defs>
          </svg>

        </div>

      </div>
      {open && (
        <div className='poagintion-popup' style={{ bottom: 0 }}>
          {options.map(el => (
            <div
              key={el}
              className={`poagintion-popup-el ${page == el && 'poagintion-popup-el-active'}`}
              onClick={() => {
                onChange({ page: parseInt(el) })
                setOpen(false)
              }}
            >
              {el + 1}
            </div>
          ))}
        </div>
      )} */}
    </div>
  )
}

const WorkflowLogs = () => {
  dayjs.extend(utc)
  // const [timePeriod, setTimePeriod] = useContext(DashBoardDateContext)
  // setTimePeriod(getPeriod(dayjs(), MODES.monthly))

  const navigate = useNavigate()
  const [items, setItems] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)

  const [sortBy, setSortBy] = useState({
    name: 'Time & date',
    asc: false
  })
  const today = dayjs()

  const defaultFilters = {
    page: 0,
    size: 25,
    sort: 'actionAt,desc',
    timePeriod: 'CUSTOM',
    startDate: today.startOf('month').format('YYYY-MM-DD'),
    endDate: today.endOf('month').format('YYYY-MM-DD')
  }

  const [filters, setFilters] = useState(defaultFilters)
  const handleFilter = param => {
    const newParam = param?.status ? { status: param.status.toUpperCase() } : param?.sort ? { sort: param.sort } : param
    setFilters({ ...filters, ...newParam })
  }

  const handleSort = field => {
    const fieldset = {
      'Time & date': 'actionAt',
      User: 'userFirstName',
      Action: 'workflowActionType',
      Trigger: 'workflowTriggerName',
      Workflow: 'workflowName'
    }

    const fieldName = fieldset[field]
    setSortBy({
      name: field,
      asc: field == sortBy?.name ? !sortBy.asc : true
    })

    handleFilter({ sort: `${fieldName},${field == sortBy?.name ? (sortBy.asc ? 'desc' : 'asc') : 'asc'}` })
  }

  useEffect(() => {
    setLoading(true)
    workflowsService
      .getWorkflowsLogs(filters)
      .then(resp => {
        setLoading(false)
        setItems(resp.data)
        setTotal(parseInt(resp.headers['x-total-count']))
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }, [filters])

  return (
    <div style={{ background: '#F8F8FB', height: 'calc(100vh)', position: 'relative' }}>
      <div className='container-1089 column' style={{ paddingTop: 32, height: 'calc(100vh - 55px)' }}>
        <div
          className='row row-center'
          onClick={() => navigate('/workflows')}
          style={{ marginBottom: 14, cursor: 'pointer' }}
        >
          <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M16 8.25H1.75' stroke='#6123FF' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <path
              d='M8.5 15L1.75 8.25L8.5 1.5'
              stroke='#6123FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <p style={{ color: '#6123FF', marginLeft: 8 }}>Back to Workflows</p>
        </div>

        <div className='row row-center'>
          <h1 style={{ fontSize: 36, margin: 0 }}>Total historical logs</h1>
          <PeriodPicker onChange={handleFilter} />
        </div>

        <div
          className='row row-center '
          style={{ background: '#232EFF08', padding: 16, borderRadius: 12, marginTop: 30 }}
        >
          <SortHeader name='Time & date' sort={sortBy} onSort={handleSort} width={'156px'} />
          <SortHeader name='User' sort={sortBy} onSort={handleSort} width={'208px'} />
          <SortHeader name='Trigger' sort={sortBy} onSort={handleSort} width={'260px'} />
          <SortHeader name='Action' sort={sortBy} onSort={handleSort} width={'208px'} />
          <SortHeader name='Workflow' sort={sortBy} onSort={handleSort} width={'156px'} />
        </div>
        {loading ? (
          <LoadingIndicator />
        ) : items && items.length == 0 ? (
          <div className='row row-center' style={{ flex: 1, justifyContent: 'center' }}>
            <p style={{ opacity: 0.5, fontSize: 20 }}>no actions yet</p>
          </div>
        ) : (
          <div className='logs-list' style={{ flex: 1, overflow: 'auto' }}>
            {items.map(el => (
              <div
                key={el.id}
                className='row'
                style={{ width: '100%', borderBottom: '1px solid #E7E8EA', padding: '16px 16px 26px 16px' }}
              >
                <div className='column' style={{ width: '156px' }}>
                  <p style={{ fontWeight: 600, fontSize: 14 }}>{dayjs.utc(el.actionAt).local().format('HH:mm')}</p>
                  <p style={{ fontSize: 13 }}>{dayjs.utc(el.actionAt).local().format('DD MMM YYYY')}</p>
                </div>
                <div className='column' style={{ width: '208px', paddingRight: 8 }}>
                  <p className='text-shrink-1' style={{ fontWeight: 600, fontSize: 14 }}>
                    {el.userFirstName} {el.userLastName}
                  </p>
                  <p className='text-shrink-1' style={{ fontSize: 13 }}>
                    {el.userEmail}
                  </p>
                </div>
                <div className='column' style={{ width: '260px', paddingRight: 8 }}>
                  <p className='text-shrink-2' style={{ fontWeight: 600, fontSize: 14 }}>
                    {el.workflowTriggerName}
                  </p>
                </div>
                <div className='column' style={{ width: '208px', alignItems: 'flex-start' }}>
                  <div style={{ fontWeight: 600, fontSize: 14 }}>
                    {el.workflowActionType !== null && (
                      <span style={{ background: '#D9F4F7', padding: '5px 10px', borderRadius: 6, fontSize: 13 }}>
                        {actionOptions[el.workflowActionType].name}
                      </span>
                    )}
                  </div>
                </div>
                <div className='column' style={{ width: '156px' }}>
                  <p className='text-shrink-2' style={{ fontSize: 13, color: '#6123FF' }}>
                    {el.workflowName}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', background: '#EFEFF6' }}>
        <div className='container-1089' style={{}}>
          <div className='row row-center' style={{ width: '100%' }}>
            <Paginator total={total} perPage={filters.size} page={filters.page} onChange={handleFilter} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkflowLogs
