import { Box, Typography } from '@mui/material'
import React from 'react'

export default function CompromiseRateLabel({ simulationsFailRate }) {
  return (
    <Box
      display={'flex'}
      height={'28px'}
      width={'200px'}
      alignItems={'center'}
      bgcolor={'#F0E7F9'}
      borderRadius={'8px'}
      padding={'4px 14px'}
    >
      <Typography fontSize={'14px'} fontWeight={600} fontFamily={'Plus Jakarta Sans'} color={'#1B1B26'}>
        {simulationsFailRate}% Compromise rate
      </Typography>
    </Box>
  )
}
