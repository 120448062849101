import React from 'react'
import { Button } from '@mui/material'

export function PreviousPageButton(props) {
  return (
    <Button
      sx={{
        border: '1px solid #D0D0D8',
        color: '#1B1B26',
        textTransform: 'unset',

        mr: '24px',
        borderRadius: '12px',
        width: '148px'
      }}
      {...props}
    >
      Previous
    </Button>
  )
}

export function NextPageButton(props) {
  return (
    <Button
      sx={{
        border: '1px solid #D0D0D8',
        color: '#1B1B26',
        textTransform: 'unset',

        borderRadius: '12px',
        width: '148px'
      }}
      {...props}
    >
      Next
    </Button>
  )
}
