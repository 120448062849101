import React, { useContext, useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'

import { styled } from '@mui/material/styles'

import { Letter } from 'react-letter'

import ThreatListItem, { THREAT_TYPES } from './components/ThreatListItem'

import './styles.css'
import { Button, Dialog, DialogContent, DialogTitle, Divider, Skeleton, Tooltip } from '@mui/material'
import Spacer from 'src/common/components/Spacer'
import DeleteIcon from 'src/common/icons/deleteIcon'
import RadioSelect from 'src/common/components/RadioSelect'
import MarkAsSelect from 'src/common/components/MarkAsSelect'
import DeleteDialog from 'src/common/components/DeleteDialog'
import threatsService from 'src/services/threats.service'
import dayjs from 'dayjs'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import { SnackBarContext, UserViewContext } from 'src/App'
import utc from 'dayjs/plugin/utc'
import userService from 'src/services/user.service'

const SearchInput = styled(TextField)(() => ({
  marginBottom: '20px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    border: '1px solid #E7E7EE',
    background: '#fff'
  }
}))

const threatsActions = {
  REPLIED: 'Replied to the email',
  DOWNLOADED: 'Downloaded a file',
  OPENED: 'Opened an attachment',
  VISITED: 'Followed a link',
  ENTERED: 'Entered my password',
  FORWARDED: 'Forwarded this email'
}

const Threats = () => {
  dayjs.extend(utc)
  const [selectedId, setSelectedId] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [newMerkAs, setNewMerkAs] = useState(null)
  const [markAs, setMarkAs] = useState(null)
  const [openMarkDialog, setOpenMarkDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [data, setData] = useState(null)
  const [dataSorted, setDataSorted] = useState(null)
  const [messageFromAdmin, setMessageFromAdmin] = useState('')

  const { setSuccessMessage, setInfoMessage } = useContext(SnackBarContext)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingList, setIsLoadingList] = useState(true)

  const [search, setSearch] = useState('')

  const [, setUserViewId] = useContext(UserViewContext)

  const handleOpenUser = () => {
    userService
      .getUser(selectedItem?.userId)
      .then(() => {
        setUserViewId(selectedItem?.userId)
      })
      .catch(e => {
        console.error(e)
        setInfoMessage('User not found')
      })
  }

  const handleMarkAs = as => {
    setMarkAs(as)
    setNewMerkAs(as)
    setOpenMarkDialog(true)
  }

  const getList = () => {
    return threatsService
      .getThreats()
      .then(resp => {
        setData(resp.data)
        setDataSorted(resp.data)
        setIsLoadingList(false)
      })
      .catch(err => {
        console.error(err)
        setIsLoadingList(false)
      })
  }

  useEffect(() => {
    getList()
  }, [])

  useEffect(() => {
    if (selectedId) {
      setIsLoading(true)
      threatsService
        .getThreat(selectedId)
        .then(data => {
          setIsLoading(false)

          setSelectedItem(data.data)
          setMarkAs(data.data?.markAs)
          setNewMerkAs(data.data?.markAs)
        })
        .catch(e => {
          setIsLoading(false)
          console.error(e)
        })
    }
  }, [selectedId])

  const handleDelete = () => {
    threatsService.delete(selectedId).then(() => {
      setSelectedId(null)
      setShowDeleteDialog(false)
      getList()
      setSuccessMessage('Threat successfully deleted')
    })
  }
  const handleUpdate = id => {
    threatsService.update(id, { markAs: markAs, messageFromAdmin: messageFromAdmin }).then(resp => {
      setSelectedItem(resp.data)
      setMarkAs(resp.data?.markAs)
      setNewMerkAs(resp.data?.markAs)
      setOpenMarkDialog(false)
      setMessageFromAdmin('')
      getList()
      setSuccessMessage('Marked as ' + markAs.toLowerCase())
    })
  }

  const handleSortThreats = e => {
    if (e == 'All reported emails') {
      setDataSorted(data)
    } else {
      setDataSorted(data.filter(el => !el.markAs))
    }
  }

  const resetMarkAs = () => {
    setMarkAs(selectedItem?.markAs)
    setNewMerkAs(selectedItem?.markAs)
  }

  return (
    <>
      <div className='row' style={{ position: 'relative' }}>
        {isLoadingList && (
          <div
            style={{
              background: '#ffffff80',
              position: 'absolute',
              top: '0px',
              left: '0px',
              height: '100vh',
              width: '100%',
              zIndex: 1000
            }}
            className='column column-center'
          >
            <LoadingIndicator />
          </div>
        )}
        <div style={{ width: '380px', padding: '24px' }}>
          <div className='row row-center' style={{ height: 63 }}>
            <h1 style={{ margin: 0, fontSize: 24 }}>Threats</h1>
            <Spacer />
            <RadioSelect
              options={['All reported emails', 'Unsorted Emails']}
              value={'All reported emails'}
              onSelectOption={handleSortThreats}
            />
          </div>
          <SearchInput
            fullWidth
            sx={{ boxSizing: 'border-box' }}
            size='small'
            placeholder='Search'
            onChange={e => setSearch(e.target.value)}
            value={search}
            variant='outlined'
          />
          {isLoadingList ? (
            <div className='threats-list'></div>
          ) : dataSorted?.length ? (
            <div className='threats-list'>
              {dataSorted
                .filter(el => el.subject.toLowerCase().includes(search.toLowerCase()))
                .map(el => (
                  <ThreatListItem
                    search={search}
                    selected={selectedId == el.id}
                    onSelect={setSelectedId}
                    key={el.id}
                    item={el}
                  />
                ))}
            </div>
          ) : (
            <div
              className='column threats-list'
              style={{
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <svg width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  opacity='0.2'
                  d='M45 112.5L52.5 75L22.5 63.75L75 7.5L67.5 45L97.5 56.25L45 112.5Z'
                  fill='#E7E7EE'
                />
                <path
                  d='M45 112.5L52.5 75L22.5 63.75L75 7.5L67.5 45L97.5 56.25L45 112.5Z'
                  stroke='#E7E7EE'
                  strokeWidth='5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <p
                style={{
                  textAlign: 'center',
                  lineHeight: '22px',
                  color: '#76767F'
                }}
              >
                No one was found for your search.
                <br />
                Try changing your query
              </p>
            </div>
          )}
        </div>
        <div
          style={{
            flex: '1',
            backgroundColor: '#F8F8FB',
            borderLeft: '1px solid #E7E7EE',
            width: 'calc(100% - 430px)'
          }}
          className='column'
        >
          {selectedId ? (
            <>
              <div className='row row-center'>
                <MarkAsSelect onSelectOption={handleMarkAs} option={newMerkAs || selectedItem?.markAs} />
                <Spacer />
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={() => setShowDeleteDialog(true)}
                  sx={{
                    borderRadius: '12px',
                    color: '#B40020',
                    textTransform: 'none',
                    mr: '20px'
                  }}
                >
                  Delete
                </Button>
              </div>
              <div
                className='column'
                style={{
                  padding: '0px 32px 0px 32px',
                  height: 'calc(100vh - 100px)',
                  overflowY: 'auto'
                }}
              >
                <Tooltip title={selectedItem?.subject || ''} arrow>
                  <h2 className='threat-details-title text-shrink-2'>{selectedItem?.subject}</h2>
                </Tooltip>
                <Divider
                  style={{
                    marginTop: '18px',
                    marginBottom: '24px'
                  }}
                />

                <div className='reported-by'>
                  <div className='column'>
                    <div className='row row-center'>
                      <div className='row row-center'>
                        <div className='reported-by-avatar'>
                          {/* <img width={'100%'} src="https://image.pngaaa.com/730/4806730-middle.png" alt="" /> */}
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              opacity='0.2'
                              d='M12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15Z'
                              fill='#1B1B26'
                            />
                            <path
                              d='M12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15Z'
                              stroke='#1B1B26'
                              strokeMiterlimit='10'
                            />
                            <path
                              d='M2.90625 20.2491C3.82834 18.6531 5.1542 17.3278 6.75064 16.4064C8.34708 15.485 10.1579 15 12.0011 15C13.8444 15 15.6552 15.4851 17.2516 16.4065C18.848 17.3279 20.1739 18.6533 21.0959 20.2493'
                              stroke='#1B1B26'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                        <div onClick={handleOpenUser} className='column pointer'>
                          <p className='reported-by-title'>
                            {' '}
                            {isLoading ? <Skeleton width={100} /> : selectedItem?.userFullName}
                          </p>
                          {isLoading ? (
                            <Skeleton width={150} />
                          ) : (
                            <p className='reported-by-description'>
                              Reported by:{' '}
                              <span
                                style={{
                                  color: '#274AF2'
                                }}
                              >
                                {selectedItem?.to}
                              </span>
                            </p>
                          )}
                        </div>
                      </div>
                      <Spacer style={{ minWidth: '30px' }} />
                      <p className='reported-by-dateTime'>
                        {isLoading ? (
                          <Skeleton width={100} />
                        ) : (
                          dayjs.utc(selectedItem?.actionAt).local().format('D MMM HH:mm YYYY')
                        )}
                      </p>
                    </div>
                    {selectedItem?.actions?.length ? (
                      <>
                        <Divider
                          style={{
                            margin: '20px 0px'
                          }}
                        />
                        <div className=''>
                          {isLoading ? (
                            <Skeleton width={100} />
                          ) : (
                            selectedItem.actions.map((el, index) => (
                              <p key={el}>
                                {index + 1}.{' '}
                                <span
                                  style={{
                                    color: '#B40020'
                                  }}
                                >
                                  {threatsActions[el]}
                                </span>
                              </p>
                            ))
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <Divider
                          style={{
                            margin: '20px 0px'
                          }}
                        />
                        <div>{isLoading ? <Skeleton width={100} /> : <p>1. Opened email</p>}</div>
                      </>
                    )}
                  </div>
                </div>

                <div
                  className='column'
                  style={{
                    padding: '24px 24px 0px 24px',
                    background: '#ffffff',
                    marginTop: '20px',
                    borderRadius: '10px 10px 0px 0px'
                  }}
                >
                  <div className='row row-center'>
                    <div className='row row-center'>
                      <div className='column'>
                        {/* <p className='reported-by-title' >{isLoading ? <Skeleton width={120} /> : selectedItem?.userFullName}</p> */}
                        {isLoading ? (
                          <Skeleton width={100} />
                        ) : (
                          <p className='reported-by-description'>
                            Reported email:{' '}
                            <span
                              style={{
                                color: '#274AF2'
                              }}
                            >
                              {selectedItem?.from}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <Divider style={{ marginTop: '20px' }} />
                </div>
                <div className='column threat-details-template-preview'>
                  {isLoading ? (
                    <>
                      <Skeleton width={100} />
                      <Skeleton width={140} />
                      <Skeleton width={120} />
                      <Skeleton width={130} />
                      <Skeleton width={110} />
                    </>
                  ) : (
                    <div
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      <Letter html={selectedItem?.body} style={{ maxWidth: '1px' }} className='letteerr' />
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : isLoadingList ? null : (
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%'
              }}
              className='column'
            >
              <svg width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M105 26.25L60 67.5L15 26.25H105Z' fill='#E7E7EE' />
                <path
                  d='M15 26.25H105V90C105 90.9946 104.605 91.9484 103.902 92.6517C103.198 93.3549 102.245 93.75 101.25 93.75H18.75C17.7554 93.75 16.8016 93.3549 16.0984 92.6517C15.3951 91.9484 15 90.9946 15 90V26.25Z'
                  stroke='#E7E7EE'
                  strokeWidth='5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M51.8142 60L16.1523 92.6902'
                  stroke='#E7E7EE'
                  strokeWidth='5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M103.846 92.6904L68.1836 60'
                  stroke='#E7E7EE'
                  strokeWidth='5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path d='M106 24V24.9202L60 67.25L14 24.9202V24' fill='#E7E7EE' />
              </svg>
              <p style={{ color: '#76767F' }}>No message selected</p>
            </div>
          )}
        </div>
      </div>

      <Dialog
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '22px',
            paddingTop: '8px',
            maxWidth: 'unset',
            width: '684px'
          }
        }}
        open={openMarkDialog}
        onClose={() => {
          setOpenMarkDialog(false)
          resetMarkAs()
          setMessageFromAdmin('')
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Satoshi',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '35px',
            lineHeight: '110%',
            color: '#1B1B26'
          }}
        >
          Mark as {markAs && markAs.toLowerCase()}
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              fontSize: '14px',
              marginBottom: '32px',
              lineHeight: '22px'
            }}
          >
            {markAs == THREAT_TYPES.fraudlent &&
              'If you consider this email uninvited and unwanted, you may mark it as fraudulent, and the employees who reported this email will be notified automatically'}
            {markAs == THREAT_TYPES.spam &&
              'If you consider this email uninvited and unwanted, you may mark it as spam, and the employees who reported this email will be notified automatically'}
          </p>
          <TextField
            multiline
            fullWidth
            rows={5}
            value={messageFromAdmin}
            onChange={e => setMessageFromAdmin(e.target.value)}
            placeholder='Message (optional)'
          />
          {/* <TextareaAutosize fullWidth /> */}
          <p
            style={{
              fontSize: '13px',
              color: '#76767F',
              margin: '16px 0px 16px 8px'
            }}
          >
            Please enter the details of your report
          </p>
          <div className='row'>
            <Button
              sx={{
                padding: '10px 0px',
                fontFamily: 'Plus Jakarta Sans',
                textTransform: 'none',
                borderRadius: '8px',
                borderColor: '#D0D0D8',
                color: '#1B1B26'
              }}
              variant={'outlined'}
              fullWidth
              onClick={() => {
                setOpenMarkDialog(false)
                resetMarkAs()
                setMessageFromAdmin('')
              }}
            >
              Cancel
            </Button>
            <div style={{ width: '20px' }}></div>
            <Button
              sx={{
                padding: '10px 0px',
                fontFamily: 'Plus Jakarta Sans',
                textTransform: 'none',
                borderRadius: '8px',
                backgroundColor: '#6123FF'
              }}
              variant={'contained'}
              fullWidth
              onClick={() => handleUpdate(selectedId)}
            >
              Mark as {markAs && markAs.toLowerCase()}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <DeleteDialog
        str={selectedItem?.subject}
        open={showDeleteDialog}
        onAction={handleDelete}
        onClose={() => setShowDeleteDialog(false)}
      />
    </>
  )
}

export default Threats
