import { useState } from 'react'
import { useEffect } from 'react'
import workflowsService from 'src/services/workflows.service'

export function useWorkflows() {
  const [item, setItem] = useState(null)
  const [lastLog, setLastLog] = useState(null)
  const [items, setItems] = useState([])
  const [openCreate, setOpenCreate] = useState(false)
  const [loading, setLoading] = useState(true)

  const defaultFilters = {
    page: 0,
    size: 50
  }

  const [filters, setFilters] = useState(defaultFilters)
  const handleFilter = param => {
    const newParam = param?.status ? { status: param.status.toUpperCase() } : param?.sort ? { sort: param.sort } : param
    setFilters({ ...filters, ...newParam })
  }

  const handleUpdate = () => {
    !loading && setLoading(true)

    workflowsService
      .getWorkflowsLastLog()
      .then(resp => {
        setLastLog(resp.data)
      })
      .catch(err => {
        console.error(err)
      })

    workflowsService
      .getWorkflows(filters)
      .then(resp => {
        setLoading(false)
        setItems(resp.data)
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    handleUpdate()
  }, [filters])

  return {
    item,
    setItem,
    lastLog,
    loading,
    openCreate,
    setOpenCreate,
    items,
    setItems,
    handleUpdate,
    handleFilter
  }
}
