import React from 'react'
import ProgressCard from './ProgressCard'
import { useGetSimulationStatsQuery } from '../../../../api/simulation/simulation'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'

const Dashboard = () => {
  const { id } = useParams()
  const { data } = useGetSimulationStatsQuery({ id })

  const stats = [
    { value: data?.totalCount, label: 'Total Sent', total: 0 },
    {
      value: data?.openedCount,
      label: 'Opened',
      total: data?.totalCount,
      color: '#0FA9FF'
    },
    {
      value: data?.phishedCount,
      label: 'Phished',
      total: data?.totalCount,
      color: '#F16A82'
    },
    {
      value: data?.reportedCount,
      label: 'Reported',
      total: data?.totalCount,
      color: '#34D9E9',
      benchmark: (data?.totalCount * 0.6).toFixed(0)
    }
  ]

  return (
    <Box display={'flex'} justifyContent={'space-between'} pt={'24px'}>
      {stats?.map((item, index) => (
        <ProgressCard
          key={index}
          value={item.value}
          label={item.label}
          total={item.total}
          color={item.color}
          benchmark={item.benchmark}
        />
      ))}
    </Box>
  )
}

export default Dashboard
