import React from 'react'

export const TimelineCell = ({ value }) => (
  <div
    className='row row-center'
    style={{
      padding: '6px 14px',
      border: '2px solid #EDF1FFB2',
      borderRadius: 8
    }}
  >
    <svg
      style={{ marginRight: 6 }}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M11 9.5L14 6.5L11 3.5' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M12 13.5H2.5C2.36739 13.5 2.24021 13.4473 2.14645 13.3536C2.05268 13.2598 2 13.1326 2 13V5.5'
        stroke='#1B1B26'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.6875 11C5.0205 9.71201 5.77193 8.57116 6.82375 7.75665C7.87556 6.94213 9.16815 6.5001 10.4985 6.5H13.9985'
        stroke='#1B1B26'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
    <p style={{ fontSize: 14 }}>{value}</p>
  </div>
)
