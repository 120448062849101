import React from 'react'
import GroupUserSelection from '../GroupUserSelection/GroupUserSelection'
import { toggleElement } from '../../../heplers'

export default function GroupUserSelectionField({ input }) {
  const { value, onChange } = input

  const handleGroupSelect = (id, bulk) => {
    if (Array.isArray(id)) {
      let newItems = value.groupIds || []
      if (typeof bulk !== 'undefined') {
        id.forEach(element => {
          newItems = bulk
            ? newItems.includes(element)
              ? newItems.filter(el => el !== element)
              : newItems
            : [...newItems, element]
        })
      }
      onChange({ ...value, groupIds: newItems })
    } else {
      onChange({ ...value, groupIds: toggleElement(value.groupIds, id) })
    }
  }

  const handleUserSelect = (id, bulk) => {
    if (Array.isArray(id)) {
      let newItems = value.userIds || []
      if (typeof bulk !== 'undefined') {
        id.forEach(element => {
          newItems = bulk
            ? newItems.includes(element)
              ? newItems.filter(el => el !== element)
              : newItems
            : [...newItems, element]
        })
      }
      onChange({ ...value, userIds: newItems })
    } else {
      onChange({ ...value, userIds: toggleElement(value.userIds, id) })
    }
  }

  return (
    <GroupUserSelection
      selectedGroups={value.groupIds}
      selectedUsers={value.userIds}
      onSelectGroup={handleGroupSelect}
      onSelectUser={handleUserSelect}
    />
  )
}
