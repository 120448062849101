import React from 'react'

export const PhishingSimStatus = ({ status }) => {
  switch (status) {
    case 'PHISHED':
      return (
        <div style={{ width: '20%' }} className='row row-center'>
          <svg
            style={{ marginRight: 6 }}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              opacity='0.06'
              d='M3.75 10V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5V10C20.25 17.8764 13.5651 20.486 12.2303 20.9285C12.0811 20.9798 11.9189 20.9798 11.7697 20.9285C10.4349 20.486 3.75 17.8764 3.75 10Z'
              fill='#B40020'
            />
            <path
              d='M3.75 10V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5V10C20.25 17.8764 13.5651 20.486 12.2303 20.9285C12.0811 20.9798 11.9189 20.9798 11.7697 20.9285C10.4349 20.486 3.75 17.8764 3.75 10Z'
              stroke='#B40020'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>

          <p style={{ color: '#B40020' }}>Phished</p>
        </div>
      )

    case 'REPORTED':
      return (
        <div style={{ width: '20%' }} className='row row-center'>
          <svg
            style={{ marginRight: 6 }}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              opacity='0.1'
              d='M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z'
              fill='#00BC62'
            />
            <path
              d='M12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25Z'
              stroke='#00BC62'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path d='M12 1.875V5.625' stroke='#00BC62' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M1.875 12H5.625' stroke='#00BC62' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M12 22.125V18.375' stroke='#00BC62' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M22.125 12H18.375' stroke='#00BC62' strokeLinecap='round' strokeLinejoin='round' />
            <path
              d='M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z'
              stroke='#00BC62'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>

          <p style={{ color: '#00BC62' }}>Reported</p>
        </div>
      )

    case 'SENT':
      return (
        <div style={{ width: '20%' }} className='row row-center'>
          <svg
            style={{ marginRight: 6 }}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M18 6L7 17L6.5 17.5' stroke='#76767F' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M15.75 18H6V8.25' stroke='#76767F' strokeLinecap='round' strokeLinejoin='round' />
          </svg>

          <p style={{ color: '#76767F' }}>Sent</p>
        </div>
      )
    case 'OPENED':
      return (
        <div style={{ width: '20%' }} className='row row-center'>
          <svg
            style={{ marginRight: 6 }}
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path opacity='0.2' d='M13 3L8 8L3 3H13Z' fill='#274af2' />
            <path d='M13 8L8 13L3 8' stroke='#274af2' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M13 3L8 8L3 3H13Z' stroke='#274af2' strokeLinecap='round' strokeLinejoin='round' />
          </svg>

          <p style={{ color: '#274af2' }}>Opened</p>
        </div>
      )

    default:
      return <></>
  }
}
