import React from 'react'
import { useLazyGetStepSimulationStatisticsQuery } from '../../../../api/simulation/simulation'

import NameEmailColumn from '../Activity/NameEmailColumn'
import DateTimeColumn from './DateTimeColumn'
import { Typography } from '@mui/material'

export default function useUsersHistoryTable({ id, stepId }) {
  const [getSimulations, { data, error, isLoading }] = useLazyGetStepSimulationStatisticsQuery()

  const columns = [
    {
      field: 'actionAt',
      headerName: 'Time & date',
      sortable: true,
      minWidth: 110,
      width: 130,
      align: 'left',
      renderComponent: (value, row) => <DateTimeColumn row={row} />
    },

    {
      field: 'firstName',
      headerName: 'User',
      sortable: true,
      align: 'left',
      maxWidth: '60%',
      renderComponent: (value, row) => <NameEmailColumn row={row} />
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      align: 'left',
      minWidth: '20%',
      renderComponent: (value, row) => (
        <Typography noWrap fontFamily={'Plus Jakarta Sans'} fontWeight={500} fontSize={14} color={'#1B1B26'}>
          {statusMapper[row?.status]}
        </Typography>
      )
    }
  ]
  function fetchData({ page, size, sort, params }) {
    getSimulations({ id, stepId, page, size, sort, ...params })
  }

  const statistics = data?.data
  const totalCount = data?.totalCount
  return {
    columns,
    fetchData,
    statistics,
    error,
    totalCount,
    isLoading: isLoading
  }
}
const statusMapper = {
  SENT: 'Sent',
  OPENED: 'Opened',
  PHISHED: 'Phished',
  REPORTED: 'Reported',
  REPLIED: 'Replied',
  LINK_CLICKED: 'Link Clicked',
  ATTACH_LINK_CLICKED: 'Attach opened',
  QR_CODE_LINK_CLICKED: 'QR Code Scanned'
}
