import React from 'react'

import { Field } from 'react-final-form'
import { Box } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import FormFieldTitle from './FormFieldTitle'

import { datePickerProps } from './datePickerProps'
import GenericSelect from '../../../common/components/GenericSelect'

import { deadlineTypeOptions } from './utils'

export default function CompletionDeadline({ values }) {
  return (
    <Box display='flex' flexDirection='column' rowGap={2}>
      <FormFieldTitle title='Employee completion deadline' />
      <Box display='flex' columnGap={2}>
        <Box display='flex' width='50%'>
          <Field
            name='deadlineType'
            render={({ input }) => (
              <GenericSelect
                label={'Type'}
                width={'100%'}
                options={deadlineTypeOptions}
                value={input.value}
                onChange={input.onChange}
              />
            )}
          />
        </Box>
        {values?.deadlineType === 'FIXED_DATE_DEADLINE' && (
          <Box display='flex' width='50%' flexDirection='column' rowGap={2}>
            <Field
              name='deadlineDate'
              render={({ input, meta }) => <DatePicker {...datePickerProps(input, meta, 'Deadline Date')} />}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}
