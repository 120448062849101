import React, { useContext } from 'react'
import { Box, Button, Typography } from '@mui/material'

import { ReactComponent as AIicon } from '../../../common/icons/aiPower.svg'
import { PageNumberContext } from '../../../context/PageNumberContext'
import WrapperInfoCard from './InfoCards/WrapperInfoCard'
import FirstStepCard from './InfoCards/FirstStepCard'
import NavTabs from '../../../common/components/NavTabs'
import { Field } from 'react-final-form'

import AllMicrotrainingsField from './AllMicrotrainings/AllMicrotrainingsField'

export default function SecondPageForm({ values }) {
  const { setPage } = useContext(PageNumberContext)
  const tabsConfig = [
    {
      label: 'All Modules ',
      content: <Field name='trainingModuleIds' component={AllMicrotrainingsField} />,
      value: 0,
      to: ``
    },
    {
      label: 'AI generation',
      content: <div></div>,
      component: () => (
        <Box display={'flex'} alignItems={'center'} columnGap={1}>
          <AIicon />
          <Typography fontFamily={'Plus Jakarta Sans'} fontWeight={600} fontSize={'16px'} color={'#C908D9'}>
            AI generation
          </Typography>
          <Typography fontFamily={'Plus Jakarta Sans'} fontWeight={600} fontSize={'16px'} color={'#C908D9'}>
            (soon)
          </Typography>
        </Box>
      ),
      disabled: true,
      value: `ai-generation`,
      to: 'ai-generation'
    }
  ]
  return (
    <Box display='flex' flexDirection='column' pt={3} rowGap={3} width='100%'>
      <Typography fontFamily={'Satoshi'} fontWeight={500} fontSize={'24px'} color={'#1B1B26'}>
        Training info
      </Typography>
      <WrapperInfoCard onChange={() => setPage(1)}>
        <FirstStepCard />
      </WrapperInfoCard>
      <Typography fontFamily={'Satoshi'} fontWeight={500} fontSize={'24px'} color={'#1B1B26'}>
        Includes Modules
      </Typography>
      <NavTabs tabItems={tabsConfig} />
      <Box
        width='100%'
        display='flex'
        columnGap={2}
        justifyContent='space-between'
        sx={{ position: 'sticky', bottom: 0, background: 'transparent' }}
      >
        <Box sx={{ padding: '16px' }}>
          <Button sx={{ ...buttonStyles, background: '#6123FF' }} autoFocus onClick={() => setPage(1)}>
            Back
          </Button>
        </Box>
        <Box sx={{ padding: '16px' }}>
          <Button
            disabled={isDisabled(values)}
            sx={{ ...buttonStyles, background: !isDisabled(values) && '#6123FF' }}
            autoFocus
            onClick={() => setPage(3)}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
const buttonStyles = {
  borderRadius: '12px',
  fontFamily: 'Plus Jakarta Sans',
  color: '#fff',
  textTransform: 'none',
  border: 'none',
  width: 200,
  '&:hover': {
    background: '#1565c0'
  }
}

function isDisabled(values) {
  return values?.trainingModuleIds.length === 0
}
