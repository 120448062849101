import React, { useState } from 'react'
import { useLazyGetSimulationUsersListQuery } from '../../../../api/simulation/simulation'
import PercentageComponent from '../../SimulationTable/PercentageComponent'
import NameEmailColumn from './NameEmailColumn'
import StatusColumn from './StatusColumn'
import { Box } from '@mui/material'

export default function useSimulationUsersTable({ id }) {
  const [getSimulationUsersList, { data, error, isLoading }] = useLazyGetSimulationUsersListQuery()
  const [open, setOpen] = useState(false)
  const [userId, setUserId] = useState(null)

  function handleOpen(userId) {
    setUserId(userId)
    setOpen(true)
  }
  function handleClose() {
    setUserId(null)
    setOpen(false)
  }
  const columns = [
    {
      field: 'firstName',
      headerName: 'Name',
      sortable: true,
      stickyLeft: true,
      width: 250,
      align: 'left',
      renderComponent: (value, row) => (
        <Box
          display={'flex'}
          flexDirection={'column'}
          width={250}
          onClick={() => handleOpen(row.id)}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#F8F8FB',
              borderRadius: '12px'
            }
          }}
        >
          <NameEmailColumn row={row} />
        </Box>
      )
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      align: 'center',
      width: 190,
      renderComponent: (value, row) => (
        <>
          <StatusColumn row={row} />
        </>
      )
    },
    {
      field: 'simulationsFailRate',
      headerName: 'Compromise Rate (%)',
      sortable: true,
      align: 'left',
      maxWidth: 150,
      renderComponent: (value, row) => <PercentageComponent percentage={row.simulationsFailRate} />
    },

    { field: 'simulationsSentCount', headerName: 'Sent', sortable: true, align: 'left', maxWidth: 120 },
    { field: 'simulationsOpenedCount', headerName: 'Opened', sortable: true, align: 'left', maxWidth: 120 },
    {
      field: 'simulationsLinkClickedCount',
      headerName: 'Links Clicked',
      sortable: true,
      align: 'left',
      maxWidth: 120
    },
    {
      field: 'simulationsAttachmentOpenedCount',
      headerName: 'Attachments Opened',
      sortable: true,
      align: 'left',
      maxWidth: 120
    },
    { field: 'simulationsQrLinkClickedCount', headerName: 'QR Scanned', sortable: true, align: 'left', maxWidth: 120 },
    {
      field: 'simulationsCredentialsProvidedCount',
      headerName: 'Creds Provided',
      sortable: true,
      align: 'left',
      maxWidth: 150
    },
    { field: 'simulationsRepliedCount', headerName: 'Replied', sortable: true, align: 'left', maxWidth: 120 }
  ]
  function fetchData({ page, size, sort, params }) {
    getSimulationUsersList({ id, page, size, sort, ...params })
  }

  const users = data?.data
  const totalCount = data?.totalCount
  return {
    columns,
    fetchData,
    users,
    error,
    totalCount,
    isLoading,
    open,
    userId,
    handleClose
  }
}
