import DoneIcon from '../../../../common/icons/done'
import SpinnerIcon from '../../../../common/icons/spiner'
import Archive from '../../../../common/icons/archive'
import { ReactComponent as PlannedIcon } from '../../../../common/icons/hourglassIcon.svg'
import React from 'react'
import { Box, Typography } from '@mui/material'

export default function StatusColumn({ row }) {
  const Icon = () => iconMapper[row.status]
  return (
    <Box display={'flex'} width={'100%'} alignItems={'flex-start'} rowGap={1}>
      <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} rowGap={1}>
        <div
          style={{
            display: 'flex',
            columnGap: 8,
            alignItems: 'flex-start',
            color: row.status === 'COMPLETED' ? '#00BA97' : row.status === 'IN_PROGRESS' ? '#1B1B26' : '#76767F',
            fontFamily: 'Plus Jakarta Sans',
            fontSize: 14,
            fontWeight: 600
          }}
        >
          <Icon style={{ height: '24px', width: '24px' }} />
          {statusNameMapper[row.status]}
        </div>
        <Typography pl={'28px'} fontSize={'13px'} fontWeight={400} fontFamily={'Plus Jakarta Sans'} clor={'#76767F'}>
          {row.stepsCompletedCount} / {row.stepsTotalCount} steps
        </Typography>
      </Box>
    </Box>
  )
}

export const statusNameMapper = {
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In Progress',
  ARCHIVED: 'Archived',
  PLANNED: 'Planned'
}

export const iconMapper = {
  COMPLETED: <DoneIcon />,
  IN_PROGRESS: <SpinnerIcon />,
  ARCHIVED: <Archive />,
  PLANNED: <PlannedIcon />
}
