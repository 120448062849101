import React, { useState } from 'react'
import './styles.css'
import CyberSwitch from 'src/common/components/Switch'
import DifficultyChip from 'src/common/components/DifficultyChip'
import { Button, Divider, Snackbar } from '@mui/material'
import { useEffect } from 'react'
import templatesService from 'src/services/templates.service'
import { Letter } from 'react-letter'
import Spacer from 'src/common/components/Spacer'
import LoadingIndicator from 'src/common/components/LoadingIndicator'

const SendIcon = ({ disabled }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.2'
      d='M19.7099 3.3641L2.24172 8.29104C2.09476 8.33249 1.96396 8.4178 1.86679 8.53558C1.76962 8.65336 1.71071 8.79799 1.69794 8.95015C1.68517 9.1023 1.71915 9.25473 1.79532 9.38706C1.8715 9.51939 1.98626 9.62531 2.12425 9.69068L10.1503 13.4925C10.3068 13.5666 10.4329 13.6927 10.507 13.8492L14.3088 21.8752C14.3742 22.0132 14.4801 22.128 14.6124 22.2041C14.7447 22.2803 14.8972 22.3143 15.0493 22.3015C15.2015 22.2888 15.3461 22.2299 15.4639 22.1327C15.5817 22.0355 15.667 21.9047 15.7084 21.7578L20.6354 4.28953C20.6716 4.16122 20.6729 4.02558 20.6392 3.89658C20.6056 3.76758 20.5381 3.64988 20.4439 3.55561C20.3496 3.46134 20.2319 3.3939 20.1029 3.36024C19.9739 3.32658 19.8383 3.32791 19.7099 3.3641Z'
      fill={disabled ? '#aaa' : '#6123FF'}
    />
    <path
      d='M19.7099 3.3641L2.24172 8.29104C2.09476 8.33249 1.96396 8.4178 1.86679 8.53558C1.76962 8.65336 1.71071 8.79799 1.69794 8.95015C1.68517 9.1023 1.71915 9.25473 1.79532 9.38706C1.8715 9.51939 1.98626 9.62531 2.12425 9.69068L10.1503 13.4925C10.3068 13.5666 10.4329 13.6927 10.507 13.8492L14.3088 21.8752C14.3742 22.0132 14.4801 22.128 14.6124 22.2041C14.7447 22.2803 14.8972 22.3143 15.0493 22.3015C15.2015 22.2888 15.3461 22.2299 15.4639 22.1327C15.5817 22.0355 15.667 21.9047 15.7084 21.7578L20.6354 4.28953C20.6716 4.16122 20.6729 4.02558 20.6392 3.89658C20.6056 3.76758 20.5381 3.64988 20.4439 3.55561C20.3496 3.46134 20.2319 3.3939 20.1029 3.36024C19.9739 3.32658 19.8383 3.32791 19.7099 3.3641Z'
      stroke={disabled ? '#aaa' : '#6123FF'}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.3906 13.602L14.6333 9.35938'
      stroke={disabled ? '#aaa' : '#6123FF'}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const TemplateView = ({ id, name, language, onActivate = () => {} }) => {
  const [template, setTemplate] = useState(null)
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingTemplate, setLoadingTemplate] = useState(false)
  const [showSentSnack, setShowSentSnack] = useState(false)

  const handleActivate = () => {
    setLoading(true)
    templatesService.publishTemplate(!active, [template.id]).then(resp => {
      setLoading(false)
      setActive(!active)
      onActivate()
    })
  }

  const handleSendToMe = () => {
    setLoading(true)
    templatesService.sendTemplate(template?.id).then(resp => {
      setLoading(false)
      setShowSentSnack(true)
    })
  }

  useEffect(() => {
    if (id) {
      setLoadingTemplate(true)
      templatesService.getTemplateById(id).then(resp => {
        setTemplate(resp.data)
        setLoadingTemplate(false)
      })
    } else {
      setLoadingTemplate(true)
      templatesService.getTemplate(name, language).then(resp => {
        setLoadingTemplate(false)
        setTemplate(resp.data)
        setActive(resp.data.active)
      })
    }
  }, [])

  return (
    <div className='row template-view' style={{ width: '1150px' }}>
      <div className='template-container'>
        <div className='row row-center template-container-header'>
          <p>Template preview</p>
        </div>
        <div className='row template-preview'>
          {loadingTemplate && <LoadingIndicator />}
          <div
            onClick={e => {
              e.preventDefault()
            }}
          >
            <Letter html={template?.body} />
          </div>
        </div>
      </div>
      <div className='template-details'>
        <div className='row row-center'>
          {!id && <CyberSwitch disabled={loading} checked={active} onChange={() => handleActivate()} />}
          <Spacer />
          <Button
            onClick={handleSendToMe}
            sx={{
              color: '#6123FF',
              textTransform: 'none',
              fontFamily: 'Plus Jakarta Sans'
            }}
            startIcon={<SendIcon disabled={showSentSnack} />}
            disabled={showSentSnack}
          >
            Send to me
          </Button>
        </div>
        <p className='template-details-title'>{template?.subject}</p>
        <p className='template-details-description'>{template?.name}</p>
        <div className='column template-details-group'>
          <p className='template-details-group-title'>Difficulty</p>
          <DifficultyChip level={template?.level} />
        </div>
        <div className='column template-details-group'>
          <p className='template-details-group-title'>Category</p>
          <p className='template-details-group-text'>{template?.types.join(', ')}</p>
        </div>
        <div className='column template-details-group'>
          <p className='template-details-group-title'>Job function</p>
          <p className='template-details-group-text'>{template?.departments.join(', ')}</p>
        </div>
        <div className='column template-details-group'>
          <p className='template-details-group-title'>Location</p>
          <p className='template-details-group-text'>{template?.locations.join(', ')}</p>
        </div>
        <div className='column template-details-group'>
          <p className='template-details-group-title'>Department</p>
          <p className='template-details-group-text'>{template?.departments.join(', ')}</p>
        </div>
        <div className='column template-details-group'>
          <p className='template-details-group-title'>Language</p>
          <p className='template-details-group-text'>{template?.languages.join(', ')}</p>
        </div>

        <Divider sx={{ mb: '24px' }} />

        <div className='column template-details-group'>
          <p className='template-details-group-title'>Phishing email pretended sender hashtag</p>
          <p className='template-details-group-text'>{template?.pretendedSenders.join(', ')}</p>
        </div>
      </div>

      <Snackbar
        sx={{ '& .MuiSnackbarContent-root': { borderRadius: '8px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={showSentSnack}
        autoHideDuration={6000}
        onClose={() => setShowSentSnack(false)}
        message={'Template successfully sent to your email'}
      />
    </div>
  )
}

export default TemplateView
