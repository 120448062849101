import React from 'react'

const PieChart = ({ data }) => {
  return (
    <div className='column' style={{ alignItems: 'center' }}>
      {data < 25 && <img src='/images/piechart1.png' alt='chart-1-low' width={130} />}
      {data > 24 && data < 50 && <img src='/images/piechart2.png' alt='chart-1-low' width={130} />}
      {data > 49 && data < 75 && <img src='/images/piechart3.png' alt='chart-1-low' width={130} />}
      {data > 74 && <img src='/images/piechart4.png' alt='chart-1-low' width={130} />}
      <p
        style={{
          fontFamily: 'Satoshi',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '24px',
          lineHeight: '120%',
          textAlign: 'center',
          color: '#1B1B26',
          marginTop: '-24px'
        }}
      >
        {data}%
      </p>
      <p
        style={{
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '22px',
          color: '#76767F'
        }}
      >
        Reported
      </p>
    </div>
  )
}

export default PieChart
