import React, { useEffect, useState } from 'react'
import {
  DashBoardSectionBlock,
  DashBoardSectionBlockSubTitle,
  DashBoardSectionBlockTitle,
  DashBoardSectionlabel,
  Row
} from '..'
import Spacer from 'src/common/components/Spacer'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis } from 'recharts'
import dayjs from 'dayjs'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
// import threatsService from "src/services/threats.service";
import { getPercent } from 'src/heplers'
import statsService from 'src/services/stats.service'

const CustomizedLabelReportingUsers = props => {
  const { x, y, stroke, value } = props
  return value == '28' ? null : (
    <text x={x} y={y} dy={-8} fill={stroke} fontSize={16} textAnchor='middle'>
      {value}%
    </text>
  )
}

export function ActiveReportingUsers({ totalUsers, timePeriod }) {
  const [loading, setloading] = useState(true)
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    statsService
      .getCurrentCompanyExternalPhishingMonthly(timePeriod)
      .then(resp => {
        setloading(false)
        setChartData(resp.data)
      })
      .catch(err => {
        console.error(err)
        setloading(false)
      })
  }, [])

  const data = chartData.slice(-3).map(el => ({
    name: dayjs(el.month).format('MMM'),
    activeUsersCount: getPercent(totalUsers, el.activeUsersCount)
  }))

  const labelType =
    (chartData[chartData.length - 1]?.activeUsersCount || 0) < 33
      ? 3
      : (chartData[chartData.length - 1]?.activeUsersCount || 0) > 66
        ? 1
        : 2

  return (
    <DashBoardSectionBlock style={{ width: 'calc(50% - 4px)' }}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Row>
            <div className='column'>
              <DashBoardSectionBlockTitle>Active reporting users</DashBoardSectionBlockTitle>
              <DashBoardSectionBlockSubTitle>For last 3 month</DashBoardSectionBlockSubTitle>
            </div>
            <Spacer />
            <DashBoardSectionlabel type={labelType}></DashBoardSectionlabel>
          </Row>
          <p
            className='secondary-text-16'
            style={{
              maxWidth: 364,
              marginTop: 14,
              marginBottom: 33
            }}
          >
            Percentage of active employees who reported emails through plugin
          </p>
          <ResponsiveContainer width='100%' height={160}>
            <AreaChart data={data} margin={{ top: 30, right: 30, left: 30, bottom: 0 }}>
              <defs>
                <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='5%' stopColor='#6123FF' stopOpacity={0.4} />
                  <stop offset='95%' stopColor='#6123FF' stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey='name' />
              {/* <YAxis /> */}
              <CartesianGrid horizontal={false} strokeDasharray='3 3' />
              {/* <Tooltip /> */}
              {/* <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" label={<CustomizedLabel />} /> */}
              <Area
                dot={{ r: 2 }}
                type='monotone'
                dataKey='activeUsersCount'
                stroke='#6123FF'
                strokeWidth={2}
                fillOpacity={1}
                fill='url(#colorUv)'
                label={<CustomizedLabelReportingUsers />}
              />
            </AreaChart>
          </ResponsiveContainer>
        </>
      )}
    </DashBoardSectionBlock>
  )
}
