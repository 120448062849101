import React, { useState } from 'react'

import { Box, Typography, styled } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useNavigate } from 'react-router-dom'

export const Card = styled(Box)({
  borderRadius: '30px',
  width: '1050px',
  height: '700px',
  position: 'absolute',
  transition: 'margin-left .4s ease-out,opacity .2s ease-out,transform .4s ease-out .1s',
  transformOrigin: 'center right',
  boxShadow: 'inset 0 0 0 1px #27274714',
  overflow: 'hidden'
})

export const CardMedia = styled(Box)({})

export const CardTitle = styled(Typography)(({ light }) => ({
  color: light ? '#fff' : '#303051',
  fontSize: '60px',
  fontWeight: '700',
  lineHeight: '70px',
  letterSpacing: '0em',
  textAlign: 'left',
  padding: '50px 120px'
}))

export const CardContent = styled(Box)(({ light }) => ({
  '& p': {
    color: light ? '#fff' : '#303051',
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '40px',
    letterSpacing: '0em',
    textAlign: 'left',
    padding: '20px 120px'
  }
}))

const CardsContainer = styled(Box)({
  width: '1050px',
  height: '680px',
  animation: 'fadeIn 1s ease-out',
  display: 'flex',
  flexDirection: 'row'
})

const TrainingCards = () => {
  const cardsInitial = [
    // {
    //   id: 8,
    //   background: '#FDF3FF',
    //   scale: 9,
    //   opacity: 1,
    //   ml: 0,
    //   media: 'card1media.jpg',
    //   title: `You just got phished — but
    //   it’s okay, this time it was just
    //   an exercise.`,
    //   content: <Typography>The phishing risk cannot <strong>be taken lightly</strong>. Follow
    //     these 5 tips to prepare yourself.</Typography>,
    // },
    {
      id: 8,
      background: '#FFD6D6',
      scale: 9,
      opacity: 1,
      ml: 0,
      title: `Passwords: Protecting Your Online Accounts`,
      lightText: false,
      // media: 'card2media.png',
      content: (
        <Typography>
          Passwords are the first line of defense in securing your online accounts. How you create and manage them can
          significantly impact your digital security.
        </Typography>
      )
    },
    {
      id: 7,
      background: '#6123FF',
      scale: 8,
      opacity: 1,
      ml: 4,
      title: `Take your time, don’t rush
      things out, ask for advice.`,
      lightText: true,
      media: 'card2media.png',
      content: (
        <Typography>
          Hackers will <strong>push you into compromising your credentials</strong> . They will create a situation where
          you have to act as soon as possible.{' '}
        </Typography>
      )
    },
    {
      id: 6,
      background: '#EEFBFF',
      scale: 7,
      opacity: 1,
      ml: 8,
      title: `Take a close look at
      the sender, at the
      spelling and at the
      links.`,
      content: (
        <Typography>
          Most of the time, the <strong>sender&apos;s e-mail address</strong> is not the one you know or is misspelled,
          as can also be the text of the message. Similarly, links pointing to the wrong website should be a{' '}
          <strong>strong red flag</strong>.{' '}
        </Typography>
      )
    },
    {
      id: 5,
      background: '#FFD6D6',
      scale: 6,
      opacity: 1,
      ml: 12,
      title: `Stay alert, especially on
      weekends or during the
      pre-holiday period.`,
      content: (
        <Typography>
          Hackers take advantage of moments of reduced vigilance or staffing levels to attack. As a preliminary work,
          they research the companies they are trying to penetrate.
        </Typography>
      )
    },
    {
      id: 4,
      background: '#fff',
      scale: 5,
      opacity: 0,
      ml: 16,
      title: `Do not download
      suspicious files or
      files of unknown
      origin.`,
      content: (
        <Typography>
          From time to time, our protection barriers let through dangerous files, especially when they are compressed
          and/or password protected. It is imperative that you ask yourself why you are receiving such files before you
          make a mistake.
        </Typography>
      )
    },
    {
      id: 3,
      background: '#6123FF',
      scale: 4,
      opacity: 0,
      ml: 20,
      media: 'card3media.png',
      lightText: true,
      title: `Always use a side channel.`,
      content: (
        <Typography>
          If you are unsure the email is legitimate, you should avoid clicking links and go directly to the official
          website — or call the sender.
        </Typography>
      )
    },
    {
      id: 2,
      background: '#fff',
      scale: 3,
      opacity: 0,
      ml: 24,
      media: 'card4media.png',
      title: `Get a strong password..`,
      content: (
        <Typography>
          Your current <strong>password is weak</strong> : it would take a computer only a few seconds to crack it. The
          golden rule is getting a password with at least 13 characters, a number and an exotic character.
        </Typography>
      )
    },
    {
      id: 1,
      background: '#6123FF',
      scale: 2,
      opacity: 0,
      ml: 28,
      lightText: true,
      title: `We’re here for you.`,
      content: (
        <>
          <Typography>
            Don’t hesitate to ask for advice, and if you ever get caught by a (real) phishing attack, please notify the
            cybersecurity team (alexander@cyberlift.com) as soon as possible.
          </Typography>
          ,
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography>Stay safe</Typography>,
            <img width={'377px'} src='/images/alexandlogo.png' alt='alexandlogo' />
          </Box>
        </>
      )
    }
  ]
  const [activeCard, setActiveCard] = useState(8)
  const [cards, setCards] = useState(cardsInitial)
  const [progress, setProgress] = useState(100 / 8)

  const navigate = useNavigate()

  // const router = useRouter()

  const handleNext = () => {
    if (activeCard == 1) {
      navigate('/trainings')

      return
    }
    setActiveCard(activeCard - 1)
    setProgress(progress + 100 / 8)
    setCards(
      cards.map(el => ({
        ...el,
        scale: el.scale + 1,
        ml: el.ml - 4,
        opacity: el.id < activeCard && el.id >= activeCard - 3 ? 1 : 0
      }))
    )
  }

  const handlePerv = () => {
    if (activeCard == 8) {
      return
    }
    setActiveCard(activeCard + 1)
    setProgress(progress - 100 / 8)

    setCards(
      cards.map(el => ({
        ...el,
        scale: el.scale - 1,
        ml: el.ml + 4,
        opacity: el.id < activeCard + 2 && el.id >= activeCard - 1 ? 1 : 0
      }))
    )
  }

  return (
    <Box
      sx={{
        background: '#E9E9FF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        overflow: 'hidden'
      }}
    >
      <IconButton
        sx={{
          mr: '80px',
          zIndex: 10,
          backgroundColor: '#fff',
          width: '64px',
          height: '64px'
        }}
        onClick={handlePerv}
      >
        {/* <Icon icon='material-symbols:arrow-back-rounded' fontSize={40} /> */}
        <svg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M9.10207 2.19103L2.8457 8.40997L9.10207 14.6289'
            stroke='#6123FF'
            strokeWidth='2.67544'
            strokeLinecap='round'
          />
          <path d='M2.8457 8.41014L17.7517 8.41016' stroke='#6123FF' strokeWidth='2.67544' strokeLinecap='round' />
        </svg>
      </IconButton>

      <CardsContainer sx={{}}>
        {cards.map(el => (
          <Card
            key={el.id}
            sx={{
              background: el.background,
              zIndex: el.id,
              ml: el.ml,
              scale: `${el.scale / 10}`,
              opacity: el.opacity
            }}
          >
            {el?.media ? (
              <CardMedia>
                <img width={'100%'} src={`/images/${el.media}`} alt='media' />
              </CardMedia>
            ) : (
              <Box sx={{ height: '50px' }}></Box>
            )}
            {el?.title && <CardTitle light={el.lightText}>{el.title}</CardTitle>}
            {el?.content && <CardContent light={el.lightText}>{el.content}</CardContent>}
          </Card>
        ))}
      </CardsContainer>

      <IconButton
        style={{ backgroundColor: '#6123FF', zIndex: '1000' }}
        onClick={handleNext}
        sx={{
          ml: '120px',
          backgroundColor: '#6123FF',
          color: '#fff',
          width: '64px',
          height: '64px'
        }}
      >
        <svg width='19' height='16' viewBox='0 0 19 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M10.4721 2.19103L16.7285 8.40997L10.4721 14.6289'
            stroke='#DEDEFF'
            strokeWidth='2.67544'
            strokeLinecap='round'
          />
          <path d='M16.7285 8.41014L1.8225 8.41016' stroke='#DEDEFF' strokeWidth='2.67544' strokeLinecap='round' />
        </svg>
      </IconButton>

      <Box
        sx={{
          width: '500px',
          height: '5px',
          background: '#fff',
          position: 'absolute',
          bottom: '40px',
          left: 'calc(50% - 250px)',
          borderRadius: '5px'
        }}
      >
        <Box
          sx={{
            width: `${progress}%`,
            position: 'relative',
            height: '5px',
            background: '#6123FF',
            borderRadius: '5px',
            transition: '500ms'
          }}
        ></Box>
      </Box>
    </Box>
  )
}

export default TrainingCards
