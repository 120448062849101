import React from 'react'
import { Box, Divider, IconButton, TextField } from '@mui/material'
import { ReactComponent as PaperclipIcon } from '../../../../common/icons/Paperclip.svg'
import { ReactComponent as CloseIcon } from '../../../../common/icons/close_icon.svg'
import SelectExtensionDropdown from './SelectExtensionDropdown'

export default function FileAttachment({
  handleRemove,
  setTemplateChanged,
  fileName,
  setFileName,
  extension,
  setExtension
}) {
  function handleFileName(e) {
    setTemplateChanged(true)
    setFileName(e.target.value)
  }

  return (
    <Box
      width={'100%'}
      display={'flex'}
      borderRadius={'10px'}
      pt={0.5}
      pb={0.5}
      px={2}
      bgcolor={'#EAEAF1'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Box display={'flex'} columnGap={1} alignItems={'center'}>
        <PaperclipIcon />
        <TextField
          value={fileName}
          variant={'standard'}
          autoFocus={true}
          onInput={handleFileName}
          sx={{
            fontSize: 16,
            fontWeight: 400,
            fontFamily: 'Plus Jakarta Sans',
            color: '#1B1B26'
          }}
        />
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Divider
          sx={{
            width: '2px',
            marginRight: '8px'
          }}
          variant={'middle'}
          flexItem
          orientation={'vertical'}
        />
        <SelectExtensionDropdown
          setTemplateChanged={setTemplateChanged}
          selectedExtension={extension}
          setSelectedExtension={setExtension}
        />
        <Box display={'flex'} alignItems={'center'}>
          <IconButton onClick={handleRemove}>
            <CloseIcon onClick={handleRemove} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
