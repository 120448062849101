import dayjs from 'dayjs'

export const datePickerProps = (input, meta, label) => ({
  format: 'MM/DD/YYYY',
  disablePast: true,
  minDate: dayjs(),
  sx: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      fontSize: 16,
      fontWeight: 400,
      backgroundColor: '#ffffff',
      fontFamily: 'Plus Jakarta Sans',
      borderRadius: '10px'
    },
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px'
    }
  },
  slotProps: {
    textField: {
      error: !!meta.error,
      helperText: meta.error
    },
    inputAdornment: { position: 'start', variant: 'standard' }
  },
  onChangeRaw: e => e.preventDefault(),
  shouldDisableDate: date => date.isBefore(dayjs(), 'day'),
  value: dayjs(input.value),
  label,
  onChange: date => {
    const selectedDate = dayjs(date)
    input.onChange(
      selectedDate.isBefore(dayjs(), 'day') ? dayjs().format('YYYY-MM-DD') : selectedDate.format('YYYY-MM-DD')
    )
  }
})
