import { useState, useEffect } from 'react'

const useSortableList = (items, type) => {
  const [sortedItems, setSortedItems] = useState(items)
  const [sortBy, setSortBy] = useState(null)

  useEffect(() => {
    setSortedItems(items)
  }, [items])

  const handleSort = field => {
    const isGroup = type === 'groups'
    const fieldMap = { name: 'name', email: 'email', users: 'usersCount' }
    const fieldName = fieldMap[field.toLowerCase()]

    const newSortedItems = items
      .map(el => (isGroup ? el : { ...el, name: `${el.firstName || 'unknown'} ${el.lastName || 'unknown'}` }))
      .sort((a, b) =>
        a[fieldName] < b[fieldName] ? (sortBy?.asc ? -1 : 1) : a[fieldName] > b[fieldName] ? (sortBy?.asc ? 1 : -1) : 0
      )

    setSortedItems(newSortedItems)
    setSortBy({ name: field, asc: field === sortBy?.name ? !sortBy.asc : true })
  }

  return { sortedItems, handleSort, sortBy }
}

export default useSortableList
