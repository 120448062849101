import CustomCheckbox from '../../../common/components/CustomCheckbox'
import Highlighter from 'react-highlight-words'
import UserAvatar from '../../../common/components/UserAvatar'
import React from 'react'
import { TableBigNum } from '../../../views/PackageCreate'
import { Box } from '@mui/material'

const MODES = { groups: 'groups', users: 'users' }
export const ListItem = ({ item, onSelect, selected, search, type }) => {
  const isGroup = type === MODES.groups
  return (
    <Box key={item.name} display={'flex'} alignItems={'center'} pt={'24px'}>
      <CustomCheckbox checked={selected} onClick={() => onSelect(item.id)} />
      <div className='row' style={{ width: isGroup ? '75%' : '55%', alignItems: isGroup ? 'flex-start' : 'center' }}>
        {isGroup ? (
          <Highlighter
            highlightClassName='highlight-text'
            searchWords={[search]}
            autoEscape
            textToHighlight={item.name}
          />
        ) : (
          <>
            <UserAvatar firstName={item?.firstName} lastName={item?.lastName} />
            <Highlighter
              highlightClassName='highlight-text'
              searchWords={[search]}
              autoEscape
              textToHighlight={`${item?.firstName || 'unknown'} ${item?.lastName || 'unknown'}`}
            />
          </>
        )}
      </div>
      <div style={{ width: isGroup ? '25%' : '45%' }}>
        {isGroup ? <TableBigNum color='#1B1B26' num={item.usersCount} /> : item.email}
      </div>
    </Box>
  )
}
