import React, { useState } from 'react'
import { Button, Popover, Typography, Box, CircularProgress } from '@mui/material'
import { useGetLoginPageHtmlQuery } from '../../../../api/simulation/simulation'
import Github from '../../../../common/images/gitgub.jpg'
import GoogleMeet from '../../../../common/images/google_meet.jpg'

export default function LoginPagePopover({ name, loginPage }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const { data: html, isLoading, error } = useGetLoginPageHtmlQuery({ loginPage })

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const handleButtonClick = event => {
    setAnchorEl(event?.currentTarget)
  }

  return (
    <div>
      <Button
        sx={{
          fontFamily: 'Plus Jakarta Sans',
          color: '#6123FF',
          textTransform: 'none'
        }}
        aria-describedby={id}
        variant='text'
        onClick={handleButtonClick}
      >
        {name}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Box sx={{ padding: 2 }}>
          {error ? (
            <Typography variant='h6' color='error'>
              Sorry, the content failed to load.
            </Typography>
          ) : isLoading ? (
            <CircularProgress />
          ) : loginPage === 'GITHUB' || loginPage === 'GOOGLE_MEET' ? (
            <img
              src={loginPage === 'GITHUB' ? Github : GoogleMeet}
              alt={`${loginPage} login`}
              width={1200}
              height={750}
            />
          ) : (
            <Box sx={{ position: 'relative', width: '1200px', height: '800px' }}>
              <iframe
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen'
                loading='lazy'
                width='1200'
                height='800'
                srcDoc={html?.body}
                sandbox='allow-same-origin allow-popups allow-forms allow-scripts'
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'transparent',
                  zIndex: 1
                }}
              />
            </Box>
          )}
        </Box>
      </Popover>
    </div>
  )
}
