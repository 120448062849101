import React from 'react'
import { Box } from '@mui/material'

export default function OptionsTag({ title, isHidden, color = '#FFFFFF' }) {
  if (isHidden) {
    return null
  }
  return (
    <Box
      borderRadius={'8px'}
      display={'flex'}
      padding={'8px 12px'}
      bgcolor={color}
      fontWeight={400}
      fontSize={'14px'}
      fontFamily={'Plus Jakarta Sans'}
    >
      {title}
    </Box>
  )
}
