import React from 'react'
import { Box, Typography, Select, MenuItem } from '@mui/material'
import { PreviousPageButton, NextPageButton } from './CustomPaginationButtons'

export default function CustomTablePagination({
  totalCount,
  page,
  rowsPerPage,
  onRequestPageChange,
  onRequestRowsPerPageChange,
  sticky = false
}) {
  const handleChangePage = newPage => {
    onRequestPageChange(newPage)
  }

  const handleChangeRowsPerPage = event => {
    onRequestRowsPerPageChange(parseInt(event.target.value, 10))
  }

  return (
    <Box
      className={sticky ? 'sticky-pagination' : 'regular-pagination'}
      display='flex'
      bgcolor={'#EFEFF6'}
      alignItems='center'
      justifyContent='space-between'
      pt={'8px'}
      pb={'8px'}
    >
      <Box display='flex' alignItems='center' columnGap={'4px'}>
        <PreviousPageButton onClick={() => handleChangePage(page - 1)} disabled={page === 0} />
        <NextPageButton
          onClick={() => handleChangePage(page + 1)}
          disabled={page >= Math.ceil(totalCount / rowsPerPage) - 1}
        />
      </Box>
      <Box display={'flex'} columnGap={'4px'} alignItems={'center'}>
        <Select
          sx={{
            minWidth: '70px',
            width: 'fit-content',
            height: '24px',
            borderRadius: '6px',
            border: 'none',
            color: '#76767F',
            fontFamily: 'Plus Jakarta Sans',
            fontSize: '13px',
            fontWeight: 400,
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
              borderColor: 'transparent',
              '&:hover': {
                border: 'none',
                borderColor: 'transparent'
              },
              '&:focus': {
                border: 'none',
                borderColor: 'transparent'
              }
            }
          }}
          value={page}
          onChange={event => handleChangePage(event.target.value)}
        >
          {Array.from({ length: Math.ceil(totalCount / rowsPerPage) }, (_, i) => i).map((_, index) => (
            <MenuItem key={index} value={index}>
              Page {index + 1} of {Math.ceil(totalCount / rowsPerPage)}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box display='flex' alignItems='center' columnGap={'4px'}>
        <Typography color={'#76767B'} fontSize={'13px'} fontWeight={400} fontFamily={'Plus Jakarta Sans'}>
          Rows per page
        </Typography>
        <Select
          sx={{
            width: '75px',
            height: '24px',
            borderRadius: '6px',
            backgroundColor: 'transparent',
            color: '#76767F',
            fontFamily: 'Plus Jakarta Sans',
            fontSize: '13px',
            fontWeight: 400,
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
              borderColor: 'transparent',
              '&:hover': {
                border: 'none',
                borderColor: 'transparent'
              },
              '&:focus': {
                border: 'none',
                borderColor: 'transparent'
              }
            }
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[25, 100, 250].map(rows => (
            <MenuItem key={rows} value={rows}>
              {rows}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  )
}
