export const deadlineTypeOptions = [
  {
    name: 'No Deadline',
    value: 'NO_DEADLINE'
  },
  {
    name: 'Fixed date deadline',
    value: 'FIXED_DATE_DEADLINE'
  }
]
