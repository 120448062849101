import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Box, Button, Menu, MenuItem, TextField, Typography } from '@mui/material'
import { ReactComponent as SearchIcon } from '../../../common/icons/search.svg'
import List from './List'
import groupsService from 'src/services/groups.service'
import userService from 'src/services/user.service'
import useDebounce from '../../../hooks'
import { useForm } from 'react-final-form'

const MODES = { groups: 'groups', users: 'users' }
const defaultFilters = { page: '0', size: 10, statuses: 'ACTIVE' }

export function GroupUserSelection({ selectedUsers = [], selectedGroups = [], onSelectUser, onSelectGroup }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [mode, setMode] = useState(MODES.groups)
  const [allGroups, setAllGroups] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState(defaultFilters)

  const debouncedSearchTerm = useDebounce(search, 500)
  const open = Boolean(anchorEl)
  const form = useForm()
  useLayoutEffect(() => {
    setSearch('')
  }, [])

  useEffect(() => {
    setFilters(prevFilters => ({ ...prevFilters, searchText: debouncedSearchTerm || '' }))
  }, [debouncedSearchTerm])

  useEffect(() => {
    const fetchItems = mode === MODES.groups ? groupsService.getGroups : userService.getUsers
    fetchItems(filters)
      .then(response => {
        mode === MODES.groups ? setAllGroups(response.data) : setAllUsers(response.data)
        setTotalPages(Math.ceil(parseInt(response.headers['x-total-count']) / defaultFilters.size))
      })
      .catch()
  }, [filters, mode])

  useEffect(() => {
    setFilters(prevFilters => ({ ...prevFilters, page: page - 1 }))
  }, [page])

  const handleSelectAll = isSelectedAll => {
    const ids = (mode === MODES.groups ? allGroups : allUsers).map(el => el.id)
    mode === MODES.groups ? onSelectGroup(ids, isSelectedAll) : onSelectUser(ids, isSelectedAll)
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center' pt={'28px'} pb={'24px'}>
        <Typography fontSize={'24px'} fontWeight={500} fontFamily={'Satoshi'}>
          Choose users
          <span
            onClick={e => setAnchorEl(e.currentTarget)}
            className='users-groups-switcher'
            style={{ color: '#6123FF', cursor: 'pointer', marginLeft: 10, fontWeight: 700 }}
          >
            by {mode === MODES.groups ? 'Groups' : 'Users'}
            <svg
              style={{ margin: '0 10px', transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: '300ms' }}
              width='18'
              height='10'
              viewBox='0 0 18 10'
              fill='none'
            >
              <path
                d='M1.5 1L9 8.5L16.5 1'
                stroke='#6123FF'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </span>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null)
                setMode(MODES.groups)
                setPage(1)
                setSearch('')
                form.change('groupUserSelection.userIds', [])
              }}
            >
              Groups
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null)
                setMode(MODES.users)
                setPage(1)
                setSearch('')
                form.change('groupUserSelection.groupIds', [])
              }}
            >
              Users
            </MenuItem>
          </Menu>
        </Typography>
        <TextField
          size='small'
          placeholder='Search'
          onChange={e => {
            setSearch(e.target.value)
            setPage(1)
          }}
          value={search}
          variant='outlined'
          InputProps={{
            endAdornment: <SearchIcon />
          }}
          sx={{ width: 440, '& .MuiInputBase-root': { borderRadius: '14px' } }}
        />
      </Box>
      <List
        items={mode === MODES.groups ? allGroups : allUsers}
        handleSelectAll={handleSelectAll}
        selectedItems={mode === MODES.groups ? selectedGroups : selectedUsers}
        onSelect={mode === MODES.groups ? onSelectGroup : onSelectUser}
        search={search}
        type={mode}
      />
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <p>
          Page <strong>{page}</strong> of <strong>{totalPages}</strong>
        </p>
        <Box display={'flex'} columnGap={1}>
          <Button
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
            variant='outlined'
            sx={{ mt: '8px', mb: '12px', mr: '24px', borderRadius: '12px', width: '148px' }}
          >
            Previous
          </Button>
          <Button
            onClick={() => setPage(page + 1)}
            disabled={page === totalPages || !totalPages}
            variant='outlined'
            sx={{ mt: '8px', mb: '12px', borderRadius: '12px', width: '148px' }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default GroupUserSelection
