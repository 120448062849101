import { CloseDrawer } from '../../icons/closeDrawer'

import React from 'react'
import { Drawer } from '@mui/material'
import backGround from '../../images/bg.svg'

export default function BasedDrawer({ open, handleClose, children }) {
  return (
    <Drawer
      ModalProps={{
        slot: 'modal',
        BackdropProps: {
          style: {
            backgroundImage: `url(${backGround})`,
            backgroundSize: 'cover',
            color: '#fff'
          }
        }
      }}
      PaperProps={{
        sx: {
          width: '51vw',
          backgroundColor: '#F8F8FB',
          padding: 4
        }
      }}
      anchor='right'
      open={open}
      onClose={handleClose}
    >
      <div
        style={{
          position: 'fixed',
          top: '24px',
          right: '52vw',
          cursor: 'pointer'
        }}
        onClick={handleClose}
      >
        <CloseDrawer />
      </div>
      {children}
    </Drawer>
  )
}
