import React from 'react'

const EmployeesPlatformIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.1'
      d='M10 13.75C12.0711 13.75 13.75 12.0711 13.75 10C13.75 7.92893 12.0711 6.25 10 6.25C7.92893 6.25 6.25 7.92893 6.25 10C6.25 12.0711 7.92893 13.75 10 13.75Z'
      fill='#1B1B26'
    />
    <path d='M10 10L17.5 2.5' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M15.3034 4.6959C13.9748 3.36998 12.197 2.59099 10.3216 2.51306C8.44623 2.43512 6.60991 3.06392 5.17593 4.27504C3.74196 5.48617 2.81478 7.1914 2.57784 9.05338C2.3409 10.9154 2.81145 12.7985 3.8964 14.3301C4.98135 15.8618 6.60167 16.9304 8.4368 17.3247C10.2719 17.7189 12.1882 17.41 13.8065 16.4591C15.4247 15.5081 16.6272 13.9844 17.1759 12.1894C17.7245 10.3944 17.5795 8.45885 16.7695 6.76565'
      stroke='#1B1B26'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.6514 7.34827C12.0315 6.72832 11.2139 6.34521 10.3407 6.26553C9.46761 6.18584 8.59416 6.41461 7.87221 6.91208C7.15025 7.40956 6.62543 8.14427 6.38897 8.98854C6.1525 9.8328 6.21933 10.7332 6.57786 11.5333C6.93638 12.3334 7.56392 12.9826 8.35141 13.368C9.1389 13.7535 10.0366 13.8508 10.8883 13.6431C11.7401 13.4353 12.4922 12.9357 13.0139 12.231C13.5355 11.5264 13.7938 10.6612 13.7437 9.78584'
      stroke='#1B1B26'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default EmployeesPlatformIcon
