import api from './api.service'
import authHeader from './auth-header'

class BreachesService {
  getBreaches() {
    return api.get('/v1/breaches', { headers: authHeader() })
  }
}

export default new BreachesService()
