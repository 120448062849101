import { apiSlice } from '../apiSlice'
import { TRAINING_PACKAGES } from '../tags'

export const trainingPackagesAPI = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTrainingPackages: builder.query({
      query: params => {
        return {
          url: 'v1/training-packages',
          method: 'GET',
          params
        }
      },
      transformResponse: (response, meta) => {
        const totalCount = meta.response.headers.get('X-Total-Count')
        return { data: response, totalCount }
      },
      providesTags: [TRAINING_PACKAGES.LIST]
    }),
    getOneTrainingPackage: builder.query({
      query: id => `v1/training-packages/${id}`,
      providesTags: [TRAINING_PACKAGES.TRAINING_PACKAGE]
    }),
    getTrainingUsers: builder.query({
      query: ({ id, ...params }) => {
        return {
          url: `v1/training-packages/${id}/users`,
          method: 'GET',
          params
        }
      },
      providesTags: [TRAINING_PACKAGES.USERS],
      transformResponse: (response, meta) => {
        const totalCount = meta.response.headers.get('X-Total-Count')
        return { data: response, totalCount }
      }
    }),
    getPackagesFilterCounters: builder.query({
      query: () => 'v1/training-packages/counters',
      providesTags: [TRAINING_PACKAGES.LIST]
    }),
    archiveTrainingPackage: builder.mutation({
      query: id => ({
        url: `v1/training-packages/${id}/archive`,
        method: 'POST'
      }),
      invalidatesTags: [TRAINING_PACKAGES.LIST, TRAINING_PACKAGES.TRAINING_PACKAGE]
    }),
    getAllModules: builder.query({
      query: lang => `v1/trainings/modules?language=${lang}`,
      providesTags: [TRAINING_PACKAGES.MODULES]
    }),
    addPackage: builder.mutation({
      query: packageBody => ({
        url: 'v1/training-packages/',
        method: 'POST',
        body: packageBody
      }),
      invalidatesTags: [TRAINING_PACKAGES.LIST]
    }),
    updatePackage: builder.mutation({
      query: ({ id, ...packageBody }) => ({
        url: `v1/training-packages/${id}`,
        method: 'PUT',
        body: packageBody
      }),
      invalidatesTags: [TRAINING_PACKAGES.LIST, TRAINING_PACKAGES.TRAINING_PACKAGE, TRAINING_PACKAGES.USERS]
    }),
    getCurrentCompanyTrainingStats: builder.query({
      query: () => 'v1/companies/current/training-package-stats',
      providesTags: [TRAINING_PACKAGES.STATS]
    })
  })
})

export const {
  useGetCurrentCompanyTrainingStatsQuery,
  useUpdatePackageMutation,
  useLazyGetTrainingUsersQuery,
  useAddPackageMutation,
  useLazyGetAllModulesQuery,
  useArchiveTrainingPackageMutation,
  useGetTrainingPackagesQuery,
  useLazyGetTrainingPackagesQuery,
  useGetOneTrainingPackageQuery,
  useLazyGetOneTrainingPackageQuery,
  useGetPackagesFilterCountersQuery
} = trainingPackagesAPI
