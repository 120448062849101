import { PageNumberContext } from '../PageNumberContext'
import React, { useContext } from 'react'
import { Box, Fab, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'

export default function NewTemplateCard() {
  const { setPage } = useContext(PageNumberContext)

  function handleCardClick() {
    setPage('NEW_EMAIL_TEMPLATE')
  }

  return (
    <Box
      onClick={handleCardClick}
      display={'flex'}
      flexDirection={'column'}
      rowGap={'16px'}
      alignItems={'center'}
      sx={{
        position: 'relative',
        height: '220px',
        minWidth: '120px',
        width: 'calc((100% - 48px) / 5)',
        padding: '8px',
        border: '0.36px solid #DBDBE6',
        borderRadius: '14px',
        background: '#C0A7FF1A',
        cursor: 'pointer'
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          width: '100%',
          height: '250px',
          borderRadius: '12px',
          border: '1px dashed #C0A7FF',
          overflow: 'hidden',
          cursor: 'pointer'
        }}
      >
        <Fab
          sx={{
            background: '#6123FF0D',
            '& .MuiSvgIcon-root': {
              fontSize: '40px',
              fill: '#6123FF',
              color: '#6123FF'
            }
          }}
        >
          <Add
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: '40px',
                color: '#6123FF'
              }
            }}
          />
        </Fab>
      </Box>
      <Typography color={'#6123FF'} fontSize={'14px'} fontWeight={500} fontFamily={'Plus Jakarta Sans '}>
        Create new template
      </Typography>
    </Box>
  )
}
