import React from 'react'

const PlusIcon = ({ color = '#6123FF' }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.1'
      d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
      fill={color}
    />
    <path
      d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
      stroke={color}
      strokeMiterlimit='10'
    />
    <path d='M8.25 12H15.75' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12 8.25V15.75' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

export default PlusIcon
