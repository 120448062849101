import React from 'react'
import { Box, Typography } from '@mui/material'
import { ReactComponent as BrowserIcon } from '../../../../common/icons/browser_icon.svg'
import { useFormState } from 'react-final-form'
import OptionsTag from './OptionsTag'
import dayjs from 'dayjs'

export default function FirstStepCard() {
  const { values } = useFormState()

  return (
    <Box display={'flex'} columnGap={2}>
      <BrowserIcon />
      <Box display={'flex'} flexDirection={'column'} rowGap={1} width={'50%'}>
        <Typography fontFamily={'Satoshi'} fontWeight={500} fontSize={'20px'} color={'#1B1B26'}>
          {values.name}
        </Typography>
        <Typography fontFamily={'Plus Jakarta Sans'} fontWeight={500} fontSize={'14px'} color={'#76767F'}>
          {values.description}
        </Typography>
        <Box display={'flex'} columnGap={2} flexWrap={'wrap'} rowGap={1}>
          <OptionsTag title={'The training is mandatory'} isHidden={!values.mandatory} />
          <OptionsTag title={'Send notifications & reminders'} isHidden={!values.sendNotifications} />
          <OptionsTag title={'Employees can retake the training'} isHidden={!values.retrainingAllowed} />
          <OptionsTag title={'Send via Cyberlift bot on Slack or Teams'} isHidden={!values.sendViaBot} />
        </Box>
      </Box>
      <div className='row'>
        <svg
          style={{ margin: '5px 10px' }}
          width='8'
          height='34'
          viewBox='0 0 8 34'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M4 4V32' stroke='#C7EFF4' />
          <circle cx='4' cy='4' r='4' fill='#C7EFF4' />
          <circle cx='4' cy='30' r='4' fill='#C7EFF4' />
        </svg>
        <div className='column'>
          <p style={{ marginBottom: '10px' }}>
            {values.startDate ? dayjs(values.startDate).format('MMM DD, YYYY') : 'no start date'}
          </p>
          <p>{values.endDate ? dayjs(values.endDate).format('MMM DD, YYYY') : 'no end date'}</p>
        </div>
      </div>
    </Box>
  )
}
