import { useState } from 'react'

export const useSorting = (initialOrderBy = '', initialOrder = '') => {
  const [orderBy, setOrderBy] = useState(initialOrderBy)
  const [order, setOrder] = useState(initialOrder)

  const handleRequestSort = (property, newOrder) => {
    setOrderBy(property)
    setOrder(newOrder)
  }

  return { orderBy, order, handleRequestSort }
}
