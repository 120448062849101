import React, { useEffect, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import './App.css'

import Templates from './pages/templates'
import Team from './pages/team'

import Nav from './common/nav/Nav'
import LoginPage from './pages/login'
import Microtraining from './pages/microtraining'
import TrainingCards from './pages/training-cards'
import RewardPage from './pages/reward'
import Threats from './pages/threats'
import Overview from './pages/overview'
import { store } from './api/store'
import Settings from './pages/settings'
import settingsService from './services/settings.service'
import AuthLinkPage from './pages/auth-link'
import NotFoundPage from './pages/not-found'
import Groups from './pages/groups'
import GoogleRedirectPage from './pages/google-redirect'
import OutlookRedirectPage from './pages/outlook-redirect'
import { parseJwt } from './heplers'
import GoogleRedirectSsoPage from './pages/google-redirect-sso'
import OutlookRedirectSsoPage from './pages/outlook-redirect-sso'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
// import Onboarding from './common/components/Onboarding';
import { useQuery } from './hooks'
import authService from './services/auth.service'
import LoadingIndicator from './common/components/LoadingIndicator'
import Workflows from './pages/workflows'
import Breaches from './pages/breaches'
import { Workspace } from './pages/settings/components/workspace'
import { PhishingSimulations } from './pages/settings/components/phishingSimulations'
import { Integrations } from './pages/settings/components/integrations'
import { Members } from './pages/settings/components/members'
import { Alerts } from './pages/settings/components/alerts'
import OutlookWorkflowsRedirectPage from './pages/outlook-workflows-redirect'
import { Drawer } from '@mui/material'
import UserView from './views/UserView'
import SlackRedirectPage from './pages/slack-redirect'
import ManualSimulation from './pages/manual-simulation'
import ManualSimulationV2 from './pages/manual-simulation-v2'
import RegistrationPage from './pages/registration'
import GetStartedPage from './pages/get-started'
import SnackbarCustom from './common/components/SnackbarCustom'
import NewCampaignPage from './pages/new-campaign'
import { EditCampaign } from './pages/edit-campaign'
import { PluginsAndBot } from './pages/settings/components/plugins'
import DashBoardNew from './pages/dashboard-new'
import { PhishingClickRate } from './pages/dashboard-new/pages/phishingClickRate'
import { PhishingMicroTrainingFollowed } from './pages/dashboard-new/pages/phishingMicroTrainingFollowed'
import { RiskyIndividuals } from './pages/dashboard-new/pages/riskyIndividuals'
import { RiskyDepartments } from './pages/dashboard-new/pages/riskyDepartments'
import { Provider } from 'react-redux'
import CampaignDetails from './pages/manual-simulation-v2/CampaignDetails/CampaignDetails'
import OktaRedirectPage from './pages/okta-redirect'
import OktaRedirectSsoPage from './pages/okta-redirect-sso'
import StepStatistic from './pages/manual-simulation-v2/CampaignDetails/Overview/StepStatistic'
import companyService from './services/company.service'
import Companies from './pages/companies'
import { SsoSettings } from './pages/settings/components/sso'
import { RiskyBehaviors } from './pages/dashboard-new/pages/riskyBehaviors'
import WorkflowLogs from './pages/workflows/total-logs'
import SlackSimRedirectPage from './pages/slack-simulation-redirect'
import SecurityAssistant from './pages/security-assistant'
import TrainingPackages2 from './pages/training-packages-2/TrainingPackages2'
import TrainingDetails from './pages/training-packages-2/TrainingDetails/TrainingDetails'
import { LargerScreenMobile } from './common/components/LargerScreenMobile'
import Logo from './common/logo'
import Spacer from './common/components/Spacer'
import Copyright from './common/components/Copyright'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='column column-center large-screen-mobile'>
          <Logo />
          <Spacer />
          <p
            className='gradient-text'
            style={{
              fontFamily: 'Satoshi',
              color: '#ffffff',
              fontSize: '96px',
              fontWeight: '500'
            }}
          >
            Oops!
          </p>
          <p
            style={{
              fontFamily: 'Satoshi',
              fontSize: '24px',
              color: '#6858D9',
              textAlign: 'center'
            }}
          >
            Something went wrong. Please try later
          </p>
          <Spacer />
          <Copyright />
        </div>
      )
    }

    return this.props.children
  }
}

const AppContent = ({ children }) => <div className='app-content'>{children}</div>

export const SettingsContext = React.createContext()
export const UserViewContext = React.createContext()
export const OnboardingContext = React.createContext()
export const SnackBarContext = React.createContext()
export const DashBoardDateContext = React.createContext()

const AppLayout = () => {
  const [settings, setSettings] = useState({ languages: [] })
  const [onboarding, setOnboarding] = useState({
    activeStep: 1,
    done: true,
    importUsersDone: false,
    showImport: false
  })

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1280)
  const handleWindowResizeEvent = () => {
    setIsMobile(window.innerWidth < 1280)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResizeEvent)
    return () => {
      window.removeEventListener('resize', handleWindowResizeEvent)
    }
  }, [])

  const updateOnboarding = o => {
    settingsService
      .setOnboardingStatus({
        currentStep: o.activeStep,
        completedSteps: []
      })
      .then(() => {
        // console.log(resp);
      })
    setOnboarding(o)
  }

  const [userViewId, setUserViewId] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [infoMessage, setInfoMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [warningMessage, setWarningMessage] = useState(null)
  const [timePeriod, setTimePeriod] = useState(null)

  const updateSettings = () => {
    const userLocal = JSON.parse(localStorage.getItem('user'))
    const user = parseJwt(userLocal.accessToken)

    const allowedLangs = ['Polish', 'English']

    Promise.all([
      settingsService.getlocations(),
      settingsService.getlanguages(),
      settingsService.getdepartments(),
      settingsService.getOnboardingStatus(),
      companyService.getCompany(user.companyId)
    ]).then(values => {
      const [countriesData, languagesData, departmentsData, onboardingData, companyData] = values
      const companyLanguage = allowedLangs.includes(companyData.data.language) ? companyData.data.language : 'English'
      const countries = countriesData.data
      const languages = languagesData.data
      const departments = departmentsData.data
      const onboardingStatus = onboardingData.data
      setSettings({ countries, languages, departments, companyLanguage })
      setOnboarding({
        ...onboarding,
        activeStep: onboardingStatus.currentStep < 1 ? 1 : onboardingStatus.currentStep,
        done: onboardingStatus.currentStep == 4
      })
    })
  }

  useEffect(() => {
    updateSettings()
  }, [])

  return (
    <div className='app-layout'>
      {isMobile && <LargerScreenMobile />}
      <Provider store={store}>
        <SettingsContext.Provider value={[settings, updateSettings]}>
          <OnboardingContext.Provider value={[onboarding, updateOnboarding]}>
            <UserViewContext.Provider value={[userViewId, setUserViewId]}>
              <SnackBarContext.Provider
                value={{
                  setSuccessMessage,
                  setInfoMessage,
                  setErrorMessage,
                  setWarningMessage
                }}
              >
                {/* <Onboarding /> */}
                <ErrorBoundary>
                  <Nav />
                  <DashBoardDateContext.Provider value={[timePeriod, setTimePeriod]}>
                    <AppContent>
                      <Outlet />
                    </AppContent>
                  </DashBoardDateContext.Provider>
                </ErrorBoundary>
                <Drawer
                  anchor='right'
                  open={!!userViewId}
                  sx={{ zIndex: 1300 }}
                  onClose={() => {
                    setUserViewId(null)
                    // setShowUsersDialog(true)
                  }}
                >
                  <div
                    style={{
                      position: 'fixed',
                      top: '24px',
                      right: '1042px',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setUserViewId(null)
                      // setShowUsersDialog(true)
                    }}
                  >
                    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M37.5 10.5L10.5 37.5'
                        stroke='white'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M37.5 37.5L10.5 10.5'
                        stroke='white'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  {userViewId && <UserView userID={userViewId} />}
                </Drawer>
              </SnackBarContext.Provider>
            </UserViewContext.Provider>
          </OnboardingContext.Provider>

          <SnackbarCustom
            successMessage={successMessage}
            infoMessage={infoMessage}
            warningMessage={warningMessage}
            errorMessage={errorMessage}
            onClose={() => {
              setSuccessMessage(null)
              setInfoMessage(null)
              setWarningMessage(null)
              setErrorMessage(null)
            }}
          />
        </SettingsContext.Provider>
        <ToastContainer />
      </Provider>
    </div>
  )
}

function RequireAuth({ children }) {
  let auth = localStorage.getItem('user')
  // const userRole = auth ? parseJwt(JSON.parse(auth).accessToken).role : ''
  const exp = auth ? parseJwt(JSON.parse(auth).accessToken).exp : ''

  const currentDate = new Date()
  const timestamp = currentDate.getTime()

  let location = useLocation()

  const query = useQuery()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(!auth)
  const [authirized, setAuthirized] = useState(true)

  useEffect(() => {
    if (query.get('token')) {
      setLoading(true)
      authService
        .validateToken(query.get('token'))
        .then(() => {
          setLoading(false)
          setAuthirized(true)
          navigate(location.pathname)
        })
        .catch(err => {
          setLoading(false)
          console.error(err)
          setAuthirized(false)
        })
    }

    if (exp ? exp < timestamp / 1000 : !exp) {
      localStorage.removeItem('user')
      setAuthirized(false)
      navigate('/login')
    }
  }, [])

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          {authirized && children}
          {!authirized && authirized !== null && <Navigate to='/login' state={{ from: location }} replace />}
        </>
      )}
    </>
  )
}

function App() {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Routes>
          <Route path='*' element={<NotFoundPage />} />
          <Route
            path='/'
            element={
              <RequireAuth>
                <AppLayout />
              </RequireAuth>
            }
          >
            <Route index element={<DashBoardNew />} />
            <Route path='/get-started' element={<GetStartedPage />} />
            <Route path='/phishing-click-rate' element={<PhishingClickRate />} />
            <Route path='/all-risks' element={<RiskyBehaviors />} />
            <Route path='/risky-individuals' element={<RiskyIndividuals />} />
            <Route path='/risky-departments' element={<RiskyDepartments />} />
            <Route path='/phishing-micro-training-followed' element={<PhishingMicroTrainingFollowed />} />
            <Route path='/team' element={<Team />} />
            <Route path='/companies' element={<Companies />} />
            <Route path='/groups' element={<Groups />} />
            <Route path='/threats' element={<Threats />} />
            <Route path='/workflows' element={<Workflows />} />
            <Route path='/workflows-total-logs' element={<WorkflowLogs />} />

            <Route path='/overview' element={<Overview />} />
            <Route path='/templates' element={<Templates />} />
            <Route path='/manual-simulation' element={<ManualSimulation />} />
            <Route path='/simulation-campaign/*' element={<ManualSimulationV2 />} />
            <Route path='/simulation-campaign/:id/*' element={<CampaignDetails />} />
            <Route path='/simulation-campaign/:id/steps/:stepId/*' element={<StepStatistic />} />

            <Route path='/new-campaign' element={<NewCampaignPage />} />
            <Route path='/edit-campaign' element={<EditCampaign />} />

            <Route path='/breaches' element={<Breaches />} />

            <Route path='/microtraining' element={<Microtraining />} />
            {/*<Route path='/training-packages' element={<TrainingPackages />} />*/}
            <Route path='/training-packages' element={<TrainingPackages2 />} />
            <Route path='/training-packages/:id/*' element={<TrainingDetails />} />
            <Route path='/security-assistant' element={<SecurityAssistant />} />
            <Route path='/settings' element={<Settings />}>
              <Route index element={<Workspace />} />
              <Route path='/settings/phishing-simulations' element={<PhishingSimulations />} />
              <Route path='/settings/integrations' element={<Integrations />} />
              <Route path='/settings/sso' element={<SsoSettings />} />
              <Route path='/settings/plugins' element={<PluginsAndBot />} />
              <Route path='/settings/alerts' element={<Alerts />} />
              <Route path='/settings/members' element={<Members />} />
            </Route>
          </Route>

          <Route path='/login' element={<LoginPage />} />
          <Route path='/registration' element={<RegistrationPage />} />

          <Route path='/auth-link' element={<AuthLinkPage />} />

          <Route path='/google-redirect' element={<GoogleRedirectPage />} />
          <Route path='/okta-redirect' element={<OktaRedirectPage />} />
          <Route path='/outlook-redirect' element={<OutlookRedirectPage />} />
          <Route path='/slack-redirect' element={<SlackRedirectPage />} />
          <Route path='/slack-simulation-redirect' element={<SlackSimRedirectPage />} />

          <Route path='/outlook-workflows-redirect' element={<OutlookWorkflowsRedirectPage />} />

          <Route path='/google-sso-redirect' element={<GoogleRedirectSsoPage />} />
          <Route path='/okta-sso-redirect' element={<OktaRedirectSsoPage />} />
          <Route path='/outlook-sso-redirect' element={<OutlookRedirectSsoPage />} />

          <Route path='/cards' element={<TrainingCards />} />
          <Route path='/reward' element={<RewardPage />} />
          <Route path='/user' element={<LoginPage />} />
        </Routes>
      </LocalizationProvider>
    </div>
  )
}

export default App
