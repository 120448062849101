import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { useContext, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { SnackBarContext } from 'src/App'
import Spacer from 'src/common/components/Spacer'
import { OktaLogo } from 'src/common/logo/okta'
import settingsService from 'src/services/settings.service'

export const ConnectorIcon = ({ type }) => {
  if (type == 'GOOGLE') {
    return (
      <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M29.403 15.3598C29.403 14.3467 29.305 13.301 29.1416 12.3206H14.9915V18.1048H23.096C22.7692 19.9675 21.6908 21.6015 20.0895 22.6472L24.926 26.4053C27.7691 23.7583 29.403 19.9022 29.403 15.3598Z'
          fill='#4280EF'
        />
        <path
          d='M14.9915 30C19.0437 30 22.4424 28.6602 24.926 26.3726L20.0895 22.6472C18.7496 23.5622 17.0176 24.0851 14.9915 24.0851C11.07 24.0851 7.76946 21.4381 6.56033 17.9088L1.59311 21.7322C4.14208 26.7975 9.30538 30 14.9915 30Z'
          fill='#34A353'
        />
        <path
          d='M6.56033 17.876C5.93942 16.0133 5.93942 13.9872 6.56033 12.1245L1.59311 8.26837C-0.531035 12.5167 -0.531035 17.5166 1.59311 21.7322L6.56033 17.876Z'
          fill='#F6B704'
        />
        <path
          d='M14.9915 5.94817C17.1157 5.91549 19.2071 6.73247 20.7431 8.20303L25.024 3.88938C22.3117 1.34041 18.717 -0.0321088 14.9915 0.000570345C9.30538 0.000570345 4.14208 3.20312 1.59311 8.26839L6.56033 12.1245C7.76946 8.5625 11.07 5.94817 14.9915 5.94817Z'
          fill='#E54335'
        />
      </svg>
    )
  }
  if (type == 'SLACK') {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 2447.6 2452.5'>
        <g clipRule='evenodd' fillRule='evenodd'>
          <path
            d='m897.4 0c-135.3.1-244.8 109.9-244.7 245.2-.1 135.3 109.5 245.1 244.8 245.2h244.8v-245.1c.1-135.3-109.5-245.1-244.9-245.3.1 0 .1 0 0 0m0 654h-652.6c-135.3.1-244.9 109.9-244.8 245.2-.2 135.3 109.4 245.1 244.7 245.3h652.7c135.3-.1 244.9-109.9 244.8-245.2.1-135.4-109.5-245.2-244.8-245.3z'
            fill='#36c5f0'
          />
          <path
            d='m2447.6 899.2c.1-135.3-109.5-245.1-244.8-245.2-135.3.1-244.9 109.9-244.8 245.2v245.3h244.8c135.3-.1 244.9-109.9 244.8-245.3zm-652.7 0v-654c.1-135.2-109.4-245-244.7-245.2-135.3.1-244.9 109.9-244.8 245.2v654c-.2 135.3 109.4 245.1 244.7 245.3 135.3-.1 244.9-109.9 244.8-245.3z'
            fill='#2eb67d'
          />
          <path
            d='m1550.1 2452.5c135.3-.1 244.9-109.9 244.8-245.2.1-135.3-109.5-245.1-244.8-245.2h-244.8v245.2c-.1 135.2 109.5 245 244.8 245.2zm0-654.1h652.7c135.3-.1 244.9-109.9 244.8-245.2.2-135.3-109.4-245.1-244.7-245.3h-652.7c-135.3.1-244.9 109.9-244.8 245.2-.1 135.4 109.4 245.2 244.7 245.3z'
            fill='#ecb22e'
          />
          <path
            d='m0 1553.2c-.1 135.3 109.5 245.1 244.8 245.2 135.3-.1 244.9-109.9 244.8-245.2v-245.2h-244.8c-135.3.1-244.9 109.9-244.8 245.2zm652.7 0v654c-.2 135.3 109.4 245.1 244.7 245.3 135.3-.1 244.9-109.9 244.8-245.2v-653.9c.2-135.3-109.4-245.1-244.7-245.3-135.4 0-244.9 109.8-244.8 245.1 0 0 0 .1 0 0'
            fill='#e01e5a'
          />
        </g>
      </svg>
    )
  }
  if (type == 'OUTLOOK') {
    return (
      <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_782_126058)'>
          <path
            d='M28.5959 2.00006H11.404C10.6286 2.00006 10 2.62868 10 3.40408V5.00008L19.69 8.00011L29.9999 5.00008V3.40408C29.9999 2.62868 29.3713 2.00006 28.5959 2.00006Z'
            fill='#0364B8'
          />
          <path
            d='M31.6499 17.4049C31.7964 16.9445 31.9133 16.4753 31.9999 15.9999C31.9996 15.7623 31.8727 15.5428 31.6668 15.424L31.6538 15.4159L31.6497 15.4139L20.8118 9.23996C20.765 9.20968 20.7166 9.18184 20.6667 9.15702C20.2466 8.94839 19.7528 8.94839 19.3327 9.15702C19.2829 9.18156 19.2344 9.20896 19.1876 9.23895L8.34964 15.4149L8.34562 15.4169L8.33357 15.424C8.12738 15.5425 8.00011 15.7622 7.99953 15.9999C8.0862 16.4753 8.203 16.9445 8.3495 17.4049L19.8415 25.81L31.6495 17.4049H31.6499Z'
            fill='#0A2767'
          />
          <path
            d='M23.9999 4.99994H16.9999L14.9789 7.99996L16.9999 11L23.9999 17H29.9999V11L23.9999 4.99994Z'
            fill='#28A8EA'
          />
          <path d='M10 4.99994H17V11H10V4.99994Z' fill='#0078D4' />
          <path d='M23.9999 4.99994H29.9999V11H23.9999V4.99994Z' fill='#50D9FF' />
          <path
            d='M23.9999 17L16.9999 11H9.99989V17L16.9999 23.0001L27.8319 24.7681L23.9999 17.0002V17Z'
            fill='#0364B8'
          />
          <path d='M17 11H24V17H17V11Z' fill='#0078D4' />
          <path d='M10 17.0001H17V23.0001H10V17.0001Z' fill='#064A8C' />
          <path d='M23.9999 17.0001H29.9999V23.0001H23.9999V17.0001Z' fill='#0078D4' />
          <path
            opacity='0.5'
            d='M20.1899 25.218L8.39685 16.6179L8.89188 15.748C8.89188 15.748 19.6369 21.868 19.8009 21.9599C19.9365 22.0142 20.0886 22.0099 20.2209 21.9479L31.1539 15.7178L31.6499 16.5868L20.1899 25.2178V25.218Z'
            fill='#0A2767'
          />
          <path
            d='M31.667 16.577L31.6529 16.5851L31.6499 16.5871L20.8119 22.761C20.3743 23.0433 19.8213 23.0777 19.3519 22.852L23.1259 27.9129L31.3798 29.71V29.714C31.7693 29.4322 32 28.9806 31.9999 28.4999V15.9999C31.9999 16.238 31.873 16.458 31.6668 16.5769L31.667 16.577Z'
            fill='#1490DF'
          />
          <path
            opacity='0.05'
            d='M32 28.4999V27.762L22.017 22.074L20.812 22.7611C20.3744 23.0433 19.8214 23.0777 19.3521 22.852L23.1261 27.9129L31.38 29.71V29.714C31.7694 29.4322 32.0001 28.9806 32 28.4999Z'
            fill='black'
          />
          <path
            opacity='0.1'
            d='M31.9499 28.8829L21.0069 22.65L20.8119 22.76C20.3744 23.0425 19.8214 23.0774 19.3519 22.852L23.1259 27.9129L31.3798 29.7099V29.714C31.6598 29.5112 31.8611 29.2181 31.9498 28.884V28.883L31.9499 28.8829Z'
            fill='black'
          />
          <path
            d='M8.34993 16.59V16.5799H8.33989L8.3099 16.56C8.11548 16.4406 7.99782 16.2281 7.99997 15.9999V28.4999C7.99882 29.3273 8.66862 29.9988 9.49596 29.9999C9.49725 29.9999 9.49869 29.9999 9.49998 29.9999H30.5C30.625 29.9987 30.7492 29.9819 30.87 29.95C30.9327 29.9391 30.9934 29.9189 31.05 29.89C31.0712 29.888 31.0917 29.8811 31.11 29.8701C31.1917 29.8367 31.2691 29.7929 31.34 29.7401C31.3599 29.73 31.37 29.7301 31.38 29.7101L8.35008 16.5901L8.34993 16.59Z'
            fill='#28A8EA'
          />
          <path
            opacity='0.1'
            d='M18 24.667V8.33299C17.9978 7.59777 17.4022 7.00215 16.667 7H10.03V14.456L8.35007 15.4141L8.34505 15.4161L8.333 15.4231C8.1268 15.5421 7.99996 15.762 7.99996 16.0001V26.0001H16.667C17.4022 25.9979 17.9978 25.4023 18 24.6671V24.667Z'
            fill='black'
          />
          <path
            opacity='0.2'
            d='M17 25.6669V9.33293C16.9979 8.59771 16.4023 8.00209 15.667 7.99994H10.03V14.456L8.35007 15.414L8.34505 15.4161L8.333 15.4231C8.1268 15.542 7.99996 15.762 7.99996 16V27H15.6669C16.4021 26.9979 16.9977 26.4023 16.9999 25.667L17 25.6669Z'
            fill='black'
          />
          <path
            opacity='0.2'
            d='M17 23.667V9.33311C16.9979 8.59789 16.4023 8.00227 15.667 8.00012H10.03V14.4562L8.35007 15.4142L8.34505 15.4162L8.333 15.4233C8.1268 15.5422 7.99996 15.7622 7.99996 16.0002V25.0002H15.6669C16.4021 24.998 16.9977 24.4024 16.9999 23.6672L17 23.667Z'
            fill='black'
          />
          <path
            opacity='0.2'
            d='M15.9999 23.667V9.33311C15.9978 8.59789 15.4022 8.00227 14.6669 8.00012H10.03V14.4562L8.35007 15.4142L8.34505 15.4162L8.333 15.4233C8.1268 15.5422 7.99996 15.7622 7.99996 16.0002V25.0002H14.6669C15.4022 24.998 15.9978 24.4024 15.9999 23.6672V23.667Z'
            fill='black'
          />
          <path
            d='M1.33299 7.99994H14.6669C15.4032 7.99994 15.9999 8.5967 15.9999 9.33293V22.6669C15.9999 23.4031 15.4032 23.9999 14.6669 23.9999H1.33299C0.596762 23.9999 0 23.4031 0 22.6669V9.33293C0 8.5967 0.596762 7.99994 1.33299 7.99994Z'
            fill='#0078D4'
          />
          <path
            d='M3.86697 13.468C4.22124 12.7131 4.79303 12.0813 5.50903 11.654C6.30223 11.1999 7.20548 10.9736 8.11906 11C8.96506 10.9816 9.79972 11.1961 10.5321 11.62C11.2214 12.0307 11.7765 12.6327 12.1301 13.353C12.5152 14.147 12.707 15.0208 12.6901 15.9031C12.7086 16.8246 12.511 17.7377 12.1132 18.5691C11.752 19.3151 11.1801 19.9388 10.4682 20.3631C9.70717 20.8004 8.8408 21.0208 7.96323 21C7.09872 21.0205 6.24512 20.8037 5.49526 20.373C4.80035 19.9618 4.23874 19.359 3.8773 18.6369C3.4896 17.8546 3.29517 16.9909 3.31024 16.1179C3.29345 15.2039 3.48386 14.2979 3.86726 13.4678L3.86697 13.468ZM5.61693 17.7259C5.80562 18.2032 6.1256 18.6171 6.53999 18.9199C6.96212 19.2153 7.46791 19.3679 7.98303 19.3549C8.53173 19.3763 9.07267 19.2188 9.52408 18.906C9.93374 18.6034 10.2457 18.1871 10.421 17.709C10.6177 17.1762 10.7147 16.6119 10.707 16.044C10.7132 15.4708 10.6218 14.9007 10.4369 14.358C10.2739 13.8699 9.97319 13.4393 9.571 13.118C9.1315 12.7896 8.59228 12.6225 8.04401 12.6451C7.5177 12.6317 7.00058 12.7854 6.56696 13.0841C6.14454 13.3875 5.8171 13.8047 5.62296 14.2871C5.19336 15.3931 5.19092 16.6194 5.61593 17.7271L5.61693 17.7261V17.7259Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_782_126058'>
            <rect width='32' height='32' fill='white' />
          </clipPath>
        </defs>
      </svg>
    )
  }
  if (type == 'AD') {
    return (
      <svg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M2.13887 18.3415L17.5 28.2927L33.0555 18.1464L35 20.4878L17.5 32L-1.52588e-05 20.4878L2.13887 18.3415Z'
          fill='#50E6FF'
        />
        <path d='M3.30556 16.9756L17.5 0L31.8889 16.9756L17.5 26.1463L3.30556 16.9756Z' fill='white' />
        <path d='M17.5 0V26.1463L3.30556 16.9756L17.5 0Z' fill='#50E6FF' />
        <path d='M17.5 0V26.1463L31.8889 16.9756L17.5 0Z' fill='url(#paint0_linear_782_126044)' />
        <path d='M17.5 13.4634L31.8889 16.9756L17.5 26.1463V13.4634Z' fill='#53B1E0' />
        <path d='M17.5 26.1463L3.30556 16.9756L17.5 13.4634V26.1463Z' fill='#9CEBFF' />
        <path d='M17.5 32L35 20.4878L33.0555 18.1464L17.5 28.2927V32Z' fill='url(#paint1_linear_782_126044)' />
        <defs>
          <linearGradient
            id='paint0_linear_782_126044'
            x1='25.8599'
            y1='23.7954'
            x2='16.8081'
            y2='6.70927'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#1988D9' />
            <stop offset='0.9' stopColor='#54AEF0' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_782_126044'
            x1='21.9839'
            y1='18.8232'
            x2='28.2385'
            y2='29.5751'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0.1' stopColor='#54AEF0' />
            <stop offset='0.29' stopColor='#4FABEE' />
            <stop offset='0.51' stopColor='#41A2E9' />
            <stop offset='0.74' stopColor='#2A93E0' />
            <stop offset='0.88' stopColor='#1988D9' />
          </linearGradient>
        </defs>
      </svg>
    )
  }
  if (type == 'OKTA') {
    return <OktaLogo />
  }
}

const connectorsLocal = [
  {
    title: 'Google',
    connectorType: 'GOOGLE',
    availableToUpdate: false
  },
  {
    title: 'Outlook',
    connectorType: 'OUTLOOK',
    availableToUpdate: false
  },
  {
    title: 'Active Directory',
    connectorType: 'AD',
    availableToUpdate: true
  },
  {
    title: 'Okta',
    connectorType: 'OKTA',
    availableToUpdate: false
  }
]

export const Integrations = () => {
  dayjs.extend(utc)

  const [open, setOpen] = useState(false)
  const [selectedConnector, setSelectedConnector] = useState(null)

  const { setSuccessMessage, setErrorMessage } = useContext(SnackBarContext)

  const onClose = () => {
    setOpen(false)
  }

  const onAction = () => {
    settingsService
      .disconnect(selectedConnector)
      .then(() => {
        updateConnectors()
        setOpen(false)
        setSuccessMessage('Provider disconnected')
      })
      .catch(err => {
        console.error(err)
        setErrorMessage('Something went wrong')
      })
  }

  const { connectors, query, handleConnectTo, updateConnectors, setUpdadeDialog } = useOutletContext()

  function disconnect(connector) {
    setOpen(true)
    setSelectedConnector(connector)
    if (connector == 'AD') {
      setUpdadeDialog(false)
    }
  }

  const resyncOkta = () => {
    settingsService
      .resyncOkta()
      .then(() => {
        setSuccessMessage('Resync successfully completed')
      })
      .catch(error => {
        console.error(error)
        setErrorMessage('Something went wrong')
      })
  }

  const resyncOutlook = () => {
    settingsService
      .resyncOutlook()
      .then(() => {
        setSuccessMessage('Resync successfully completed')
      })
      .catch(error => {
        setErrorMessage('Something went wrong')
      })
  }

  return (
    <div className='settings-section'>
      <h2 id='integrations-section'>Sync users</h2>
      <p style={{ maxWidth: '730px', lineHeight: '24px' }}>
        Connect Cyberlift to your external workforce platform. Users and groups will then sync automatically
      </p>
      <div
        style={{
          marginTop: '20px',
          flexWrap: 'wrap',
          width: '100%',
          marginBottom: 20
        }}
        className='row'
      >
        {connectorsLocal?.map(el => {
          return (
            <div
              className='column'
              key={el.connectorType}
              style={{
                alignItems: 'flex-start',
                border:
                  query.get('errorSync') && query.get('errorSyncType') == el.connectorType
                    ? '1px solid #B40020'
                    : '1px solid #E7E7EE',
                padding: '17px 21px',
                width: 322,
                borderRadius: 14,
                marginRight: 14,
                marginBottom: 16,
                height: 180
              }}
            >
              <div style={{ marginBottom: 7 }}>
                <ConnectorIcon type={el.connectorType} />
              </div>

              <p style={{ fontWeight: 600 }}>{el.title}</p>

              {connectors?.map(connector => connector.provider).includes(el.connectorType) && (
                <>
                  <div
                    style={{
                      color: '#00BC62',
                      marginTop: 10
                    }}
                    key={el.connectorType}
                    className='row row-center'
                  >
                    <p>Synced</p>
                    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M13.5 4.5L6.5 11.4997L3 8'
                        stroke='#00BC62'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  {/*<p*/}
                  {/*  style={{*/}
                  {/*    fontSize: 13,*/}
                  {/*    opacity: 0.7,*/}
                  {/*    marginTop: 6*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  Last sync:{' '}*/}
                  {/*  {dayjs*/}
                  {/*    .utc(connectors.find(con => con.provider == el.connectorType).updatedAt)*/}
                  {/*    .local()*/}
                  {/*    .format('DD MMM YYYY HH:mm')}*/}
                  {/*</p>*/}
                </>
              )}
              {query.get('errorSync') && query.get('errorSyncType') == el.connectorType && (
                <p className='error-text' style={{ marginTop: 20, marginLeft: 0 }}>
                  {query.get('errorSync')}
                </p>
              )}
              <Spacer />
              {connectors?.map(connector => connector.provider).includes(el.connectorType) ? (
                <div className='row' style={{ width: '100%' }}>
                  <Button
                    key={el.connectorType + 'btn'}
                    sx={{
                      fontFamily: 'Plus Jakarta Sans',
                      fontSize: '15px',
                      color: '#6123FF',
                      textTransform: 'none',
                      padding: 0,
                      mr: '20px'
                    }}
                    onClick={() => disconnect(el.connectorType)}
                  >
                    Disconnect
                  </Button>
                  {el.availableToUpdate && (
                    <Button
                      key={el.connectorType + 'btn'}
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '15px',
                        color: '#6123FF',
                        textTransform: 'none',
                        padding: 0
                      }}
                      onClick={() => handleConnectTo(el.connectorType, true)}
                    >
                      Update
                    </Button>
                  )}
                  {el.connectorType == 'OKTA' && (
                    <Button
                      key={el.connectorType + 'btn'}
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '15px',
                        color: '#6123FF',
                        textTransform: 'none',
                        padding: 0
                      }}
                      onClick={() => resyncOkta()}
                    >
                      Resync
                    </Button>
                  )}
                  {el.connectorType == 'OUTLOOK' && (
                    <Button
                      key={el.connectorType + 'btn'}
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '15px',
                        color: '#6123FF',
                        textTransform: 'none',
                        padding: 0
                      }}
                      onClick={() => resyncOutlook()}
                    >
                      Resync
                    </Button>
                  )}
                </div>
              ) : (
                <Button
                  key={el.connectorType + 'btn'}
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    fontSize: '15px',
                    color: '#6123FF',
                    textTransform: 'none',
                    padding: 0
                  }}
                  onClick={() => handleConnectTo(el.connectorType)}
                >
                  Connect
                </Button>
              )}
            </div>
          )
        })}
      </div>

      <Dialog
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '22px',
            paddingTop: '8px',
            maxWidth: 'unset',
            width: '684px'
          }
        }}
        open={open}
        onClose={onClose}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Satoshi',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '35px',
            lineHeight: '110%',
            color: '#1B1B26'
          }}
        >
          Disconnect?
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              fontSize: '14px',
              marginBottom: '80px',
              lineHeight: '22px'
            }}
          >
            Are you sure you want to disconnect this provider?
          </p>
          <div className='row'>
            <Button
              sx={{
                padding: '10px 0px',
                fontFamily: 'Plus Jakarta Sans',
                textTransform: 'none',
                borderRadius: '8px',
                borderColor: '#D0D0D8',
                color: '#1B1B26'
              }}
              variant={'outlined'}
              fullWidth
              onClick={onClose}
            >
              Cancel
            </Button>
            <div style={{ width: '20px' }}></div>
            <Button
              onClick={onAction}
              sx={{
                padding: '10px 0px',
                fontFamily: 'Plus Jakarta Sans',
                textTransform: 'none',
                borderRadius: '8px',
                background: '#6123FF'
              }}
              variant={'contained'}
              fullWidth
            >
              Yes
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
