import React from 'react'
import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import ActivityModalCard from './ActivityModalCard'

const groupByDate = data => {
  return data.reduce((acc, current) => {
    const date = dayjs(current.simulation.actionAt).format('YYYY-MM-DD')
    if (!acc[date]) {
      acc[date] = []
    }
    acc[date].push(current)
    return acc
  }, {})
}

const ActivityList = ({ data }) => {
  const groupedData = groupByDate(data)

  return (
    <Box
      display='flex'
      flexDirection='column'
      rowGap={3}
      maxHeight={'calc(95vh - 190px)'}
      pl={'12px'}
      pr={'12px'}
      pt={3}
      sx={{
        overflowY: 'auto',
        overflowX: 'auto',
        '::-webkit-scrollbar': {
          width: '6px',
          height: '6px'
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: '#DBDBE6',
          borderRadius: '2px',
          '&:hover': {
            backgroundColor: '#DBDBE6'
          }
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: 'transparent'
        },
        scrollbarWidth: 'thin',
        scrollbarColor: '#DBDBE6 transparent'
      }}
    >
      {Object.keys(groupedData).map(date => (
        <Box key={date} display='flex' flexDirection='column' rowGap={2}>
          <Typography fontWeight={500} fontSize={16} fontFamily={'Satoshi'}>
            {dayjs(date).format('MMMM DD, YYYY')}
          </Typography>

          {groupedData[date].map((item, index) => (
            <ActivityModalCard
              key={index}
              type={item.step.type}
              sequenceNumber={item.step.sequenceNumber}
              status={item.simulation.status}
              browser={item.simulation.browser}
              ipAddress={item.simulation.ipAddress}
              location={item.simulation.location}
              actionAt={item.simulation.actionAt}
            />
          ))}
        </Box>
      ))}
    </Box>
  )
}

export default ActivityList
