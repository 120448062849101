import React from 'react'
import { Button as MuiButton } from '@mui/material'

export function Button(props) {
  return (
    <MuiButton
      sx={{
        fontFamily: 'Plus Jakarta Sans',
        textTransform: 'none',
        borderRadius: '8px',
        fontSize: '16px',
        color: '#1B1B26',
        padding: '6px 20px',
        '&.MuiButton-contained': {
          background: '#6123FF',
          color: '#ffffff'
        },
        '&.Mui-disabled': {
          background: '#b6b6b6',
          color: '#ffffff'
        }
      }}
      {...props}
    />
  )
}
