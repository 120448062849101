import { Button } from '@mui/material'
import React, { useContext } from 'react'
import { useOutletContext } from 'react-router-dom'
import { UserViewContext } from 'src/App'
import UserAvatar from 'src/common/components/UserAvatar'
import EditIcon from 'src/common/icons/editIcon'

export const Members = () => {
  const { admins, setInviteAdminDialog } = useOutletContext()

  const [userViewId, setUserViewId] = useContext(UserViewContext)

  return (
    <div className='settings-section'>
      <h2>Members</h2>
      <p style={{ maxWidth: '730px', lineHeight: '24px' }}>
        Here is the company administrators list. Click invite to add a new company admin.
      </p>
      <div className='column' style={{ marginTop: '20px', marginBottom: '20px' }}>
        {admins &&
          admins.map(el => (
            <div
              onClick={() => {
                setUserViewId(el.id)
              }}
              key={el?.id}
              className='row row-center admins-list-item'
            >
              <div className='row' style={{ alignItems: 'center', width: '400px' }}>
                <UserAvatar firstName={el?.firstName} lastName={el?.lastName} />
                <div className='column'>
                  <p
                    style={{
                      fontWeight: '600',
                      fontSize: '16px',
                      marginBottom: '8px'
                    }}
                  >
                    {(el?.firstName || 'unknown') + ' ' + (el?.lastName || 'unknown')}
                  </p>
                  <p
                    style={{
                      fontSize: '13px',
                      color: '#76767F'
                    }}
                  >
                    {el?.email}
                  </p>
                </div>
              </div>

              <div className='row row-center'>
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    opacity='0.2'
                    d='M4.23348 18.3791C4.25686 18.4777 4.29995 18.5705 4.36014 18.652C4.42034 18.7335 4.4964 18.802 4.58375 18.8534C4.6711 18.9047 4.76794 18.9378 4.86843 18.9508C4.96892 18.9637 5.07099 18.9562 5.16851 18.9287C9.63937 17.6943 14.3611 17.6938 18.8322 18.9274C18.9297 18.9549 19.0317 18.9624 19.1322 18.9495C19.2326 18.9365 19.3294 18.9034 19.4167 18.8521C19.5041 18.8008 19.5801 18.7323 19.6403 18.6509C19.7005 18.5694 19.7436 18.4766 19.767 18.3781L22.1566 8.22336C22.1886 8.08742 22.182 7.94525 22.1375 7.81285C22.0931 7.68046 22.0125 7.56311 21.905 7.47401C21.7974 7.38492 21.6672 7.32762 21.5288 7.30857C21.3904 7.28953 21.2495 7.30949 21.1219 7.36621L16.3797 9.47386C16.2084 9.54997 16.0149 9.55922 15.8372 9.49978C15.6595 9.44035 15.5105 9.31654 15.4195 9.15274L12.6583 4.18264C12.5933 4.06572 12.4983 3.9683 12.383 3.90047C12.2677 3.83264 12.1364 3.79688 12.0027 3.79688C11.8689 3.79688 11.7376 3.83264 11.6223 3.90047C11.5071 3.9683 11.412 4.06572 11.3471 4.18264L8.5859 9.15274C8.49489 9.31654 8.34587 9.44035 8.16815 9.49978C7.99044 9.55921 7.79692 9.54997 7.62568 9.47386L2.88273 7.36589C2.75513 7.30918 2.61424 7.28921 2.47592 7.30824C2.33759 7.32727 2.20732 7.38454 2.09978 7.47359C1.99223 7.56265 1.91169 7.67996 1.8672 7.81231C1.82272 7.94466 1.81607 8.08681 1.84799 8.22274L4.23348 18.3791Z'
                    fill='#76767F'
                  />
                  <path
                    d='M4.23348 18.3791C4.25686 18.4777 4.29995 18.5705 4.36014 18.652C4.42034 18.7335 4.4964 18.802 4.58375 18.8534C4.6711 18.9047 4.76794 18.9378 4.86843 18.9508C4.96892 18.9637 5.07099 18.9562 5.16851 18.9287C9.63937 17.6943 14.3611 17.6938 18.8322 18.9274C18.9297 18.9549 19.0317 18.9624 19.1322 18.9495C19.2326 18.9365 19.3294 18.9034 19.4167 18.8521C19.5041 18.8008 19.5801 18.7323 19.6403 18.6509C19.7005 18.5694 19.7436 18.4766 19.767 18.3781L22.1566 8.22336C22.1886 8.08742 22.182 7.94525 22.1375 7.81285C22.0931 7.68046 22.0125 7.56311 21.905 7.47401C21.7974 7.38492 21.6672 7.32762 21.5288 7.30857C21.3904 7.28953 21.2495 7.30949 21.1219 7.36621L16.3797 9.47386C16.2084 9.54997 16.0149 9.55922 15.8372 9.49978C15.6595 9.44035 15.5105 9.31654 15.4195 9.15274L12.6583 4.18264C12.5933 4.06572 12.4983 3.9683 12.383 3.90047C12.2677 3.83264 12.1364 3.79688 12.0027 3.79688C11.8689 3.79688 11.7376 3.83264 11.6223 3.90047C11.5071 3.9683 11.412 4.06572 11.3471 4.18264L8.5859 9.15274C8.49489 9.31654 8.34587 9.44035 8.16815 9.49978C7.99044 9.55921 7.79692 9.54997 7.62568 9.47386L2.88273 7.36589C2.75513 7.30918 2.61424 7.28921 2.47592 7.30824C2.33759 7.32727 2.20732 7.38454 2.09978 7.47359C1.99223 7.56265 1.91169 7.67995 1.8672 7.81231C1.82272 7.94466 1.81607 8.08681 1.84799 8.22274L4.23348 18.3791Z'
                    stroke='#76767F'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M9 15.1574C10.9945 14.9475 13.0055 14.9475 15 15.1574'
                    stroke='#76767F'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                <p>Admin</p>
              </div>
            </div>
          ))}
      </div>
      <Button
        variant='outlined'
        onClick={() => setInviteAdminDialog(true)}
        sx={{
          borderColor: '#E7E7EE',
          borderRadius: '12px',
          color: '#6123FF',
          textTransform: 'none',
          flex: '1',
          mr: '12px',
          width: '262px'
        }}
        startIcon={<EditIcon />}
      >
        Invite Admin
      </Button>
    </div>
  )
}
