import React, { useState } from 'react'
import { Button } from '@mui/material'
import PlusIcon from '../../../common/icons/plusIcon'
import TrainingModal from './TrainingModal'

export default function NewTrainingButton({ variant = 'contained' }) {
  const [open, setOpen] = useState(false)

  function handleClick() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <>
      <Button
        variant={variant}
        sx={{
          background: variant === 'contained' ? '#6123FF' : 'none',
          borderRadius: '12px',
          fontFamily: 'Plus Jakarta Sans',
          color: variant === 'contained' ? '#fff' : '#6123FF',
          textTransform: 'none',
          border: variant === 'outlined' ? '1px solid #E7E7E7' : 'none',
          width: 230
        }}
        startIcon={<PlusIcon color={variant === 'contained' ? '#ffffff' : '#6123FF'} />}
        onClick={handleClick}
      >
        New Training
      </Button>
      {open && <TrainingModal title={'Create new package'} open={open} handleClose={handleClose} />}
    </>
  )
}
