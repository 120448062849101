import React from 'react'

const GroupsIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.2'
      d='M21 6.75H14.5C14.3377 6.75 14.1798 6.69737 14.05 6.6L11.45 4.65C11.3202 4.55263 11.1623 4.5 11 4.5H6.75C6.65151 4.5 6.55398 4.5194 6.46299 4.55709C6.37199 4.59478 6.28931 4.65002 6.21967 4.71967C6.15002 4.78931 6.09478 4.87199 6.05709 4.96299C6.0194 5.05398 6 5.15151 6 5.25V7.5H8.00002C8.16229 7.5 8.32018 7.55263 8.45 7.65L11.05 9.6C11.1798 9.69737 11.3377 9.75 11.5 9.75H18C18.0985 9.75 18.196 9.7694 18.287 9.80709C18.378 9.84478 18.4607 9.90002 18.5303 9.96967C18.6 10.0393 18.6552 10.122 18.6929 10.213C18.7306 10.304 18.75 10.4015 18.75 10.5V16.5H21.0833C21.1709 16.5 21.2576 16.4828 21.3384 16.4493C21.4193 16.4158 21.4928 16.3667 21.5547 16.3047C21.6166 16.2428 21.6657 16.1693 21.6993 16.0885C21.7328 16.0076 21.75 15.9209 21.75 15.8333V7.5C21.75 7.40151 21.7306 7.30398 21.6929 7.21299C21.6552 7.12199 21.6 7.03931 21.5303 6.96967C21.4607 6.90002 21.378 6.84478 21.287 6.80709C21.196 6.7694 21.0985 6.75 21 6.75Z'
      fill='#1B1B26'
    />
    <path
      d='M18.75 10.5V18.8333C18.75 19.0101 18.6798 19.1797 18.5547 19.3047C18.4297 19.4298 18.2601 19.5 18.0833 19.5H3.75C3.55109 19.5 3.36032 19.421 3.21967 19.2803C3.07902 19.1397 3 18.9489 3 18.75V8.25C3 8.05109 3.07902 7.86032 3.21967 7.71967C3.36032 7.57902 3.55109 7.5 3.75 7.5H8C8.16228 7.5 8.32018 7.55263 8.45 7.65L11.05 9.6C11.1798 9.69737 11.3377 9.75 11.5 9.75H18C18.1989 9.75 18.3897 9.82902 18.5303 9.96967C18.671 10.1103 18.75 10.3011 18.75 10.5Z'
      stroke='#1B1B26'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6 7.5V5.25C6 5.05109 6.07902 4.86032 6.21967 4.71967C6.36032 4.57902 6.55109 4.5 6.75 4.5H11C11.1623 4.5 11.3202 4.55263 11.45 4.65L14.05 6.6C14.1798 6.69737 14.3377 6.75 14.5 6.75H21C21.1989 6.75 21.3897 6.82902 21.5303 6.96967C21.671 7.11032 21.75 7.30109 21.75 7.5V15.8333C21.75 15.9209 21.7328 16.0076 21.6993 16.0885C21.6657 16.1693 21.6166 16.2428 21.5547 16.3047C21.4928 16.3666 21.4193 16.4157 21.3385 16.4493C21.2576 16.4828 21.1709 16.5 21.0833 16.5H18.75'
      stroke='#1B1B26'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default GroupsIcon
