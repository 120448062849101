import React, { useState, useEffect, useContext } from 'react'
import { TextField, Box, Drawer, Slide, Typography } from '@mui/material'

import Spacer from '../../../../common/components/Spacer'
import { LoadingButton } from '@mui/lab'
import { DialogRefContext } from '../../../../common/components/CustomDialog'
import { useSendAiDraftPromptMutation } from '../../../../api/simulation/simulation'
import customToastWithAlert from '../../../../common/components/CustomToastWithAlert'
import { ReactComponent as AIIcon } from '../../../../common/icons/aiPower.svg'

const SUGGESTIONS = [
  'New policy document alert! Please read and share your feedback. Your insights are invaluable.',
  'Where should our next event be? Your vote decides! Click to choose.',
  'A big surprise is coming from our CEO! Click to add the reveal to your calendar. Don’t miss out!',
  'Upcoming project deadline! Please review and ensure all tasks are completed on time. Your attention to detail is critical.',
  'Your opinion matters! Please fill out our anonymous survey to help improve our workplace.'
]

export default function AiDrawer({ open, onClose, onInsert }) {
  const { dialogRef } = useContext(DialogRefContext)
  const [aIDraftFormText, setAIDraftFormText] = useState('')
  const [dialogStyles, setDialogStyles] = useState({})
  const container = dialogRef?.current

  const [handleSendPrompt, { isLoading }] = useSendAiDraftPromptMutation()

  const handleInsert = () => {
    handleSendPrompt(aIDraftFormText)
      .unwrap()
      .then(res => {
        onInsert(res)
      })
      .then(() => {
        setAIDraftFormText('')
        onClose()
      })
      .catch(() => {
        customToastWithAlert({ message: 'AI temporary unavailable', type: 'error' })
      })
  }

  useEffect(() => {
    if (open && container) {
      const rect = container.getBoundingClientRect()

      setDialogStyles({
        position: 'absolute',
        top: rect.bottom - 580,
        width: rect.width,
        left: rect.left,
        margin: 0,
        borderRadius: '0 0 24px 24px'
      })
    }
  }, [open, container, top])

  return (
    <Drawer
      tran
      open={open}
      onClose={() => {
        setAIDraftFormText('')
        onClose()
      }}
      PaperProps={{
        style: {
          ...dialogStyles,
          height: '580px',
          boxShadow: 'none'
        }
      }}
      TransitionComponent={Slide}
      transitionDuration={500}
    >
      <Box
        p={2}
        display='flex'
        flexDirection='column'
        gap={2}
        sx={{
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            columnGap: '8px',
            padding: '16px'
          }}
        >
          <AIIcon style={{ width: 32, height: 32 }} />

          <Typography sx={{ fontFamily: 'Satoshi', fontSize: 24, fontWeight: 500, color: '#1B1B26' }}>
            Write with AI
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 2,
            borderTop: '1px solid #eeeeee',
            paddingTop: '10px',
            maxHeight: 520,
            overflowY: 'auto',
            '::-webkit-scrollbar': {
              width: '6px',
              height: '6px'
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: '#DBDBE6',
              borderRadius: '2px',
              '&:hover': {
                backgroundColor: '#DBDBE6'
              }
            },
            '::-webkit-scrollbar-track': {
              backgroundColor: 'transparent'
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#DBDBE6 transparent'
          }}
        >
          {SUGGESTIONS.map(el => (
            <Box
              key={el.length}
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: 400,
                fontFamily: 'Plus Jakarta Sans',
                padding: '16px',
                borderRadius: '14px',
                background: 'rgba(201,8,217,0.05)',
                cursor: 'pointer'
              }}
              onClick={() => setAIDraftFormText(el)}
            >
              <p>{el}</p>
            </Box>
          ))}
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 12,
            borderTop: '1px solid #eeeeee',
            marginTop: 10,
            paddingTop: 10
          }}
        >
          <TextField
            value={aIDraftFormText}
            onChange={e => {
              setAIDraftFormText(e.target.value)
            }}
            InputLabelProps={{
              shrink: false,
              sx: {
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '14px',
                fontWeight: 400,
                top: aIDraftFormText ? '5px' : '40%',
                transform: aIDraftFormText ? 'translateY(0)' : 'translateY(-50%)',
                padding: '0px 12px',
                '&.Mui-focused': {
                  top: '5px',
                  background: 'transparent',
                  transform: 'translateY(0)',
                  color: '#76767F',
                  fontSize: '14px'
                }
              }
            }}
            InputProps={{
              sx: {
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                fontWeight: 400,
                borderRadius: '14px',
                padding: '0 16px',
                backgroundColor: '#fff',
                '&.Mui-focused': {
                  borderColor: '#76767F'
                }
              }
            }}
            sx={{
              '& .MuiInputBase-root': {
                display: 'flex',
                height: 70,
                padding: '0 16px'
              },
              '& .MuiInputBase-input': {
                marginTop: '12px',
                padding: '0px'
              }
            }}
            label='Content you want in the email'
            multiline
            fullWidth
            minRows={1}
            maxRows={3}
          />
          <div
            className='row '
            style={{
              borderTop: '1px solid #eeeeee',
              marginTop: 10,
              paddingTop: 10
            }}
          >
            <Spacer />
            <LoadingButton
              loading={isLoading}
              className='ai-draft-form-loading-button'
              onClick={handleInsert}
              variant={'contained'}
              sx={{
                padding: '6px 25px!important',
                width: 'unset!important',
                height: 'unset!important',
                color: '#ffffff',
                background: '#6123FF!important',
                textTransform: 'none',
                fontFamily: 'Plus Jakarta Sans'
              }}
            >
              Generate
            </LoadingButton>
          </div>
        </Box>
      </Box>
    </Drawer>
  )
}
