import React, { useContext, useEffect, useState } from 'react'
import { DashBoardSectionBlock, DashBoardSectionBlockTitle, DashBoardSectionlabel, Row, RowCenter } from '..'
import Spacer from 'src/common/components/Spacer'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis } from 'recharts'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import statsService from 'src/services/stats.service'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import { RiskiestList } from '../components/riskiestList'
import { Info } from '@mui/icons-material'
import { Drawer, Popover } from '@mui/material'
import { MODES, PeriodPicker } from '../components/periodPicker'
import { DashBoardDateContext } from 'src/App'
import TemplateView from 'src/views/TemplateView'
import { CloseDrawer } from 'src/common/icons/closeDrawer'
import { DashboardFooterLogo } from '../components/dashboardFooterLogo'

const data = [
  {
    name: '3 Apr',
    uv: 28
  },
  {
    name: '5 Apr',
    uv: 27
  },
  {
    name: '7 Apr',
    uv: 32
  },
  {
    name: '9 Apr',
    uv: 39
  },
  {
    name: '11 Apr',
    uv: 46
  },
  {
    name: '13 Apr',
    uv: 48
  },
  {
    name: 'Today',
    uv: 51
  }
]

const CustomizedLabel = props => {
  const { x, y, stroke, value } = props
  return value == '28' ? null : (
    <text x={x} y={y} dy={-8} fill={stroke} fontSize={16} textAnchor='middle'>
      {value}%
    </text>
  )
}
const CustomizedAxisTick = props => {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={20} dx={-20} textAnchor='center' fill='#666' fontSize={'12px'}>
        {payload.value}
      </text>
    </g>
  )
}

export const tabs = {
  individual: 'individual',
  departments: 'departments',
  lcoations: 'lcoations'
}

export function PhishingClickRate() {
  const [timePeriod] = useContext(DashBoardDateContext)

  const [tab, settab] = useState(tabs.individual)

  const [stats, setStats] = useState(null)
  const [loading, setLoading] = useState(false)
  const [chart, setChart] = useState(null)
  const [risks, setRisks] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const [topThemes, setTopThemes] = useState(null)
  const [frequency, setFrequency] = useState(null)
  const [timeToReport, setTimeToReport] = useState(null)

  const [openTemplateDrawer, setOpenTemplateDrawer] = useState(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState(null)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const getAllData = () => {
    const timePeriod3Month = {
      timePeriod: 'CUSTOM',
      startDate: dayjs(timePeriod.endDate).subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
      endDate: timePeriod.endDate
    }
    setLoading(true)
    Promise.all([
      statsService.getCurrentCompanySimulationStats(timePeriod),
      statsService.getSimulationDailyStats(timePeriod3Month),
      statsService.getTopRisksStats(timePeriod),

      statsService.getTopPhishingThemes(),
      statsService.getFrequency(timePeriod),
      statsService.getTimeToReport(timePeriod)
    ])
      .then(values => {
        const [
          simStatsResp,
          simulationDailyStatsResp,
          topRisksStatsResp,
          topThemesResp,
          frequencyResp,
          timeToReportResp
        ] = values
        setStats(simStatsResp.data)
        setChart(simulationDailyStatsResp.data)
        setRisks(topRisksStatsResp.data)

        setTopThemes(topThemesResp.data)
        setFrequency(frequencyResp.data)
        setTimeToReport(timeToReportResp.data)

        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (timePeriod) {
      getAllData()
    }
  }, [timePeriod])

  function getChartData(data) {
    const result = data
      .filter(el => el.phishedPercentage !== 0)
      .map(el => {
        return {
          name: dayjs(el.day).format('DD MMM'),
          fullName: el.day,
          phishing: el.phishedPercentage
        }
      })
    return result
  }

  const chartData = chart?.length ? getChartData(chart) : data

  const getComparedClickRate = () => {
    let prev = 0
    let current = 0

    chart?.forEach(element => {
      if (dayjs(element.day).month() == dayjs().month() && dayjs(element.day).year() == dayjs().year()) {
        current += element.phishedCount
      }
      if (
        dayjs(element.day).month() == dayjs().subtract(1, 'month').month() &&
        dayjs(element.day).year() == dayjs().year()
      ) {
        prev += element.phishedCount
      }
    })
    // console.log(prev, current);

    return {
      direction: prev > current ? 'down' : 'up',
      percentage: current == 0 ? 100 : prev == 0 ? 100 : ((prev - current) * 100) / prev
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const comparedClickRate = getComparedClickRate()

  const otherRisks = () => {
    let sum = 0
    const lastItems = risks.slice(3)

    lastItems.forEach(el => {
      sum += el.typePercentage
    })

    return {
      commonPercentage: sum,
      items: lastItems
    }
  }

  return (
    <div
      className='column'
      style={{
        backgroundColor: '#F8F8FB',
        height: '100vh',
        overflowY: 'auto',
        padding: '24px'
      }}
    >
      <div className='container-1030' style={{ minHeight: '100vh' }}>
        <div className='row row-center' onClick={() => navigate('/')} style={{ marginBottom: 14, cursor: 'pointer' }}>
          <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M16 8.25H1.75' stroke='#6123FF' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <path
              d='M8.5 15L1.75 8.25L8.5 1.5'
              stroke='#6123FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>

          <p style={{ color: '#6123FF', marginLeft: 8 }}>Back to Dashboard</p>
        </div>
        <div style={{ marginBottom: 24 }} className='row row-center'>
          <h1 style={{ margin: 0, fontSize: '36px' }}>
            Phishing click rate for{' '}
            {dayjs(timePeriod ? timePeriod.endDate : null).format(timePeriod?.mode == MODES.yearly ? 'YYYY' : 'MMMM')}
          </h1>
          <PeriodPicker />
          <div className='row'></div>
        </div>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <div style={{ paddingBottom: 16 }}>
            <Row style={{ marginBottom: 16 }}>
              <DashBoardSectionBlock style={{ width: '100%' }}>
                <Row
                  style={{
                    marginBottom: 46,
                    alignItems: 'flex-start'
                  }}
                >
                  <div className='column'>
                    <DashBoardSectionBlockTitle>Phishing click rate</DashBoardSectionBlockTitle>
                    {/* <DashBoardSectionBlockSubTitle>{comparedClickRate.direction == 'up' ? 'Higher' : 'Lower'} by <span className={comparedClickRate.direction == 'up' ? 'red-text' : "green-text"} style={{ margin: '0px 6px' }}>{Math.round(comparedClickRate.percentage)}%
                      <svg style={{ margin: '0px 0px 3px 5px', transform: comparedClickRate.direction == 'up' ? 'rotate(0deg)' : 'rotate(180deg)' }} width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.529236 0.196875H8.52924L4.52924 5.79688L0.529236 0.196875Z" fill={comparedClickRate.direction == 'up' ? "#ED4563" : "#00BC62"} />
                      </svg>
                    </span>compared to {dayjs(timePeriod ? timePeriod.endDate : null).subtract(1, 'month').format('MMMM')}</DashBoardSectionBlockSubTitle> */}
                  </div>
                  <Spacer />
                  {!!stats?.phishedPercentage && (
                    <DashBoardSectionlabel
                      type={stats?.phishedPercentage < 33 ? 1 : stats?.phishedPercentage > 66 ? 3 : 2}
                    ></DashBoardSectionlabel>
                  )}
                </Row>
                <ResponsiveContainer
                  style={{
                    position: 'relative',
                    overflow: 'hidden'
                  }}
                  width='100%'
                  height={250}
                >
                  <div
                    className='benchmark-sign-container'
                    style={{
                      width: '100%',
                      position: 'absolute',
                      bottom: '60%',
                      zIndex: 10
                    }}
                  >
                    <div
                      className='benchmark-sign'
                      style={{
                        opacity: '0',
                        transition: '300ms',
                        position: 'absolute',
                        background: 'rgb(229 249 251)',
                        borderRadius: 4,
                        border: '1px solid #00C8DC1A',
                        padding: '2px 6px 6px 6px',
                        left: 40,
                        zIndex: 10
                      }}
                    >
                      <span style={{ fontSize: '13px' }}>Benchmark — 60%</span>
                    </div>
                    <svg width='968' height='3' viewBox='0 0 968 3' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M0 1.16406C345.073 1.16406 257.183 3.99609 967.499 1.16406'
                        stroke='#00C8DC'
                        strokeDasharray='2 2'
                      />
                    </svg>
                  </div>
                  <AreaChart
                    data={chartData.slice(-10)}
                    margin={{
                      top: 30,
                      right: 30,
                      left: 30,
                      bottom: 0
                    }}
                  >
                    <defs>
                      <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                        <stop offset='5%' stopColor='#6123FF' stopOpacity={0.4} />
                        <stop offset='95%' stopColor='#6123FF' stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <XAxis tick={<CustomizedAxisTick />} dataKey='name' />
                    {/* <YAxis /> */}
                    <CartesianGrid horizontal={false} strokeDasharray='3 3' />
                    {/* <Tooltip /> */}
                    {/* <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" label={<CustomizedLabel />} /> */}
                    <Area
                      dot={{ r: 2 }}
                      type='monotone'
                      dataKey='phishing'
                      stroke='#6123FF'
                      strokeWidth={2}
                      fillOpacity={1}
                      fill='url(#colorUv)'
                      label={<CustomizedLabel />}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </DashBoardSectionBlock>
              <Spacer />
            </Row>

            <Row style={{ marginBottom: 16 }}>
              <DashBoardSectionBlock style={{ width: 'calc(66% - 8px)' }}>
                <Row>
                  <div className='column'>
                    <DashBoardSectionBlockTitle>Email sent</DashBoardSectionBlockTitle>
                    {/* <DashBoardSectionBlockSubTitle>Lower by <span className='green-text' style={{ margin: '0px 6px' }}>30%

                      <svg style={{ margin: '0px 0px 3px 5px' }} width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.529236 0.196875H8.52924L4.52924 5.79688L0.529236 0.196875Z" fill="#00BC62" />
                      </svg>
                    </span>
                      compared to March</DashBoardSectionBlockSubTitle> */}
                  </div>
                  <Spacer />
                </Row>

                <Row style={{ marginTop: 16 }}>
                  <div className='row' style={{ flex: 1 }}>
                    <div className='column'>
                      <div
                        style={{
                          padding: 10,
                          height: 142,
                          width: 146,
                          marginRight: 10,
                          marginBottom: 14,
                          position: 'relative',
                          overflow: 'hidden'
                        }}
                        className='column dash'
                      >
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: stats?.totalCount == 0 ? '0%' : '100%',
                            overflow: 'hidden'
                          }}
                        >
                          <svg
                            style={{ opacity: 0.7 }}
                            width='147'
                            height='142'
                            viewBox='0 0 147 142'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <g opacity='0.7'>
                              <path
                                opacity='0.1'
                                d='M-50.2656 153L194.929 -11'
                                stroke='#292929'
                                strokeWidth='12.5738'
                              />
                              <path opacity='0.1' d='M-8.66492 153L236.53 -11' stroke='#292929' strokeWidth='12.5738' />
                              <path opacity='0.1' d='M32.9258 153L278.12 -11' stroke='#292929' strokeWidth='12.5738' />
                              <path opacity='0.1' d='M74.5166 153L319.711 -11' stroke='#292929' strokeWidth='12.5738' />
                              <path opacity='0.1' d='M116.12 153L361.315 -11' stroke='#292929' strokeWidth='12.5738' />
                              <path
                                opacity='0.1'
                                d='M-215.994 153L29.2006 -11'
                                stroke='#292929'
                                strokeWidth='12.5738'
                              />
                              <path opacity='0.1' d='M-174.393 153L70.802 -11' stroke='#292929' strokeWidth='12.5738' />
                              <path
                                opacity='0.1'
                                d='M-132.802 153L112.393 -11'
                                stroke='#292929'
                                strokeWidth='12.5738'
                              />
                              <path opacity='0.1' d='M-91.21 153L153.985 -11' stroke='#292929' strokeWidth='12.5738' />
                            </g>
                          </svg>
                        </div>
                        <div className='column'>
                          <p className='secondary-text-14'>{stats?.totalCount == 0 ? '0' : '100'}%</p>
                        </div>
                        <Spacer />
                        <p
                          style={{
                            zIndex: 10,
                            fontSize: '24px',
                            fontFamily: 'Satoshi'
                          }}
                        >
                          {stats?.totalCount}
                        </p>
                      </div>
                      <p
                        style={{
                          zIndex: 10,
                          fontSize: '13px'
                        }}
                      >
                        Total sent
                      </p>
                    </div>
                    <div className='column'>
                      <div
                        style={{
                          padding: 10,
                          height: 142,
                          width: 146,
                          marginRight: 10,
                          position: 'relative',
                          marginBottom: 14
                        }}
                        className='column'
                      >
                        <div
                          style={{
                            position: 'absolute',
                            height: stats?.openedPercentage + '%',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            background: '#E0E8F7'
                          }}
                        ></div>
                        <div className='column'>
                          <p
                            className='secondary-text-14'
                            style={{
                              position: 'absolute',
                              left: 10,
                              bottom:
                                stats?.openedPercentage < 35
                                  ? '35%'
                                  : stats?.openedPercentage > 80
                                    ? '80%'
                                    : `${stats?.openedPercentage}%`
                            }}
                          >
                            {stats?.openedPercentage}%
                          </p>
                        </div>
                        <Spacer />
                        <p
                          style={{
                            zIndex: 10,
                            fontSize: '24px',
                            fontFamily: 'Satoshi'
                          }}
                        >
                          {stats?.openedCount}
                        </p>
                      </div>
                      <p
                        style={{
                          zIndex: 10,
                          fontSize: '13px'
                        }}
                      >
                        Opened
                      </p>
                    </div>
                    <div className='column'>
                      <div
                        style={{
                          padding: 10,
                          height: 142,
                          width: 146,
                          marginRight: 10,
                          position: 'relative',
                          marginBottom: 14
                        }}
                        className='column'
                      >
                        <div
                          style={{
                            position: 'absolute',
                            height: stats?.phishedPercentage + '%',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            background: '#F2D5CC'
                          }}
                        ></div>
                        <div className='column'>
                          <p
                            className='secondary-text-14'
                            style={{
                              position: 'absolute',
                              left: 10,
                              bottom:
                                stats?.phishedPercentage < 35
                                  ? '35%'
                                  : stats?.phishedPercentage > 80
                                    ? '80%'
                                    : `${stats?.phishedPercentage}%`
                            }}
                          >
                            {stats?.phishedPercentage}%
                          </p>
                        </div>
                        <Spacer />
                        <p
                          style={{
                            zIndex: 10,
                            fontSize: '24px',
                            fontFamily: 'Satoshi'
                          }}
                        >
                          {stats?.phishedCount}
                        </p>
                      </div>
                      <p
                        style={{
                          zIndex: 10,
                          fontSize: '13px'
                        }}
                      >
                        Phished
                      </p>
                    </div>

                    <div className='column'>
                      <div
                        style={{
                          padding: 10,
                          height: 142,
                          width: 146,
                          marginRight: 10,
                          position: 'relative',
                          marginBottom: 14
                        }}
                        className='column'
                      >
                        <div
                          style={{
                            position: 'absolute',
                            height: stats?.reportedPercentage + '%',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            background: '#01BD6325'
                          }}
                        ></div>
                        <div className='column'>
                          <p
                            className='secondary-text-14'
                            style={{
                              position: 'absolute',
                              left: 10,
                              bottom:
                                stats?.reportedPercentage < 35
                                  ? '35%'
                                  : stats?.reportedPercentage > 80
                                    ? '80%'
                                    : `${stats?.reportedPercentage}%`
                            }}
                          >
                            {stats?.reportedPercentage}%
                          </p>
                        </div>
                        <Spacer />
                        <p
                          style={{
                            zIndex: 10,
                            fontSize: '24px',
                            fontFamily: 'Satoshi'
                          }}
                        >
                          {stats?.reportedCount}
                        </p>
                        {stats?.totalCount !== 0 && (
                          <div
                            style={{
                              position: 'absolute',
                              bottom: '60%',
                              left: 0
                            }}
                            className='column column-center'
                          >
                            <p
                              style={{
                                fontSize: '14px',
                                fontWeight: 600,
                                marginBottom: '10px'
                              }}
                            >
                              Benchmark — {Math.round(stats?.totalCount * 0.6)}
                            </p>
                            <svg
                              width='147'
                              height='1'
                              viewBox='0 0 147 1'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <line x1='147' y1='0.5' x2='0.764694' y2='0.5' stroke='#00C8DC' strokeDasharray='2 2' />
                            </svg>
                          </div>
                        )}
                      </div>
                      <p
                        style={{
                          zIndex: 10,
                          fontSize: '13px'
                        }}
                      >
                        Reported
                      </p>
                    </div>
                  </div>
                </Row>
              </DashBoardSectionBlock>

              <Spacer />
              <DashBoardSectionBlock style={{ width: 'calc(33% - 4px)' }}>
                <Row>
                  <div className='column'>
                    <DashBoardSectionBlockTitle style={{ marginBottom: 20 }}>
                      Top phishing themes
                    </DashBoardSectionBlockTitle>
                  </div>
                </Row>
                {topThemes?.length ? (
                  topThemes.slice(0, 3)?.map((el, i) => (
                    <div
                      onClick={() => {
                        setOpenTemplateDrawer(true)
                        setSelectedTemplateId(el.templateId)
                      }}
                      className='row top-themes-element'
                      key={el.templateId}
                    >
                      <div className='column column-center' style={{ marginRight: 12 }}>
                        {i !== 0 && <div style={{ width: 1, height: 5, background: '#C7EFF4' }}></div>}
                        <div
                          style={{
                            height: 8,
                            width: 8,
                            borderRadius: 8,
                            background: '#C7EFF4',
                            marginTop: i !== 0 ? 0 : 5
                          }}
                        ></div>
                        <div style={{ width: 1, flex: 1, background: '#C7EFF4' }}></div>
                      </div>
                      <p className='secondary-text-14' style={{ fontWeight: 600, marginTop: 1, marginRight: 8 }}>
                        0{i + 1}
                      </p>
                      <p style={{ marginBottom: 12, width: '100%' }} className='text-shrink-2'>
                        {el?.subject}
                      </p>
                    </div>
                  ))
                ) : (
                  <p style={{ color: '#666' }}>No data for this time period</p>
                )}
              </DashBoardSectionBlock>
            </Row>

            <Row>
              <div className='column' style={{ width: 'calc(66% - 4px)' }}>
                <DashBoardSectionBlock style={{ marginBottom: 16 }}>
                  <Row style={{ height: '100%', alignItems: 'center' }}>
                    <div className='column' style={{ flex: 1 }}>
                      <p style={{ marginBottom: 12 }}>Simulation Frequency</p>
                      <RowCenter>
                        <svg
                          style={{ marginRight: 8 }}
                          width='32'
                          height='32'
                          viewBox='0 0 32 32'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z'
                            stroke='#8D8D94'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M9.63404 22.3607C8.79739 21.5255 8.13365 20.5335 7.68078 19.4415C7.22791 18.3495 6.99481 17.179 6.99481 15.9968C6.99481 14.8146 7.22791 13.644 7.68078 12.552C8.13365 11.46 8.79739 10.468 9.63404 9.63281'
                            stroke='#8D8D94'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M22.3646 9.63281C23.2012 10.468 23.8649 11.46 24.3178 12.552C24.7707 13.644 25.0038 14.8146 25.0038 15.9968C25.0038 17.179 24.7707 18.3495 24.3178 19.4415C23.8649 20.5335 23.2012 21.5255 22.3646 22.3607'
                            stroke='#8D8D94'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M6.10027 25.9006C4.79883 24.6013 3.76634 23.0582 3.06188 21.3596C2.35742 19.6609 1.99481 17.84 1.99481 16.0011C1.99481 14.1621 2.35742 12.3412 3.06188 10.6425C3.76634 8.94387 4.79883 7.40078 6.10027 6.10156'
                            stroke='#8D8D94'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M25.9011 6.10156C27.2025 7.40078 28.235 8.94388 28.9395 10.6425C29.6439 12.3412 30.0065 14.1621 30.0065 16.0011C30.0065 17.84 29.6439 19.6609 28.9395 21.3596C28.235 23.0582 27.2025 24.6013 25.9011 25.9006'
                            stroke='#8D8D94'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <p className='sato-32'>{frequency?.frequency}/month</p>
                      </RowCenter>
                    </div>
                    <div style={{ height: '100%', width: 1, background: '#E7E8EA', margin: '0px 32px' }}></div>
                    <div className='column' style={{ flex: 1 }}>
                      <p style={{ marginBottom: 12 }}>Time to Report</p>
                      <RowCenter>
                        <svg
                          style={{ marginRight: 8 }}
                          width='32'
                          height='32'
                          viewBox='0 0 32 32'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27Z'
                            stroke='#8D8D94'
                            strokeWidth='1.5'
                            strokeMiterlimit='10'
                          />
                          <path
                            d='M16 16.0044L20.9497 11.0547'
                            stroke='#8D8D94'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M13 1H19'
                            stroke='#8D8D94'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <p className='sato-32'>
                          {Math.floor((timeToReport?.minutesToReport || 0) / 60)}h{' '}
                          {(timeToReport?.minutesToReport || 0) % 60}min
                        </p>
                      </RowCenter>
                    </div>
                  </Row>
                </DashBoardSectionBlock>
                <RiskiestList tab={tab} settab={settab} />
              </div>
              <Spacer />
              <DashBoardSectionBlock style={{ width: 'calc(33% - 4px)' }}>
                <DashBoardSectionBlockTitle>TOP 3 Risks</DashBoardSectionBlockTitle>
                <p className='secondary-text-16'>Compared to {dayjs().subtract(1, 'month').format('MMMM')}</p>

                <div
                  style={{
                    height: 400,
                    borderRadius: 12,
                    overflow: 'hidden',
                    marginTop: 20
                  }}
                  className='column'
                >
                  {risks?.length == 0 && (
                    <div className='column column-center'>
                      <p style={{ marginTop: 160 }} className='secondary-text-16'>
                        No data yet
                      </p>
                    </div>
                  )}
                  {risks?.slice(0, 3).map((el, i) => (
                    <div
                      key={el.type}
                      className='column'
                      style={{
                        height: el.typePercentage + '%',
                        minHeight: el.typePercentage == 0 ? '15%' : el.typePercentage < 11 ? '18%' : '24%',
                        background: ['#F8F2E2', '#E2F7F8', '#FAEAF5', '#BFBFBF26'][i],
                        padding: '16px 36px',
                        borderBottom: '1px solid #fff'
                      }}
                    >
                      <Spacer />
                      <Row
                        style={{
                          alignItems: 'flex-end'
                        }}
                      >
                        <p
                          style={{
                            fontWeight: '500'
                          }}
                        >
                          {el.type}
                        </p>
                        <Spacer />
                        <p style={{ fontSize: 20 }}>
                          {el.typePercentage}
                          <span style={{ fontSize: 16 }}>%</span>
                        </p>
                      </Row>
                    </div>
                  ))}
                  {risks?.length > 3 && (
                    <div
                      className='column'
                      style={{
                        height: '18%',
                        background: '#BFBFBF26',
                        padding: '16px 36px',
                        borderBottom: '1px solid #fff'
                      }}
                    >
                      <Spacer />
                      <Row
                        style={{
                          alignItems: 'flex-end'
                        }}
                      >
                        <p
                          style={{
                            fontWeight: '500'
                          }}
                        >
                          Other
                        </p>
                        <Info
                          color='#1B1B26'
                          fontSize='16px'
                          sx={{
                            ml: '10px',
                            cursor: 'pointer'
                          }}
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                        />

                        <Popover
                          id='mouse-over-popover'
                          sx={{
                            pointerEvents: 'none'
                          }}
                          open={open}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                          }}
                          transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          <div
                            style={{
                              background: '#1B1B26',
                              color: '#fff',
                              padding: '12px 16px'
                            }}
                            className='column'
                          >
                            {otherRisks().items.map(el => (
                              <div key={el.type}>
                                {el.typePercentage}%{' '}
                                <span
                                  style={{
                                    textTransform: 'uppercase',
                                    opacity: 0.7,
                                    fontSize: '14px',
                                    marginLeft: 6
                                  }}
                                >
                                  {' '}
                                  {el.type}
                                </span>
                              </div>
                            ))}
                          </div>
                        </Popover>
                        <Spacer />
                        <p style={{ fontSize: 20 }}>
                          {otherRisks().commonPercentage}
                          <span style={{ fontSize: 16 }}>%</span>
                        </p>
                      </Row>
                    </div>
                  )}
                </div>
              </DashBoardSectionBlock>
            </Row>
          </div>
        )}
        <DashboardFooterLogo />
      </div>
      <Drawer anchor='right' open={openTemplateDrawer} onClose={() => setOpenTemplateDrawer(false)}>
        <div
          style={{ position: 'fixed', top: '24px', right: '1182px', cursor: 'pointer' }}
          onClick={() => setOpenTemplateDrawer(false)}
        >
          <CloseDrawer />
        </div>
        <TemplateView id={selectedTemplateId} />
      </Drawer>
    </div>
  )
}
