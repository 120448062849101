import React, { useState, useEffect } from 'react'
import { Button, TextField, Box, Drawer, Typography, Slide } from '@mui/material'
import PlusIcon from '../../../../common/icons/plusIcon'

export default function ImageDrawer({ open, onClose, onInsert, container }) {
  const [imageUrl, setImageUrl] = useState('')
  const [imageWidth, setImageWidth] = useState('')
  const [dialogStyles, setDialogStyles] = useState({})

  function handleReset() {
    setImageUrl('')
    setImageWidth('')
  }
  const handleInsert = () => {
    onInsert(imageUrl, imageWidth)
    handleReset()
    onClose()
  }

  useEffect(() => {
    if (open && container) {
      const rect = container.getBoundingClientRect()

      setDialogStyles({
        position: 'absolute',
        top: rect.bottom - 297,
        width: rect.width,
        left: rect.left,
        margin: 0,
        borderRadius: '0 0 24px 24px'
      })
    }
  }, [open, container, top])

  return (
    <Drawer
      open={open}
      onClose={() => {
        handleReset()
        onClose()
      }}
      PaperProps={{
        style: {
          ...dialogStyles,
          height: '297px',
          boxShadow: 'none'
        }
      }}
      TransitionComponent={Slide}
      transitionDuration={500}
    >
      <Box p={2} display='flex' flexDirection='column' gap={2}>
        <Typography fontFamily={'Satoshi'} fontWeight={500} fontSize={'24px'}>
          Choose image
        </Typography>
        <TextField label='Image URL' value={imageUrl} onChange={e => setImageUrl(e.target.value)} fullWidth />
        <TextField
          sx={{ width: 270 }}
          label='Width (px)'
          value={imageWidth}
          onChange={e => setImageWidth(e.target.value)}
          fullWidth
        />
        <Box display='flex' width={'100%'} justifyContent={'flex-end'}>
          <Button
            disabled={!imageUrl}
            variant='contained'
            onClick={handleInsert}
            startIcon={<PlusIcon color={'#ffffff'} />}
            sx={{
              width: 181,
              background: '#6123FF',
              borderRadius: '12px',
              color: '#fff',
              padding: '8px 16px',
              fontFamily: 'Plus Jakarta Sans',
              fontSize: '16px',
              fontWeight: 500,
              textTransform: 'none'
            }}
          >
            Add Image
          </Button>
        </Box>
      </Box>
    </Drawer>
  )
}
